export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? 'T. Conversão (NUs)' : 'Conv. Rate (NUs)',
    tip: language === 'pt-BR'
      ? 'Transações / Novos Usuários'
      : 'Transactions / New Users',

  };
}
