export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    date: language === 'pt-BR' ? 'Datas' : 'Dates',
    apply: language === 'pt-BR' ? 'Aplicar' : 'Apply',
    currentLabel: language === 'pt-BR' ? 'Per.Atual' : 'Curr.Period',
    prevLabel: language === 'pt-BR' ? 'Per.Ant.' : 'Prev.Period',
  };
}
