import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 8px;
  }
`;

export const FavoriteWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  div {
    margin: 0;
  }

  .infos {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-bottom: 16px;
    }
    .arrow{
      color: ${(props) => props.theme.yellow};
    }
    .category-title{
      font-style: italic;
      color: ${(props) => props.theme.grayLight}
    }
  }

  .links {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }

    button {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
      }
    }
  }
`;
