export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Lista de Categorias' : 'Category\'s List',
    search: language === 'pt-BR' ? 'Pesquisar' : 'Search',
    placeholderSearchTerm: language === 'pt-BR' ? 'Nome ou produto' : 'Name or product',
    addButton: language === 'pt-BR' ? 'Adicionar Novo' : 'Add New',
    textSubCategory: language === 'pt-BR' ? 'Tem SubCategoria?' : 'Has SubCategory?',
    categoryInfo: language === 'pt-BR' ? 'Sem Categorias para listar' : 'No Categories to list',
    btnUpdateOrdering: language === 'pt-BR' ? 'Atualizar Ordem' : 'Update Ordering',
    language,
  };
}
