import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import SubCategoryProductsService from '../../../../../services/SubCategoryProduct';
import TitlePage from '../../../../../components/TitlePage';
import Loader from '../../../../../components/Loader';
import { Button } from '../../../../../components/Button';
import { NoDataMessage } from '../../../../../components/NoDataMessage';
import toast from '../../../../../helpers/toast';
import { Translate } from './translate';
import { Container, ListContainer, AreaDragDrop } from './style';
import { SubCategoryItem } from './SubCategoryItem';

function SubCategoriesByCategory() {
  const translate = Translate();
  const { id } = useParams();
  const location = useLocation();
  const nameCategoryParam = new URLSearchParams(location.search).get('name');
  const [subCategories, setSubCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [btnOrderingIsDisabled, setBtnOrderingIsDisabled] = useState(false);

  useEffect(() => {
    getSubcategories();
  }, []);

  async function getSubcategories() {
    try {
      setIsLoading(true);
      const subCategoriesProduct = await SubCategoryProductsService.findAll(id, 'category');
      setSubCategories(subCategoriesProduct);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteSubcategory(id) {
    try {
      await SubCategoryProductsService.delete(id);
      toast('success', 'Subcategory deleted');
      getSubcategories();
    } catch (error) {
      toast('danger', error.message);
    }
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const newList = reorder(subCategories, startIndex, endIndex);
    setSubCategories(newList);
  }

  async function updateOrdering() {
    try {
      setBtnOrderingIsDisabled(true);
      await SubCategoryProductsService.updateOrdering(subCategories);
      toast('success', 'Ordering updated');
      getSubcategories();
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setBtnOrderingIsDisabled(false);
    }
  }

  return (
    <>
      <TitlePage title={`${translate.title} | (${translate.titleCategory}: ${nameCategoryParam})`}>
        <Button color="blue" onClick={updateOrdering} disabled={btnOrderingIsDisabled}>{btnOrderingIsDisabled ? 'Loading Ordering' : translate.btnUpdateOrdering}</Button>
      </TitlePage>
      <Container>
        <ListContainer>
          <Loader loading={isLoading} />
          {!isLoading && (
            subCategories.length === 0 ? <NoDataMessage>{translate.subCategoryInfo}</NoDataMessage>
              : subCategories && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="subCategoryDragDrop" direction="vertical" type="list">
                  {(provided) => (
                    <AreaDragDrop
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {subCategories.map((subCategoryProduct, index) => (
                        <SubCategoryItem
                          key={subCategoryProduct.id}
                          subCategory={subCategoryProduct}
                          deleteSubcategory={deleteSubcategory}
                          index={index}
                        />
                      ))}
                      {provided.placeholder}
                    </AreaDragDrop>
                  )}
                </Droppable>
              </DragDropContext>
              )
          )}
        </ListContainer>
      </Container>
    </>
  );
}

export default SubCategoriesByCategory;
