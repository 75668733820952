export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Grupos de Acesso' : 'Access Group',
    yes: language === 'pt-BR' ? 'Sim' : 'Yes',
    no: language === 'pt-BR' ? 'Não' : 'No',
    labelButtonAddNew: language === 'pt-BR' ? 'Adicionar novo' : 'Add new',
    errorMsg: language === 'pt-BR' ? 'Grupos de acessos não cadastrados' : 'Access Group not inserted',
    search: language === 'pt-BR' ? 'Pesquisa' : 'Search',
    placeholderSearch: language === 'pt-BR' ? 'Nome' : 'Name',
  };
}
