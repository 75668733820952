import styled from 'styled-components';

export const URLContainer = styled.div`
  margin-bottom: 32px;

  .url-label {
    margin: 0;
    margin-bottom: 8px;
  }

  .url-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      flex-direction: column;
      height: auto;
      align-items: flex-start;
    }

    select {
      width: 100px;

      @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: 100%;
      }
    }

    .url-input-container {
      flex: 1;
      width: 100%;
      height: 12px;

      input {
        width: 100%;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        width: 100%;
        padding: ${(props) => props.theme.sizes.s8};
      }

      .url-result-container {
        margin-top: 4px;
        font-size: 12px;

        .url-url {
          color: ${({ theme }) => theme.yellow}
        }

        .url-format {
          color: ${({ theme }) => theme.grayLight}
        }


      }
    }

  }
`;
