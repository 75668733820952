import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-top: 16px;

  a {
    width: 100%;
    text-align: center;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AreaDragDrop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SquareContainer = styled.div`
  background-color: ${({ theme }) => theme.grayMenu};
    padding: 24px;
    border-radius: 8px;
    font-size: 16px;
    font-size:17px ;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  img:hover {
    cursor: pointer;
    filter: invert(40%) sepia(6%) saturate(233%) hue-rotate(187deg) brightness(95%) contrast(84%);
  }

  .c-square {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-bottom: 16px;
    }
  }

  .c-square-name {
    width: 250px;
    display: flex;
    align-items: center;
    gap: 8px;

    .c-icon-drag {
      cursor: grab;

      &:hover {
        filter: none;
      }
    }
  }

  .c-square-action {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      align-self: flex-end;
    }

    a {
      width: 20px;
      height: 20px;
    }

    img {
      width: 20px;
      height: 20px;
    }

    img:hover {
      cursor: pointer;
      filter: invert(40%) sepia(6%) saturate(233%) hue-rotate(187deg) brightness(95%) contrast(84%)
    }

    input {
      width: 40px !important;
      border: 1px solid ${({ theme }) => theme.grayLight};
    }
  }
`;
