import { Translate } from './translate';

export function ListKpis() {
  const { isEcommerce } = JSON.parse(localStorage.getItem('clientInfo'));
  const translate = Translate();

  return isEcommerce === 'Y'
    ? [
      {
        id: 1,
        name: translate.language === 'pt-BR' ? 'Receita' : 'Revenue',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 2,
        name: translate.language === 'pt-BR' ? 'Investimento Perf' : 'Investiment Perf',
        infos: {
          level: '1st & 2nd',
          source: 'APIs/GA',
        },
      },
      {
        id: 3,
        name: 'ROAS',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Receita / Investimento Perf' : 'Revenue / Investment Perf',
        },
      },
      {
        id: 4,
        name: translate.language === 'pt-BR' ? 'TM' : 'AOV',
        infos: {
          level: '2nd',
          source: translate.language === 'pt-BR' ? 'Receita / Transações' : 'Revenue / Transactions',
        },
      },
      {
        id: 5,
        name: translate.language === 'pt-BR' ? 'Impressões' : 'Impressions',
        infos: {
          level: '1st & 2nd',
          source: 'APIs/GA',
        },
      },
      {
        id: 6,
        name: 'CPM',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Investimento Perf / (Impressões / 1000)' : 'Investment Perf / (Impressions / 1000)',
        },
      },
      {
        id: 7,
        name: 'CTR',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Cliques / Impressões' : 'Clicks / Impressions',
        },
      },
      {
        id: 8,
        name: 'CPC',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Investimento Perf / Cliques' : 'Investment Perf / Clicks',
        },
      },
      {
        id: 9,
        name: translate.language === 'pt-BR' ? 'Cliques' : 'Clicks',
        infos: {
          level: '1st & 2nd',
          source: 'APIs/GA',
        },
      },
      {
        id: 10,
        name: 'Clicks Ratio',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Usuários / Cliques' : 'Users / Clicks',
        },
      },
      {
        id: 11,
        name: translate.language === 'pt-BR' ? 'CPC Estimado' : 'Estimated CPC',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Investimento Perf / Sessões' : 'Investment Perf / Sessions',
        },
      },
      {
        id: 12,
        name: translate.language === 'pt-BR' ? 'Sessões' : 'Sessions',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 13,
        name: translate.language === 'pt-BR' ? 'Sessões Engajadas' : 'Engaged Sessions',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 14,
        name: translate.language === 'pt-BR' ? 'Usuários' : 'Users',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 15,
        name: translate.language === 'pt-BR' ? 'Novos Usuários' : 'New Users',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 16,
        name: translate.language === 'pt-BR' ? 'Transações' : 'Transactions',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 17,
        name: translate.language === 'pt-BR' ? 'T. Conversão (S)' : 'Conv. Rate (S)',
        infos: {
          level: '2nd',
          source: translate.language === 'pt-BR' ? 'Transações / Sessões' : 'Transactions / Sessions',
        },
      },
      {
        id: 18,
        name: translate.language === 'pt-BR' ? 'T. Conversão (Us)' : 'Conv. Rate (Us)',
        infos: {
          level: '2nd',
          source: translate.language === 'pt-BR' ? 'Transações / Usuários' : 'Transactions / Users',
        },
      },
      {
        id: 19,
        name: translate.language === 'pt-BR' ? 'T. Conversão (NUs)' : 'Conv. Rate (NUs)',
        infos: {
          level: '2nd',
          source: translate.language === 'pt-BR' ? 'Transações / Novos Usuários' : 'Transactions / New Users',
        },
      },
      {
        id: 20,
        name: translate.language === 'pt-BR' ? 'Eventos' : 'Events',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 21,
        name: translate.language === 'pt-BR' ? 'CPL Eventos' : 'CPL Events',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Investimento Perf / Eventos' : 'Investment Perf / Events',
        },
      },
      {
        id: 22,
        name: translate.language === 'pt-BR' ? 'T. Conversão Eventos (S)' : 'Conv. Rate Events (S)',
        infos: {
          level: '2nd',
          source: translate.language === 'pt-BR' ? 'Eventos / Sessões' : 'Events / Sessions',
        },
      },
      {
        id: 23,
        name: translate.language === 'pt-BR' ? 'T. Conversão Eventos (Us)' : 'Conv. Rate Events (Us)',
        infos: {
          level: '2nd',
          source: translate.language === 'pt-BR' ? 'Eventos / Usuários' : 'Events / Users',
        },
      },
      {
        id: 24,
        name: translate.language === 'pt-BR' ? 'T. Conversão Eventos (NUs)' : 'Conv. Rate Events (NUs)',
        infos: {
          level: '2nd',
          source: translate.language === 'pt-BR' ? 'Eventos / Novos Usuários' : 'Events / New Users',
        },
      },
    ]
    : [
      {
        id: 2,
        name: translate.language === 'pt-BR' ? 'Investimento Perf' : 'Investiment Perf',
        infos: {
          level: '1st & 2nd',
          source: 'APIs/GA',
        },
      },
      {
        id: 5,
        name: translate.language === 'pt-BR' ? 'Impressões' : 'Impressions',
        infos: {
          level: '1st & 2nd',
          source: 'APIs/GA',
        },
      },
      {
        id: 6,
        name: 'CPM',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Investimento Perf / (Impressões / 1000)' : 'Investment Perf / (Impressions / 1000)',
        },
      },
      {
        id: 7,
        name: 'CTR',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Cliques / Impressões' : 'Clicks / Impressions',
        },
      },
      {
        id: 8,
        name: 'CPC',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Investimento Perf / Cliques' : 'Investment Perf / Clicks',
        },
      },
      {
        id: 9,
        name: translate.language === 'pt-BR' ? 'Cliques' : 'Clicks',
        infos: {
          level: '1st & 2nd',
          source: 'APIs/GA',
        },
      },
      {
        id: 10,
        name: 'Clicks Ratio',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Usuários / Cliques' : 'Users / Clicks',
        },
      },
      {
        id: 11,
        name: translate.language === 'pt-BR' ? 'CPC Estimado' : 'Estimated CPC',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Investimento Perf / Sessões' : 'Investment Perf / Sessions',
        },
      },
      {
        id: 12,
        name: translate.language === 'pt-BR' ? 'Sessões' : 'Sessions',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 13,
        name: translate.language === 'pt-BR' ? 'Sessões Engajadas' : 'Engaged Sessions',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 14,
        name: translate.language === 'pt-BR' ? 'Usuários' : 'Users',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 15,
        name: translate.language === 'pt-BR' ? 'Novos Usuários' : 'New Users',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 20,
        name: translate.language === 'pt-BR' ? 'Eventos' : 'Events',
        infos: {
          level: '2nd',
          source: 'GA',
        },
      },
      {
        id: 21,
        name: translate.language === 'pt-BR' ? 'CPL Eventos' : 'CPL Events',
        infos: {
          level: '1st & 2nd',
          source: translate.language === 'pt-BR' ? 'Investimento Perf / Eventos' : 'Investment Perf / Events',
        },
      },
      {
        id: 22,
        name: translate.language === 'pt-BR' ? 'T. Conversão Eventos (S)' : 'Conv. Rate Events (S)',
        infos: {
          level: '2nd',
          source: translate.language === 'pt-BR' ? 'Eventos / Sessões' : 'Events / Sessions',
        },
      },
      {
        id: 23,
        name: translate.language === 'pt-BR' ? 'T. Conversão Eventos (Us)' : 'Conv. Rate Events (Us)',
        infos: {
          level: '2nd',
          source: translate.language === 'pt-BR' ? 'Eventos / Usuários' : 'Events / Users',
        },
      },
      {
        id: 24,
        name: translate.language === 'pt-BR' ? 'T. Conversão Eventos (NUs)' : 'Conv. Rate Events (NUs)',
        infos: {
          level: '2nd',
          source: translate.language === 'pt-BR' ? 'Eventos / Novos Usuários' : 'Events / New Users',
        },
      },
    ];
}
