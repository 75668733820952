import { styled } from 'styled-components';

export const VideoContainer = styled.div`
  flex: 4;
  padding: 16px;
  min-height: 100vh;
  width: 70%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
    width: 100%;
  }

  .head {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .video-actions-container {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    height: 64px;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
      width: 100%;
      flex-direction: column;
      height: initial;
    }

    /* Rattings */
    div {
      /* width: 100%; */
      flex: 5;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
        order: 2;
        width: 100%;
      }
    }

    /* Btn mark as complete */
    button {
      flex: 1;
      height: 100%;
      border-radius: 8px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
        order: 1;
      }
    }

    p {
      padding: 10px 0px;
    }
  }`;

export const TextContainer = styled.div`
  margin-top: 16px;
  padding: 20px;
  background-color: ${({ theme }) => theme.grayMenu};
  border-radius: 8px;
  width: 100%;

  h3 {
    margin-bottom: 16px;
  }
`;
