export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Clientes' : 'Clients',
    yes: language === 'pt-BR' ? 'Sim' : 'Yes',
    no: language === 'pt-BR' ? 'Não' : 'No',
    labelButtonAddNew: language === 'pt-BR' ? 'Adicionar cliente' : 'Add client',
    errorMsg: language === 'pt-BR' ? 'Não foi possível carregar os clients' : 'Clients list unavailable',
    search: language === 'pt-BR' ? 'Pesquisa' : 'Search',
    placeholderSearch: language === 'pt-BR' ? 'ID do client ou nome' : 'Client\'s ID or client\'s name',
    typeClient: language === 'pt-BR' ? 'Perfil do Cliente' : 'Client\'s Profile',
    currency: language === 'pt-BR' ? 'Moeda' : 'Currency',
  };
}
