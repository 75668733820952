class VideosLikeService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async store({ idVideo }) {
    const path = `${this.baseUrl}/video-like`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({ idVideo }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async delete({ idVideo }) {
    const path = `${this.baseUrl}/video-like/${idVideo}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'DELETE',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }
}

export default new VideosLikeService();
