import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import UTMService from '../../../../../services/UTM';
import Loader from '../../../../../components/Loader';
import { Translate } from './translate';
import { FormItem } from './styles';

function States({
  value, setValue, countryId, stateId,
}) {
  const translate = Translate();
  const [statesFromDB, setStatesFromDB] = useState([]);
  const [isLoading] = useState(false);

  useEffect(() => {
    if (countryId) {
      loadListOfState();
      if (!stateId && !value) {
        setValue((prevState) => ({ ...prevState, state: '', stateId: null }));
      }
    }

    return () => {
      setStatesFromDB([]);
    };
  }, [countryId]);

  async function loadListOfState() {
    const states = await UTMService.getStates(countryId);
    if (states.length === 0) {
      setStatesFromDB([]);
    } else {
      setStatesFromDB(states);
    }
  }

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, state: event.target.value }));
    const [state] = statesFromDB?.filter((state) => state.value === event.target.value && Number(state.countryID) === Number(countryId));
    if (state) {
      setValue((prevState) => ({ ...prevState, stateId: state.id, state: state.value }));
    }
  }

  return (
    <FormItem>
      <label htmlFor="state">{translate.label}</label>
      <select value={value} onChange={handleSelect} name="state" disabled={isLoading}>
        <option className="noneParam" value="">None</option>
        {statesFromDB && statesFromDB.map((item) => (
          <option key={item.id} value={item.value}>{item.name}</option>
        ))}
      </select>
      { isLoading && <Loader loading={isLoading} tofield /> }
    </FormItem>
  );
}

States.defaultProps = {
  countryId: null,
};

States.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  countryId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
};

export default States;
