import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { FilterContext } from '../Filters';
import Dates_New from '../../../../../helpers/Dates_New';
import { DateContext } from '../../../../../context/DateContext';
import GoogleAnalitycs from '../../../../../services/GoogleAnalitycs';
import GoogleAnalitycsOthersPlatformsService from '../../../../../services/GoogleAnalitycsOthersPlatforms';
import VtexService from '../../../../../services/Vtex';
import LeadsGoogleAnalitycsService from '../../../../../services/LeadsGoogleAnalitycs';
import GoogleAdwords from '../../../../../services/GoogleAdwords';
import MetaAds from '../../../../../services/MetaAds';
import LinkedInAds from '../../../../../services/LinkedInAds';

const DataContext = createContext();

const DataProvider = React.memo(({ children }) => {
  const { infoDateSelected, setDisableSelectDates } = useContext(DateContext);
  const datesCompletePreviousMonth = Dates_New.getInfoDateCompletePreviousMonth();
  const datesForecast = Dates_New.getInfoDateForecast();
  const {
    accountName,
    accountNameApplied,
    platformApplied,
    metaIdApplied,
    regionApplied,
    strategyApplied,
    channelApplied,
    utmCampaignApplied,
    leadType,
    statusVtexApplied,
    originVtexApplied,
  } = useContext(FilterContext);

  // Google Analytics
  const [googleAnalyticsCurrent, setGoogleAnalyticsCurrent] = useState({});
  const [googleAnalyticsPrevious, setGoogleAnalyticsPrevious] = useState({});
  const [googleAnalyticsYoY, setGoogleAnalyticsYoY] = useState({});
  const [googleAnalyticsPreviousComplete, setGoogleAnalyticsPreviousComplete] = useState({});
  const [googleAnalyticsForecast, setGoogleAnalyticsForecast] = useState({});

  // Google Analytics Funnel
  const [googleAnalyticsByFunnelCurrent, setGoogleAnalyticsByFunnelCurrent] = useState([]);
  const [googleAnalyticsByFunnelPrevious, setGoogleAnalyticsByFunnelPrevious] = useState([]);
  const [googleAnalyticsByFunnelYoY, setGoogleAnalyticsByFunnelYoY] = useState([]);
  const [googleAnalyticsByFunnelPreviousComplete, setGoogleAnalyticsByFunnelPreviousComplete] = useState([]);
  const [googleAnalyticsByFunnelForecast, setGoogleAnalyticsByFunnelForecast] = useState([]);

  // Leads GoogleAnalytics
  const [leadsGoogleAnalyticsCurrent, setLeadsGoogleAnalyticsCurrent] = useState({});
  const [leadsGoogleAnalyticsPrevious, setLeadsGoogleAnalyticsPrevious] = useState({});
  const [leadsGoogleAnalyticsYoY, setLeadsGoogleAnalyticsYoY] = useState({});
  const [leadsGoogleAnalyticsPreviousComplete, setLeadsGoogleAnalyticsPreviousComplete] = useState({});
  const [leadsGoogleAnalyticsForecast, setLeadsGoogleAnalyticsForecast] = useState({});

  // Leads GoogleAnalytics By Funnel
  const [leadsGoogleAnalyticsByFunnelCurrent, setLeadsGoogleAnalyticsByFunnelCurrent] = useState({});
  const [leadsGoogleAnalyticsByFunnelPrevious, setLeadsGoogleAnalyticsByFunnelPrevious] = useState({});
  const [leadsGoogleAnalyticsByFunnelYoY, setLeadsGoogleAnalyticsByFunnelYoY] = useState({});
  const [leadsGoogleAnalyticsByFunnelPreviousComplete, setLeadsGoogleAnalyticsByFunnelPreviousComplete] = useState({});
  const [leadsGoogleAnalyticsByFunnelForecast, setLeadsGoogleAnalyticsByFunnelForecast] = useState({});

  // Google Analytics Others Platforms
  const [googleAnalyticsOthersPlatformsCurrent, setGoogleAnalyticsOthersPlatformsCurrent] = useState({});
  const [googleAnalyticsOthersPlatformsPrevious, setGoogleAnalyticsOthersPlatformsPrevious] = useState({});
  const [googleAnalyticsOthersPlatformsYoY, setGoogleAnalyticsOthersPlatformsYoY] = useState({});
  const [googleAnalyticsOthersPlatformsPreviousComplete, setGoogleAnalyticsOthersPlatformsPreviousComplete] = useState({});
  const [googleAnalyticsOthersPlatformsForecast, setGoogleAnalyticsOthersPlatformsForecast] = useState({});

  // Google Analytics Others Platforms
  const [googleAnalyticsOthersPlatformsByFunnelCurrent, setGoogleAnalyticsOthersPlatformsByFunnelCurrent] = useState({});
  const [googleAnalyticsOthersPlatformsByFunnelPrevious, setGoogleAnalyticsOthersPlatformsByFunnelPrevious] = useState({});
  const [googleAnalyticsOthersPlatformsByFunnelYoY, setGoogleAnalyticsOthersPlatformsByFunnelYoY] = useState({});
  const [googleAnalyticsOthersPlatformsByFunnelPreviousComplete, setGoogleAnalyticsOthersPlatformsByFunnelPreviousComplete] = useState({});
  const [googleAnalyticsOthersPlatformsByFunnelForecast, setGoogleAnalyticsOthersPlatformsByFunnelForecast] = useState({});

  // Google Adwords
  const [googleAdwordsCurrent, setGoogleAdwordsCurrent] = useState({});
  const [googleAdwordsPrevious, setGoogleAdwordsPrevious] = useState({});
  const [googleAdwordsYoY, setGoogleAdwordsYoY] = useState({});
  const [googleAdwordsPreviousComplete, setGoogleAdwordsPreviousComplete] = useState({});
  const [googleAdwordsForecast, setGoogleAdwordsForecast] = useState({});

  // Google Adwords By Funnel
  const [googleAdwordsByFunnelCurrent, setGoogleAdwordsByFunnelCurrent] = useState({});
  const [googleAdwordsByFunnelPrevious, setGoogleAdwordsByFunnelPrevious] = useState({});
  const [googleAdwordsByFunnelYoY, setGoogleAdwordsByFunnelYoY] = useState({});
  const [googleAdwordsByFunnelPreviousComplete, setGoogleAdwordsByFunnelComplete] = useState({});
  const [googleAdwordsByFunnelForecast, setGoogleAdwordsByFunnelForecast] = useState({});

  // Meta Ads
  const [metaAdsCurrent, setMetaAdsCurrent] = useState({});
  const [metaAdsPrevious, setMetaAdsPrevious] = useState({});
  const [metaAdsYoY, setMetaAdsYoY] = useState({});
  const [metaAdsPreviousComplete, setMetaAdsPreviousComplete] = useState({});
  const [metaAdsForecast, setMetaAdsForecast] = useState({});

  // Meta Ads By Funnel
  const [metaAdsByFunnelCurrent, setMetaAdsByFunnelCurrent] = useState({});
  const [metaAdsByFunnelPrevious, setMetaAdsByFunnelPrevious] = useState({});
  const [metaAdsByFunnelYoY, setMetaAdsByFunnelYoY] = useState({});
  const [metaAdsByFunnelPreviousComplete, setMetaAdsPreviousByFunnelComplete] = useState({});
  const [metaAdsByFunnelForecast, setMetaAdsByFunnelForecast] = useState({});

  // LinkedIn Ads
  const [linkedinAdsCurrent, setLinkedinAdsCurrent] = useState({});
  const [linkedinAdsPrevious, setLinkedinAdsPrevious] = useState({});
  const [linkedinAdsYoY, setLinkedinAdsYoY] = useState({});
  const [linkedinAdsPreviousComplete, setLinkedinAdsPreviousComplete] = useState({});
  const [linkedinAdsForecast, setLinkedinAdsForecast] = useState({});

  // LinkedIn Ads
  const [linkedinAdsByFunnelCurrent, setLinkedinAdsByFunnelCurrent] = useState({});
  const [linkedinAdsByFunnelPrevious, setLinkedinAdsByFunnelPrevious] = useState({});
  const [linkedinAdsByFunnelYoY, setLinkedinAdsByFunnelYoY] = useState({});
  const [linkedinAdsByFunnelPreviousComplete, setLinkedinAdsByFunnelPreviousComplete] = useState({});
  const [linkedinAdsByFunnelForecast, setLinkedinAdsByFunnelForecast] = useState({});

  // Vtex Revenue
  const [vtexRevenueCurrent, setVtexRevenueCurrent] = useState([]);
  const [vtexRevenuePrevious, setVtexRevenuePrevious] = useState([]);
  const [vtexRevenueYoY, setVtexRevenueYoY] = useState([]);
  const [vtexRevenuePreviousComplete, setVtexRevenuePreviousComplete] = useState([]);
  const [vtexRevenueForecast, setVtexRevenueForecast] = useState([]);

  // Vtex Revenue Without Filter "OriginType"
  const [vtexRevenueWithoutFilterCurrent, setVtexRevenueWithoutFilterCurrent] = useState([]);
  const [vtexRevenueWithoutFilterPrevious, setVtexRevenueWithoutFilterPrevious] = useState([]);
  const [vtexRevenueWithoutFilterYoY, setVtexRevenueWithoutFilterYoY] = useState([]);
  const [vtexRevenueWithoutFilterPreviousComplete, setVtexRevenueWithoutFilterPreviousComplete] = useState([]);
  const [vtexRevenueWithoutFilterForecast, setVtexRevenueWithoutFilterForecast] = useState([]);

  // Vtex Client
  const [vtexClientsCurrent, setVtexClientsCurrent] = useState([]);
  const [vtexClientsPrevious, setVtexClientsPrevious] = useState([]);
  const [vtexClientsYoY, setVtexClientsYoY] = useState([]);
  const [vtexClientsPreviousComplete, setVtexClientsPreviousComplete] = useState([]);
  const [vtexClientsForecast, setVtexClientsForecast] = useState([]);

  // Vtex Client Without Filter "OriginType"
  const [vtexClientsWithoutFilterCurrent, setVtexClientsWithoutFilterCurrent] = useState([]);
  const [vtexClientsWithoutFilterPrevious, setVtexClientsWithoutFilterPrevious] = useState([]);
  const [vtexClientsWithoutFilterYoY, setVtexClientsWithoutFilterYoY] = useState([]);
  const [vtexClientsWithoutFilterPreviousComplete, setVtexClientsWithoutFilterPreviousComplete] = useState([]);
  const [vtexClientsWithoutFilterForecast, setVtexClientsWithoutFilterForecast] = useState([]);

  const [isLoandingData, setIsLoandingData] = useState(true);
  const [isLoandingFunnelData, setIsLoandingFunnelData] = useState(false);
  const [isLoandingGALeadsData, setIsLoandingGALeadsData] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);

  useEffect(() => {
    async function fecthDatas() {
      setIsLoandingData(true);
      if (accountNameApplied || accountName) {
        const accountNameToSend = accountNameApplied || accountName;
        await getDataFiltered(accountNameToSend);
        await getDataByFunnelFiltered(accountNameToSend);
        setIsLoandingData(false);
      }
    }
    fecthDatas();
    return () => {};
  }, [
    accountNameApplied,
    metaIdApplied,
    platformApplied,
    regionApplied,
    strategyApplied,
    channelApplied,
    utmCampaignApplied,
    statusVtexApplied,
    originVtexApplied,
    infoDateSelected,
  ]);

  useEffect(() => {
    async function fetchDatas() {
      const accountNameToSend = accountNameApplied || accountName;
      await getLeadsFiltered(accountNameToSend);
      await getDataByFunnelFiltered(accountNameToSend);
    }
    fetchDatas();
    return () => {};
  }, [leadType]);

  async function getDataFiltered(accountNameToSend) {
    if (infoDateSelected) {
      try {
        setIsLoandingGALeadsData(true);
        setDisableSelectDates(true);
        const [
          googleAnalyticsCurrentData,
          googleAnalyticsPreviousData,
          googleAnalyticsYoYData,
          googleAnalyticsProviousCompleteData,
          googleAnalyticsForecastData,

          googleAnalyticsOthersPlatformsCurrentData,
          googleAnalyticsOthersPlatformsPreviousData,
          googleAnalyticsOthersPlatformsYoYData,
          googleAnalyticsOthersPlatformsPreviousCompleteData,
          googleAnalyticsOthersPlatformsForecastData,

          googleAdwordsCurrentData,
          googleAdwordsPreviousData,
          googleAdwordsYoYData,
          googleAdwordsPreviousCompleteData,
          googleAdwordsForecastData,

          metaAdsCurrentData,
          metaAdsPreviousData,
          metaAdsYoYData,
          metaAdsPreviousCompleteData,
          metaAdsForecastData,

          linkedinAdsCurrentData,
          linkedinAdsPreviousData,
          linkedinAdsYoYData,
          linkedinAdsPreviousCompleteData,
          linkedinAdsForecastData,

          leadsGoogleAnalyticsCurrentData,
          leadsGoogleAnalyticsPreviousData,
          leadsGoogleAnalyticsYoYData,
          leadsGoogleAnalyticsPreviousCompleteData,
          leadsGoogleAnalyticsForecastData,

          vtexRevenueCurrentData,
          vtexRevenuePreviousData,
          vtexRevenueYoYData,
          vtexRevenuePreviousCompleteData,
          vtexRevenueForecastData,

          vtexRevenueWithoutOriginTypeFilterCurrentData,
          vtexRevenueWithoutOriginTypeFilterPreviousData,
          vtexRevenueWithoutOriginTypeFilterYoYData,
          vtexRevenueWithoutOriginTypeFilterPreviousCompleteData,
          vtexRevenueWithoutOriginTypeFilterForecastData,

          vtexClientsCurrentData,
          vtexClientsPreviousData,
          vtexClientsYoYData,
          vtexClientsPreviousCompleteData,
          vtexClientsForecastData,

          vtexClientsWithoutOriginTypeFilterCurrentData,
          vtexClientsWithoutOriginTypeFilterPreviousData,
          vtexClientsWithoutOriginTypeFilterYoYData,
          vtexClientsWithoutOriginTypeFilterPreviousCompleteData,
          vtexClientsWithoutOriginTypeFilterForecastData,
        ] = await Promise.allSettled([

          getDataFromGoogleAnalytics(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromGoogleAnalytics(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromGoogleAnalytics(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromGoogleAnalytics(accountNameToSend, datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromGoogleAnalytics(accountNameToSend, datesForecast.init.value, datesForecast.end.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataFromGoogleAnalyticsOthersPlatforms(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromGoogleAnalyticsOthersPlatforms(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromGoogleAnalyticsOthersPlatforms(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromGoogleAnalyticsOthersPlatforms(accountNameToSend, datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromGoogleAnalyticsOthersPlatforms(accountNameToSend, datesForecast.init.value, datesForecast.end.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataFromGoogleAdwords(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromGoogleAdwords(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromGoogleAdwords(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromGoogleAdwords(accountNameToSend, datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromGoogleAdwords(accountNameToSend, datesForecast.init.value, datesForecast.end.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataByMetaAds(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByMetaAds(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByMetaAds(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByMetaAds(accountNameToSend, datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromMetaAds(accountNameToSend, datesForecast.init.value, datesForecast.end.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataFromLinkedinAds(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromLinkedinAds(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromLinkedinAds(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromLinkedinAds(accountNameToSend, datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromLinkedinAds(accountNameToSend, datesForecast.init.value, datesForecast.end.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataFromLeadsGoogleAnalytics(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromLeadsGoogleAnalytics(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromLeadsGoogleAnalytics(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromLeadsGoogleAnalytics(accountNameToSend, datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromLeadsGoogleAnalytics(accountNameToSend, datesForecast.init.value, datesForecast.end.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataFromVtexRevenue(infoDateSelected?.init?.value, infoDateSelected?.end?.value, statusVtexApplied, originVtexApplied),
          getDataFromVtexRevenue(infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, statusVtexApplied, originVtexApplied),
          getDataFromVtexRevenue(infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, statusVtexApplied, originVtexApplied),
          getDataFromVtexRevenue(datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, statusVtexApplied, originVtexApplied),
          getDataFromVtexRevenue(datesForecast.init.value, datesForecast.end.value, statusVtexApplied, originVtexApplied),

          getDataFromVtexRevenueWithoutOriginTypeFilter(infoDateSelected?.init?.value, infoDateSelected?.end?.value, statusVtexApplied),
          getDataFromVtexRevenueWithoutOriginTypeFilter(infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, statusVtexApplied),
          getDataFromVtexRevenueWithoutOriginTypeFilter(infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, statusVtexApplied),
          getDataFromVtexRevenueWithoutOriginTypeFilter(datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, statusVtexApplied),
          getDataFromVtexRevenueWithoutOriginTypeFilter(datesForecast.init.value, datesForecast.end.value, statusVtexApplied),

          getDataFromVtexClients(infoDateSelected?.init?.value, infoDateSelected?.end?.value, originVtexApplied),
          getDataFromVtexClients(infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, originVtexApplied),
          getDataFromVtexClients(infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, originVtexApplied),
          getDataFromVtexClients(datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, originVtexApplied),
          getDataFromVtexClients(datesForecast.init.value, datesForecast.end.value, originVtexApplied),

          getDataFromVtexClientsWithoutOriginTypeFilter(infoDateSelected?.init?.value, infoDateSelected?.end?.value),
          getDataFromVtexClientsWithoutOriginTypeFilter(infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value),
          getDataFromVtexClientsWithoutOriginTypeFilter(infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value),
          getDataFromVtexClientsWithoutOriginTypeFilter(datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value),
          getDataFromVtexClientsWithoutOriginTypeFilter(datesForecast.init.value, datesForecast.end.value),
        ]);

        setGoogleAnalyticsCurrent(googleAnalyticsCurrentData?.value);
        setGoogleAnalyticsPrevious(googleAnalyticsPreviousData?.value);
        setGoogleAnalyticsYoY(googleAnalyticsYoYData?.value);
        setGoogleAnalyticsPreviousComplete(googleAnalyticsProviousCompleteData?.value);
        setGoogleAnalyticsForecast(googleAnalyticsForecastData?.value);

        setGoogleAnalyticsOthersPlatformsCurrent(googleAnalyticsOthersPlatformsCurrentData?.value);
        setGoogleAnalyticsOthersPlatformsPrevious(googleAnalyticsOthersPlatformsPreviousData?.value);
        setGoogleAnalyticsOthersPlatformsYoY(googleAnalyticsOthersPlatformsYoYData?.value);
        setGoogleAnalyticsOthersPlatformsPreviousComplete(googleAnalyticsOthersPlatformsPreviousCompleteData?.value);
        setGoogleAnalyticsOthersPlatformsForecast(googleAnalyticsOthersPlatformsForecastData?.value);

        setGoogleAdwordsCurrent(googleAdwordsCurrentData?.value);
        setGoogleAdwordsPrevious(googleAdwordsPreviousData?.value);
        setGoogleAdwordsYoY(googleAdwordsYoYData?.value);
        setGoogleAdwordsPreviousComplete(googleAdwordsPreviousCompleteData?.value);
        setGoogleAdwordsForecast(googleAdwordsForecastData?.value);

        setMetaAdsCurrent(metaAdsCurrentData?.value);
        setMetaAdsPrevious(metaAdsPreviousData?.value);
        setMetaAdsYoY(metaAdsYoYData?.value);
        setMetaAdsPreviousComplete(metaAdsPreviousCompleteData?.value);
        setMetaAdsForecast(metaAdsForecastData?.value);

        setLinkedinAdsCurrent(linkedinAdsCurrentData?.value);
        setLinkedinAdsPrevious(linkedinAdsPreviousData?.value);
        setLinkedinAdsYoY(linkedinAdsYoYData?.value);
        setLinkedinAdsPreviousComplete(linkedinAdsPreviousCompleteData?.value);
        setLinkedinAdsForecast(linkedinAdsForecastData?.value);

        setLeadsGoogleAnalyticsCurrent(leadsGoogleAnalyticsCurrentData?.value);
        setLeadsGoogleAnalyticsPrevious(leadsGoogleAnalyticsPreviousData?.value);
        setLeadsGoogleAnalyticsYoY(leadsGoogleAnalyticsYoYData?.value);
        setLeadsGoogleAnalyticsPreviousComplete(leadsGoogleAnalyticsPreviousCompleteData?.value);
        setLeadsGoogleAnalyticsForecast(leadsGoogleAnalyticsForecastData?.value);

        setVtexRevenueCurrent(vtexRevenueCurrentData?.value);
        setVtexRevenuePrevious(vtexRevenuePreviousData?.value);
        setVtexRevenueYoY(vtexRevenueYoYData?.value);
        setVtexRevenuePreviousComplete(vtexRevenuePreviousCompleteData?.value);
        setVtexRevenueForecast(vtexRevenueForecastData?.value);

        setVtexRevenueWithoutFilterCurrent(vtexRevenueWithoutOriginTypeFilterCurrentData?.value);
        setVtexRevenueWithoutFilterPrevious(vtexRevenueWithoutOriginTypeFilterPreviousData?.value);
        setVtexRevenueWithoutFilterYoY(vtexRevenueWithoutOriginTypeFilterYoYData?.value);
        setVtexRevenueWithoutFilterPreviousComplete(vtexRevenueWithoutOriginTypeFilterPreviousCompleteData?.value);
        setVtexRevenueWithoutFilterForecast(vtexRevenueWithoutOriginTypeFilterForecastData?.value);

        setVtexClientsCurrent(vtexClientsCurrentData?.value);
        setVtexClientsPrevious(vtexClientsPreviousData?.value);
        setVtexClientsYoY(vtexClientsYoYData?.value);
        setVtexClientsPreviousComplete(vtexClientsPreviousCompleteData?.value);
        setVtexClientsForecast(vtexClientsForecastData?.value);

        setVtexClientsWithoutFilterCurrent(vtexClientsWithoutOriginTypeFilterCurrentData?.value);
        setVtexClientsWithoutFilterPrevious(vtexClientsWithoutOriginTypeFilterPreviousData?.value);
        setVtexClientsWithoutFilterYoY(vtexClientsWithoutOriginTypeFilterYoYData?.value);
        setVtexClientsWithoutFilterPreviousComplete(vtexClientsWithoutOriginTypeFilterPreviousCompleteData?.value);
        setVtexClientsWithoutFilterForecast(vtexClientsWithoutOriginTypeFilterForecastData?.value);
      } finally {
        setIsLoandingGALeadsData(false);
        setDisableSelectDates(false);
      }
    }
  }

  async function getDataByFunnelFiltered(accountNameToSend) {
    if (infoDateSelected) {
      try {
        setIsLoandingFunnelData(true);
        const [
          googleAnalitycsByFunnelCurrentData,
          googleAnalitycsByFunnelPreviousData,
          googleAnalitycsByFunnelYoYData,
          googleAnalitycsByFunnelPreviousCompleteData,
          googleAnalitycsByFunnelForecastData,

          googleAdwordsByFunnelCurrentData,
          googleAdwordsByFunnelPreviousData,
          googleAdwordsByFunnelYoYData,
          googleAdwordsByFunnelProviousCompleteData,
          googleAdwordsByFunnelForecastData,

          metaAdsByFunnelCurrentData,
          metaAdsByFunnelPreviousData,
          metaAdsByFunnelYoYData,
          metaAdsByFunnelProviousCompleteData,
          metaAdsByFunnelForecastData,

          linkedinAdsByFunnelCurrentData,
          linkedinAdsByFunnelPreviousData,
          linkedinAdsByFunnelYoYData,
          linkedinAdsByFunnelProviousCompleteData,
          linkedinAdsByFunnelForecastData,

          googleAnalyticsOthersPlatformsByFunnelCurrentData,
          googleAnalyticsOthersPlatformsByFunnelPreviousData,
          googleAnalyticsOthersPlatformsByFunnelYoYData,
          googleAnalyticsOthersPlatformsByFunnelPreviousCompleteData,
          googleAnalyticsOthersPlatformsByFunnelForecastData,

          leadsGoogleAnalyticsByFunnelCurrentData,
          leadsGoogleAnalyticsByFunnelPreviousData,
          leadsGoogleAnalyticsByFunnelYoYData,
          leadsGoogleAnalyticsByFunnelPreviousCompleteData,
          leadsGoogleAnalyticsByFunnelForecastData,

        ] = await Promise.allSettled([
          getDataByFunnelFromGoogleAnalytics(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromGoogleAnalytics(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromGoogleAnalytics(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromGoogleAnalytics(accountNameToSend, datesCompletePreviousMonth.init?.value, datesCompletePreviousMonth.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromGoogleAnalytics(accountNameToSend, datesForecast?.init?.value, datesForecast?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataByFunnelFromGoogleAdwords(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromGoogleAdwords(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromGoogleAdwords(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromGoogleAdwords(accountNameToSend, datesCompletePreviousMonth.init?.value, datesCompletePreviousMonth.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromGoogleAdwords(accountNameToSend, datesForecast?.init?.value, datesForecast?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataByFunnelFromMetaAds(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromMetaAds(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromMetaAds(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromMetaAds(accountNameToSend, datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromMetaAds(accountNameToSend, datesForecast.init.value, datesForecast.end.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataByFunnelFromLinkedinAds(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromLinkedinAds(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromLinkedinAds(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromLinkedinAds(accountNameToSend, datesCompletePreviousMonth.init?.value, datesCompletePreviousMonth.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromLinkedinAds(accountNameToSend, datesForecast?.init?.value, datesForecast?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataByFunnelFromGoogleAnalyticsOthersPlatforms(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromGoogleAnalyticsOthersPlatforms(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromGoogleAnalyticsOthersPlatforms(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromGoogleAnalyticsOthersPlatforms(accountNameToSend, datesCompletePreviousMonth.init?.value, datesCompletePreviousMonth.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromGoogleAnalyticsOthersPlatforms(accountNameToSend, datesForecast?.init?.value, datesForecast?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

          getDataByFunnelFromLeadsGoogleAnalytics(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromLeadsGoogleAnalytics(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromLeadsGoogleAnalytics(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByFunnelFromLeadsGoogleAnalytics(accountNameToSend, datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromLeadsGoogleAnalytics(accountNameToSend, datesForecast.init.value, datesForecast.end.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
        ]);

        setGoogleAnalyticsByFunnelCurrent(googleAnalitycsByFunnelCurrentData?.value);
        setGoogleAnalyticsByFunnelPrevious(googleAnalitycsByFunnelPreviousData?.value);
        setGoogleAnalyticsByFunnelYoY(googleAnalitycsByFunnelYoYData?.value);
        setGoogleAnalyticsByFunnelPreviousComplete(googleAnalitycsByFunnelPreviousCompleteData?.value);
        setGoogleAnalyticsByFunnelForecast(googleAnalitycsByFunnelForecastData?.value);

        setGoogleAdwordsByFunnelCurrent(googleAdwordsByFunnelCurrentData?.value);
        setGoogleAdwordsByFunnelPrevious(googleAdwordsByFunnelPreviousData?.value);
        setGoogleAdwordsByFunnelYoY(googleAdwordsByFunnelYoYData?.value);
        setGoogleAdwordsByFunnelComplete(googleAdwordsByFunnelProviousCompleteData?.value);
        setGoogleAdwordsByFunnelForecast(googleAdwordsByFunnelForecastData?.value);

        setMetaAdsByFunnelCurrent(metaAdsByFunnelCurrentData?.value);
        setMetaAdsByFunnelPrevious(metaAdsByFunnelPreviousData?.value);
        setMetaAdsByFunnelYoY(metaAdsByFunnelYoYData?.value);
        setMetaAdsPreviousByFunnelComplete(metaAdsByFunnelProviousCompleteData?.value);
        setMetaAdsByFunnelForecast(metaAdsByFunnelForecastData?.value);

        setLinkedinAdsByFunnelCurrent(linkedinAdsByFunnelCurrentData?.value);
        setLinkedinAdsByFunnelPrevious(linkedinAdsByFunnelPreviousData?.value);
        setLinkedinAdsByFunnelYoY(linkedinAdsByFunnelYoYData?.value);
        setLinkedinAdsByFunnelPreviousComplete(linkedinAdsByFunnelProviousCompleteData?.value);
        setLinkedinAdsByFunnelForecast(linkedinAdsByFunnelForecastData?.value);

        setGoogleAnalyticsOthersPlatformsByFunnelCurrent(googleAnalyticsOthersPlatformsByFunnelCurrentData?.value);
        setGoogleAnalyticsOthersPlatformsByFunnelPrevious(googleAnalyticsOthersPlatformsByFunnelPreviousData?.value);
        setGoogleAnalyticsOthersPlatformsByFunnelYoY(googleAnalyticsOthersPlatformsByFunnelYoYData?.value);
        setGoogleAnalyticsOthersPlatformsByFunnelPreviousComplete(googleAnalyticsOthersPlatformsByFunnelPreviousCompleteData?.value);
        setGoogleAnalyticsOthersPlatformsByFunnelForecast(googleAnalyticsOthersPlatformsByFunnelForecastData?.value);

        setLeadsGoogleAnalyticsByFunnelCurrent(leadsGoogleAnalyticsByFunnelCurrentData?.value);
        setLeadsGoogleAnalyticsByFunnelPrevious(leadsGoogleAnalyticsByFunnelPreviousData?.value);
        setLeadsGoogleAnalyticsByFunnelYoY(leadsGoogleAnalyticsByFunnelYoYData?.value);
        setLeadsGoogleAnalyticsByFunnelPreviousComplete(leadsGoogleAnalyticsByFunnelPreviousCompleteData?.value);
        setLeadsGoogleAnalyticsByFunnelForecast(leadsGoogleAnalyticsByFunnelForecastData?.value);
      } finally {
        setIsLoandingFunnelData(false);
      }
    }
  }

  async function getLeadsFiltered(accountNameToSend) {
    if (infoDateSelected) {
      try {
        setIsLoandingGALeadsData(true);
        const [
          leadsGoogleAnalyticsCurrentData,
          leadsGoogleAnalyticsPreviousData,
          leadsGoogleAnalyticsYoYData,
          leadsGoogleAnalyticsPreviousCompleteData,
          leadsGoogleAnalyticsForecastData,
        ] = await Promise.allSettled([
          getDataFromLeadsGoogleAnalytics(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromLeadsGoogleAnalytics(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromLeadsGoogleAnalytics(accountNameToSend, infoDateSelected?.initYoY?.value, infoDateSelected?.endYoY?.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataFromLeadsGoogleAnalytics(accountNameToSend, datesCompletePreviousMonth.init.value, datesCompletePreviousMonth.end.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
          getDataByDateFromLeadsGoogleAnalytics(accountNameToSend, datesForecast.init.value, datesForecast.end.value, platformApplied, leadType, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
        ]);

        setLeadsGoogleAnalyticsCurrent(leadsGoogleAnalyticsCurrentData.value);
        setLeadsGoogleAnalyticsPrevious(leadsGoogleAnalyticsPreviousData.value);
        setLeadsGoogleAnalyticsYoY(leadsGoogleAnalyticsYoYData.value);
        setLeadsGoogleAnalyticsPreviousComplete(leadsGoogleAnalyticsPreviousCompleteData.value);
        setLeadsGoogleAnalyticsForecast(leadsGoogleAnalyticsForecastData.value);
      } finally {
        setIsLoandingGALeadsData(false);
      }
    }
  }

  // Google Analytics
  async function getDataFromGoogleAnalytics(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await GoogleAnalitycs.getTotalOnly(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByFunnelFromGoogleAnalytics(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await GoogleAnalitycs.getTotalByFunnel(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByDateFromGoogleAnalytics(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await GoogleAnalitycs.getTotalByDate(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  // Google Analytics Others Platforms
  async function getDataFromGoogleAnalyticsOthersPlatforms(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await GoogleAnalitycsOthersPlatformsService.getTotalOnly(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByDateFromGoogleAnalyticsOthersPlatforms(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await GoogleAnalitycsOthersPlatformsService.getTotalByDate(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByFunnelFromGoogleAnalyticsOthersPlatforms(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await GoogleAnalitycsOthersPlatformsService.getTotalByFunnel(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  // Leads Google Analytics
  async function getDataFromLeadsGoogleAnalytics(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, leadType = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await LeadsGoogleAnalitycsService.getTotalOnly(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, leadType, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByFunnelFromLeadsGoogleAnalytics(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, leadType = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await LeadsGoogleAnalitycsService.getTotalByFunnel(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, leadType, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByDateFromLeadsGoogleAnalytics(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, leadType = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await LeadsGoogleAnalitycsService.getTotalByDate(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, leadType, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  // Google Adwords
  async function getDataFromGoogleAdwords(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await GoogleAdwords.getTotalOnly(accountName, platform, infoDateSelectedInit, infoDateSelectedEnd, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByDateFromGoogleAdwords(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await GoogleAdwords.getTotalByDate(accountName, platform, infoDateSelectedInit, infoDateSelectedEnd, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByFunnelFromGoogleAdwords(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await GoogleAdwords.getTotalByFunnel(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  // Meta Ads
  async function getDataByMetaAds(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, metaId = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await MetaAds.getTotalOnly(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, metaId, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByDateFromMetaAds(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, metaId = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await MetaAds.getTotalByDate(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, metaId, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByFunnelFromMetaAds(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, metaId = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await MetaAds.getTotalByFunnel(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, metaId, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  // LinkedIn Ads
  async function getDataFromLinkedinAds(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await LinkedInAds.getTotalOnly(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByDateFromLinkedinAds(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await LinkedInAds.getTotalByDate(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  async function getDataByFunnelFromLinkedinAds(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform = null, region = null, strategy = null, channel = null, utmCampaing = null) {
    if (accountName) {
      const datas = await LinkedInAds.getTotalByFunnel(accountName, infoDateSelectedInit, infoDateSelectedEnd, platform, region, strategy, channel, utmCampaing);
      return datas;
    }
  }

  // Vtex Revenue
  async function getDataFromVtexRevenue(infoDateSelectedInit, infoDateSelectedEnd, orderStatus, originType = null) {
    const datas = await VtexService.getVtexRevenue(infoDateSelectedInit, infoDateSelectedEnd, orderStatus, originType);
    return datas;
  }

  async function getDataFromVtexRevenueWithoutOriginTypeFilter(infoDateSelectedInit, infoDateSelectedEnd, orderStatus) {
    const datas = await VtexService.getVtexRevenueWithoutOriginTypeFilter(infoDateSelectedInit, infoDateSelectedEnd, orderStatus);
    return datas;
  }

  // Vtex Clients
  async function getDataFromVtexClients(infoDateSelectedInit, infoDateSelectedEnd, originType = null) {
    const datas = await VtexService.getVtexClients(infoDateSelectedInit, infoDateSelectedEnd, originType);
    return datas;
  }

  async function getDataFromVtexClientsWithoutOriginTypeFilter(infoDateSelectedInit, infoDateSelectedEnd) {
    const datas = await VtexService.getVtexClients(infoDateSelectedInit, infoDateSelectedEnd);
    return datas;
  }

  // TODO: Pass this value to value attr on DataContext.Provider
  const contextValues = useMemo(() => {
  // DataContext.Provider
  });

  return (
    /* eslint-disable-next-line react/jsx-no-constructed-context-values */
    <DataContext.Provider value={{
      isLoandingData,
      isLoandingGALeadsData,
      isLoandingFunnelData,

      googleAnalyticsCurrent,
      googleAnalyticsPrevious,
      googleAnalyticsYoY,
      googleAnalyticsPreviousComplete,
      googleAnalyticsForecast,

      googleAnalyticsByFunnelCurrent,
      googleAnalyticsByFunnelPrevious,
      googleAnalyticsByFunnelYoY,
      googleAnalyticsByFunnelPreviousComplete,
      googleAnalyticsByFunnelForecast,

      leadsGoogleAnalyticsCurrent,
      leadsGoogleAnalyticsPrevious,
      leadsGoogleAnalyticsYoY,
      leadsGoogleAnalyticsPreviousComplete,
      leadsGoogleAnalyticsForecast,

      leadsGoogleAnalyticsByFunnelCurrent,
      leadsGoogleAnalyticsByFunnelPrevious,
      leadsGoogleAnalyticsByFunnelYoY,
      leadsGoogleAnalyticsByFunnelPreviousComplete,
      leadsGoogleAnalyticsByFunnelForecast,

      googleAnalyticsOthersPlatformsCurrent,
      googleAnalyticsOthersPlatformsPrevious,
      googleAnalyticsOthersPlatformsYoY,
      googleAnalyticsOthersPlatformsPreviousComplete,
      googleAnalyticsOthersPlatformsForecast,

      googleAnalyticsOthersPlatformsByFunnelCurrent,
      googleAnalyticsOthersPlatformsByFunnelPrevious,
      googleAnalyticsOthersPlatformsByFunnelYoY,
      googleAnalyticsOthersPlatformsByFunnelPreviousComplete,
      googleAnalyticsOthersPlatformsByFunnelForecast,

      googleAdwordsCurrent,
      googleAdwordsPrevious,
      googleAdwordsYoY,
      googleAdwordsPreviousComplete,
      googleAdwordsForecast,

      googleAdwordsByFunnelCurrent,
      googleAdwordsByFunnelPrevious,
      googleAdwordsByFunnelYoY,
      googleAdwordsByFunnelPreviousComplete,
      googleAdwordsByFunnelForecast,

      metaAdsCurrent,
      metaAdsPrevious,
      metaAdsYoY,
      metaAdsPreviousComplete,
      metaAdsForecast,

      metaAdsByFunnelCurrent,
      metaAdsByFunnelPrevious,
      metaAdsByFunnelYoY,
      metaAdsByFunnelPreviousComplete,
      metaAdsByFunnelForecast,

      linkedinAdsCurrent,
      linkedinAdsPrevious,
      linkedinAdsYoY,
      linkedinAdsPreviousComplete,
      linkedinAdsForecast,

      linkedinAdsByFunnelCurrent,
      linkedinAdsByFunnelPrevious,
      linkedinAdsByFunnelYoY,
      linkedinAdsByFunnelPreviousComplete,
      linkedinAdsByFunnelForecast,

      vtexRevenueCurrent,
      vtexRevenuePrevious,
      vtexRevenueYoY,
      vtexRevenuePreviousComplete,
      vtexRevenueForecast,

      vtexRevenueWithoutFilterCurrent,
      vtexRevenueWithoutFilterPrevious,
      vtexRevenueWithoutFilterYoY,
      vtexRevenueWithoutFilterPreviousComplete,
      vtexRevenueWithoutFilterForecast,

      vtexClientsCurrent,
      vtexClientsPrevious,
      vtexClientsYoY,
      vtexClientsPreviousComplete,
      vtexClientsForecast,

      vtexClientsWithoutFilterCurrent,
      vtexClientsWithoutFilterPrevious,
      vtexClientsWithoutFilterYoY,
      vtexClientsWithoutFilterPreviousComplete,
      vtexClientsWithoutFilterForecast,
    }}
    >
      {children}
    </DataContext.Provider>
  );
});


export { DataContext, DataProvider };
