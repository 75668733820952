import React from 'react';
import { Link } from 'react-router-dom';
import GifLogoYellow from '../../styles/gifs/logo_yellow.gif';
import { Translate } from './translate';
import { Container } from './styles';

function NotFound() {
  const translate = Translate();

  return (
    <Container>
      <div className="message">
        <p>{translate.mainSentence}...</p>
      </div>
      <img className="image" src={GifLogoYellow} alt="Page not found" />
      <Link to="/client">{translate.btnLabel}</Link>
    </Container>
  );
}

export default NotFound;
