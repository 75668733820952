import styled from 'styled-components';

export const ContainerScore = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 8px;
`;

export const ContainerProgressBars = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .container-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px !important;
    text-decoration: none;

    &:hover {
       text-decoration: underline;
       cursor: pointer;
    }

    .stars {
      max-width: 80px;
    }

    .percentage {
      max-width: 40px;
    }
  }
`;

export const ContainerLink = styled.div`
  padding: 16px;
  text-align: center;

  a {
    text-decoration: none;
  }

  a:hover {
    color: ${({ theme }) => theme.fontWhite};
    text-decoration: underline;
  }
`;
