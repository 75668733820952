class CategoryUsersService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findById({ idCategory }) {
    const path = `${this.baseUrl}/categories-users/${idCategory}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async userAuthorization({ idCategory }) {
    const path = `${this.baseUrl}/categories-users/${idCategory}/authorization`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async storeAndUpdate({ idCategory, listOfUsers }) {
    const path = `${this.baseUrl}/categories-users`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        idCategory, listOfUsers,
      }),
    });

    const body = await response.json();

    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);

    return body;
  }
}

export default new CategoryUsersService();
