import React from 'react';
import parse from 'html-react-parser';
import { Translate } from './translate';
import { TextContainer } from './style';

export function Description({ description }) {
  const translate = Translate();

  return (
    <TextContainer>
      <p>{parse(`${description}`)}</p>
    </TextContainer>
  );
}
