export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    language,
    titlePage: language === 'pt-BR' ? 'Criar URL' : 'Create URL',
    infoPage: language === 'pt-BR' ? 'Escolha um valor para um ou mais parâmetros' : 'Choose a value for one or more params',
    saveText: language === 'pt-BR'
      ? 'Para salvar a URL, preencha o campo de "URL de Destino" e selecione "UTM Source," "UTM Medium" e pelo menos uma opção da "UTM Campaign".'
      : 'To save the URL, fill in the "Destination URL" field and select "UTM Source," "UTM Medium," and at least one option from the "UTM Campaign".',
    nameHelperMessage: language === 'pt-BR'
      ? 'Nome que aparecerá no campo de escolha' : 'Name that will appear in the field of choice',
    urlValueHelperMessage: language === 'pt-BR'
      ? 'Valor que será inserido na URL' : 'Value that will be inserted in the URL',
    modalTitle: language === 'pt-BR' ? 'Adicionar valor personalizado no parâmetro' : 'Add custom value in parameter',
    modalParam: language === 'pt-BR' ? 'Parâmetro' : 'Param',
    modalName: language === 'pt-BR' ? 'Nome' : 'Name',
    placeholderName: language === 'pt-BR' ? 'Exemplo: Posts Institucionais' : 'Example: Institutional Posts',
    modalValue: language === 'pt-BR' ? 'Valor URL' : 'URL Value',
    placeholderValue: language === 'pt-BR' ? 'Exemplo: posts-institucionais' : 'Example: institutional-posts',
    tipOfAddParams: language === 'pt-BR' ? 'Adicione valores personalizados neste parâmetro' : 'Add custom values at this param',
    maskedParam: language === 'pt-BR' ? 'URL (UTMs mascaradas)' : 'URL (Masked UTMs)',
    shortenedParam: language === 'pt-BR' ? 'URL (Encurtada)' : 'URL (Shortened)',
  };
}
