export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? 'TM' : 'AOV',
    tip: language === 'pt-BR'
      ? 'Receita / Transações'
      : 'Revenue / Transactions',
  };
}
