import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import UTMService from '../../../../../services/UTM';
import Event from '../../../../../lib/EventEmitter';
import Loader from '../../../../../components/Loader';
import { Translate } from './translate';
import IconAdd from '../../../../../styles/logos/add.svg';

export function Keyword({
  value, setValue, onToogleModal, setUtmCategoryToAdd, tipOfAddParams,
}) {
  const translate = Translate();
  const [paramsCustomList, setParamsCustomList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idParam] = useState(23);

  useEffect(() => {
    getCustomsUTMSources();
    Event.addListener('IDCustomParamAdded', (idParamToAdd) => {
      if (idParamToAdd === idParam) {
        getCustomsUTMSources();
      }
    });
  }, []);

  async function getCustomsUTMSources() {
    setIsLoading(true);
    const listofParamsCustom = await UTMService.getAllBYCategoryUTM(idParam);
    setParamsCustomList(listofParamsCustom);
    setIsLoading(false);
  }

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, keyword: event.target.value }));
  }

  function handleToogleModal() {
    setUtmCategoryToAdd(idParam);
    onToogleModal();
  }

  return (
    <div className="form-item">
      <label htmlFor="name">
        {translate.label}
        <img src={IconAdd} alt="Add" title={tipOfAddParams} onClick={handleToogleModal} />
      </label>
      <select value={value} onChange={handleSelect} id="name" disabled={isLoading}>
        <option value="" className="noneParam">None</option>
        {paramsCustomList && paramsCustomList.map((item) => (
          <option key={item.id} value={item.value} className="customParam">
            {' '}
            {item.name}
            {' '}
          </option>
        ))}
        <option value="keyword-1">keyword-1</option>
        <option value="keyword-2">keyword-2</option>
        <option value="keyword-3">keyword-3</option>
        <option value="keyword-4">keyword-4</option>
        <option value="keyword-5">keyword-5</option>
        <option value="keyword-6">keyword-6</option>
        <option value="keyword-7">keyword-7</option>
        <option value="keyword-8">keyword-8</option>
        <option value="keyword-9">keyword-9</option>
        <option value="keyword-10">keyword-10</option>
      </select>
      { isLoading && <Loader loading={isLoading} tofield /> }
    </div>
  );
}

Keyword.propTypes = {
  value: PropTypes.string.isRequired,
  tipOfAddParams: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onToogleModal: PropTypes.func.isRequired,
  setUtmCategoryToAdd: PropTypes.func.isRequired,
};
