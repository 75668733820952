import React, { createContext, useMemo, useState } from 'react';

const ThemeAppContext = createContext();

function ThemeAppProvider({ children }) {
  const [theme, setTheme] = useState('dark');

  function handleTheme() {
    setTheme((prevState) => (prevState === 'dark' ? 'light' : 'dark'));
  }

  const contextValues = useMemo(() => (
    {
      theme,
      handleTheme,
    }
  ), [theme, handleTheme]);

  return (
    <ThemeAppContext.Provider value={contextValues}>
      {children}
    </ThemeAppContext.Provider>
  );
}

export { ThemeAppContext, ThemeAppProvider };
