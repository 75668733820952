import React, { useEffect, useState } from 'react';
import TitlePage from '../../../components/TitlePage';
import BackButton from '../../../components/BackButton';
import { ButtonLink } from '../../../components/ButtonLink';
import Square from '../../../components/Square';
import Loader from '../../../components/Loader';
import { NoDataMessage } from '../../../components/NoDataMessage';
import { Translate } from './translate';
import { Container, FavoriteWrap } from './style';
import VideosFavoritesService from '../../../services/VideosFavorites';

export default function FavoritesVideos() {
  const translate = Translate();
  const [favoriteList, setFavoriteList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllFavorites();
  }, []);

  async function getAllFavorites() {
    try {
      setIsLoading(true);
      const videosFavorites = await VideosFavoritesService.findAllByUser();
      setFavoriteList(videosFavorites);
    } catch {
      setFavoriteList([]);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton />
      </TitlePage>
      <Container>
        {isLoading && <Loader loading={isLoading} />}
        {(!isLoading && favoriteList.length === 0) && <NoDataMessage>{translate.noFavorites}</NoDataMessage>}
        {(!isLoading && favoriteList.length > 0) && (
          favoriteList.map((favorite) => (
            <Square size="large" key={favorite.id}>
              <FavoriteWrap>
                <div className="infos">
                  <p>
                    {translate.language === 'pt-BR' ? favorite.product_name_pt : favorite.product_name_en}
                    {(favorite.category_name_pt || favorite.category_name_en) && (
                      <>
                        <span className="arrow">{' > '}</span>
                        <span>{translate.language === 'pt-BR' ? favorite.category_name_pt : favorite.category_name_en}</span>
                      </>
                    )}
                    {(favorite.subcategory_name_pt || favorite.subcategory_name_en) && (
                      <>
                        <span className="arrow">{' > '}</span>
                        <span>{translate.language === 'pt-BR' ? favorite.subcategory_name_pt : favorite.subcategory_name_en}</span>
                      </>
                    )}
                    {(favorite.post_name_pt || favorite.post_name_en) && (
                      <>
                        <span className="arrow">{' > '}</span>
                        <span>{translate.language === 'pt-BR' ? favorite.post_name_pt : favorite.post_name_en}</span>
                      </>
                    )}
                  </p>
                </div>
                <div className="links">
                  <ButtonLink color="yellow" to={`/client/academy/${favorite.path}`}>{translate.watch}</ButtonLink>
                </div>
              </FavoriteWrap>
            </Square>
          ))
        )}
      </Container>
    </>
  );
}
