import React from 'react';

// import Brand from './Brand';
import Form from './Form';

import * as S from './styles';

function Login() {
  return (
    <S.MainContainer>
      <Form />
      {/* <Brand /> */}
    </S.MainContainer>
  );
}

export default Login;
