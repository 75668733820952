import styled from 'styled-components';

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $hasurl }) => ($hasurl ? 'space-between' : 'end')};
  margin-top: ${(props) => props.theme.sizes.s24};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
  }

  .button-container {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;
    }
  }

  input {
    background-color: ${(props) => props.theme.grayMenu};
    border-radius: ${(props) => props.theme.radius};
    border: 1px solid ${(props) => props.theme.grayMenu};
    padding: ${(props) => props.theme.sizes.s8};
    font-size: ${(props) => props.theme.sizes.s16};
    width: 350px;
    outline: none;

    :focus {
      border: 1px solid ${(props) => props.theme.grayLight};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
      margin-bottom: ${(props) => props.theme.sizes.s8};
    }
  }
`;

export const ListContainer = styled.div`
  margin-top: ${(props) => props.theme.sizes.s32};

  .message {
    font-size: ${(props) => props.theme.sizes.s16};

    strong {
      font-weight: 500;
    }
  }
`;

export const ListUrl = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.grayMenu};
  padding: ${(props) => props.theme.sizes.s16};
  border-radius: 8px;
  margin-bottom: ${(props) => props.theme.sizes.s8};

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .badges {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .actions {
      display: flex;
      gap: 12px;

      .action-clone {
        padding: 2px 8px;
        color: ${({ theme }) => theme.grayLight} !important;
        border: 1px solid ${({ theme }) => theme.grayLight} !important;
        font-size: 12px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          color: ${({ theme }) => theme.fontWhite} !important;
          border: 1px solid ${({ theme }) => theme.fontWhite} !important;
        }
      }

      .action-background {
        display: flex;
        align-items: center;
        transition: .3s all;

        img {
          width: 14px;
          height: 100%;
        }

        img:hover {
          cursor: pointer;
          color: transparent(.8);
        }

        .copy-icon{
          filter: invert(65%) sepia(0%) saturate(3%) hue-rotate(26deg) brightness(99%) contrast(91%);
        }

        .copy-icon:hover{
          filter: invert(100%) sepia(0%) saturate(6194%) hue-rotate(201deg) brightness(111%) contrast(89%);
        }

        .copy-check{
          filter: invert(70%) sepia(30%) saturate(4643%) hue-rotate(111deg) brightness(95%) contrast(101%);
        }

        .delete-icon{
          filter: invert(47%) sepia(79%) saturate(5950%) hue-rotate(3deg) brightness(105%) contrast(103%);
        }

        .delete-icon:hover{
          filter: invert(100%) sepia(0%) saturate(6194%) hue-rotate(201deg) brightness(111%) contrast(89%);
        }
      }

    }
  }

  hr {
    border: none;
    height: 1px;
    background-color: ${(props) => props.theme.grayBackground};
    margin: ${(props) => props.theme.sizes.s8} 0px;
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  gap: 8px;

  img {
    width: 14px;
    height: 16px;
  }

  img:hover {
    cursor: pointer;
    color: transparent(.8);
  }

  .copy-icon {
    filter: invert(65%) sepia(0%) saturate(3%) hue-rotate(26deg) brightness(99%) contrast(91%);
  }

  .copy-icon:hover{
    filter: invert(100%) sepia(0%) saturate(6194%) hue-rotate(201deg) brightness(111%) contrast(89%);
  }

  .copy-check{
    filter: invert(70%) sepia(30%) saturate(4643%) hue-rotate(111deg) brightness(95%) contrast(101%);
  }

  p {
    flex: 1;
    font-size: 14px;
    padding-left: ${(props) => props.theme.sizes.s8};
    padding-right: ${(props) => props.theme.sizes.s8};
    background-color: ${(props) => props.theme.grayMenu};
    outline: none;
    font-family: 'Noway';
    word-break: break-all;
  }
`;
