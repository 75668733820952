import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  margin-top: 16px;

  a {
    width: 100%;
    text-align: center;
  }
`;
