import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import ToastContainer from './components/Toast/ToastContainer';
import Router from './routes/Router';
import { AuthProvider } from './context/AuthContext';
import CreateGlobalStyle from './styles/global';
import { defaultTheme } from './styles/defaultTheme';
import ScrollToTop from './components/ScroolToTop';

function App() {
  const defaultThemeApp = defaultTheme();

  return (
    <ThemeProvider theme={defaultThemeApp}>
      <CreateGlobalStyle />
      <ToastContainer />
      <BrowserRouter>
        <AuthProvider>
          <ScrollToTop />
          <Router />
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
