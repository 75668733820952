import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import TitlePage from '../../../../../components/TitlePage';
import BackButton from '../../../../../components/BackButton';
import Square from '../../../../../components/Square';
import { Badge } from '../../../../../components/Badge';
import { ButtonLink } from '../../../../../components/ButtonLink';
import FaEditIcon from '../../../../../styles/logos/FaEdit.svg';
import { Translate } from './translate';
import {
  Container, TitleContainer, SearchContainer,
} from './style';

function ListUser({
  pageTitle, userList, userListFiltered, placeholderSearchTerm, linkAddButton, linkToBackButton, handleSearchTerm,
}) {
  const translate = Translate();

  function colorBadge(isActive) {
    return isActive === 'Y' ? 'green' : 'red';
  }

  return (
    <>
      <TitlePage title={pageTitle}>
        <BackButton to={linkToBackButton} />
      </TitlePage>
      <Container>
        {userList
        && (
        <SearchContainer>
          <div className="c-search">
            <label htmlFor="search-term">{translate.search}</label>
            <input type="text" id="search-term" placeholder={placeholderSearchTerm} onChange={handleSearchTerm} />
          </div>
          <div className="c-actions">
            <ButtonLink color="blue" to={linkAddButton}>{translate.labelButtonAddNew}</ButtonLink>
          </div>
        </SearchContainer>
        )}

        {!userList
          ? (<p>{translate.errorMsg}</p>)
          : (userListFiltered && userListFiltered?.map((user) => (
            <Square size="large" key={user.id}>
              <TitleContainer>
                <div className="c-users">
                  <div className="c-users-name">
                    {user.first_name}
                    {' '}
                    {user.last_name}
                  </div>
                  <Badge>
                    {user.email}
                  </Badge>
                  {user.profile && (
                    <Badge>
                      {user.profile}
                    </Badge>
                  )}
                  {user.client_name && (
                    <Badge>
                      {user.client_name}
                    </Badge>
                  )}
                  <Badge color={colorBadge(user.active)}>
                    Active |
                    {' '}
                    {user.active}
                  </Badge>
                </div>
                <div className="c-users-action">
                  <Link to={`/admin/users/edit/${user.id}`} title="Edit User">
                    <img src={FaEditIcon} alt="Edit User" />
                  </Link>
                </div>
              </TitleContainer>
            </Square>
          )))}
      </Container>
    </>
  );
}

ListUser.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  userList: PropTypes.array.isRequired,
  userListFiltered: PropTypes.array.isRequired,
  placeholderSearchTerm: PropTypes.string.isRequired,
  linkAddButton: PropTypes.string.isRequired,
  linkToBackButton: PropTypes.string.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
};

export default ListUser;
