import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { DataContext } from '../../../../../context/Data';
import { Translate } from './translate';

const TransactionsVtexTmkt = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    vtexRevenueWithoutFilterCurrent,
    vtexRevenueWithoutFilterPrevious,
    vtexRevenueWithoutFilterYoY,
    vtexRevenueWithoutFilterPreviousComplete,
    vtexRevenueWithoutFilterForecast,
  } = useContext(DataContext);

  const object = 'transactions';
  const current = vtexRevenueWithoutFilterCurrent?.filter((item) => item.origin_type === 'TMKT').reduce((acc, arr) => acc + Number(arr?.[object]), 0);
  const previous = vtexRevenueWithoutFilterPrevious?.filter((item) => item.origin_type === 'TMKT').reduce((acc, arr) => acc + Number(arr?.[object]), 0);
  const yoy = vtexRevenueWithoutFilterYoY?.filter((item) => item.origin_type === 'TMKT').reduce((acc, arr) => acc + Number(arr?.[object]), 0);
  const completeMonth = vtexRevenueWithoutFilterPreviousComplete?.filter((item) => item.origin_type === 'TMKT').reduce((acc, arr) => acc + Number(arr?.[object]), 0);

  const forecastData = vtexRevenueWithoutFilterForecast?.length > 0 && vtexRevenueWithoutFilterForecast?.filter((item) => item.origin_type === 'TMKT').map((data) => ({ date: data.date, income: Number(data[object]) }));
  const forecast = forecastData && (ForecastMonthly(forecastData) || 0);

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta red';
    }
    return 'delta green';
  }

  return (
    <tr>
      {showLevelColumn ? <td>3rd</td> : null}
      {showSourceColumn ? <td>VTEX</td> : null}
      <td>{translate.kpi}</td>
      <td>{numberFormat(current, 'number', 0)}</td>
      <td>{numberFormat(previous, 'number', 0)}</td>
      <td className={classNameAtrr(deltaNormal)}>
        {numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(yoy, 'number', 0)}</td>
      <td className={classNameAtrr(deltaYoY)}>
        {numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(forecast, 'number', 0)}</td>
      <td>{numberFormat(completeMonth, 'number', 0)}</td>
      <td className={classNameAtrr(deltaForecast)}>
        {numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

TransactionsVtexTmkt.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default TransactionsVtexTmkt;
