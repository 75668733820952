import React from 'react';
import Proptypes from 'prop-types';

import * as S from './style';

export default function Square({ children, size }) {
  return (
    <S.Container size={size}>
      {children}
    </S.Container>
  );
}

Square.defaultProps = {
  size: 'small',
};

Square.propTypes = {
  size: Proptypes.oneOf(['small', 'medium', 'large']),
  children: Proptypes.node.isRequired,
};
