import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavContainer = styled.div`
  margin: auto;
  background-color: ${({ theme }) => theme.grayMenu};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
      flex-direction: column;
      height: 100%;
      gap: 24px;
      padding: 20px;
  }

  & * {
    background-color: ${({ theme }) => theme.grayMenu};
  }

  > img {
    cursor: pointer;
    color: ${({ theme }) => theme.yellow};
    width: 40px;
    transform: ${({ $toogle }) => ($toogle ? 'rotate(0)' : 'rotate(-180deg)')};
    transition: .4s ease-in-out;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
      margin-left: auto;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  height: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
      flex-direction: column;
      position: relative;
    }
`;

export const ButtonMetting = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.yellow};;
  border-radius: 4px;
  padding: 16px 20px;
  text-decoration: none;
  transition: .3s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.fontWhite};

    span {
      color: ${({ theme }) => theme.fontWhite};
    }
  }

  span {
    transition: .3s ease-in-out;
    color: ${({ theme }) => theme.yellow};
  }
`;

export const UlUser = styled.ul`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  min-width: 180px;
  border: 1px solid ${({ theme }) => theme.grayDark};
  border-radius: 4px;
  position: relative;
  gap: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
    width: 100%;
    position: initial;
  }

  .c-user {
    display: flex;
    flex-direction: column;
  }

  .c-user__email {
    font-size: 12px;
    color: ${({ theme }) => theme.grayLight}
  }

  .arrow {
    margin-top: -10px;
  }
`;

export const ContainerList = styled.div`
  display: ${({ $showmenu }) => ($showmenu ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 250px;
  border: 1px solid ${({ theme }) => theme.grayDark};
  z-index: 10;
  border-radius: 4px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
    top: 100%;
  }

  a {
    text-decoration: none;


    img {
      width: 20px;
    }
  }

  a:hover * {
    background-color: ${({ theme }) => theme.grayBackground};
  }

  li {
    border-radius: 4px;
    list-style: none;
    padding: 12px 0px 12px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
