import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-top: 16px;

  a {
    width: 100%;
    text-align: center;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;

  .c-search {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 2;

    input {
      width:  100%;
    }
  }

  .c-actions {
    a {
      width: 100%;
    }
    input {
      width:  100%;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 19px;
  }

  img:hover {
    cursor: pointer;
    filter: invert(40%) sepia(6%) saturate(233%) hue-rotate(187deg) brightness(95%) contrast(84%)
  }

  .c-client {
    display: flex;
    flex-wrap: wrap;
  }

  .c-client-name {
    width: 200px;
    max-width: 200px;
    word-break: keep-all;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-bottom: 16px;
    }
  }

  .c-client-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;
  }

  .c-client-actions {
    display: flex;
    gap: 8px;
  }
`;
