import React from 'react';
import TitlePage from '../../../../../components/TitlePage';
import { Button } from '../../../../../components/Button';
import BackButton from '../../../../../components/BackButton';
import Square from '../../../../../components/Square';
import ButtonsSaveCancel from '../../../../../components/ButtonsSaveCancel';
import { Translate } from './translate';
import {
  Container, InfoContainer, ListContainer,
} from './style';
import useSubCategoryAdd from './useSubCategoryAdd';

function SubCategoryAdd() {
  const translate = Translate();
  const {
    subCategory,
    setSubCategory,
    productsList,
    categoriesList,
    productSelected,
    setProductSelected,
    categorySelected,
    handleCategory,
    isDisabled,
    handleGenerateSlug,
    saveSubCategoryProduct,
    isLoadingContent,
  } = useSubCategoryAdd();

  return (
    <>
      {isLoadingContent ? 'Loading Infos' : (
        <>
          <TitlePage title={translate.title}>
            <BackButton />
          </TitlePage>
          <Container>
            <ListContainer>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textName}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <label htmlFor="name_pt">{translate.labelNamePt}</label>
                      <input
                        className="l-input-large"
                        type="text"
                        id="name_pt"
                        value={subCategory.namePt}
                        onChange={(e) => {
                          setSubCategory({ ...subCategory, namePt: e.target.value });
                        }}
                      />
                    </div>
                    <div className="c-input">
                      <label htmlFor="name_en">{translate.labelNameEn}</label>
                      <input
                        type="text"
                        className="l-input-large"
                        id="name_en"
                        value={subCategory.nameEn}
                        onChange={(e) => {
                          setSubCategory({ ...subCategory, nameEn: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </InfoContainer>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textOrdering}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <input
                        type="text"
                        id="ordering"
                        value={subCategory.ordering}
                        onChange={(e) => {
                          setSubCategory({ ...subCategory, ordering: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </InfoContainer>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textPost}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <select
                        type="text"
                        id="has_post"
                        value={subCategory.hasPosts}
                        onChange={(e) => setSubCategory({ ...subCategory, hasPosts: e.target.value })}
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>
                    </div>
                  </div>
                </InfoContainer>
                <div className="c-explanation">{translate.warningPosts}</div>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    Slug
                  </div>
                  <div className="c-right">
                    <Button color="yellow" onClick={handleGenerateSlug}>Gerar slug</Button>
                    <div className="c-input">
                      <input
                        className="l-input-w"
                        type="text"
                        id="slug"
                        value={subCategory.slug}
                        onChange={(e) => {
                          setSubCategory({ ...subCategory, slug: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </InfoContainer>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textCategory}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <label htmlFor="product">{translate.labelProduct}</label>
                      <select
                        type="text"
                        className="l-input-large"
                        id="product"
                        value={productSelected}
                        onChange={(e) => setProductSelected(e.target.value)}
                      >
                        {productsList && (
                          productsList?.map((product) => (
                            <option value={product.id} key={product.id}>
                              {translate.language === 'pt-BR' ? product.namePt : product.nameEn }
                            </option>
                          )))}
                      </select>
                    </div>
                    {categoriesList.length > 0 ? (
                      <div className="c-input">
                        <label htmlFor="product">{translate.labelCategory}</label>
                        <select
                          type="text"
                          className="l-input-large"
                          id="product"
                          value={categorySelected}
                          onChange={(e) => {
                            handleCategory(e.target.value);
                          }}
                        >
                          {categoriesList && (
                            categoriesList?.map((category) => (
                              <option value={category.id} key={category.id}>
                                {translate.language === 'pt-BR' ? category.namePt : category.nameEn }
                              </option>
                            )))}
                        </select>
                      </div>
                    ) : null}
                  </div>
                </InfoContainer>
                <div className="c-explanation">{translate.warningProduct}</div>
              </Square>
            </ListContainer>
            <ButtonsSaveCancel
              onSave={saveSubCategoryProduct}
              goToWhenCancel="/admin/sub-categoriesList"
              disabled={isDisabled}
              smallText={isDisabled ? translate.warnText : ''}
            />
          </Container>
        </>
      )}
    </>
  );
}

export default SubCategoryAdd;
