import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  margin-bottom: 24px;

  * {
    margin: 0;
    padding: 0;
  }

  h3 {
    color: ${({ theme }) => theme.grayLight};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-top: 32px;
      text-align: center;
    }
  }

`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;

  .container-last-videos {
    h3 {
      margin-bottom: 16px;
    }

    .container-last-videos-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      //square
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

         .l-video {
          background-color: ${({ theme }) => theme.gray1};
          padding: 4px 14px 4px 8px;
          border-top-right-radius: 45px;
          border-bottom-right-radius: 45px;
          position: relative;
        }

        .video-product {
          z-index: 4 !important;
        }

        .video-category {
          background-color: ${({ theme }) => theme.gray2};
          padding: 4px 14px 4px 16px;
          margin-left: -10px;
          z-index: 3 !important;
        }

        .video-subcategory {
          background-color: ${({ theme }) => theme.gray3};
          color: ${({ theme }) => theme.fontWhite};
          padding: 4px 8px 4px 16px;
          margin-left: -10px;
          z-index: 2 !important;
        }

        .video-post {
          background-color: ${({ theme }) => theme.gray4};
          color: ${({ theme }) => theme.gray1};
          padding: 4px 8px 4px 16px;
          margin-left: -10px;
          z-index: 1 !important;
        }
      }
    }
  }
`;
