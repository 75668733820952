import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { FilterContext } from '../Filters';
import { DateContext } from '../../../../../context/DateContext';
import LeadsGoogleAnalitycs from '../../../../../services/LeadsGoogleAnalitycs';
import GoogleAnalitycs from '../../../../../services/GoogleAnalitycs';
import GoogleAnalitycsGoogleAds from '../../../../../services/GoogleAnalitycsGoogleAds';
import GoogleAnalitycsOthersPlatforms from '../../../../../services/GoogleAnalitycsOthersPlatforms';
import MetaAds from '../../../../../services/MetaAds';
import LinkedInAds from '../../../../../services/LinkedInAds';
import GoogleAdwords from '../../../../../services/GoogleAdwords';
import { ListKpis } from './ListKpis';
import { ListUserViewOptions } from './ListUserViewOptions';
// eslint-disable-next-line import/no-cycle
import { Session } from './KPIs/Session';
import { Users } from './KPIs/Users';
import { Transactions } from './KPIs/Transactions';
import { NewUsers } from './KPIs/NewUsers';
import { EngagedSessions } from './KPIs/EngagedSessions';
import { Revenue } from './KPIs/Revenue';
import { ConvRateSessions } from './KPIs/ConvRateSession';
import { ConvRateNewUsers } from './KPIs/ConvRateNewUsers';
import { ConvRateUsers } from './KPIs/ConvRateUsers';
import { Events } from './KPIs/Events';
import { ConvRateEventsSessions } from './KPIs/ConvRateEventsSession';
import { ConvRateEventsUsers } from './KPIs/ConvRateEventsUsers';
import { ConvRateEventsNewUsers } from './KPIs/ConvRateEventsNewUsers';
import { Cost } from './KPIs/Cost';
import { Clicks } from './KPIs/Clicks';
import { Impressions } from './KPIs/Impressions';
import { Roas } from './KPIs/ROAS';
import { AOV } from './KPIs/AOV';
import { CPM } from './KPIs/CPM';
import { CTR } from './KPIs/CTR';
import { CPC } from './KPIs/CPC';
import { ClicksRatio } from './KPIs/ClicksRatio';
import { EstimatedCPC } from './KPIs/EstimatedCPC';
import { CPLEvents } from './KPIs/CPLEvents';

const DataChannelsOverviewContext = createContext();

function DataChannelsOverviewProvider({ children }) {
  const { infoDateSelected } = useContext(DateContext);
  const {
    accountName,
    accountNameApplied,
    platformApplied,
    metaIdApplied,
    regionApplied,
    strategyApplied,
    channelApplied,
    utmCampaignApplied,
  } = useContext(FilterContext);
  // Filters
  const kpiLoaded = useMemo(() => ListKpis().filter((kpi) => kpi.id === 12), [ListKpis]);
  const [kpiSelected, setKpiSelected] = useState(kpiLoaded[0]);
  const [userViewList] = useState(ListUserViewOptions());
  const [userViewSelected, setUserViewSelected] = useState(ListUserViewOptions()[1]);
  const [event, setEvent] = useState('');
  const [eventList, setEventList] = useState([]);
  const [isLoadingEventList, setIsLoadingEventList] = useState(true);
  const [isLoadingAllDatas, setIsLoadingAllDatas] = useState(true);

  // Datas
  const [googleAdwordsCurrent, setGoogleAdwordsCurrent] = useState([]);
  const [googleAdwordsPrevious, setGoogleAdwordsPrevious] = useState([]);
  const [googleAnalyticsCurrent, setGoogleAnalyticsCurrent] = useState([]);
  const [googleAnalyticsPrevious, setGoogleAnalyticsPrevious] = useState([]);
  const [googleAnalyticsFilterGoogleAdsCurrent, setGoogleAnalyticsFilterGoogleAdsCurrent] = useState([]);
  const [googleAnalyticsFilterGoogleAdsPrevious, setGoogleAnalyticsFilterGoogleAdsPrevious] = useState([]);
  const [googleAnalyticsFilterOthersSourcesCurrent, setGoogleAnalyticsFilterOthersSourcesCurrent] = useState([]);
  const [googleAnalyticsFilterOthersSourcesPrevious, setGoogleAnalyticsFilterOthersSourcesPrevious] = useState([]);
  const [metaAdsCurrent, setMetaAdsCurrent] = useState([]);
  const [metaAdsPrevious, setMetaAdsPrevious] = useState([]);
  const [linkedinAdsCurrent, setLinkedinCurrent] = useState([]);
  const [linkedinAdsPrevious, setLinkedinAdsPrevious] = useState([]);
  const [leadsAnalyticsCurrent, setLeadsAnalyticsCurrent] = useState([]);
  const [leadsAnalyticsPrevious, setLeadsAnalyticsPrevious] = useState([]);

  // Data to show
  const [kpisInfo, setKpisInfo] = useState('');

  useEffect(
    () => buildViewTableByUserView(),
    [
      googleAdwordsCurrent,
      googleAdwordsPrevious,
      googleAnalyticsCurrent,
      googleAnalyticsPrevious,
      googleAnalyticsFilterGoogleAdsCurrent,
      googleAnalyticsFilterGoogleAdsPrevious,
      googleAnalyticsFilterOthersSourcesCurrent,
      googleAnalyticsFilterOthersSourcesPrevious,
      metaAdsCurrent,
      metaAdsPrevious,
      linkedinAdsCurrent,
      linkedinAdsPrevious,
      leadsAnalyticsCurrent,
      leadsAnalyticsPrevious,
      userViewSelected,
      event,
      kpiSelected,
    ],
  );

  function buildViewTableByUserView() {
    let group = '';
    if (userViewSelected.id === 1) group = 'platform';
    if (userViewSelected.id === 2) group = 'channel';
    if (userViewSelected.id === 3) group = 'campaign';
    if (userViewSelected.id === 4) group = 'product';

    if (kpiSelected.id === 1) {
      const kpisInfo = Revenue(group, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 2) {
      const kpisInfo = Cost(group, metaAdsCurrent, metaAdsPrevious, linkedinAdsCurrent, linkedinAdsPrevious, googleAdwordsCurrent, googleAdwordsPrevious, googleAnalyticsFilterOthersSourcesCurrent, googleAnalyticsFilterOthersSourcesPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 3) {
      const kpisInfo = Roas(group, googleAnalyticsCurrent, googleAnalyticsPrevious, metaAdsCurrent, metaAdsPrevious, linkedinAdsCurrent, linkedinAdsPrevious, googleAdwordsCurrent, googleAdwordsPrevious, googleAnalyticsFilterOthersSourcesCurrent, googleAnalyticsFilterOthersSourcesPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 4) {
      const kpisInfo = AOV(group, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 5) {
      const kpisInfo = Impressions(group, metaAdsCurrent, metaAdsPrevious, linkedinAdsCurrent, linkedinAdsPrevious, googleAdwordsCurrent, googleAdwordsPrevious, googleAnalyticsFilterOthersSourcesCurrent, googleAnalyticsFilterOthersSourcesPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 6) {
      const kpisInfo = CPM(group, metaAdsCurrent, metaAdsPrevious, linkedinAdsCurrent, linkedinAdsPrevious, googleAdwordsCurrent, googleAdwordsPrevious, googleAnalyticsFilterOthersSourcesCurrent, googleAnalyticsFilterOthersSourcesPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 7) {
      const kpisInfo = CTR(group, metaAdsCurrent, metaAdsPrevious, linkedinAdsCurrent, linkedinAdsPrevious, googleAdwordsCurrent, googleAdwordsPrevious, googleAnalyticsFilterOthersSourcesCurrent, googleAnalyticsFilterOthersSourcesPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 8) {
      const kpisInfo = CPC(group, metaAdsCurrent, metaAdsPrevious, linkedinAdsCurrent, linkedinAdsPrevious, googleAdwordsCurrent, googleAdwordsPrevious, googleAnalyticsFilterOthersSourcesCurrent, googleAnalyticsFilterOthersSourcesPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 9) {
      const kpisInfo = Clicks(group, metaAdsCurrent, metaAdsPrevious, linkedinAdsCurrent, linkedinAdsPrevious, googleAdwordsCurrent, googleAdwordsPrevious, googleAnalyticsFilterOthersSourcesCurrent, googleAnalyticsFilterOthersSourcesPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 10) {
      const kpisInfo = ClicksRatio(group, googleAnalyticsCurrent, googleAnalyticsPrevious, metaAdsCurrent, metaAdsPrevious, linkedinAdsCurrent, linkedinAdsPrevious, googleAdwordsCurrent, googleAdwordsPrevious, googleAnalyticsFilterOthersSourcesCurrent, googleAnalyticsFilterOthersSourcesPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 11) {
      const kpisInfo = EstimatedCPC(group, googleAnalyticsCurrent, googleAnalyticsPrevious, metaAdsCurrent, metaAdsPrevious, linkedinAdsCurrent, linkedinAdsPrevious, googleAdwordsCurrent, googleAdwordsPrevious, googleAnalyticsFilterOthersSourcesCurrent, googleAnalyticsFilterOthersSourcesPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 12) {
      const kpisInfo = Session(group, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 13) {
      const kpisInfo = EngagedSessions(group, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 14) {
      const kpisInfo = Users(group, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 15) {
      const kpisInfo = NewUsers(group, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 16) {
      const kpisInfo = Transactions(group, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 17) {
      const kpisInfo = ConvRateSessions(group, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 18) {
      const kpisInfo = ConvRateUsers(group, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 19) {
      const kpisInfo = ConvRateNewUsers(group, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 20) {
      const kpisInfo = Events(group, leadsAnalyticsCurrent, leadsAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 21) {
      const kpisInfo = CPLEvents(group, leadsAnalyticsCurrent, leadsAnalyticsPrevious, metaAdsCurrent, metaAdsPrevious, linkedinAdsCurrent, linkedinAdsPrevious, googleAdwordsCurrent, googleAdwordsPrevious, googleAnalyticsFilterOthersSourcesCurrent, googleAnalyticsFilterOthersSourcesPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 22) {
      const kpisInfo = ConvRateEventsSessions(group, leadsAnalyticsCurrent, leadsAnalyticsPrevious, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 23) {
      const kpisInfo = ConvRateEventsUsers(group, leadsAnalyticsCurrent, leadsAnalyticsPrevious, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }

    if (kpiSelected.id === 24) {
      const kpisInfo = ConvRateEventsNewUsers(group, leadsAnalyticsCurrent, leadsAnalyticsPrevious, googleAnalyticsCurrent, googleAnalyticsPrevious);
      setKpisInfo(kpisInfo);
    }
  }

  // Get Events List to fill the Event's input from Channels Overview Card
  useEffect(() => {
    if (accountNameApplied || accountName) {
      try {
        setIsLoadingEventList(true);
        async function getEventList() {
          const accountNameSelected = accountNameApplied || accountName;
          const eventListData = await LeadsGoogleAnalitycs.getLeadsTypes(accountNameSelected);
          setEventList(eventListData);
        }
        getEventList();
      } catch { } finally {
        setIsLoadingEventList(false);
      }
    }
  }, [accountName, accountNameApplied]);

  // Get Datas when upload the screen or upload filters
  useEffect(
    () => {
      async function fecthDatas() {
        setIsLoadingAllDatas(true);
        try {
          if (accountNameApplied || accountName) {
            const accountNameToSend = accountNameApplied || accountName;
            const [
              googleAdwordsDataCurrent,
              googleAdwordsDataPrevious,

              googleAnaliticysDataCurrent,
              googleAnaliticysDataPrevious,

              googleAnaliticysFilterGoogleAdsDataCurrent,
              googleAnaliticysFilterGoogleAdsDataPrevious,

              googleAnaliticysFilterOtherSourcesDataCurrent,
              googleAnaliticysFilterOtherSourcesDataPrevious,

              metaAdsDataCurrent,
              metaAdsDataPrevious,

              linkedinAdsDataCurrent,
              linkedinAdsDataPrevious,

              leadGoogleDataCurrent,
              leadGoogleDataPrevious,
            ] = await Promise.allSettled([
              getDataFromGoogleAdwords(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
              getDataFromGoogleAdwords(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

              getDataFromGoogleAnalitycs(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
              getDataFromGoogleAnalitycs(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

              getDataFromGoogleAnalitycsFilterGoogleAds(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
              getDataFromGoogleAnalitycsFilterGoogleAds(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

              getDataFromGoogleAnalitycsFilterOthersPlatforms(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
              getDataFromGoogleAnalitycsFilterOthersPlatforms(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

              getDataFromMetaAds(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
              getDataFromMetaAds(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, metaIdApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

              getDataFromLinkedinAds(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
              getDataFromLinkedinAds(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),

              getDataFromLeadsGoogleAnalitycs(accountNameToSend, infoDateSelected?.init?.value, infoDateSelected?.end?.value, platformApplied, event, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
              getDataFromLeadsGoogleAnalitycs(accountNameToSend, infoDateSelected?.initPrev?.value, infoDateSelected?.endPrev?.value, platformApplied, event, regionApplied, strategyApplied, channelApplied, utmCampaignApplied),
            ]);

            setGoogleAdwordsCurrent(googleAdwordsDataCurrent.value);
            setGoogleAdwordsPrevious(googleAdwordsDataPrevious.value);

            setGoogleAnalyticsCurrent(googleAnaliticysDataCurrent.value);
            setGoogleAnalyticsPrevious(googleAnaliticysDataPrevious.value);

            setGoogleAnalyticsFilterGoogleAdsCurrent(googleAnaliticysFilterGoogleAdsDataCurrent.value);
            setGoogleAnalyticsFilterGoogleAdsPrevious(googleAnaliticysFilterGoogleAdsDataPrevious.value);

            setGoogleAnalyticsFilterOthersSourcesCurrent(googleAnaliticysFilterOtherSourcesDataCurrent.value);
            setGoogleAnalyticsFilterOthersSourcesPrevious(googleAnaliticysFilterOtherSourcesDataPrevious.value);

            setMetaAdsCurrent(metaAdsDataCurrent.value);
            setMetaAdsPrevious(metaAdsDataPrevious.value);

            setLinkedinCurrent(linkedinAdsDataCurrent.value);
            setLinkedinAdsPrevious(linkedinAdsDataPrevious.value);

            setLeadsAnalyticsCurrent(leadGoogleDataCurrent.value);
            setLeadsAnalyticsPrevious(leadGoogleDataPrevious.value);
          }
        } catch {} finally {
          setIsLoadingAllDatas(false);
        }
      }
      fecthDatas();
      return () => {};
    },
    [
      accountNameApplied,
      metaIdApplied,
      platformApplied,
      regionApplied,
      strategyApplied,
      channelApplied,
      utmCampaignApplied,
      infoDateSelected,
      event,
    ],
  );

  async function getDataFromGoogleAdwords(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaing) {
    const data = await GoogleAdwords.getTotal(accountName, platform, dateInit, dateEnd, region, strategy, channel, utmCampaing);
    return data;
  }

  async function getDataFromGoogleAnalitycs(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign) {
    const data = await GoogleAnalitycs.getTotal(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign);
    return data;
  }

  async function getDataFromGoogleAnalitycsFilterGoogleAds(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign) {
    const data = await GoogleAnalitycsGoogleAds.getTotal(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign);
    return data;
  }

  async function getDataFromGoogleAnalitycsFilterOthersPlatforms(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign) {
    const data = await GoogleAnalitycsOthersPlatforms.getTotal(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign);
    return data;
  }

  async function getDataFromMetaAds(accountName, dateInit, dateEnd, platform, metaId, region, strategy, channel, utmCampaign) {
    const data = await MetaAds.getTotal(accountName, dateInit, dateEnd, platform, metaId, region, strategy, channel, utmCampaign);
    return data;
  }

  async function getDataFromLinkedinAds(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign) {
    const data = await LinkedInAds.getTotal(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign);
    return data;
  }

  async function getDataFromLeadsGoogleAnalitycs(accountName, dateInit, dateEnd, platform, leadType, region, strategy, channel, utmCampaign) {
    const data = await LeadsGoogleAnalitycs.getTotal(accountName, dateInit, dateEnd, platform, leadType, region, strategy, channel, utmCampaign);
    return data;
  }


  const contextData = useMemo(() => ({
    ListKpis,
    kpiSelected,
    setKpiSelected,
    userViewList,
    userViewSelected,
    setUserViewSelected,
    event,
    setEvent,
    eventList,
    isLoadingEventList,
    kpisInfo,
    isLoadingAllDatas,
  }), [
    ListKpis,
    kpiSelected,
    setKpiSelected,
    userViewList,
    userViewSelected,
    setUserViewSelected,
    event,
    setEvent,
    eventList,
    isLoadingEventList,
    kpisInfo,
    isLoadingAllDatas,
  ]);

  return (
    <DataChannelsOverviewContext.Provider value={contextData}>
      {children}
    </DataChannelsOverviewContext.Provider>
  );
}

export { DataChannelsOverviewContext, DataChannelsOverviewProvider };
