import React from 'react';
import { PropTypes } from 'prop-types';
import { URLContainer } from './style';
import { Translate } from './translate';

function URLDestination({
  url, setUrl, schemaUrl, setSchemaUrl,
}) {
  const translate = Translate();

  return (
    <URLContainer>
      <p className="url-label">{translate.urlText}</p>
      <div className="url-container">
        <select onChange={(e) => setSchemaUrl(e.target.value)} value={schemaUrl}>
          <option value="https://" defaultValue autoFocus>https://</option>
          <option value="http://">http://</option>
        </select>
        <div className="url-input-container">
          <input
            type="text"
            placeholder={translate.urlPlaceholder}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <div className="url-result-container">
            {url.length > 0 && (
              <>
                <span className="url-format">{translate.yourURL}: </span>
                <span className="url-url">{schemaUrl}{url}</span>
                <span>/?utm_source=[......]&utm_medium=[......]&utm_campaign=[......]</span>
              </>
            )}
          </div>
        </div>
      </div>
    </URLContainer>
  );
}

URLDestination.propTypes = {
  url: PropTypes.string.isRequired,
  schemaUrl: PropTypes.string.isRequired,
  setUrl: PropTypes.func.isRequired,
  setSchemaUrl: PropTypes.func.isRequired,
};

export default URLDestination;
