export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    phrase1: language === 'en-US'
      ? 'Forgot your password? No problem!'
      : 'Esqueceu sua senha? Sem problemas!',
    phrase2: language === 'en-US' ? 'Send your email below and we will send you instructions.' : 'Envie seu e-mail abaixo e mandaremos as instruções.',
    placeholder: language === 'en-US' ? 'Enter your email' : 'Insira seu e-mail',
    btn: language === 'en-US' ? 'Submit' : 'Enviar',
    backHome: language === 'en-US' ? 'Back to login page' : 'Voltar para a página de login',
  };
}
