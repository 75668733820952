import React, {
  useState, useRef, useMemo, useEffect,
} from 'react';
import './style.css';
import JoditEditor from 'jodit-react';

export function Editor({ placeholder, onHandleDescription, contentFromDb = '' }) {
  const editor = useRef(null);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (contentFromDb) {
      setContent(contentFromDb);
    }
  }, [contentFromDb]);

  const config = useMemo(() => ({
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: placeholder || '',
    height: 400,
  }), [placeholder]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <JoditEditor
      className="joditeditor"
      ref={editor}
      value={content}
      config={config}
      tabIndex={0} // tabIndex of textarea
      onBlur={(newContent) => onHandleDescription(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => {}}
    />
  );
}
