import React from 'react';
import ApexChart from 'react-apexcharts';
import './PieChart.css';

export function ChartPie({ kpiInfo, type }) {
  const typeInfo = type === 'current' ? 'currentNumber' : 'previousNumber';
  let seriesData = [];
  let labelsData = [];

  if (kpiInfo) {
    const { data } = kpiInfo;
    seriesData = data.slice(0, 6).map((item) => {
      const isInvalidNumber = isNaN(Number(item[typeInfo].toFixed(2))) || Number(item[typeInfo].toFixed(2)) === Infinity;
      if (isInvalidNumber) return 0;
      return Number(item[typeInfo].toFixed(2));
    });
    labelsData = data.slice(0, 6).map((item) => item.view.substring(0, 20));
  }

  const confs = {
    series: seriesData,
    options: {
      chart: {
        width: 250,
        type: 'pie',
        height: 300,
        fontFamily: 'noway, Arial, sans-serif',
      },
      legend: {
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '11px',
      },
      labels: labelsData,
      colors: ['#6EDCE6', '#FC4C02', '#00D689', '#FFD100', '#FFDEFF', '#994848'],
      responsive: [{
        breakpoint: 320,
        options: {
          chart: {
            width: 80,
          },
          legend: {
            position: 'bottom',
          },
        },
      }],
      stroke: {
        show: false, // Esta opção tenta ocultar as bordas das fatias
      },
    },
  };
  return (
    <div className="pie-chart-container">
      <ApexChart
        options={confs.options}
        series={confs.series}
        type={confs.options.chart.type}
        width={confs.options.chart.width}
      />
    </div>
  );
}


