import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import UTMService from '../../../../../services/UTM';
import Event from '../../../../../lib/EventEmitter';
import Loader from '../../../../../components/Loader';
import { Translate } from './translate';
import IconAdd from '../../../../../styles/logos/add.svg';

function StrategyType({
  value, setValue, onToogleModal, setUtmCategoryToAdd, tipOfAddParams,
}) {
  const translate = Translate();
  const [paramsCustomList, setParamsCustomList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idParam] = useState(9);

  useEffect(() => {
    getCustomsUTMSources();
    Event.addListener('IDCustomParamAdded', (idParamToAdd) => {
      if (idParamToAdd === idParam) {
        getCustomsUTMSources();
      }
    });
  }, []);

  async function getCustomsUTMSources() {
    setIsLoading(true);
    const listofParamsCustom = await UTMService.getAllBYCategoryUTM(idParam);
    setParamsCustomList(listofParamsCustom);
    setIsLoading(false);
  }

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, strategyType: event.target.value }));
  }

  function handleToogleModal() {
    setUtmCategoryToAdd(idParam);
    onToogleModal();
  }

  return (
    <div className="form-item">
      <label htmlFor="strategy-type">
        {translate.label}
        <img src={IconAdd} alt="Add" title={tipOfAddParams} onClick={handleToogleModal} />
      </label>
      <select value={value} onChange={handleSelect} id="strategy-type" disabled={isLoading}>
        <option value="" className="noneParam">None</option>
        {paramsCustomList && paramsCustomList.map((item) => (
          <option key={item.id} value={item.value} className="customParam">
            {' '}
            {item.name}
            {' '}
          </option>
        ))}
        <option value="all">all</option>
        <option value="aquisicao">aquisicao</option>
        <option value="aquisicao-cpc">aquisicao-cpc</option>
        <option value="aquisicao-cpm">aquisicao-cpm</option>
        <option value="awareness">awareness</option>
        <option value="awareness-cpc">awareness-cpc</option>
        <option value="awareness-cpm">awareness-cpm</option>
        <option value="awareness-cpv">awareness-cpv</option>
        <option value="conversao">conversao</option>
        <option value="conversao-cpa">conversao-cpa</option>
        <option value="conversao-cpc">conversao-cpc</option>
        <option value="goodlesyndication">goodlesyndication</option>
        <option value="manutencao">manutencao</option>
        <option value="mobile-optimization">mobile-optimization</option>
        <option value="other">other</option>
        <option value="reengajamento">reengajamento</option>
        <option value="referral">referral</option>
        <option value="remarketing">remarketing</option>
        <option value="remarketing-cpa">remarketing-cpa</option>
        <option value="remarketing-cpc">remarketing-cpc</option>
        <option value="targeting">targeting</option>
        <option value="trafego-cpc">trafego-cpc</option>
        <option value="trafego-cpm">trafego-cpm</option>
      </select>
      { isLoading && <Loader loading={isLoading} tofield /> }
    </div>
  );
}

StrategyType.propTypes = {
  value: PropTypes.string.isRequired,
  tipOfAddParams: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onToogleModal: PropTypes.func.isRequired,
  setUtmCategoryToAdd: PropTypes.func.isRequired,
};

export default StrategyType;
