import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { DataContext } from '../../../../../context/Data';

import { Translate } from './translate';

const CPMTr = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    googleAdwordsCurrent, googleAdwordsPrevious, googleAdwordsYoY, googleAdwordsPreviousComplete, googleAdwordsForecast,
    googleAnalyticsOthersPlatformsCurrent, googleAnalyticsOthersPlatformsPrevious, googleAnalyticsOthersPlatformsYoY, googleAnalyticsOthersPlatformsPreviousComplete, googleAnalyticsOthersPlatformsForecast,
    metaAdsCurrent, metaAdsPrevious, metaAdsYoY, metaAdsPreviousComplete, metaAdsForecast,
    linkedinAdsCurrent, linkedinAdsPrevious, linkedinAdsYoY, linkedinAdsPreviousComplete, linkedinAdsForecast,
  } = useContext(DataContext);

  const objectDataFirst = 'clicks';
  const objectDataSecond = 'impressions';

  // Current Date
  const currentDataFirst = (Number(googleAdwordsCurrent?.[objectDataFirst]) || 0)
                            + (Number(metaAdsCurrent?.[objectDataFirst]) || 0)
                            + (Number(linkedinAdsCurrent?.[objectDataFirst]) || 0)
                            + (Number(googleAnalyticsOthersPlatformsCurrent?.[objectDataFirst]) || 0);
  const currentDataSecond = (Number(googleAdwordsCurrent?.[objectDataSecond]) || 0)
                            + (Number(metaAdsCurrent?.[objectDataSecond]) || 0)
                            + (Number(linkedinAdsCurrent?.[objectDataSecond]) || 0)
                            + (Number(googleAnalyticsOthersPlatformsCurrent?.[objectDataSecond]) || 0);
  const current = accountKPI(currentDataFirst, currentDataSecond);

  // Previous Date
  const previousDataFirst = (Number(googleAdwordsPrevious?.[objectDataFirst]) || 0)
                              + (Number(metaAdsPrevious?.[objectDataFirst]) || 0)
                              + (Number(linkedinAdsPrevious?.[objectDataFirst]) || 0)
                              + (Number(googleAnalyticsOthersPlatformsPrevious?.[objectDataFirst]) || 0);
  const previousDataSecond = (Number(googleAdwordsPrevious?.[objectDataSecond]) || 0)
                              + (Number(metaAdsPrevious?.[objectDataSecond]) || 0)
                              + (Number(linkedinAdsPrevious?.[objectDataSecond]) || 0)
                              + (Number(googleAnalyticsOthersPlatformsPrevious?.[objectDataSecond]) || 0);
  const previous = accountKPI(previousDataFirst, previousDataSecond);

  // YoY
  const yoyDataFirst = (Number(googleAdwordsYoY?.[objectDataFirst]) || 0)
                        + (Number(metaAdsYoY?.[objectDataFirst]) || 0)
                        + (Number(linkedinAdsYoY?.[objectDataFirst]) || 0)
                        + (Number(googleAnalyticsOthersPlatformsYoY?.[objectDataFirst]) || 0);
  const yoyDataSecond = (Number(googleAdwordsYoY?.[objectDataSecond]) || 0)
                        + (Number(metaAdsYoY?.[objectDataSecond]) || 0)
                        + (Number(linkedinAdsYoY?.[objectDataSecond]) || 0)
                        + (Number(googleAnalyticsOthersPlatformsYoY?.[objectDataSecond]) || 0);
  const yoy = accountKPI(yoyDataFirst, yoyDataSecond);

  // Complete Month
  const completeMonthDataFirst = (Number(googleAdwordsPreviousComplete?.[objectDataFirst]) || 0)
                                  + (Number(metaAdsPreviousComplete?.[objectDataFirst]) || 0)
                                  + (Number(linkedinAdsPreviousComplete?.[objectDataFirst]) || 0)
                                  + (Number(googleAnalyticsOthersPlatformsPreviousComplete?.[objectDataFirst]) || 0);
  const completeMonthDataSecond = (Number(googleAdwordsPreviousComplete?.[objectDataSecond]) || 0)
                                  + (Number(metaAdsPreviousComplete?.[objectDataSecond]) || 0)
                                  + (Number(linkedinAdsPreviousComplete?.[objectDataSecond]) || 0)
                                  + (Number(googleAnalyticsOthersPlatformsPreviousComplete?.[objectDataSecond]) || 0);
  const completeMonth = accountKPI(completeMonthDataFirst, completeMonthDataSecond);

  // Forecast
  const forecastGoogleAdwordsDataFirst = googleAdwordsForecast?.length > 0 && googleAdwordsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataFirst]) }));
  const forecastMetaAdsDataFirst = metaAdsForecast?.length > 0 && metaAdsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataFirst]) }));
  const forecastLinkedinAdsDataFirst = linkedinAdsForecast?.length > 0 && linkedinAdsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataFirst]) }));
  const forecastGoogleAnalyticsOthersPlatformsDataFirst = googleAnalyticsOthersPlatformsForecast?.length > 0 && googleAnalyticsOthersPlatformsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataFirst]) }));

  const forecastGoogleAdwordsDataSecond = googleAdwordsForecast?.length > 0 && googleAdwordsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataSecond]) }));
  const forecastMetaAdsDataSecond = metaAdsForecast?.length > 0 && metaAdsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataSecond]) }));
  const forecastLinkedinAdsDataSecond = linkedinAdsForecast?.length > 0 && linkedinAdsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataSecond]) }));
  const forecastGoogleAnalyticsOthersPlatformsDataSecond = googleAnalyticsOthersPlatformsForecast?.length > 0 && googleAnalyticsOthersPlatformsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataSecond]) }));

  const forecastDataFirst = (forecastGoogleAdwordsDataFirst && (ForecastMonthly(forecastGoogleAdwordsDataFirst) || 0))
                              + (forecastMetaAdsDataFirst && (ForecastMonthly(forecastMetaAdsDataFirst) || 0))
                              + (forecastLinkedinAdsDataFirst && (ForecastMonthly(forecastLinkedinAdsDataFirst) || 0))
                              + (forecastGoogleAnalyticsOthersPlatformsDataFirst && (ForecastMonthly(forecastGoogleAnalyticsOthersPlatformsDataFirst) || 0));
  const forecastDataSecond = (forecastGoogleAdwordsDataSecond && (ForecastMonthly(forecastGoogleAdwordsDataSecond) || 0))
                              + (forecastMetaAdsDataSecond && (ForecastMonthly(forecastMetaAdsDataSecond) || 0))
                              + (forecastLinkedinAdsDataSecond && (ForecastMonthly(forecastLinkedinAdsDataSecond) || 0))
                              + (forecastLinkedinAdsDataSecond && (ForecastMonthly(forecastGoogleAnalyticsOthersPlatformsDataSecond) || 0));

  const forecast = accountKPI(forecastDataFirst, forecastDataSecond);

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function accountKPI(num1, num2) {
    return num1 / num2;
  }

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta green';
    }
    return 'delta red';
  }

  return (
    <tr title={translate.tip}>
      {showLevelColumn ? <td>1st & 2nd</td> : null}
      {showSourceColumn ? <td>Calc</td> : null}
      <td>{translate.kpi}</td>
      <td>{numberFormat(current, 'percentage', 2)}</td>
      <td>{numberFormat(previous, 'percentage', 2)}</td>
      <td className={classNameAtrr(deltaNormal)}>
        {numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(yoy, 'percentage', 2)}</td>
      <td className={classNameAtrr(deltaYoY)}>
        {numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(forecast, 'percentage', 2)}</td>
      <td>{numberFormat(completeMonth, 'percentage', 2)}</td>
      <td className={classNameAtrr(deltaForecast)}>
        {numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

CPMTr.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default CPMTr;
