import React, { useContext } from 'react';
import { Table } from './style';
import { DataChannelsOverviewContext } from '../../../context/DataCardChannelsOverview';

export function TableTop10() {
  const { kpiSelected, kpisInfo, userViewSelected } = useContext(DataChannelsOverviewContext);

  return (
    <Table>
      <thead>
        <tr>
          <td className="column-main">Top 10</td>
          <td className="column-value">{userViewSelected.name}</td>
          <td className="column-value">{kpiSelected.name}</td>
        </tr>
      </thead>
      <tbody>
        {kpisInfo
            && kpisInfo.data?.map((data, index) => (
              <tr key={data.view}>
                <td className="column-main">{index + 1}</td>
                <td className="column-value">{data.view}</td>
                <td className="column-value">{data.current}</td>
              </tr>
            )).slice(0, 10)}
      </tbody>
    </Table>
  );
}
