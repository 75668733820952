class Modules {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async getAll() {
    const path = `${this.baseUrl}/module`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }
}

export default new Modules();
