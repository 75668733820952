import React, { useRef, Fragment } from 'react';
import IconArrow from '../../../styles/logos/arrow.svg';
import { TranscriptContainer } from './style';
import { Translate } from './translate';

export function Transcript({ transcript }) {
  const translate = Translate();
  const contentModal = useRef();
  const arrow = useRef();

  function toogleModal() {
    if (contentModal) {
      if (contentModal.current.style.display === 'none' || contentModal.current.style.display === '') {
        contentModal.current.style.display = 'block';
        arrow.current.style.transform = 'rotate(-180deg)';
      } else {
        contentModal.current.style.display = 'none';
        arrow.current.style.transform = 'rotate(0deg)';
      }
    }
  }

  function formatTranscript(transcript) {
    return transcript.split('\r\n').map((text, index) => (
      <Fragment key={index[0]}>
        {text}
        <br />
      </Fragment>
    ));
  }

  return (
    <TranscriptContainer>
      <div className="header">
        <h3>{translate.title}</h3>
        <img src={IconArrow} alt="Open Modal" onClick={toogleModal} ref={arrow} />
      </div>
      <div className="content" ref={contentModal}>
        {formatTranscript(transcript)}
      </div>
    </TranscriptContainer>
  );
}
