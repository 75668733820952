import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${(props) => props.theme.sizes.s40};
  width: 100%;

  div {
    margin-bottom: ${(props) => props.theme.sizes.s20};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        margin:0 ;
      }
  }
`;
