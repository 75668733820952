export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    star: language === 'pt-BR' ? 'estrela' : 'star',
    stars: language === 'pt-BR' ? 'estrelas' : 'stars',
    rating: language === 'pt-BR' ? 'avaliação' : 'rating',
    ratings: language === 'pt-BR' ? 'avaliações' : 'ratings',
    reviews: language === 'pt-BR' ? 'Consulte todas as avaliações' : 'See all ratings',
    language,
  };
}
