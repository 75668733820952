/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import IconLikeTransparent from '../../../styles/logos/like_transparent.svg';
import { NoDataMessage } from '../../../components/NoDataMessage';
import Loader from '../../../components/Loader';
import IconLike from '../../../styles/logos/like.svg';
import {
  Container, CointainerVideo, VideoArea, VideoInfosArea, VideoActions, Likes, PathModule, PathTitle, DescriptionArea,
} from './style';
import { Translate } from './translate';

import { FeedContext } from '../../../context/FeedContext';

export function Feed() {
  const translate = Translate();
  const {
    videosFeed, isLoadingVideos, handleLike, handleToogleDescription,
  } = useContext(FeedContext);

  function cutDescriptionCharacters(string, number) {
    if (string.length > number) return `${string.slice(0, number)}...`;
    return string;
  }

  return (
    <Container>
      <h2>{translate.title}</h2>
      {isLoadingVideos && <Loader loading={isLoadingVideos} />}
      {(!isLoadingVideos && videosFeed?.length > 0) && (
        videosFeed?.map((video) => (
          <CointainerVideo key={video.id}>
            <VideoArea>
              <h3>{translate.language === 'pt-BR' ? video.title_pt : video.title_en}</h3>
              {parse(video.link_video)}
            </VideoArea>
            <VideoInfosArea>
              <Likes>
                {video.totalLikes} {video.totalLikes === 1 ? translate.like : translate.likes}
              </Likes>
              <hr />
              <VideoActions>
                <div className="container-like" onClick={() => handleLike(video.id)}>
                  {video.userLiked ? <img src={IconLike} alt="Like" /> : <img src={IconLikeTransparent} alt="Like" />}
                  {video.userLiked ? translate.likeBtnLiked : translate.likeBtnLike}
                </div>
                <div className="container-path">
                  <span>{translate.messageWholeContent}</span>
                  <Link to={`/client/${video.product_type}/${video.id_product}/${video.id}`} className="container-links">
                    <PathModule color={video.product_color}>{translate.language === 'pt-BR' ? video.product_name_pt : video.product_name_en}</PathModule>
                    <PathTitle>{translate.language === 'pt-BR' ? cutDescriptionCharacters(video.title_pt, 80) : cutDescriptionCharacters(video.title_en, 80)}</PathTitle>
                  </Link>
                </div>
              </VideoActions>
              {(translate.language === 'pt-BR' ? video.description_pt.length > 0 : video.description_en.length > 0) && (
                <>
                  <hr />
                  <DescriptionArea>
                    {(translate.language === 'pt-BR' ? video.description_pt.length <= 80 : video.description_en.length <= 80) && (
                      translate.language === 'pt-BR' ? parse(video.description_pt) : parse(video.description_en)
                    )}
                    {(translate.language === 'pt-BR' ? video.description_pt.length >= 80 : video.description_en.length >= 80) && (
                      !video.opened
                        ? (
                          <>
                            {translate.language === 'pt-BR' ? parse(video.description_pt.slice(0, 80)) : parse(video.description_en.slice(0, 80))}
                            <span onClick={() => handleToogleDescription(video.id)}> {translate.seeMore}</span>
                          </>
                        )
                        : (
                          <>
                            {translate.language === 'pt-BR' ? parse(video.description_pt) : parse(video.description_en)}
                            <span onClick={() => handleToogleDescription(video.id)}> {translate.seeLess}</span>
                          </>
                        )
                    )}
                  </DescriptionArea>
                </>
              )}
            </VideoInfosArea>
          </CointainerVideo>
        ))
      )}
      {(!isLoadingVideos && videosFeed.length === 0) && (
        <NoDataMessage>Sem dados para gerar</NoDataMessage>
      )}
    </Container>
  );
}
