import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
  display: flex;
  gap: 32px;
`;

export const FieldSet = styled.fieldset`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.grayDark};
  border-radius: 4px;

  legend {
    color: ${({ theme }) => theme.grayLight};
  }

  .container-input {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;
