export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Adicionar SubCategoria' : 'Add SubCategory',
    textName: language === 'pt-BR' ? 'Nome' : 'Name',
    textCategory: language === 'pt-BR' ? 'Produto | Categoria' : 'Product | Category',
    textPost: language === 'pt-BR' ? 'Possui Posts?' : 'Are there Posts?',
    warningProduct: language === 'pt-BR'
      ? 'Faça o vínculo de um Produto e uma Categoria (São filtradas só as Categorias que estão habilitadas a serem inseridas Sub-Categoria)'
      : 'Link a Product and a Category (Only Categories that are enabled to be inserted into a Sub-Category are filtered)',
    warningPosts: language === 'pt-BR'
      ? 'Escolha "Y" para habilitar Posts a esta Subcategoria. Ao escolher "N", é possível fazer o vínculo de vídeos diretamente a esta Subcategoria'
      : 'Choose "Y" to enable Posts for this Subcategory. By choosing "N", you can link videos directly to this Subcategory',
    textOrdering: language === 'pt-BR' ? 'Ordem' : 'Order',
    labelNamePt: language === 'pt-BR' ? 'Nome PT' : 'Name PT',
    labelNameEn: language === 'pt-BR' ? 'Nome EN' : 'Name EN',
    labelProduct: language === 'pt-BR' ? 'Produto' : 'Product',
    labelCategory: language === 'pt-BR' ? 'Categoria' : 'Category',
    warnText: language === 'pt-BR' ? 'Todos os campos preenchidos e SubCategoria associada para habilitar o cadastro' : 'All fields completed and associated SubCategory to enable registration',
    language,
  };
}
