import { useContext, useMemo, useState } from 'react';
import Square from '../../../../../components/Square';
import Loader from '../../../../../components/Loader';
import { InputsFromCard } from './InputsFromCard';
import { ChartPie } from './ChartPie';
import { ChartLine } from './ChartLine';
import { TableByUserView } from './TableByUserView';
import { TableTop10 } from './TableTop10';
import { Translate } from './translate';
import { TitleContainer, InputsContainer, ChartsContainer } from './style';
import { DataChannelsOverviewContext } from '../../context/DataCardChannelsOverview';
import { ListKpis } from '../../context/DataCardChannelsOverview/ListKpis';
import { DateContext } from '../../../../../context/DateContext';

export function CardChannelsOverview() {
  const translate = Translate();
  const kpi = ListKpis();
  const { infoDateSelected } = useContext(DateContext);
  const { kpisInfo, isLoadingAllDatas, kpiSelected } = useContext(DataChannelsOverviewContext);
  const [showTotalOnChartLine, setShowTotalOnChartLine] = useState(false);

  const getInfoFromKPISelected = useMemo(() => {
    const kpiSelectedInfo = kpi.filter((kpi) => kpi.id === Number(kpiSelected.id));
    return kpiSelectedInfo[0];
  }, [kpiSelected]);

  function toogleTotalOnChartLine(e) {
    setShowTotalOnChartLine(e.target.checked);
  }

  return (
    <Square size="large">
      <TitleContainer>
        <div>
          <h3>Channels Overview</h3>
          <small>{translate.subtitle}</small>
        </div>
        <InputsContainer>
          <InputsFromCard />
          {getInfoFromKPISelected.infos && (
            <small>
              <strong>{translate.level}: </strong> {getInfoFromKPISelected.infos.level} {' '}
              <strong>{translate.source}: </strong>{getInfoFromKPISelected.infos.source}
            </small>
          )}
        </InputsContainer>
      </TitleContainer>

      <ChartsContainer>
        <div className="l-container">
          <div className="l-pies">
            <div className="l-pie">
              <div className="l-pie-title">
                <h4>{translate.current}</h4>
                <p>{infoDateSelected?.init?.description} - {infoDateSelected?.end?.description}</p>
              </div>
              {isLoadingAllDatas ? <Loader loading={isLoadingAllDatas} /> : (
                <ChartPie
                  kpiInfo={kpisInfo}
                  type="current"
                />
              )}
            </div>
            <div className="l-pie">
              <div className="l-pie-title">
                <h4>{translate.previous}</h4>
                <p>{infoDateSelected?.initPrev?.description} - {infoDateSelected?.endPrev?.description}</p>
              </div>
              {isLoadingAllDatas ? <Loader loading={isLoadingAllDatas} /> : (
                <ChartPie
                  kpiInfo={kpisInfo}
                  type="previous"
                />
              )}
            </div>
          </div>
          <div className="l-table">
            {isLoadingAllDatas ? <Loader loading={isLoadingAllDatas} /> : (
              <TableByUserView />
            )}
          </div>
        </div>
        <div className="l-container">
          {isLoadingAllDatas ? <Loader loading={isLoadingAllDatas} /> : (
            <div className="chartline_container">
              <div className="toogle-container">
                <label htmlFor="show_total">{translate.showTotal}</label>
                <input type="checkbox" id="show_total" onClick={toogleTotalOnChartLine} />
              </div>
              <ChartLine
                kpiInfo={kpisInfo}
                showTotalOnChartLine={showTotalOnChartLine}
              />
            </div>
          )}
          {isLoadingAllDatas ? <Loader loading={isLoadingAllDatas} /> : <TableTop10 />}
        </div>
      </ChartsContainer>

    </Square>
  );
}
