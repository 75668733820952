/* eslint-disable no-prototype-builtins */
import { isNumberInvalid } from '../../../../../helpers/isNumberInvalid';

export function joinObjectsPropertiesAndSumIt(arrOfObjects) {
  const data = {};

  if (arrOfObjects.length > 0) {
    arrOfObjects.forEach((source) => {
      if (!source) return 0;

      // Iterar sobre as chaves (propriedades) de cada objeto da fonte de dados
      Object.keys(source).forEach((key) => {
        if (data.hasOwnProperty(key)) {
          data[key] += isNumberInvalid(source[key]) ? 0 : source[key];
        } else {
          data[key] = isNumberInvalid(source[key]) ? 0 : source[key];
        }
      });
    });

    return data;
  }
}
