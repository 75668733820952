import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import UTMService from '../../../../../services/UTM';
import Event from '../../../../../lib/EventEmitter';
import Loader from '../../../../../components/Loader';
import { Translate } from './translate';
import IconAdd from '../../../../../styles/logos/add.svg';

function ProductCategory({
  value, setValue, onToogleModal, setUtmCategoryToAdd, tipOfAddParams,
}) {
  const translate = Translate();
  const [paramsCustomList, setParamsCustomList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idParam] = useState(16);

  useEffect(() => {
    getCustomsUTMSources();
    Event.addListener('IDCustomParamAdded', (idParamToAdd) => {
      if (idParamToAdd === idParam) {
        getCustomsUTMSources();
      }
    });
  }, []);

  async function getCustomsUTMSources() {
    setIsLoading(true);
    const listofParamsCustom = await UTMService.getAllBYCategoryUTM(idParam);
    setParamsCustomList(listofParamsCustom);
    setIsLoading(false);
  }

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, productCategory: event.target.value }));
  }

  function handleToogleModal() {
    setUtmCategoryToAdd(idParam);
    onToogleModal();
  }

  return (
    <div className="form-item">
      <label htmlFor="product-category">
        {translate.label}
        <img src={IconAdd} alt="Add" title={tipOfAddParams} onClick={handleToogleModal} />
      </label>
      <select value={value} onChange={handleSelect} id="product-category" disabled={isLoading}>
        <option value="" className="noneParam">None</option>
        {paramsCustomList && paramsCustomList.map((item) => (
          <option key={item.id} value={item.value} className="customParam">
            {' '}
            {item.name}
            {' '}
          </option>
        ))}
        <option value="main-product">main-product</option>
        <option value="sku-1">sku-1</option>
        <option value="sku-2">sku-2</option>
        <option value="sku-3">sku-3</option>
        <option value="sku-4">sku-4</option>
        <option value="sku-5">sku-5</option>
        <option value="sku-6">sku-6</option>
        <option value="sku-7">sku-7</option>
        <option value="sku-8">sku-8</option>
        <option value="sku-9">sku-9</option>
        <option value="sku-10">sku-10</option>
        <option value="sku-11">sku-11</option>
        <option value="sku-12">sku-12</option>
        <option value="sku-13">sku-13</option>
        <option value="sku-14">sku-14</option>
        <option value="sku-15">sku-15</option>
        <option value="sku-16">sku-16</option>
        <option value="sku-17">sku-17</option>
        <option value="sku-18">sku-18</option>
        <option value="sku-19">sku-19</option>
        <option value="sku-20">sku-20</option>
      </select>
      { isLoading && <Loader loading={isLoading} tofield /> }
    </div>
  );
}

ProductCategory.propTypes = {
  value: PropTypes.string.isRequired,
  tipOfAddParams: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onToogleModal: PropTypes.func.isRequired,
  setUtmCategoryToAdd: PropTypes.func.isRequired,
};

export default ProductCategory;
