import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 32px;

  div {
    flex: 1;
  }

  div > small {
    color: ${({ theme }) => theme.grayLight};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
    flex-direction: column;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
    height: 100%;
  }


  .l-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 500px;
    margin-top: 32px;
    width: 100%;
    height: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
      flex-direction: column;
      max-height: none;
    }

    div,table {
      flex: 1;
    }

    .chartline_container {
      display: flex;
      flex-direction: column;

      .toogle-container {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        margin-right: 8px;

      }
    }
  }

  .l-pies {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
      flex-direction: column;
      gap: 24px;
    }
  }

  .l-pie {
    min-height: inherit;

    /* ChartPie area */
    div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      /* width: 100%; */
    }
  }

  .l-pie-title {
    margin-bottom: 16px;
    text-align: center;

    h4 {
      font-weight: 500;
    }

    p {
      font-size: 14px;
    }
  }

  .l-table {
    flex: 1;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
      flex-direction: column;
      margin-top: 32px;
      height: 100%;
      overflow: auto;
    }
  }
`;


