import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import UTMService from '../../../../../services/UTM';
import Event from '../../../../../lib/EventEmitter';
import Loader from '../../../../../components/Loader';
import { Translate } from './translate';
import IconAdd from '../../../../../styles/logos/add.svg';

function Format({
  value, setValue, onToogleModal, setUtmCategoryToAdd, tipOfAddParams,
}) {
  const translate = Translate();
  const [paramsCustomList, setParamsCustomList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idParam] = useState(7);

  useEffect(() => {
    getCustomsUTMSources();
    Event.addListener('IDCustomParamAdded', (idParamToAdd) => {
      if (idParamToAdd === idParam) {
        getCustomsUTMSources();
      }
    });
  }, []);

  async function getCustomsUTMSources() {
    setIsLoading(true);
    const listofParamsCustom = await UTMService.getAllBYCategoryUTM(idParam);
    setParamsCustomList(listofParamsCustom);
    setIsLoading(false);
  }

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, format: event.target.value }));
  }

  function handleToogleModal() {
    setUtmCategoryToAdd(idParam);
    onToogleModal();
  }

  return (
    <div className="form-item">
      <label htmlFor="format">
        {translate.label}
        <img src={IconAdd} alt="Add" title={tipOfAddParams} onClick={handleToogleModal} />
      </label>
      <select value={value} onChange={handleSelect} id="format" disabled={isLoading}>
        <option value="" className="noneParam">None</option>
        {paramsCustomList && paramsCustomList.map((item) => (
          <option key={item.id} value={item.value} className="customParam">
            {' '}
            {item.name}
            {' '}
          </option>
        ))}
        <option value="banner">banner</option>
        <option value="bumper-ad">bumper-ad</option>
        <option value="carrousel">carrousel</option>
        <option value="image">image</option>
        <option value="instream">instream</option>
        <option value="link-ad">link-ad</option>
        <option value="search">search</option>
        <option value="testimonial">testimonial</option>
        <option value="text">text</option>
        <option value="text-image">text-image</option>
        <option value="video">video</option>
        <option value="video-ad">video-ad</option>
      </select>
      { isLoading && <Loader loading={isLoading} tofield /> }
    </div>
  );
}

Format.propTypes = {
  value: PropTypes.string.isRequired,
  tipOfAddParams: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onToogleModal: PropTypes.func.isRequired,
  setUtmCategoryToAdd: PropTypes.func.isRequired,
};

export default Format;
