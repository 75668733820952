class CategoryProductService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findAll(id) {
    let query = '';
    if (id) {
      query = `?idProduct=${id}`;
    }

    const path = `${this.baseUrl}/categories-product${query}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async findById(id) {
    const path = `${this.baseUrl}/categories-product/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async store({
    namePt, nameEn, ordering, hasSubCategory, slug, typeCategory, idProduct,
  }) {
    const path = `${this.baseUrl}/categories-product`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        namePt, nameEn, ordering, hasSubCategory, slug, typeCategory, idProduct,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async update({
    id, namePt, nameEn, ordering, hasSubCategory, slug, typeCategory, idProduct,
  }) {
    const path = `${this.baseUrl}/categories-product/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify({
        namePt, nameEn, ordering, hasSubCategory, slug, typeCategory, idProduct,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async updateOrdering(categoriesList) {
    const path = `${this.baseUrl}/categories-product/ordering`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify(categoriesList),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async delete(id) {
    const path = `${this.baseUrl}/categories-product/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'DELETE',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }
}

export default new CategoryProductService();
