class Settings {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async update({ lang, firstName, lastName }) {
    if (!lang || !firstName || !lastName) throw new Error('Password is required');

    const response = await fetch(`${this.baseUrl}/user-basic`, {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ language: lang, firstName, lastName }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(response.message);
    return body;
  }

  async updatePassword(password) {
    if (!password || password.length < 6) throw new Error('Password is required');

    const response = await fetch(`${this.baseUrl}/user/password`, {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ password }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(body.message);
    return body;
  }
}

export default new Settings();
