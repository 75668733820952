import styled from 'styled-components';

const FormGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  @media(max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export default FormGroup;
