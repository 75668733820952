import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
    flex-direction: column;

    div:nth-of-type(1) {
      order: 2;
    }

    div:nth-of-type(2) {
      order: 1;
    }
  }
`;
