import React, { useContext, useState } from 'react';
import ClientRoutes from '../../routes/ClientRoutes';
import SideBarUser from '../../components/SidebarUser';
import Menu from '../../components/Menu';
import { MainContainer } from '../../components/MainContainer';
import SideBarUserMock from '../../components/SidebarUserMock';
import { ProductsProvider } from '../../context/ProductsContext';
import { ModulesProvider } from '../../context/ModulesContext';
import { FeedProvider } from '../../context/FeedContext';
import { AuthContext } from '../../context/AuthContext';
import * as S from './style';

function IndexUser() {
  const [toogle, setToogle] = useState(true);
  const { userInfo } = useContext(AuthContext);

  function handleToogleMenu() {
    setToogle((prevState) => !prevState);
  }

  return (
    <>
      <ModulesProvider>
        <ProductsProvider>
          {/* TODO: Retirar isso depois */}
          {userInfo.email === 'suporte+111menu@shifters.com.br' ? <SideBarUserMock toogle={toogle} /> : <SideBarUser toogle={toogle} />}
        </ProductsProvider>
        <S.PageContainer $toogle={toogle}>
          <Menu onHandleToogle={handleToogleMenu} toogle={toogle} />
          <MainContainer>
            <FeedProvider>
              <ClientRoutes />
            </FeedProvider>
          </MainContainer>
        </S.PageContainer>
      </ModulesProvider>
    </>
  );
}

export default IndexUser;
