import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import VideosRatingService from '../../../services/VideosRating';
import TitlePage from '../../../components/TitlePage';
import Square from '../../../components/Square';
import { Badge } from '../../../components/Badge';
import Loader from '../../../components/Loader';
import { NoDataMessage } from '../../../components/NoDataMessage';
import Dates_New from '../../../helpers/Dates_New';
import { Translate } from './translate';
import { Container, TitleCommentContainer, Description } from './style';


export function RatingsVideos() {
  const translate = Translate();
  const location = useLocation();
  const { idVideo } = useParams();
  const [ratings, setRatings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const startFilteredQueryParam = new URLSearchParams(location.search).get('s');

  useEffect(() => {
    getAllComments();
  }, []);

  async function getAllComments() {
    setIsLoading(true);
    if (idVideo) {
      const ratingsDB = await VideosRatingService.findAllComments({ idVideo, startFilteredQueryParam });
      setRatings(ratingsDB);
    }
    setIsLoading(false);
  }

  return (
    <>
      <TitlePage title={translate.title} />
      {isLoading && <Loader loading={isLoading} />}
      {(!isLoading && ratings.length === 0) && (
        <NoDataMessage>{translate.message}</NoDataMessage>
      )}
      {(!isLoading && ratings.length > 0) && (
        <Container>
          {ratings && (
            ratings.map((rating) => (
              <Square size="large">
                <TitleCommentContainer>
                  <Badge>{translate.ratedIn}: {Dates_New.formatDatesMask(rating.dateAct)}</Badge>
                  <Rating
                    initialValue={Number(rating.score)}
                    readonly
                    transition
                    size={20}
                    titleSeparator=""
                    allowFraction
                    fillClassName="filled-icons"
                    fillColor="#FFD100"
                  />
                  <div>{rating.score}</div>
                </TitleCommentContainer>
                {rating.description.length > 0 && (
                  <Description>{rating.description}</Description>
                )}
              </Square>
            ))
          )}
        </Container>
      )}
    </>
  );
}
