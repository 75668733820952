class ProductsService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findAll() {
    const path = `${this.baseUrl}/products`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findOne(id) {
    const path = `${this.baseUrl}/products/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async getList(id) {
    const path = `${this.baseUrl}/products/${id}/list`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async calculatePercentageWatched({ idProduct }) {
    const path = `${this.baseUrl}/products/calculate-progress/${idProduct}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async store(product) {
    const path = `${this.baseUrl}/products`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify(product),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async update(id, product) {
    const path = `${this.baseUrl}/products/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify(product),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async delete(id) {
    const path = `${this.baseUrl}/products/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'DELETE',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }
}

export default new ProductsService();
