import styled from 'styled-components';

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

      select {
        background-color: ${(props) => props.theme.grayMenu};
        border-radius: ${(props) => props.theme.radius};
        border: 1px solid ${(props) => props.theme.grayMenu};
        padding: ${(props) => props.theme.sizes.s8};
        font-size: ${(props) => props.theme.sizes.s16};
        outline: none;
        width: 350px;

        :focus {
          border: 1px solid ${(props) => props.theme.grayLight};
        }

        //select responsive
        @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
          width: 100%;
          margin-bottom: ${(props) => props.theme.sizes.s8};
        }
      }

    .btn-primary {
      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
          text-align: center;
          width: 100%;
          margin-bottom: ${(props) => props.theme.sizes.s8};
        }
      }


    //Container responsive
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
    }
`;

export const ListContainer = styled.div`
  margin-top: ${(props) => props.theme.sizes.s32};

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) => props.theme.sizes.s16};
    height: ${(props) => props.theme.sizes.s72};
    background-color: ${(props) => props.theme.grayMenu};
    margin-bottom: ${(props) => props.theme.sizes.s8};
    border-radius: ${(props) => props.theme.radius};

    .infos-area {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;

      .data-area {
        margin-right: ${(props) => props.theme.sizes.s16};
      }

    }

    .btns-area {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: ${(props) => props.theme.sizes.s8};

      img {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }

      .edit-icon{
          filter: invert(63%) sepia(25%) saturate(0%) hue-rotate(184deg) brightness(97%) contrast(91%);
          height: 20px;
        }

      .edit-icon:hover{
        filter: invert(100%) sepia(0%) saturate(6194%) hue-rotate(201deg) brightness(111%) contrast(89%);
      }

      .delete-icon{
          filter: invert(47%) sepia(79%) saturate(5950%) hue-rotate(3deg) brightness(105%) contrast(103%);
        }

      .delete-icon:hover{
        filter: invert(100%) sepia(0%) saturate(6194%) hue-rotate(201deg) brightness(111%) contrast(89%);
      }
    }
  }
`;
