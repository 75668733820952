import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';
import SubCategoryProductService from '../../../../../services/SubCategoryProduct';
import CategoryProductService from '../../../../../services/CategoryProduct';
import ProductService from '../../../../../services/Products';
import toast from '../../../../../helpers/toast';
import { generateSlug } from '../../../../../helpers/generateSlug';

function useSubCategoryAdd() {
  const navigate = useNavigate();
  const [subCategory, setSubCategory] = useState({
    namePt: '', nameEn: '', ordering: 0, hasPosts: 'Y', slug: '', idProductCategory: '',
  });
  const [productsList, setProductsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [productSelected, setProductSelected] = useState();
  const [categorySelected, setCategorySelected] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoadingContent, setIsLoadingContent] = useState(true);

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    verifyEnableSaveButton();
  }, [subCategory]);

  useEffect(() => {
    getCategories(productSelected);
  }, [productSelected]);

  async function getProductList() {
    setIsLoadingContent(true);
    const productsList = await ProductService.findAll();
    if (productsList.length > 0) {
      setProductSelected(productsList[0].id);
      setProductsList(productsList);
      getCategories(productsList[0].id);
    } else {
      setProductSelected(null);
      setProductsList([]);
    }
    setIsLoadingContent(false);
  }

  async function getCategories(id) {
    const idProduct = id;
    const categoriesList = await CategoryProductService.findAll(idProduct);
    const categoriesListFiltered = categoriesList.filter((category) => category.hasSubCategory === 'Y');
    if (categoriesListFiltered.length > 0) {
      setCategoriesList(categoriesListFiltered);
      setCategorySelected(categoriesListFiltered[0]);
      setSubCategory({ ...subCategory, idProductCategory: Number(categoriesListFiltered[0].id) });
    } else {
      setCategoriesList([]);
      setCategorySelected(null);
      setSubCategory({ ...subCategory, idProductCategory: undefined });
    }
  }

  async function saveSubCategoryProduct() {
    const {
      namePt, nameEn, ordering, hasPosts, slug, idProductCategory,
    } = subCategory;
    try {
      setIsDisabled(true);
      await SubCategoryProductService.store({
        namePt, nameEn, ordering, hasPosts, slug, idProductCategory,
      });
      toast('success', 'SubCategory created successfully');
    } catch (error) {
      toast('danger', `Error to create SubCategory (${error.status} - ${error.message})`);
    } finally {
      navigate('/admin/sub-categories');
    }
  }

  function handleCategory(id) {
    setCategorySelected(id);
    setSubCategory({ ...subCategory, idProductCategory: Number(id) });
  }

  function verifyEnableSaveButton() {
    const hasNamePt = subCategory.namePt.length >= 3;
    const hasNameEn = subCategory.nameEn.length >= 3;
    const hasSlug = subCategory.slug.length >= 3;
    const hasCategory = Boolean(subCategory?.idProductCategory);

    if (hasNamePt && hasNameEn && hasSlug && hasCategory) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  function handleGenerateSlug() {
    setSubCategory({ ...subCategory, slug: generateSlug(subCategory?.namePt) });
  }

  return {
    subCategory,
    setSubCategory,
    productsList,
    categoriesList,
    productSelected,
    setProductSelected,
    categorySelected,
    handleCategory,
    isDisabled,
    handleGenerateSlug,
    saveSubCategoryProduct,
    isLoadingContent,
  };
}

export default useSubCategoryAdd;
