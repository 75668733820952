export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    titlePage: language === 'pt-BR' ? 'Editar Parâmetro' : 'Edit Param',
    nameLabel: language === 'pt-BR' ? 'Nome' : 'Name',
    namePlaceholder: language === 'pt-BR' ? 'Digite nome aqui...' : 'Enter name...',
    urlValueLabel: language === 'pt-BR' ? 'Url value' : 'Valor na URL',
    urlValuePlaceholder: language === 'pt-BR' ? 'Digite o valor que aparecerá na URL aqui...' : 'Enter url value ...',
    save: language === 'pt-BR' ? 'Url value' : 'Valor na URL',
  };
}
