import { createGlobalStyle } from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import ThinNowayEOT from './font/thin/noway-thin-webfont.eot';
import ThinNowaySVG from './font/thin/noway-thin-webfont.svg';
import ThinNowayTTF from './font/thin/noway-thin-webfont.ttf';
import ThinNowayWOFF from './font/thin/noway-thin-webfont.woff';
import ThinNowayItalicEOT from './font/thin/noway_thin_italic-webfont.eot';
import ThinNowayItalicSVG from './font/thin/noway_thin_italic-webfont.svg';
import ThinNowayItalicTTF from './font/thin/noway_thin_italic-webfont.ttf';
import ThinNowayItalicWOFF from './font/thin/noway_thin_italic-webfont.woff';

import LightNowayEOT from './font/light/noway-light-webfont.eot';
import LightNowaySVG from './font/light/noway-light-webfont.svg';
import LightNowayTTF from './font/light/noway-light-webfont.ttf';
import LightNowayWOFF from './font/light/noway-light-webfont.woff';
import LightNowayItalicEOT from './font/light/noway_light_italic-webfont.eot';
import LightNowayItalicSVG from './font/light/noway_light_italic-webfont.svg';
import LightNowayItalicTTF from './font/light/noway_light_italic-webfont.ttf';
import LightNowayItalicWOFF from './font/light/noway_light_italic-webfont.woff';

import RegularNowayEOT from './font/regular/noway-regular-webfont.eot';
import RegularNowaySVG from './font/regular/noway-regular-webfont.svg';
import RegularNowayTTF from './font/regular/noway-regular-webfont.ttf';
import RegularNowayWOFF from './font/regular/noway-regular-webfont.woff';
import RegularNowayItalicEOT from './font/regular/noway_regular_italic-webfont.eot';
import RegularNowayItalicSVG from './font/regular/noway_regular_italic-webfont.svg';
import RegularNowayItalicTTF from './font/regular/noway_regular_italic-webfont.ttf';
import RegularNowayItalicWOFF from './font/regular/noway_regular_italic-webfont.woff';

import MediumNowayEOT from './font/medium/noway-medium-webfont.eot';
import MediumNowaySVG from './font/medium/noway-medium-webfont.svg';
import MediumNowayTTF from './font/medium/noway-medium-webfont.ttf';
import MediumNowayWOFF from './font/medium/noway-medium-webfont.woff';
import MediumNowayItalicEOT from './font/medium/noway_medium_italic-webfont.eot';
import MediumNowayItalicSVG from './font/medium/noway_medium_italic-webfont.svg';
import MediumNowayItalicTTF from './font/medium/noway_medium_italic-webfont.ttf';
import MediumNowayItalicWOFF from './font/medium/noway_medium_italic-webfont.woff';

import BoldNowayEOT from './font/bold/noway-bold-webfont.eot';
import BoldNowaySVG from './font/bold/noway-bold-webfont.svg';
import BoldNowayTTF from './font/bold/noway-bold-webfont.ttf';
import BoldNowayWOFF from './font/bold/noway-bold-webfont.woff';
import BoldNowayItalicEOT from './font/bold/noway_bold_italic-webfont.eot';
import BoldNowayItalicSVG from './font/bold/noway_bold_italic-webfont.svg';
import BoldNowayItalicTTF from './font/bold/noway_bold_italic-webfont.ttf';
import BoldNowayItalicWOFF from './font/bold/noway_bold_italic-webfont.woff';

export default createGlobalStyle`

  :root {
    accent-color: white !important;
  }

  /* Fonte Thin */
  @font-face {
    font-family: 'noway';
    font-weight: 100;
    font-style: normal;
    src: url(${ThinNowayEOT});
    src: url('${ThinNowayEOT}?#iefix') format('embedded-opentype'),
         url(${ThinNowayWOFF}) format('woff'),
         url(${ThinNowayTTF}) format('truetype'),
         url('${ThinNowaySVG}#webfont') format('svg');
  }

  @font-face {
    font-family: 'noway';
    font-weight: 100;
    font-style: italic;
    src: url(${ThinNowayItalicEOT});
    src: url('${ThinNowayItalicEOT}?#iefix') format('embedded-opentype'),
         url(${ThinNowayItalicWOFF}) format('woff'),
         url(${ThinNowayItalicTTF}) format('truetype'),
         url('${ThinNowayItalicSVG}#webfont') format('svg');
  }

  /* Fonte Light */
  @font-face {
    font-family: 'noway';
    font-weight: 300;
    font-style: normal;
    src: url(${LightNowayEOT});
    src: url('${LightNowayEOT}?#iefix') format('embedded-opentype'),
         url(${LightNowayWOFF}) format('woff'),
         url(${LightNowayTTF}) format('truetype'),
         url('${LightNowaySVG}#webfont') format('svg');
  }

  @font-face {
    font-family: 'noway';
    font-weight: 300;
    font-style: italic;
    src: url(${LightNowayItalicEOT});
    src: url('${LightNowayItalicEOT}?#iefix') format('embedded-opentype'),
         url(${LightNowayItalicWOFF}) format('woff'),
         url(${LightNowayItalicTTF}) format('truetype'),
         url('${LightNowayItalicSVG}#webfont') format('svg');
  }

  /* Fonte Regular  */
  @font-face {
    font-family: 'noway';
    font-weight: 400;
    font-style: normal;
    src: url(${RegularNowayEOT});
    src: url('${RegularNowayEOT}?#iefix') format('embedded-opentype'),
         url(${RegularNowayWOFF}) format('woff'),
         url(${RegularNowayTTF}) format('truetype'),
         url('${RegularNowaySVG}#webfont') format('svg');
  }

  @font-face {
    font-family: 'noway';
    font-weight: 400;
    font-style: italic;
    src: url(${RegularNowayItalicEOT});
    src: url('${RegularNowayItalicEOT}?#iefix') format('embedded-opentype'),
         url(${RegularNowayItalicWOFF}) format('woff'),
         url(${RegularNowayItalicTTF}) format('truetype'),
         url('${RegularNowayItalicSVG}#webfont') format('svg');
  }

  /* Fonte Medium  */
  @font-face {
    font-family: 'noway';
    font-weight: 500;
    font-style: normal;
    src: url(${MediumNowayEOT});
    src: url('${MediumNowayEOT}?#iefix') format('embedded-opentype'),
         url(${MediumNowayWOFF}) format('woff'),
         url(${MediumNowayTTF}) format('truetype'),
         url('${MediumNowaySVG}#webfont') format('svg');
  }

  @font-face {
    font-family: 'noway';
    font-weight: 500;
    font-style: italic;
    src: url(${MediumNowayItalicEOT});
    src: url('${MediumNowayItalicEOT}?#iefix') format('embedded-opentype'),
         url(${MediumNowayItalicWOFF}) format('woff'),
         url(${MediumNowayItalicTTF}) format('truetype'),
         url('${MediumNowayItalicSVG}#webfont') format('svg');
  }

   /* Fonte Bold  */
  @font-face {
    font-family: 'noway';
    font-weight: 700;
    font-style: normal;
    src: url(${BoldNowayEOT});
    src: url('${BoldNowayEOT}?#iefix') format('embedded-opentype'),
         url(${BoldNowayWOFF}) format('woff'),
         url(${BoldNowayTTF}) format('truetype'),
         url('${BoldNowaySVG}#webfont') format('svg');
  }

  @font-face {
    font-family: 'noway';
    font-weight: 700;
    font-style: italic;
    src: url(${BoldNowayItalicEOT});
    src: url('${BoldNowayItalicEOT}?#iefix') format('embedded-opentype'),
         url(${BoldNowayItalicWOFF}) format('woff'),
         url(${BoldNowayItalicTTF}) format('truetype'),
         url('${BoldNowayItalicSVG}#webfont') format('svg');
  }

  * {
      font-family: 'noway', sans-serif;
      font-weight: 400;
      font-style: normal;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      /* color: ${(props) => props.theme.fontWhite}; */
  }

   body {
    background-color: ${(props) => props.theme.grayBackground};

    /* Não selecionar nenhum conteúdo do sistema */
    /* -webkit-touch-callout: none;  iPhone OS, Safari */
    /* -webkit-user-select: none;    /* Chrome, Safari 3 */
    /* -khtml-user-select: none;     Safari 2 */
    /* -moz-user-select: none;       Firefox */
    /* -ms-user-select: none;         */
    /* user-select: none;           // Possível implementação no futuro */
  }

  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    box-shadow: ${({ theme }) => theme.grayMenu};
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.grayDark};
  }

  h1, h2, h3, h4, h5, p {
    margin: 0;
    padding:0;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
    padding:0;
    font-weight: 400;
  }

  h1,h2, h3, h4, h5, span, p, button, label, input, select, strong, div, a, textarea {
    color: ${(props) => props.theme.fontWhite};
  }

  button, a {
    font-weight: 400 !important;
  }

  input,
  select,
  textarea {
    font-family: 'noway', Arial;
    font-weight: 300;
    background-color: ${(props) => props.theme.grayMenu};
    border: none;
    border-radius: ${(props) => props.theme.radius};
    outline: none;
    font-size: ${(props) => props.theme.sizes.s16};
    border: 1px solid ${(props) => props.theme.grayMenu};
    padding: ${(props) => props.theme.sizes.s8};
    outline: none;
    cursor: pointer;
  }

  input[type="text"] {
    cursor: auto;
  }

  input[type="checkbox"],
  input[type="radio"] {
    border-color: transparent;
    outline: 0;
  }

  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    border-color: ${(props) => props.theme.yellow};
  }

  input[type="radio"]:not(:checked) {
    background-color: ${(props) => props.theme.grayMenu};
  }

  input[type="checkbox"]:hover,
  input[type="radio"]:hover{
    cursor: pointer;
  }

  select:disabled {
    color: ${(props) => props.theme.grayDark}
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  select:focus {
    border: 1px solid ${(props) => props.theme.grayLight};
  }

  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    border: 1px solid ${(props) => props.theme.yellow};
  }

  hr {
    border-style: solid;
    border-color: ${(props) => props.theme.grayMenu};
    margin: ${(props) => props.theme.sizes.s24} 0px ;
  }

  strong{
    font-weight: 400;
  }

  /* Messages when it doesn't find data */
  .no-data-message {
    font-size: ${(props) => props.theme.sizes.s20};
  }

  .no-data-message span {
    color: ${(props) => props.theme.grayLight};
    font-weight: 500;
  }

  /* BTNS STYLE */
  //Normal Button
  input[type="submit"] {
    cursor: pointer;
    padding: 0 ${(props) => props.theme.sizes.s32};
    font-size: ${(props) => props.theme.sizes.s16};
    border-radius: ${(props) => props.theme.radius};
    font-weight: 400;
    height: ${(props) => props.theme.sizes.s40};
    transition: all ${(props) => props.theme.transitionTime};
  }

  input[disabled],
  input[disabled]:hover {
    background-color: ${(props) => props.theme.grayMenu};
    border-color: ${(props) => props.theme.grayBackground} !important;
    cursor: not-allowed;
    color: ${(props) => props.theme.grayDark};
  }

  a.btn {
    cursor: pointer;
    padding: 0 ${(props) => props.theme.sizes.s24};
    font-size: ${(props) => props.theme.sizes.s16};
    border-radius: ${(props) => props.theme.radius};
    font-weight: 400;
    transition: all ${(props) => props.theme.transitionTime};
    text-decoration: none;
    height: ${(props) => props.theme.sizes.s40};
    line-height: 38px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${(props) => props.theme.fontWhite};
  }

  //Icons Button
  button.icons {
    cursor: pointer;
    padding: ${(props) => props.theme.sizes.s12} ${(props) => props.theme.sizes.s12};
    border-radius: ${(props) => props.theme.radius};
    transition: all ${(props) => props.theme.transitionTime};
    display: flex;
    align-items: center;
  }

  button.icons img {
    height: 16px;
    color: ${(props) => props.theme.grayMenu};
  }

  //Ancor Icons Button
  a.btn-icons {
    cursor: pointer;
    padding: 0 ${(props) => props.theme.sizes.s12};
    border-radius: ${(props) => props.theme.radius};
    transition: all ${(props) => props.theme.transitionTime};
    display: flex;
    align-items: center;
    height: ${(props) => props.theme.sizes.s40};
  }

  a.btn-icons img {
    height: 16px;
    color: ${(props) => props.theme.grayMenu};
  }

  /* BTNS COLORS */
  button {
    cursor: pointer;
    font-size: ${(props) => props.theme.sizes.s16};
    padding: ${(props) => props.theme.sizes.s8} ${(props) => props.theme.sizes.s16};
    border-radius: ${(props) => props.theme.radius};
    transition: .3s;
    font-family: 'noway', Arial;
    font-weight: 300;
  }

  .btn-ghost {
    border: none;
    background-color: transparent;
    color: ${(props) => props.theme.grayLight}
  }

  .btn-ghost:hover{
    color: ${(props) => props.theme.fontWhite}
  }

  .btn-primary {
    border: 1px solid ${(props) => props.theme.fontWhite};
    background-color: ${(props) => props.theme.fontWhite};
    color: ${(props) => props.theme.grayBackground}
  }

  .btn-primary:hover {
    border: 1px solid ${(props) => props.theme.fontWhite};
    background-color: ${(props) => props.theme.grayMenu};
    color: ${(props) => props.theme.fontWhite}
  }

  .btn-secondary {
    border: 1px solid ${(props) => props.theme.green};
    background-color: ${(props) => props.theme.green};
    color: ${(props) => props.theme.grayBackground}
  }

  .btn-secondary:hover {
    border: 1px solid ${(props) => props.theme.green};
    background-color: ${(props) => props.theme.grayBackground};
    color: ${(props) => props.theme.green}
  }

  .btn-tertiary {
    border: 1px solid ${(props) => props.theme.red};
    background-color: ${(props) => props.theme.red};
    color: ${(props) => props.theme.grayBackground}
  }

  .btn-tertiary:hover {
    border: 1px solid ${(props) => props.theme.red};
    background-color: ${(props) => props.theme.grayBackground};
    color: ${(props) => props.theme.red}
  }

  .btn-cancel.primary {
    border: 1px solid ${(props) => props.theme.grayMenu};
    background-color: ${(props) => props.theme.grayMenu};
    color: ${(props) => props.theme.fontWhite}
  }

  .btn-cancel.tertiary {
    border: 1px solid ${(props) => props.theme.grayMenu};
    background-color: ${(props) => props.theme.grayMenu};
    color: ${(props) => props.theme.fontWhite}
  }

  .btn-cancel:hover {
    color: ${(props) => props.theme.fontWhite};
    border: 1px solid ${(props) => props.theme.fontWhite};
  }

  .btn-icon-primary {
    border: 1px solid ${(props) => props.theme.blue};
    background-color: ${(props) => props.theme.blue};
  }

  .btn-icon-secondary {
    border: 1px solid ${(props) => props.theme.yellow};
    background-color: ${(props) => props.theme.yellow};
  }

  .btn-icon-secondary * {
    color: ${(props) => props.theme.grayBackground};
  }

  .btn-icon-tertiary {
    border: 1px solid ${(props) => props.theme.red};
    background-color: ${(props) => props.theme.red};
  }

  .btn-icon-primary:hover,
  .btn-icon-secondary:hover,
  .btn-icon-tertiary:hover  {
    border: 1px solid ${(props) => props.theme.fontWhite};
    background-color: ${(props) => props.theme.fontWhite};
  }

  //Special classes
  .icon-trash {
    cursor: pointer;
    filter: invert(38%) sepia(60%) saturate(5592%) hue-rotate(2deg) brightness(105%) contrast(98%)
  }
  .icon-trash:hover {
    filter: invert(100%) sepia(5%) saturate(332%) hue-rotate(206deg) brightness(116%) contrast(89%);
  }
`;
