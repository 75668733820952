export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    level: language === 'pt-BR' ? 'Nível' : 'Level',
    source: language === 'pt-BR' ? 'Fonte' : 'Source',
    current: language === 'pt-BR' ? 'Per. Atual' : 'Current Period',
    previous: language === 'pt-BR' ? 'Per. Anterior' : 'Previous Period',
  };
}
