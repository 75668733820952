import React, { useState } from 'react';
import Square from '../../../../../components/Square';
import InputsFromCard from './InputsFromCard';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import * as S from './style';
import { Translate as TranslateInputs } from './InputsFromCard/translate';
import { Translate } from './translate';

function CardRBIModel() {
  const translate = Translate();
  const translateInputs = TranslateInputs();
  const [userView, setUserView] = useState(translateInputs.userViewSelectedOnLoad);
  const [showLevelColumn, setShowLevelColumn] = useState(true);
  const [showSourceColumn, setShowSourceColumn] = useState(true);

  function handleToogleColumnLevel() {
    setShowLevelColumn((prevState) => !prevState);
  }

  function handleToogleColumnSource() {
    setShowSourceColumn((prevState) => !prevState);
  }

  return (
    <Square size="large">
      <S.TitleContainer>
        <div>
          <h3>{translate.title}</h3>
          <small>{translate.subtitle}</small>
        </div>
        <InputsFromCard
          userView={userView}
          setUserView={setUserView}
        />
      </S.TitleContainer>
      <S.TableContainer>
        <div className="c-checkbox__area">
          <div className="c-checkbox__container">
            <input
              type="checkbox"
              name="level"
              id="level"
              onChange={handleToogleColumnLevel}
              checked={showLevelColumn}
            />
            <label htmlFor="level">
              {' '}
              {translate.level}
            </label>
          </div>
          <div className="c-checkbox__container">
            <input
              type="checkbox"
              name="source"
              id="source"
              onChange={handleToogleColumnSource}
              checked={showSourceColumn}
            />
            <label htmlFor="source">
              {' '}
              {translate.source}
            </label>
          </div>
        </div>
        <S.Table>
          <TableHeader
            showLevelColumn={showLevelColumn}
            showSourceColumn={showSourceColumn}
          />
          <TableBody
            userView={userView}
            showLevelColumn={showLevelColumn}
            showSourceColumn={showSourceColumn}
          />
        </S.Table>
      </S.TableContainer>
    </Square>
  );
}

export default CardRBIModel;
