export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Classificação do conteúdo' : 'Content rating',
    message: language === 'pt-BR' ? 'Este conteúdo não contém avaliações' : 'This content does not contain ratings',
    ratedIn: language === 'pt-BR' ? 'Avaliado em' : 'Rated in',
    language,
  };
}
