import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100vh;
  position: relative;
  overflow-y: hidden !important;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    margin-bottom: ${(props) => props.theme.sizes.s48};
  }

  form {
    width: 30%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 90%;
    }
  }
`;

export const FormItem = styled.div`
  margin-bottom: 10px;
  text-decoration: none;
  transition: all .3s;
  position: relative;

  label {
    display: block;
    margin-bottom: 4px;
  }

  input {
    height: 40px;
    width: 100%;
    border-radius: 0px !important;
    border-bottom: 1px solid ${(props) => props.theme.grayDark};
    font-size: 16px;
    outline: 0;
    border-left: none;
    border-top: none;
    border-right: none;
    background-color: ${(props) => props.theme.grayBackground} !important;
    color: ${(props) => props.theme.fontWhite};
    cursor: text !important;
  }

  input:focus {
    border: none !important;
    border-bottom: 1px solid ${(props) => props.theme.grayDark} !important;
  }

  input:active {
    background-color: ${(props) => props.theme.grayBackground};
    color: ${(props) => props.theme.fontWhite};
  }

  button {
    width: 100%;
    margin-top: 16px;
    padding: 16px 0;
    cursor: pointer;
  }

  input::selection {
    background-color: ${(props) => props.theme.yellow};
    color: ${(props) => props.theme.grayDark};
  }

  .form-forgotpass {
    font-size: 11px;
    color: ${(props) => props.theme.yellow};
    text-decoration: none;
    cursor: pointer;
    transition: all .3s;
  }

  .form-forgotpass:hover {
    color: ${(props) => props.theme.fontWhite};
  }
`;
