import styled from 'styled-components';

export const MainContainer = styled.div`
  margin-bottom: ${(props) => props.theme.sizes.s20};
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    flex: 1;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-bottom: ${({ theme }) => theme.sizes.s16};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  div {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.smallNotebook}) {
         flex-direction: column;
         gap: 4px;
      }

    p {
      display: inline-block;
      margin-right: ${(props) => props.theme.sizes.s14};
    }

    form {
      display: flex;
      align-items: center;
      justify-content: center;


      @media (max-width: ${({ theme }) => theme.breakpoints.smallNotebook}) {
         flex-direction: column;
         width: 100%;
       }

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
          gap: 0;
          margin-bottom: 0px;
        }
      }

      input {
        width: 250px;

        @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
          width: 100%;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: transparent !important;
          color: ${({ theme }) => theme.grayLight};
          border-color: ${({ theme }) => theme.grayLight};
        }
      }

      small {
        color: ${(props) => props.theme.red};
        height: 12px;

        @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
          margin-bottom: 12px;
        }
      }
    }

    select, input {
      border-color: ${(props) => props.theme.grayLight};
      margin-right: ${(props) => props.theme.sizes.s8};
    }

    select {
      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
         width: 100%;
      }
    }
  }
`;
