import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;

  .container-academy {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      grid-template-columns: repeat(1, 1fr);
    }

    //square
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      > div {
        text-align: center;
        width: 100%;
      }
    }
  }
`;
