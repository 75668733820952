/* eslint-disable import/no-unresolved */
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { register } from 'swiper/element/bundle';
import App from './App';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { ThemeAppProvider } from './context/ThemeAppContext';

register();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
    <ThemeAppProvider>
      <App />
    </ThemeAppProvider>
  </StrictMode>,

  // <>
  //   <ThemeAppProvider>
  //     <App />
  //   </ThemeAppProvider>
  // </>,
);
