import { Translate } from './translate';

export function ListUserViewOptions() {
  const translate = Translate();
  return [
    { id: 1, name: translate.language === 'pt-BR' ? 'Por Plataforma' : 'By Platform' },
    { id: 2, name: translate.language === 'pt-BR' ? 'Por Canal' : 'By Channel' },
    { id: 3, name: translate.language === 'pt-BR' ? 'Por UTM Campaign' : 'By UTM Campaign' },
    { id: 4, name: translate.language === 'pt-BR' ? 'Por Produto' : 'By Product' },
  ];
}

