import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

export default function TitlePage({ title, children }) {
  return (
    <S.Container>
      <h2>{title}</h2>
      {children}
    </S.Container>
  );
}

TitlePage.defaultProps = {
  children: '',
};

TitlePage.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
