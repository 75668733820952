import PropTypes from 'prop-types';
import Square from '../../../../../components/Square';
import ButtonSaveCancel from '../../../../../components/ButtonsSaveCancel';
import toast from '../../../../../helpers/toast';
import UsersService from '../../../../../services/Users';
import { FormContainer, FormInputsContainer } from './style';
import { Translate } from './translate';
import { Button } from '../../../../../components/Button';

export function FormUser({
  user, setUser, onSave, createByClientPath, accessGroup, isDisabled,
}) {
  const translate = Translate();

  function save(e) {
    onSave(e);
  }

  async function handleSendInvite(e, idUser) {
    e.preventDefault();
    try {
      const response = await UsersService.fowardEmail({ id: idUser });
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    }
  }

  return (
    <>
      <form onSubmit={save}>
        <FormInputsContainer>
          {createByClientPath === 'edit' && (
            <Square size="large">
              <FormContainer>
                <div className="c-fields">
                  <label htmlFor="email">{translate.labelInvite}</label>
                  <Button color="blue" onClick={(e) => handleSendInvite(e, user.id)}>{translate.textFowardEmail}</Button>
                </div>
              </FormContainer>
            </Square>
          )}
          <Square size="large">
            <FormContainer>
              <div className="c-fields">
                <label htmlFor="name">{translate.labelName}</label>
                <div>
                  <input
                    type="text"
                    className="input-large"
                    value={user.firstName}
                    id="name"
                    onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                    placeholder={translate.placeholderFirstName}
                  />
                  <input
                    type="text"
                    className="input-large"
                    value={user.lastName}
                    id="lastname"
                    onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                    placeholder={translate.placeholderLastName}
                  />
                </div>
              </div>
            </FormContainer>
          </Square>
          <Square size="large">
            <FormContainer>
              <div className="c-fields">
                <label htmlFor="email">E-mail</label>
                <input
                  type="text"
                  className="input-large"
                  value={user.email}
                  id="email"
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  placeholder="E-mail"
                  disabled={createByClientPath === 'edit'}
                />
              </div>
              <div className="c-explanation">
                Não é permitido alterar e-mail.
              </div>
            </FormContainer>
          </Square>
          <Square size="large">
            <FormContainer>
              <div className="c-fields">
                <label htmlFor="Admin">{translate.labelAdmin}</label>
                <select
                  id="admin"
                  value={user.admin}
                  onChange={(e) => setUser({ ...user, admin: e.target.value })}
                  disabled={createByClientPath !== 'client' && user.perfil !== 'B2B'}
                >
                  <option value="N">{translate.placeholderNo}</option>
                  <option value="Y">{translate.placeholderYes}</option>
                </select>
              </div>
              <div className="c-explanation">{translate.textAdmin}</div>
            </FormContainer>
          </Square>
          <Square size="large">
            <FormContainer>
              <div className="c-fields">
                <label htmlFor="admin">{translate.labelLanguage}</label>
                <select
                  id="admin"
                  value={user.language}
                  onChange={(e) => setUser({ ...user, language: e.target.value })}
                >
                  <option value="pt-BR">{translate.placeholderLanguagePt}</option>
                  <option value="en-US">{translate.placeholderLanguageEn}</option>
                </select>
              </div>
            </FormContainer>
          </Square>
          <Square size="large">
            <FormContainer>
              <div className="c-fields">
                <label htmlFor="admin">{translate.labelActive}</label>
                <select
                  id="admin"
                  value={user.active}
                  onChange={(e) => setUser({ ...user, active: e.target.value })}
                  disabled={createByClientPath !== 'edit'}
                >
                  <option value="Y">{translate.placeholderYes}</option>
                  <option value="N">{translate.placeholderNo}</option>
                </select>
              </div>
            </FormContainer>
          </Square>
          <Square size="large">
            <FormContainer>
              <div className="c-fields">
                <label htmlFor="perfil">{translate.labelProfile}</label>
                <select
                  id="perfil"
                  value={user.perfil}
                  onChange={(e) => {
                    if (e.target.value === 'SHIFTER') {
                      setUser({ ...user, accessGroupID: '', perfil: e.target.value });
                    } else {
                      setUser({ ...user, perfil: e.target.value });
                    }
                  }}
                  disabled={createByClientPath !== 'normal'}
                >
                  {createByClientPath === 'normal' && (
                    <>
                      <option value="">Escolher</option>
                      <option value="B2C">B2C</option>
                      <option value="SHIFTER">SHIFTER</option>
                    </>
                  )}

                  {createByClientPath === 'edit' && (
                    <>
                      <option value="">Escolher</option>
                      <option value="B2B">B2B</option>
                      <option value="B2C">B2C</option>
                      <option value="SHIFTER">SHIFTER</option>
                    </>
                  )}

                  {createByClientPath === 'client' && (
                    <option value="B2B">B2B</option>
                  )}
                </select>
              </div>
              <div className="c-explanation">{translate.textProfile}</div>
            </FormContainer>
          </Square>
          <Square size="large">
            <FormContainer>
              <div className="c-fields">
                <label htmlFor="perfil">{translate.labelAcessGroup}</label>
                <select
                  id="perfil"
                  value={user.accessGroupID}
                  onChange={(e) => setUser({ ...user, accessGroupID: e.target.value })}
                  disabled={user.perfil === 'SHIFTER'}
                >
                  <option value="">Escolher</option>
                  {accessGroup?.length > 0 && accessGroup?.map((accessGroup) => (
                    <option value={accessGroup.id} key={accessGroup.id}>{accessGroup.name}</option>
                  ))}
                </select>
              </div>
              <div className="c-explanation">{translate.textAccessGroup}</div>
            </FormContainer>
          </Square>
          <ButtonSaveCancel goToWhenCancel="/admin/users" onSave={() => {}} disabled={(isDisabled)} />
        </FormInputsContainer>
      </form>
    </>
  );
}

FormUser.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  onSave: PropTypes.func,
  createByClientPath: PropTypes.oneOf(['normal', 'client', 'edit']),
  accessGroup: PropTypes.array,
  isDisabled: PropTypes.bool,
};
