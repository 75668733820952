class VtexService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async getVtexRevenue(dateInit, dateEnd, orderStatus, originType) {
    if (!dateInit || !dateEnd || !orderStatus) return;

    const response = await fetch(`${this.baseUrl}/vtex`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        dateInit, dateEnd, orderStatus, originType,
      }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(body.message);
    return body;
  }

  async getVtexRevenueWithoutOriginTypeFilter(dateInit, dateEnd, orderStatus) {
    if (!dateInit || !dateEnd || !orderStatus) return;

    // Included this var to reach out the endpoint without "originType" filter;
    const originType = null;

    const response = await fetch(`${this.baseUrl}/vtex`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        dateInit, dateEnd, orderStatus, originType,
      }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(body.message);
    return body;
  }

  async getVtexClients(dateInit, dateEnd, originType) {
    if (!dateInit || !dateEnd) return;

    const response = await fetch(`${this.baseUrl}/vtex-clients`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        dateInit, dateEnd, originType,
      }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(body.message);
    return body;
  }
}

export default new VtexService();
