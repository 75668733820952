import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../../components/Button';
import Square from '../../../../../../components/Square';
import ButtonsSaveCancel from '../../../../../../components/ButtonsSaveCancel';
import { generateSlug } from '../../../../../../helpers/generateSlug';
import { Translate } from './translate';
import {
  Container, InfoContainer, ListContainer,
} from './style';

export function ProductForm({ product, setProduct, onSave }) {
  const translate = Translate();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    allowSaveForm();
  }, [product]);

  function handleGenerateSlug() {
    setProduct({ ...product, slug: generateSlug(product?.namePt) });
  }

  function handleProduct(e) {
    setProduct((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  }

  async function handleSave() {
    onSave();
  }

  function allowSaveForm() {
    const hasNamePt = product.namePt?.length >= 3;
    const hasNameEn = product.nameEn?.length >= 3;
    const hasSlug = product.slug?.length >= 3;
    const typeIsCorrect = product.type === 'home' || product.type === 'academy' || product.type === 'transformation' || product.type === 'consulting';

    if (hasNamePt && hasNameEn && hasSlug && typeIsCorrect) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  return (
    <>
      <Container>
        <ListContainer>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textName}
              </div>
              <div className="c-right">
                <div className="c-input">
                  <label htmlFor="namePt">{translate.labelNamePt}</label>
                  <input
                    className="l-input-w"
                    type="text"
                    id="namePt"
                    name="namePt"
                    value={product.namePt}
                    onChange={handleProduct}

                  />
                </div>
                <div className="c-input">
                  <label htmlFor="nameEn">{translate.labelNameEn}</label>
                  <input
                    className="l-input-w"
                    type="text"
                    id="nameEn"
                    name="nameEn"
                    value={product.nameEn}
                    onChange={handleProduct}
                  />
                </div>
              </div>
            </InfoContainer>
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textColor}
              </div>
              <div className="c-right">
                <div className="c-input">
                  <input
                    type="color"
                    name="color"
                    value={product.color}
                    onChange={handleProduct}
                  />
                </div>
              </div>
            </InfoContainer>
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textActive}
              </div>
              <div className="c-right">
                <div className="c-input">
                  <select
                    type="text"
                    name="active"
                    value={product.active}
                    onChange={handleProduct}
                  >
                    <option value="Y">{translate.labelYes}</option>
                    <option value="N">{translate.labelNo}</option>
                  </select>
                </div>
              </div>
            </InfoContainer>
            {/* <div className="c-explanation">{translate.warningSubCategory}</div> */}
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                Slug
              </div>
              <div className="c-right">
                <Button color="yellow" onClick={handleGenerateSlug}>Gerar slug</Button>
                <div className="c-input">
                  <input
                    className="l-input-w"
                    type="text"
                    name="slug"
                    value={product.slug}
                    onChange={handleProduct}
                  />
                </div>
              </div>
            </InfoContainer>
            <div className="c-explanation">{translate.warningSlug}</div>
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textOrdering}
              </div>
              <div className="c-right">
                <div className="c-input">
                  <input
                    type="text"
                    name="ordering"
                    value={product.ordering}
                    onChange={(e) => setProduct({ ...product, ordering: e.target.value })}
                  />
                </div>
              </div>
            </InfoContainer>
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textType}
              </div>
              <div className="c-right">
                <div className="c-input">
                  <select
                    type="text"
                    name="type"
                    value={product.type}
                    onChange={handleProduct}
                  >
                    <option value="home">Home</option>
                    <option value="academy">Academy</option>
                    <option value="transformation">Transformation - Immersion</option>
                    <option value="consulting">Consulting - Acceleration</option>
                    {/* "transformation" | "consulting" */}
                  </select>
                </div>
              </div>
            </InfoContainer>
          </Square>
        </ListContainer>
        <ButtonsSaveCancel
          onSave={handleSave}
          goToWhenCancel="/admin/categories"
          disabled={isDisabled}
        />
      </Container>
    </>
  );
}
