import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.sizes.s24};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
    flex-direction: column;

    h2 {
      margin-bottom: 8px;
    }
  }


`;
