import { useState, useEffect, useCallback } from 'react';
import UTMService from '../../../../services/UTM';
import toast from '../../../../helpers/toast';
import { Translate } from './translate';

export function useURLBuilderAddParams() {
  const translate = Translate();
  const [paramsList, setParamsList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState('');
  const [paramToDelete, setParamToDelete] = useState();
  const [toogleModal, setToogleModal] = useState(false);
  const [isCategoryLoading, setIsCategoryLoading] = useState(true);
  const [isParamsLoading, setIsParamsLoading] = useState(true);

  useEffect(() => {
    getAllCategories();
    return () => {};
  }, []);

  async function getAllCategories() {
    setIsCategoryLoading(true);

    try {
      const utms = await UTMService.getAllCategories();

      function filterParams(utm) {
        return utm.id !== 4 // 'Customer Journey'
               && utm.id !== 3 // 'Paid Unpaid'
               && utm.id !== 12 // 'Country'
               && utm.id !== 13; // 'Region';
      }

      const utmsFiltered = utms.filter((utm) => filterParams(utm));
      utmsFiltered.sort((a, b) => {
        const firstValue = translate.language === 'pt-BR' ? a.namePt : a.nameEn;
        const nextValue = translate.language === 'pt-BR' ? b.namePt : b.nameEn;
        return firstValue.localeCompare(nextValue);
      });
      const firstElementId = utmsFiltered[0].id;
      setCategories(utmsFiltered);
      setCategorySelected(firstElementId);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsCategoryLoading(false);
    }
  }

  const getParamsById = useCallback(async () => {
    try {
      setIsParamsLoading(true);
      const data = await UTMService.getAllByCategoryId(categorySelected);
      setParamsList(data);
    } catch {
    } finally {
      setIsParamsLoading(false);
    }
  }, [categorySelected]);

  useEffect(() => {
    getParamsById();
    return () => {};
  }, [categorySelected, getParamsById]);

  async function deleteParam() {
    try {
      const response = await UTMService.delete(paramToDelete.id);
      toast('success', response.message);
      setParamsList((prevState) => prevState.filter((param) => param.id !== paramToDelete.id));
    } catch (error) {
      toast('danger', error.message);
    } finally {
      closeModal();
    }
  }

  function closeModal() {
    setParamToDelete(null);
    setToogleModal(false);
  }

  function handleOpenModalDeleteUrl(item) {
    setParamToDelete(item);
    setToogleModal(true);
  }

  return {
    toogleModal,
    closeModal,
    deleteParam,
    categories,
    categorySelected,
    isCategoryLoading,
    isParamsLoading,
    paramToDelete,
    handleOpenModalDeleteUrl,
    setCategorySelected,
    paramsList,
  };
}
