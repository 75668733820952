import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import UTMService from '../../../../../services/UTM';
import Event from '../../../../../lib/EventEmitter';
import Loader from '../../../../../components/Loader';
import { Translate } from './translate';
import IconAdd from '../../../../../styles/logos/add.svg';

function BusinessUnit({
  value, setValue, onToogleModal, setUtmCategoryToAdd, tipOfAddParams,
}) {
  const translate = Translate();
  const [paramsCustomList, setParamsCustomList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idParam] = useState(15);

  useEffect(() => {
    getCustomsUTMSources();
    Event.addListener('IDCustomParamAdded', (idParamToAdd) => {
      if (idParamToAdd === idParam) {
        getCustomsUTMSources();
      }
    });
  }, []);

  async function getCustomsUTMSources() {
    setIsLoading(true);
    const listofParamsCustom = await UTMService.getAllBYCategoryUTM(idParam);
    setParamsCustomList(listofParamsCustom);
    setIsLoading(false);
  }

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, businessUnit: event.target.value }));
  }

  function handleToogleModal() {
    setUtmCategoryToAdd(idParam);
    onToogleModal();
  }

  return (
    <div className="form-item">
      <label htmlFor="business-unit">
        {translate.label}
        <img src={IconAdd} alt="Add" title={tipOfAddParams} onClick={handleToogleModal} />
      </label>
      <select value={value} onChange={handleSelect} id="business-unit" disabled={isLoading}>
        <option value="" className="noneParam">None</option>
        {paramsCustomList && paramsCustomList.map((item) => (
          <option key={item.id} value={item.value} className="customParam">
            {' '}
            {item.name}
            {' '}
          </option>
        ))}
        <option value="bu-bd">bu-bd</option>
        <option value="bu-br">bu-br</option>
        <option value="bu-en">bu-en</option>
        <option value="bu-in">bu-in</option>
        <option value="bu-us">bu-us</option>
      </select>
      { isLoading && <Loader loading={isLoading} tofield /> }
    </div>
  );
}

BusinessUnit.propTypes = {
  value: PropTypes.string.isRequired,
  tipOfAddParams: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onToogleModal: PropTypes.func.isRequired,
  setUtmCategoryToAdd: PropTypes.func.isRequired,
};

export default BusinessUnit;
