import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Container, FieldSet } from './styles';
import { Translate } from './translate';

function UrlMaskedOptions({
  setUTMSourceMasked, onUTMMediumMasked, onUTMCampaignMasked, onShortenedURL,
}) {
  const translate = Translate();

  function handleUtmSource(e) {
    setUTMSourceMasked(e.target.checked);
  }

  function handleUtmMedium(e) {
    onUTMMediumMasked(e.target.checked);
  }

  function handleUtmCampaign(e) {
    onUTMCampaignMasked(e.target.checked);
  }

  function handleShortededURL(e) {
    if (e.target.checked) {
      onShortenedURL(e.target.checked);
    } else {
      onShortenedURL('');
    }
  }

  return (
    <Container>
      <div>
        <FieldSet>
          <legend>{translate.maskedParam}</legend>
          <div className="container-input">
            <input onChange={handleUtmSource} type="checkbox" value="utm_source" id="utm_source" />
            <label htmlFor="utm_source">UTM Source</label>
          </div>
          <div className="container-input">
            <input onClick={handleUtmMedium} type="checkbox" value="utm_medium" id="utm_medium" />
            <label htmlFor="utm_medium">UTM Medium</label>
          </div>
          <div className="container-input">
            <input onClick={handleUtmCampaign} type="checkbox" value="utm_campaign" id="utm_campaign" />
            <label htmlFor="utm_campaign">UTM Campaign</label>
          </div>
        </FieldSet>
      </div>
      <div>
        <FieldSet>
          <legend>{translate.shortenedParam}</legend>
          <div className="container-input">
            <input onChange={handleShortededURL} type="checkbox" value="utm_source" id="shortened" />
            <label htmlFor="shortened">{translate.shortenedParamLabel}</label>
          </div>
        </FieldSet>
      </div>
    </Container>
  );
}

UrlMaskedOptions.propTypes = {
  setUTMSourceMasked: PropTypes.func.isRequired,
  onUTMMediumMasked: PropTypes.func.isRequired,
  onUTMCampaignMasked: PropTypes.func.isRequired,
  onShortenedURL: PropTypes.func.isRequired,
};

export default UrlMaskedOptions;
