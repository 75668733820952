import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import VideosService from '../../../../../services/Videos';
import TitlePage from '../../../../../components/TitlePage';
import Square from '../../../../../components/Square';
import Loader from '../../../../../components/Loader';
import { Badge } from '../../../../../components/Badge';
import { ButtonLink } from '../../../../../components/ButtonLink';
import { NoDataMessage } from '../../../../../components/NoDataMessage';
import toast from '../../../../../helpers/toast';
import FaEditIcon from '../../../../../styles/logos/FaEdit.svg';
import TrashIcon from '../../../../../styles/logos/trash-alt.svg';
import { Translate } from './translate';
import {
  Container, TitleContainer, SearchContainer, ListContainer,
} from './style';

function Videos() {
  const translate = Translate();
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    getVideos();
  }, []);

  async function getVideos() {
    try {
      setIsLoading(true);
      const videosRes = await VideosService.findAll();
      setVideos(videosRes);
    } catch { } finally {
      setIsLoading(false);
    }
  }

  async function deleteVideo(id) {
    try {
      await VideosService.delete(id);
      toast('success', 'Video delete sucessfully');
      getVideos();
    } catch (error) {
      toast('danger', error.message);
    }
  }

  function handleSearchInput(e) {
    setSearchInput(e.target.value.toLowerCase());
  }

  const videosFiltered = useMemo(() => videos?.filter((videos) => videos.title_pt.toLowerCase().includes(searchInput)
                                                                  || videos.title_en.toLowerCase().includes(searchInput)
                                                                  || videos.product_name_pt.toLowerCase().includes(searchInput)
                                                                  || videos.product_name_en.toLowerCase().includes(searchInput)
                                                                  || videos.category_name_pt?.toLowerCase().includes(searchInput)
                                                                  || videos.category_name_en?.toLowerCase().includes(searchInput)
                                                                  || videos.subcategory_name_pt?.toLowerCase().includes(searchInput)
                                                                  || videos.subcategory_name_en?.toLowerCase().includes(searchInput)
                                                                  || videos.post_name_pt?.toLowerCase().includes(searchInput)
                                                                  || videos.post_name_en?.toLowerCase().includes(searchInput)));

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>
        {!isLoading && (
          <SearchContainer>
            <div className="c-search">
              <label htmlFor="search-term">{translate.search}</label>
              <input
                type="text"
                id="search-term"
                placeholder={translate.placeholderSearchTerm}
                value={searchInput}
                onChange={handleSearchInput}
              />
            </div>
            <div className="c-actions">
              <ButtonLink color="blue" to="/admin/videos/add">{translate.addButton}</ButtonLink>
            </div>
          </SearchContainer>
        )}

        <ListContainer>
          {isLoading && <Loader loading={isLoading} />}
          {!isLoading && (
            videos.length === 0 ? <NoDataMessage>{translate.videosInfo}</NoDataMessage>
              : videos && (
                videosFiltered.map((video) => (
                  <Square size="large" key={video.id}>
                    <TitleContainer>
                      <div className="c-container">
                        <div className="c-square">
                          <div className="c-square-name">
                            {video.title_pt } | {video.title_en }
                          </div>
                          <Badge color={video.product_color}>
                            {translate.language === 'pt-BR' ? video.product_name_pt : video.product_name_en}
                          </Badge>
                          {video.relation_type === 'category' && (
                            <>
                              <Badge>{translate.categoryText}
                                {' > '}
                                {translate.language === 'pt-BR' ? video.category_name_pt : video.category_name_en}
                              </Badge>
                            </>
                          )}
                          {video.relation_type === 'subcategory' && (
                            <>
                              <Badge>{translate.subCategoryText}
                                {' > '}
                                {translate.language === 'pt-BR' ? video.subcategory_name_pt : video.subcategory_name_en}
                              </Badge>
                            </>
                          )}
                          {video.relation_type === 'post' && (
                            <>
                              <Badge>{'Post > '}
                                {translate.language === 'pt-BR' ? video.post_name_pt : video.post_name_en}
                              </Badge>
                            </>
                          )}
                        </div>
                        <div className="c-square-action">
                          <Link to={`/admin/videos/edit/${video.id}`} title="Edit Video">
                            <img src={FaEditIcon} alt="Edit User" />
                          </Link>
                          <img className="icon-trash" onClick={() => deleteVideo(video.id)} title="Delete Video" src={TrashIcon} alt="Delete Video" />
                        </div>
                      </div>
                      <div className="c-container-infos">
                        <span>Favorites: {video.qtd_favorites}</span>
                        <span>Comments: {video.qtd_comments}</span>
                      </div>
                    </TitleContainer>
                  </Square>
                ))
              )
          )}
        </ListContainer>
      </Container>
    </>
  );
}

export default Videos;
