import React, { useEffect, useMemo, useState } from 'react';
import TrackerService from '../../../../../services/Tracker';
import Loader from '../../../../../components/Loader';
import Square from '../../../../../components/Square';
import Dates_New from '../../../../../helpers/Dates_New';
import {
  Container, Title, TableContainer, Table,
} from './style';
import { Translate } from './translate';

export function TableSingleUser() {
  const translate = Translate();
  const [selectDate, setSelectDate] = useState('last12');
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const months = Dates_New.generateListMonthly(selectDate);

  useEffect(() => {
    getData();
  }, []);

  function handleSelectDate(event) {
    setSelectDate(event.target.value);
  }

  async function getData() {
    try {
      setIsLoadingData(true);
      const dateResponse = await TrackerService.getUserInfo();
      setData(dateResponse);
    } catch {

    } finally {
      setIsLoadingData(false);
    }
  }

  function injectValueOnTable(data, position) {
    const result = data.filter((value) => value.date === months[position].dateDataBase).map((value) => value.totalMarkAsComplete)[0] || 0;
    return result;
  }

  function colorTheTableNumberWithClass(number) {
    if (number === 0) return 'l-red';
    if (number === 1) return 'l-yellow';
    if (number === 2) return 'l-green';
    if (number >= 3) return 'l-blue';
  }


  return (
    <Container>
      <Square size="medium">
        <Title>
          <div>
            <h3>{translate.title}</h3>
            <small>{translate.subtitle}</small>
          </div>
          <div className="filters-container">
            <div>
              <label htmlFor="months">{translate.labelPeriod}</label>
              <select id="months" onChange={handleSelectDate} value={selectDate}>
                <option value="last12">{translate.last12Months}</option>
                <option value="currentYear">{new Date().getFullYear()}</option>
                <option value="lastYear">{new Date().getFullYear() - 1}</option>
              </select>
            </div>
          </div>
        </Title>
        {isLoadingData && <Loader loading={isLoadingData} />}
        {!isLoadingData && (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                {months.map((month) => (
                  <td key={month.dateUser}>{Dates_New.formatDatesMonthly(month.dateUser)}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {(data.length > 0 && data) && data.map((info) => (
                <tr key={info.id}>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 0))}>{injectValueOnTable(info.values, 0)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 1))}>{injectValueOnTable(info.values, 1)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 2))}>{injectValueOnTable(info.values, 2)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 3))}>{injectValueOnTable(info.values, 3)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 4))}>{injectValueOnTable(info.values, 4)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 5))}>{injectValueOnTable(info.values, 5)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 6))}>{injectValueOnTable(info.values, 6)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 7))}>{injectValueOnTable(info.values, 7)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 8))}>{injectValueOnTable(info.values, 8)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 9))}>{injectValueOnTable(info.values, 9)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 10))}>{injectValueOnTable(info.values, 10)}</td>
                  <td className={colorTheTableNumberWithClass(injectValueOnTable(info.values, 11))}>{injectValueOnTable(info.values, 11)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
        )}
      </Square>
    </Container>
  );
}
