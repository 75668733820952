/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faTruckMonster } from '@fortawesome/free-solid-svg-icons';
import ModuleService from '../../../../services/Modules';
import AccessGroupService from '../../../../services/AccessGroup';
import TitlePage from '../../../../components/TitlePage';
import BackButton from '../../../../components/BackButton';
import { AccessGroupForm } from '../components/AccessGroupForm';
import toast from '../../../../helpers/toast';
import { renameAsRestriction } from '../utils/renameAsRestriction';
import { Translate } from './translate';

export function AccessGroupAdd() {
  const translate = Translate();
  const navigate = useNavigate();
  const [accessGroup, setAccessGroup] = useState({ name: '' });
  const [modules, setModules] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Get Modules from Database
  useEffect(() => {
    async function getModules() {
      const moduleList = await ModuleService.getAll();
      const modules = moduleList?.map((module) => ({
        ...module,
        namePt: renameAsRestriction(module.restriction, module.namePt),
        nameEn: renameAsRestriction(module.restriction, module.nameEn),
        selected: false,
      }));
      setModules(modules);
    }
    getModules();
  }, []);

  // Check inputs form every time that modules's and accessGroup.name's states are modify
  useEffect(() => {
    checkFormToAllowSave();
  }, [modules, accessGroup.name]);

  async function saveForm(e) {
    e.preventDefault();
    setIsButtonDisabled(true);
    const arrayModulesIDsFiltered = modules.filter((module) => (module.selected === true)).map((module) => module.id);

    try {
      const response = await AccessGroupService.store({ name: accessGroup.name, modules: arrayModulesIDsFiltered });
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsButtonDisabled(false);
      navigate('/admin/access-group');
    }
  }

  function checkFormToAllowSave() {
    const modulesToAddCheck = modules.filter((module) => module.selected === true);
    if (accessGroup.name.length >= 3 && modulesToAddCheck.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(faTruckMonster);
    }
  }

  function selectModuleToAdd(e) {
    const idSelected = e.target.value;
    const updateModules = modules.map((module) => (module.id === Number(idSelected) ? { ...module, selected: !module.selected } : { ...module }));
    setModules(updateModules);
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton />
      </TitlePage>
      <AccessGroupForm
        accessGroup={accessGroup}
        modules={modules}
        isButtonDisabled={isButtonDisabled}
        setAccessGroup={setAccessGroup}
        saveForm={saveForm}
        selectModuleToAdd={selectModuleToAdd}
      />
    </>
  );
}
