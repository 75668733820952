class Login {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async post(form) {
    const response = await fetch(`${this.baseUrl}/login`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ email: form.email, password: form.password }),
    });

    if (!response.ok) {
      throw new Error('No credential');
    }

    const body = await response.json();

    return body;
  }

  async verifyToken(token) {
    const response = await fetch(`${this.baseUrl}/login`, {
      headers: {
        authorization: token,
        'Content-type': 'application/json',
      },
    });

    if (!response.ok) throw new Error('No authorization');
    return response;
  }
}

export default new Login();
