export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Adicionando Usuário' : 'Add User',
    labelInvite: language === 'pt-BR' ? 'Convite' : 'Invite',
    labelName: language === 'pt-BR' ? 'Nome' : 'Name',
    labelAdmin: language === 'pt-BR' ? 'Administrador?' : 'Admin?',
    labelLanguage: language === 'pt-BR' ? 'Idioma' : 'Language',
    labelActive: language === 'pt-BR' ? 'Ativo' : 'Active',
    labelProfile: language === 'pt-BR' ? 'Perfil' : 'Profile',
    labelAcessGroup: language === 'pt-BR' ? 'Grupo de Acesso' : 'Access Group',
    placeholderYes: language === 'pt-BR' ? 'Sim' : 'Yes',
    placeholderNo: language === 'pt-BR' ? 'Não' : 'No',
    placeholderFirstName: language === 'pt-BR' ? 'Nome' : 'Name',
    placeholderLastName: language === 'pt-BR' ? 'Sobrenome' : 'LastName',
    placeholderLanguagePt: language === 'pt-BR' ? 'Português (BR)' : 'Portuguese (BR)',
    placeholderLanguageEn: language === 'pt-BR' ? 'Inglês (US)' : 'English (US)',
    textAdmin: language === 'pt-BR'
      ? 'Permitido apenas para usuários do Perfil B2B. Usuário terá privilégios de administração da conta, como cadastro de usuários, visualizar LOGS do sistema e etc...'
      : 'Only allowed for B2C Profile users. User will have account administration privileges, such as registering users, viewing system LOGS, etc...',
    textProfile: language === 'pt-BR'
      ? 'Perfil é escolhido apenas no cadastro'
      : 'Profile is chosen only during registration',
    textAccessGroup: language === 'pt-BR'
      ? 'Grupo de acesso não é necessário para usuários do perfil "SHIFTER"'
      : 'Access group is not required for users of the "SHIFTER" profile',
    textFowardEmail: language === 'pt-BR'
      ? 'Reencaminhar convite por e-mail'
      : 'Forward email invitation',
  };
}
