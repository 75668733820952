import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import UTMService from '../../../../../services/UTM';
import { Translate } from './translate';
import Loader from '../../../../../components/Loader';
import { FormItem } from './styles';

function Country({ value, setValue }) {
  const translate = Translate();
  const [countriesFromDB, setCountriesFromDB] = useState([]);
  const [valueCountryLocal, setValueCountryLocal] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function init() {
      await loadCountryList();
      setValueCountryLocal(value);
    }

    init();

    // Memory Leak
    return () => {
      setCountriesFromDB([]);
    };
  }, []);

  async function loadCountryList() {
    setIsLoading(true);
    try {
      const countries = await UTMService.getCountries();
      const nameField = translate.language === 'pt-BR' ? 'name_pt' : 'name_en';
      const countriesSort = countries.sort((a, b) => (a[nameField].localeCompare(b[nameField])));
      setCountriesFromDB(countriesSort);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  function handleSelect(event) {
    setValueCountryLocal(event.target.value);
    setValue((prevState) => ({ ...prevState, country: event.target.value }));
    setValue((prevState) => ({ ...prevState, countryId: event.target.selectedOptions[0].getAttribute('data-id') }));
  }

  return (
    <FormItem>
      <label htmlFor="country">{translate.label}</label>
      <select value={valueCountryLocal} onChange={handleSelect} name="country" disabled={isLoading}>
        <option value="" className="noneParam">None</option>
        {countriesFromDB && countriesFromDB.map((item) => (
          <option key={item.id} value={item.value} data-id={item.id}>
            {translate.language === 'pt-BR' ? item.name_pt : item.name_en}
          </option>
        ))}
      </select>
      { isLoading && <Loader loading={isLoading} tofield /> }
    </FormItem>
  );
}

Country.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default Country;
