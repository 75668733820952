export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Usuários' : 'Users',
    labelButtonAddNew: language === 'pt-BR' ? 'Adicionar usuário' : 'Add user',
    errorMsg: language === 'pt-BR' ? 'Não foi possível carregar os usuários' : 'User\'s list unavailable',
    search: language === 'pt-BR' ? 'Pesquisa' : 'Search',
    placeholderSearch: language === 'pt-BR' ? 'Email ou nome' : 'Email or name',
  };
}
