import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import Loader from '../../../../../../components/Loader';
import RevenueTr from './components/RevenueTr';
import RevenueVtexTr from './components/RevenueVtexTr';
import RevenueVtexEcomTmktTr from './components/RevenueVtexEcomTmktTr';
import RevenueVtexEcom from './components/RevenueVtexEcom';
import RevenueVtexTmkt from './components/RevenueVtexTmkt';
import RevenueVtexMarketplace from './components/RevenueVtexMarketplace';
import RevenueFINSessionsGATr from './components/RevenueFINSessionsGATr';
import InvestmentTr from './components/InvestmentTr';
import InvestmentTrTop from './components/InvestmentTrTop';
import InvestmentTrMid from './components/InvestmentTrMid';
import InvestmentTrLower from './components/InvestmentTrLower';
import InvestmentTrReturn from './components/InvestmentTrReturn';
import ROITr from './components/ROITr';
import ROIFINTr from './components/ROIFINTr';
import AOVTr from './components/AOVTr';
import AOVVtexTr from './components/AOVVtexTr';
import AOVVtexEcomTmktTr from './components/AOVVtexEcomTmktTr';
import AOVVtexEcomTr from './components/AOVVtexEcomTr';
import AOVVtexTmktTr from './components/AOVVtexTmktTr';
import AOVVtexMarketplaceTr from './components/AOVVtexMarketplaceTr';
import QuebraRevenueTr from './components/QuebraRevenueTr';
import QuebraTransactionsTr from './components/QuebraTransactionsTr';
import ClicksTr from './components/ClicksTr';
import ClicksTrTop from './components/ClicksTrTop';
import ClicksTrMid from './components/ClicksTrMid';
import ClicksTrLower from './components/ClicksTrLower';
import ClicksTrReturn from './components/ClicksTrReturn';
import ClicksRatioTr from './components/ClicksRatioTr';
import ImpressionsTr from './components/ImpressionsTr';
import ImpressionsTrTop from './components/ImpressionsTrTop';
import ImpressionsTrMid from './components/ImpressionsTrMid';
import ImpressionsTrLower from './components/ImpressionsTrLower';
import ImpressionsTrReturn from './components/ImpressionsTrReturn';
import CPMTr from './components/CPMTr';
import CTRTr from './components/CTRTr';
import CPCTr from './components/CPCTr';
import CPLTr from './components/CPLTr';
import ClientsTotalTr from './components/ClientsTotalTr';
import ClientsNewTr from './components/ClientsNewTr';
import ClientsOldTr from './components/ClientsOldTr';
import CACFinTr from './components/CACFinTr';
import SessionTr from './components/SessionTr';
import SessionTrTop from './components/SessionTrTop';
import SessionTrMid from './components/SessionTrMid';
import SessionTrLower from './components/SessionTrLower';
import SessionTrReturn from './components/SessionTrReturn';
import EstimatedCPCTr from './components/EstimatedCPCTr';
import EngagedSessionTr from './components/EngagedSessionTr';
import TransactionsTr from './components/TransactionsTr';
import TransactionsTrTop from './components/TransactionsTrTop';
import TransactionsTrMid from './components/TransactionsTrMid';
import TransactionsTrLower from './components/TransactionsTrLower';
import TransactionsTrReturn from './components/TransactionsTrReturn';
import TransactionsVtexTr from './components/TransactionsVtexTr';
import TransactionsVtexEcomTmktTr from './components/TransactionsVtexEcomTmktTr';
import TransactionsVtexEcom from './components/TransactionsVtexEcom';
import TransactionsVtexTmkt from './components/TransactionsVtexTmkt';
import TransactionsVtexMarketplace from './components/TransactionsVtexMarketplace';
import TotalUsersTr from './components/TotalUsersTr';
import ConversionsTr from './components/ConversionsTr';
import NewUsersTr from './components/NewUsersTr';
import ConversionaRateSessionsTr from './components/ConversionRateSessionsTr';
import ConversionRateSessionsTrTop from './components/ConversionRateSessionsTrTop';
import ConversionRateSessionsTrMid from './components/ConversionRateSessionsTrMid';
import ConversionRateSessionsTrLower from './components/ConversionRateSessionsTrLower';
import ConversionRateSessionsTrReturn from './components/ConversionRateSessionsTrReturn';
import ConversionRateNewUsersTr from './components/ConversionRateNewUsersTr';
import ConversionRateUsersTr from './components/ConversionRateUsersTr';
import LeadsTr from './components/LeadsTr';
import LeadsTrTop from './components/LeadsTrTop';
import LeadsTrMid from './components/LeadsTrMid';
import LeadsTrLower from './components/LeadsTrLower';
import LeadsTrReturn from './components/LeadsTrReturn';
import ConversionRateLeadsSessionsTr from './components/ConversionRateLeadsSessionsTr';
import ConversionRateLeadsSessionsTrTop from './components/ConversionRateLeadsSessionsTrTop';
import ConversionRateLeadsSessionsTrMid from './components/ConversionRateLeadsSessionsTrMid';
import ConversionRateLeadsSessionsTrLower from './components/ConversionRateLeadsSessionsTrLower';
import ConversionRateLeadsSessionsTrReturn from './components/ConversionRateLeadsSessionsTrReturn';
import ConversionRateLeadsNewUsersTr from './components/ConversionRateLeadsNewUsersTr';
import ConversionRateLeadsUsersTr from './components/ConversionRateLeadsUsersTr';
import { DataContext } from '../../../context/Data';



const TableBody = React.memo(({ userView, showLevelColumn, showSourceColumn }) => {
  const { isLoandingData } = useContext(DataContext);
  const { isEcommerce, type_client } = JSON.parse(localStorage.getItem('clientInfo'));

  return (
    <>
      {(isLoandingData) && (
        <tbody>
          <tr rowSpan={9}>
            <td>
              <Loader loading={(isLoandingData)} />
            </td>
          </tr>
        </tbody>
      )}
      {!isLoandingData && (
        <tbody>
          {isEcommerce !== 'Y'
            ? null
            : (userView === 'Reduzida' || userView === 'Reduced'
                || userView === 'Intermediária' || userView === 'Intermediate'
                || userView === 'Ampliada' || userView === 'Enlarged')
              ? (
                <RevenueTr
                  showLevelColumn={showLevelColumn}
                  showSourceColumn={showSourceColumn}
                />
              )
              : null}
          {(type_client === 'VTEX' && (userView !== 'Ampliada/Jornada' && userView !== 'Enlarged/Journey')) && (
            <RevenueVtexTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <RevenueVtexEcomTmktTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <RevenueVtexEcom
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <RevenueVtexTmkt
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <RevenueVtexMarketplace
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <RevenueFINSessionsGATr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {userView !== 'VTEX' && (
            <InvestmentTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <InvestmentTrTop
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <InvestmentTrMid
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <InvestmentTrLower
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <InvestmentTrReturn
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {isEcommerce !== 'Y' ? null
            : (userView === 'Reduzida' || userView === 'Reduced'
                || userView === 'Intermediária' || userView === 'Intermediate'
                || userView === 'Ampliada' || userView === 'Enlarged')
              ? (
                <ROITr
                  showLevelColumn={showLevelColumn}
                  showSourceColumn={showSourceColumn}
                />
              )
              : null}
          {(type_client === 'VTEX' && (userView !== 'Ampliada/Jornada' && userView !== 'Enlarged/Journey')) && (
            <ROIFINTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView !== 'Ampliada/Jornada' && userView !== 'Enlarged/Journey')) && (
            <AOVVtexTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <AOVVtexEcomTmktTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <AOVVtexEcomTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <AOVVtexTmktTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <AOVVtexMarketplaceTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (
            userView === 'Intermediária' || userView === 'Intermediate'
              || userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
              <QuebraRevenueTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
          )}
          {(type_client === 'VTEX' && (
            userView === 'Intermediária' || userView === 'Intermediate'
              || userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
              <QuebraTransactionsTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
          )}
          {(type_client === 'VTEX' && (userView !== 'Ampliada/Jornada' && userView !== 'Enlarged/Journey')) && (
            <TransactionsVtexTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <TransactionsVtexEcomTmktTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <TransactionsVtexEcom
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <TransactionsVtexTmkt
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <TransactionsVtexMarketplace
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {isEcommerce !== 'Y' ? null
            : (userView === 'Reduzida' || userView === 'Reduced'
                || userView === 'Intermediária' || userView === 'Intermediate'
                || userView === 'Ampliada' || userView === 'Enlarged')
              ? (
                <AOVTr
                  showLevelColumn={showLevelColumn}
                  showSourceColumn={showSourceColumn}
                />
              )
              : null}
          {userView !== 'VTEX' && (
            <ImpressionsTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ImpressionsTrTop
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ImpressionsTrMid
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ImpressionsTrLower
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ImpressionsTrReturn
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Intermediária' || userView === 'Intermediate'
            || userView === 'Ampliada' || userView === 'Enlarged')
            ? (
              <CPMTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada' || userView === 'Enlarged')
            ? (
              <CTRTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Intermediária' || userView === 'Intermediate'
            || userView === 'Ampliada' || userView === 'Enlarged')
            ? (
              <CPCTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Intermediária' || userView === 'Intermediate'
            || userView === 'Ampliada' || userView === 'Enlarged'
            || userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ClicksTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ClicksTrTop
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ClicksTrMid
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ClicksTrLower
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ClicksTrReturn
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Intermediária' || userView === 'Intermediate'
            || userView === 'Ampliada' || userView === 'Enlarged')
            ? (
              <ClicksRatioTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada' || userView === 'Enlarged')
            ? (
              <EstimatedCPCTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {userView !== 'VTEX' && (
            <SessionTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <SessionTrTop
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <SessionTrMid
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <SessionTrLower
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <SessionTrReturn
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada' || userView === 'Enlarged')
            ? (
              <EngagedSessionTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView !== 'Ampliada/Jornada' && userView !== 'Enlarged/Journey' && userView !== 'VTEX')
            ? (
              <TotalUsersTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Intermediária' || userView === 'Intermediate'
            || userView === 'Ampliada' || userView === 'Enlarged')
            ? (
              <ConversionsTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView !== 'Ampliada/Jornada' && userView !== 'Enlarged/Journey' && userView !== 'VTEX')
            ? (
              <NewUsersTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {isEcommerce !== 'Y' ? null
            : userView !== 'VTEX' && (
              <TransactionsTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )}
          {isEcommerce !== 'Y' ? null
            : (
              (userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
                ? (
                  <TransactionsTrTop
                    showLevelColumn={showLevelColumn}
                    showSourceColumn={showSourceColumn}
                  />
                )
                : null
            )}
          {isEcommerce !== 'Y' ? null
            : (
              (userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
                ? (
                  <TransactionsTrMid
                    showLevelColumn={showLevelColumn}
                    showSourceColumn={showSourceColumn}
                  />
                )
                : null
            )}
          {isEcommerce !== 'Y' ? null
            : (
              (userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
                ? (
                  <TransactionsTrLower
                    showLevelColumn={showLevelColumn}
                    showSourceColumn={showSourceColumn}
                  />
                )
                : null
            )}
          {isEcommerce !== 'Y' ? null
            : (
              (userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
                ? (
                  <TransactionsTrReturn
                    showLevelColumn={showLevelColumn}
                    showSourceColumn={showSourceColumn}
                  />
                )
                : null
            )}
          {isEcommerce !== 'Y' ? null
            : userView !== 'VTEX' && (
              <ConversionaRateSessionsTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )}
          {isEcommerce !== 'Y' ? null
            : (
              (userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
                ? (
                  <ConversionRateSessionsTrTop
                    showLevelColumn={showLevelColumn}
                    showSourceColumn={showSourceColumn}
                  />
                )
                : null
            )}
          {isEcommerce !== 'Y' ? null
            : (
              (userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
                ? (
                  <ConversionRateSessionsTrMid
                    showLevelColumn={showLevelColumn}
                    showSourceColumn={showSourceColumn}
                  />
                )
                : null
            )}
          {isEcommerce !== 'Y' ? null
            : (
              (userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
                ? (
                  <ConversionRateSessionsTrLower
                    showLevelColumn={showLevelColumn}
                    showSourceColumn={showSourceColumn}
                  />
                )
                : null
            )}
          {isEcommerce !== 'Y' ? null
            : (
              (userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
                ? (
                  <ConversionRateSessionsTrReturn
                    showLevelColumn={showLevelColumn}
                    showSourceColumn={showSourceColumn}
                  />
                )
                : null
            )}
          {isEcommerce !== 'Y' ? null
            : (
              (userView === 'Intermediária' || userView === 'Intermediate'
                || userView === 'Ampliada' || userView === 'Enlarged')
                ? (
                  <ConversionRateUsersTr
                    showLevelColumn={showLevelColumn}
                    showSourceColumn={showSourceColumn}
                  />
                )
                : null
            )}
          {isEcommerce !== 'Y' ? null
            : (
              (userView === 'Intermediária' || userView === 'Intermediate'
                || userView === 'Ampliada' || userView === 'Enlarged')
                ? (
                  <ConversionRateNewUsersTr
                    showLevelColumn={showLevelColumn}
                    showSourceColumn={showSourceColumn}
                  />
                )
                : null
            )}
          {userView !== 'VTEX' && (
            <LeadsTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <LeadsTrTop
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <LeadsTrMid
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <LeadsTrLower
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <LeadsTrReturn
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Intermediária' || userView === 'Intermediate'
            || userView === 'Ampliada' || userView === 'Enlarged')
            ? (
              <CPLTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {userView !== 'VTEX' && (
            <ConversionRateLeadsSessionsTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ConversionRateLeadsSessionsTrTop
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ConversionRateLeadsSessionsTrMid
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ConversionRateLeadsSessionsTrLower
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Ampliada/Jornada' || userView === 'Enlarged/Journey')
            ? (
              <ConversionRateLeadsSessionsTrReturn
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Intermediária' || userView === 'Intermediate'
            || userView === 'Ampliada' || userView === 'Enlarged')
            ? (
              <ConversionRateLeadsUsersTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(userView === 'Intermediária' || userView === 'Intermediate'
            || userView === 'Ampliada' || userView === 'Enlarged')
            ? (
              <ConversionRateLeadsNewUsersTr
                showLevelColumn={showLevelColumn}
                showSourceColumn={showSourceColumn}
              />
            )
            : null}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <ClientsTotalTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <ClientsNewTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <ClientsOldTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
          {(type_client === 'VTEX' && (userView === 'Ampliada' || userView === 'Enlarged' || userView === 'VTEX')) && (
            <CACFinTr
              showLevelColumn={showLevelColumn}
              showSourceColumn={showSourceColumn}
            />
          )}
        </tbody>
      )}
    </>

  );
});

TableBody.propTypes = {
  userView: PropTypes.string.isRequired,
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default TableBody;
