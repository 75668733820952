import React, { useEffect, useState } from 'react';
import { faHeartPulse, faTemperatureEmpty } from '@fortawesome/free-solid-svg-icons';
import TrackerService from '../../../../services/Tracker';
import TitlePage from '../../../../components/TitlePage';
import { ProductList } from '../components/ProductList';
import { ListViews } from '../components/ListViews';
import { TableSingleUser } from '../components/TableSingleUser';
import { Container } from './styles';

export function TrackerUser() {
  const [productsList, setProductsList] = useState([]);
  const [videosList, setVideosList] = useState([]);
  const [isLoadingProductList, setIsLoadingProductList] = useState(true);
  const [isLoadingVideosList, setIsLoadingVideosList] = useState(true);

  useEffect(() => {
    getModulesInfo();
    getVideosInfo();

    return () => {
      setProductsList([]);
      setVideosList([]);
      setIsLoadingProductList(true);
      setIsLoadingVideosList(true);
    };
  }, []);

  async function getModulesInfo() {
    try {
      setIsLoadingProductList(true);
      const productsInfo = await TrackerService.getUserInfoMonth();
      setProductsList(productsInfo);
    } catch { } finally {
      setIsLoadingProductList(false);
    }
  }

  async function getVideosInfo() {
    try {
      setIsLoadingVideosList(true);
      const videosInfo = await TrackerService.getUserInfoVideosWatched();
      setVideosList(videosInfo);
    } catch { } finally {
      setIsLoadingVideosList(false);
    }
  }

  return (
    <Container>
      <TitlePage title="Tracker" />
      <ProductList productsList={productsList} isLoading={isLoadingProductList} />
      <TableSingleUser />
      <ListViews videoList={videosList} isLoading={isLoadingVideosList} />
    </Container>
  );
}
