/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import URLBuilderService from '../../../../services/URLBuilder';
import UTMService from '../../../../services/UTM';
import getRandowNumber from '../../../../helpers/UrlBuilderRandomNumber';
import Event from '../../../../lib/EventEmitter';
import TitlePage from '../../../../components/TitlePage';
import BackButton from '../../../../components/BackButton';
import ButtonSaveCancel from '../../../../components/ButtonsSaveCancel';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import IconHelp from '../../../../styles/logos/help_info.svg';
import toast from '../../../../helpers/toast';
import URLDestination from './URLDestination';
import UtmSource from './UTMSource';
import UtmMedium from './UTMMedium';
import CampaignName from './CampaignName';
import BusinessUnit from './BusinessUnit';
import ProductCategory from './ProductCategory';
import Distribution from './Distribution';
import PaidUnpaid from './PaidUnpaid';
import Funnel from './Funnel';
import Crm from './Crm';
import ProspectStatus from './ProspectStatus';
import AudienceIncome from './AudienceIncome';
import Touchpoint from './Touchpoint';
import Format from './Format';
import Strategy from './Strategy';
import StrategyType from './StrategyType';
import Channel from './Channel';
import ChannelType from './ChannelType';
import CampaignType from './CampaignType';
import Country from './Country';
import State from './State';
import UrlMaskedOptions from './UrlMaskedOptions';
import { AdGroupAdSet } from './AdGroupAdSet';
import { Ad } from './Ad';
import { Keyword } from './Keyword';
import { Translate } from './translate';
import * as S from './styles';

export default function UrlBuilderAdd() {
  const translate = Translate();
  const navigate = useNavigate();
  const location = useLocation();
  const cloneForID = new URLSearchParams(location.search).get('clone_for');

  const [schemaUrl, setSchemaUrl] = useState('https://');
  const [url, setUrl] = useState('');
  const [urlComplete, setUrlComplete] = useState({
    utmSource: '',
    utmMedium: '',
    campaignName: '',
    adGroupAdSet: '',
    ad: '',
    keyword: '',
    businessUnit: '',
    distribution: '',
    paidUnpaid: '',
    funnel: '',
    crm: '',
    productCategory: '',
    prospectStatus: '',
    audienceIncome: '',
    touchpoint: '',
    format: '',
    strategy: '',
    strategyType: '',
    channel: '',
    channelType: '',
    campaignType: '',
    countryId: null,
    country: '',
    stateId: null,
    state: '',
  });
  const [randomNumber, setRandomNumber] = useState('');
  const [utmSourceMasked, setUTMSourceMasked] = useState('');
  const [utmMediumMasked, setUTMMediumMasked] = useState('');
  const [utmCampaignMasked, setUTMCampaignMasked] = useState('');
  const [shortenedUrl, setShortenedURL] = useState('');
  const [utmCategoriesList, setUtmCategoriesList] = useState([]);
  const [utmCategoryToAdd, setUtmCategoryToAdd] = useState(null);
  const [customParam, setCustomParam] = useState({ name: '', urlValue: '' });

  const [isLoadingInfos, setIsLoadingInfos] = useState(true);
  const [disableButtonOnSave, setDisableButtonOnSave] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [disableButtonModalOnSave, setDisableButtonModalOnSave] = useState(true);

  const isUtmCampaingsFilled = urlComplete.campaignName.length > 0
                               || urlComplete.adGroupAdSet.length > 0
                               || urlComplete.ad.length > 0
                               || urlComplete.keyword.length > 0
                               || urlComplete.channel.length > 0
                               || urlComplete.channelType.length > 0
                               || urlComplete.campaignType.length > 0
                               || urlComplete.strategy.length > 0
                               || urlComplete.strategyType.length > 0
                               || urlComplete.productCategory.length > 0
                               || urlComplete.distribution.length > 0
                               || urlComplete.paidUnpaid.length > 0
                               || urlComplete.funnel.length > 0
                               || urlComplete.crm.length > 0
                               || urlComplete.prospectStatus.length > 0
                               || urlComplete.audienceIncome.length > 0
                               || urlComplete.touchpoint.length > 0
                               || urlComplete.format.length > 0
                               || urlComplete.businessUnit.length > 0
                               || urlComplete.country.length > 0
                               || urlComplete.state.length > 0;

  const utmSourceFinal = `${urlComplete.utmSource.length > 0 ? `utm_source=${urlComplete.utmSource}` : urlComplete.utmSource}`;
  const utmMediumFinal = `${urlComplete.utmMedium.length > 0 ? `&utm_medium=${urlComplete.utmMedium}` : urlComplete.utmMedium}`;
  const utmCampaingSymbol = `${isUtmCampaingsFilled ? '&utm_campaign=' : ''}`;

  const utmSourceChoosen = `${urlComplete.utmSource.length > 0 ? `_${urlComplete.utmSource}` : urlComplete.utmSource}`;
  const utmMediumChoosen = `${urlComplete.utmMedium.length > 0 ? `_${urlComplete.utmMedium}` : urlComplete.utmMedium}`;
  const adGroupAdSetChoosen = `${urlComplete.adGroupAdSet.length > 0 ? `_${urlComplete.adGroupAdSet}` : urlComplete.adGroupAdSet}`;
  const adChoosen = `${urlComplete.ad.length > 0 ? `_${urlComplete.ad}` : urlComplete.ad}`;
  const keywordChoosen = `${urlComplete.keyword.length > 0 ? `_${urlComplete.keyword}` : urlComplete.keyword}`;
  const campaignNameChoosen = `${urlComplete.campaignName.length > 0 ? `_${urlComplete.campaignName}` : urlComplete.campaignName}`;
  const businessUnitChoosen = `${urlComplete.businessUnit.length > 0 ? `_${urlComplete.businessUnit}` : urlComplete.businessUnit}`;
  const productCategoryChoosen = `${urlComplete.productCategory.length > 0 ? `_${urlComplete.productCategory}` : urlComplete.productCategory}`;
  const distributionChoosen = `${urlComplete.distribution.length > 0 ? `_${urlComplete.distribution}` : urlComplete.distribution}`;
  const paidUnpaidChoosen = `${urlComplete.paidUnpaid.length > 0 ? `_${urlComplete.paidUnpaid}` : urlComplete.paidUnpaid}`;
  const funnelChoosen = `${urlComplete.funnel.length > 0 ? `_${urlComplete.funnel}` : urlComplete.funnel}`;
  const crmChoosen = `${urlComplete.crm.length > 0 ? `_${urlComplete.crm}` : urlComplete.crm}`;
  const prospectStatusChoosen = `${urlComplete.prospectStatus.length > 0 ? `_${urlComplete.prospectStatus}` : urlComplete.prospectStatus}`;
  const audienceIncomeChoosen = `${urlComplete.audienceIncome.length > 0 ? `_${urlComplete.audienceIncome}` : urlComplete.audienceIncome}`;
  const touchpointChoosen = `${urlComplete.touchpoint.length > 0 ? `_${urlComplete.touchpoint}` : urlComplete.touchpoint}`;
  const formatChoosen = `${urlComplete.format.length > 0 ? `_${urlComplete.format}` : urlComplete.format}`;
  const strategyChoosen = `${urlComplete.strategy.length > 0 ? `_${urlComplete.strategy}` : urlComplete.strategy}`;
  const strategyTypeChoosen = `${urlComplete.strategyType.length > 0 ? `_${urlComplete.strategyType}` : urlComplete.strategyType}`;
  const channelChoosen = `${urlComplete.channel.length > 0 ? `_${urlComplete.channel}` : urlComplete.channel}`;
  const channelTypeChoosen = `${urlComplete.channelType.length > 0 ? `_${urlComplete.channelType}` : urlComplete.channelType}`;
  const campaignTypeChoosen = `${urlComplete.campaignType.length > 0 ? `_${urlComplete.campaignType}` : urlComplete.campaignType}`;
  const countryChoosen = `${urlComplete.country.length > 0 ? `_${urlComplete.country}` : urlComplete.country}`;
  const stateChoosen = `${urlComplete.state.length > 0 ? `_${urlComplete.state}` : urlComplete.state}`;

  const utmCampaignFinal = utmSourceChoosen + utmMediumChoosen + campaignNameChoosen + adGroupAdSetChoosen + adChoosen + keywordChoosen + channelChoosen + channelTypeChoosen + campaignTypeChoosen + strategyChoosen + strategyTypeChoosen + productCategoryChoosen + distributionChoosen + paidUnpaidChoosen + funnelChoosen + crmChoosen + prospectStatusChoosen + audienceIncomeChoosen + touchpointChoosen + formatChoosen + businessUnitChoosen + countryChoosen + stateChoosen;
  const urlFinal = `${schemaUrl}${url}/?${utmSourceFinal}${utmMediumFinal}${utmCampaingSymbol}${utmCampaignFinal}`;
  const urlFinalMasked = generateUrlMasked();
  const urlFinalShortened = generateUrlShortened();

  // init function
  useEffect(() => {
    async function init() {
      await getUtmCategoriesList();
      if (cloneForID === null) {
        setRandomNumber(getRandowNumber());
        setIsLoadingInfos(false);
      } else {
        await updateFieldsOfTheForm(cloneForID);
        setRandomNumber(getRandowNumber());
      }
    }

    init();
  }, []);

  // Check Url typed and remove invalid characters or upper case.
  useEffect(() => {
    function removeInvalidUrls() {
      // Regular expression to match 'http://', 'https:// and \'
      const regex = /http:\/\/|https:\/\/|\\/g;
      const URLChecked = url.replace(regex, '').toLowerCase();
      setUrl(URLChecked);
    }
    removeInvalidUrls();
  }, [url]);

  // Check able and disable Save Btn
  useEffect(() => {
    if (isUtmCampaingsFilled && urlComplete.utmSource.length > 0 && urlComplete.utmMedium.length > 0 && url.length) {
      setDisableButtonOnSave(false);
    } else {
      setDisableButtonOnSave(true);
    }
  }, [isUtmCampaingsFilled, urlComplete.utmSource, urlComplete.utmMedium, url]);

  // Check able and disable Save Btn on Modal
  useEffect(() => {
    checkCustomParamsWasFilled();
  }, [customParam.name, customParam.urlValue]);

  // generate UrlMasked
  useEffect(() => {
    generateUrlMasked();
  }, [utmSourceMasked, utmMediumMasked, utmCampaignMasked]);


  async function handleAddUrls() {
    const urlMasked = (!utmSourceMasked && !utmMediumMasked && !utmCampaignMasked) ? '' : urlFinalMasked;
    const urlShortened = !shortenedUrl ? '' : urlFinalShortened;
    const utms = {
      schemaUrl,
      siteUrl: url,
      ad: urlComplete.ad,
      adGroupAdSet: urlComplete.adGroupAdSet,
      audienceIncome: urlComplete.audienceIncome,
      businessUnit: urlComplete.businessUnit,
      campaignName: urlComplete.campaignName,
      campaignType: urlComplete.campaignType,
      channel: urlComplete.channel,
      channelType: urlComplete.channelType,
      crm: urlComplete.crm,
      customerJourney: urlComplete.funnel,
      distribution: urlComplete.distribution,
      format: urlComplete.format,
      keyword: urlComplete.keyword,
      paidUnpaid: urlComplete.paidUnpaid,
      productCategory: urlComplete.productCategory,
      prospectStatus: urlComplete.prospectStatus,
      strategy: urlComplete.strategy,
      strategyType: urlComplete.strategyType,
      touchpoints: urlComplete.touchpoint,
      utmMedium: urlComplete.utmMedium,
      utmSource: urlComplete.utmSource,
      countryID: urlComplete.countryId,
      stateID: urlComplete.stateId,
    };

    try {
      const response = await URLBuilderService.add({
        urlFinal, urlMasked, urlShortened, utms,
      });
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      navigate('/client/urlbuilder');
    }
  }

  async function updateFieldsOfTheForm(idUrl) {
    try {
      setIsLoadingInfos(true);
      const [urlInfos] = await URLBuilderService.getOne({ idUrl });
      setSchemaUrl(!urlInfos.schemaUrl ? '' : urlInfos.schemaUrl);
      setUrl(!urlInfos.siteURL ? '' : urlInfos.siteURL);
      setUrlComplete({
        utmSource: !urlInfos.utmSource ? '' : urlInfos.utmSource,
        utmMedium: !urlInfos.utmMedium ? '' : urlInfos.utmMedium,
        campaignName: !urlInfos.campaignName ? '' : urlInfos.campaignName,
        adGroupAdSet: !urlInfos.adGroupAdSet ? '' : urlInfos.adGroupAdSet,
        ad: !urlInfos.ad ? '' : urlInfos.ad,
        keyword: !urlInfos.keyword ? '' : urlInfos.keyword,
        businessUnit: !urlInfos.businessUnit ? '' : urlInfos.businessUnit,
        productCategory: !urlInfos.productCategory ? '' : urlInfos.productCategory,
        distribution: !urlInfos.distribution ? '' : urlInfos.distribution,
        paidUnpaid: !urlInfos.paidUnpaid ? '' : urlInfos.paidUnpaid,
        funnel: !urlInfos.customerJourney ? '' : urlInfos.customerJourney,
        crm: !urlInfos.crm ? '' : urlInfos.crm,
        prospectStatus: !urlInfos.prospectStatus ? '' : urlInfos.prospectStatus,
        audienceIncome: !urlInfos.audienceIncome ? '' : urlInfos.audienceIncome,
        touchpoint: !urlInfos.touchpoints ? '' : urlInfos.touchpoints,
        format: !urlInfos.format ? '' : urlInfos.format,
        strategy: !urlInfos.strategy ? '' : urlInfos.strategy,
        strategyType: !urlInfos.strategyType ? '' : urlInfos.strategyType,
        channel: !urlInfos.channel ? '' : urlInfos.channel,
        channelType: !urlInfos.channelType ? '' : urlInfos.channelType,
        campaignType: !urlInfos.campaignType ? '' : urlInfos.campaignType,
        countryId: !urlInfos.countryID ? '' : urlInfos.countryID,
        country: !urlInfos.countryValue ? '' : urlInfos.countryValue,
        stateId: !urlInfos.regionID ? '' : urlInfos.regionID,
        state: !urlInfos.stateValue ? '' : urlInfos.stateValue,
      });
    } finally {
      setIsLoadingInfos(false);
    }
  }

  async function getUtmCategoriesList() {
    const utmsCategories = await UTMService.getAllCategories();
    setUtmCategoriesList(utmsCategories);
  }

  async function handleSaveCustomParam() {
    const idParamToAdd = utmCategoryToAdd;
    try {
      const response = await UTMService.add(customParam.name, customParam.urlValue, idParamToAdd);
      Event.emit('IDCustomParamAdded', idParamToAdd);
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setCustomParam({ name: '', urlValue: '' });
      setUtmCategoryToAdd(null);
      setModalVisible(false);
    }
  }

  function generateUrlMasked() {
    const hasUtmSourceMasked = utmSourceMasked ? `utm_source=${randomNumber}` : utmSourceFinal;
    const hasUtmMediumMasked = utmMediumMasked ? `&utm_medium=${randomNumber}` : utmMediumFinal;
    const hasUtmCampaignMasked = utmCampaignMasked ? `&utm_campaign=${randomNumber}` : `${utmCampaingSymbol}${utmCampaignFinal}`;

    return `${schemaUrl}${url}/?${hasUtmSourceMasked}${hasUtmMediumMasked}${hasUtmCampaignMasked}`;
  }

  function generateUrlShortened() {
    return `${schemaUrl}${url}/?utm_campaign=${randomNumber}`;
  }

  function handleToogleModal() {
    if (modalVisible) {
      setUtmCategoryToAdd(null);
      setCustomParam({ name: '', urlValue: '' });
    }
    setModalVisible(!modalVisible);
  }

  function checkCustomParamsWasFilled() {
    if (customParam.name.length >= 3 && customParam.urlValue.length >= 3) {
      setDisableButtonModalOnSave(false);
    } else {
      setDisableButtonModalOnSave(true);
    }
  }

  function fillUrlValue(urlTyped) {
    const urlSanitized = urlTyped.toLowerCase()
      .replaceAll(')', '')
      .replaceAll('(', '')
      .replaceAll(']', '')
      .replaceAll('[', '')
      .replaceAll('}', '')
      .replaceAll('{', '')
      .replaceAll('^', '')
      .replaceAll('~', '')
      .replaceAll('´', '')
      .replaceAll('?', '')
      .replaceAll('&', '')
      .replaceAll('`', '')
      .replaceAll('"', '')
      .replaceAll(',', '')
      .replaceAll('.', '')
      .replaceAll(';', '')
      .replaceAll('/', '')
      .replaceAll('\\', '')
      .replaceAll('|', '')
      .replaceAll(' ', '-')
      .replaceAll('_', '-')
      .replaceAll('ç', 'c')
      .replaceAll('â', 'a')
      .replaceAll('ã', 'a')
      .replaceAll('á', 'a')
      .replaceAll('à', 'a')
      .replaceAll('ê', 'e')
      .replaceAll('é', 'e')
      .replaceAll('è', 'e')
      .replaceAll('í', 'i')
      .replaceAll('ô', 'o')
      .replaceAll('õ', 'o')
      .replaceAll('ú', 'u')
      .replaceAll('ç', 'c');
    setCustomParam((prevState) => ({ ...prevState, urlValue: urlSanitized }));
  }

  return (
    <>
      <TitlePage title={translate.titlePage}>
        <BackButton />
      </TitlePage>
      {isLoadingInfos && <Loader loading={isLoadingInfos} />}
      {!isLoadingInfos && (
        <S.Container>
          <form>
            <URLDestination
              url={url}
              setUrl={setUrl}
              schemaUrl={schemaUrl}
              setSchemaUrl={setSchemaUrl}
            />
            <S.Paragraph>{translate.infoPage}</S.Paragraph>
            <S.FormGroup>
              <div className="container row-1">
                <fieldset>
                  <legend>UTM Source</legend>
                  <UtmSource
                    value={urlComplete.utmSource}
                    setValue={setUrlComplete}
                    onToogleModal={handleToogleModal}
                    setUtmCategoryToAdd={setUtmCategoryToAdd}
                    tipOfAddParams={translate.tipOfAddParams}
                  />
                </fieldset>
                <fieldset>
                  <legend>UTM Medium</legend>
                  <UtmMedium
                    value={urlComplete.utmMedium}
                    setValue={setUrlComplete}
                    onToogleModal={handleToogleModal}
                    setUtmCategoryToAdd={setUtmCategoryToAdd}
                    tipOfAddParams={translate.tipOfAddParams}
                  />
                </fieldset>
              </div>
              <fieldset className="container row-2">
                <legend>UTM Campaign</legend>
                <CampaignName
                  value={urlComplete.campaignName}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <AdGroupAdSet
                  value={urlComplete.adGroupAdSet}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <Ad
                  value={urlComplete.ad}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <Keyword
                  value={urlComplete.keyword}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <Channel
                  value={urlComplete.channel}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <ChannelType
                  value={urlComplete.channelType}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <CampaignType
                  value={urlComplete.campaignType}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <Strategy
                  value={urlComplete.strategy}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <StrategyType
                  value={urlComplete.strategyType}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <ProductCategory
                  value={urlComplete.productCategory}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <Distribution
                  value={urlComplete.distribution}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <PaidUnpaid
                  value={urlComplete.paidUnpaid}
                  setValue={setUrlComplete}
                />
                <Funnel
                  value={urlComplete.funnel}
                  setValue={setUrlComplete}
                />
                <Crm
                  value={urlComplete.crm}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <ProspectStatus
                  value={urlComplete.prospectStatus}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <AudienceIncome
                  value={urlComplete.audienceIncome}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <Touchpoint
                  value={urlComplete.touchpoint}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <Format
                  value={urlComplete.format}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <BusinessUnit
                  value={urlComplete.businessUnit}
                  setValue={setUrlComplete}
                  onToogleModal={handleToogleModal}
                  setUtmCategoryToAdd={setUtmCategoryToAdd}
                  tipOfAddParams={translate.tipOfAddParams}
                />
                <Country
                  value={urlComplete.country}
                  setValue={setUrlComplete}
                />
                <State
                  value={urlComplete.state}
                  setValue={setUrlComplete}
                  countryId={urlComplete.countryId}
                  stateId={urlComplete.stateId}
                />
              </fieldset>
            </S.FormGroup>
          </form>

          {(isUtmCampaingsFilled && urlComplete.utmSource.length > 0 && urlComplete.utmMedium.length > 0 && url.length >= 5) && (
            <>
              <UrlMaskedOptions
                setUTMSourceMasked={setUTMSourceMasked}
                onUTMMediumMasked={setUTMMediumMasked}
                onUTMCampaignMasked={setUTMCampaignMasked}
                onShortenedURL={setShortenedURL}
              />
              <S.UrlSquare>
                <div>
                  <S.Span>URL</S.Span>
                  <p>{urlFinal}</p>
                </div>
                {(utmSourceMasked || utmMediumMasked || utmCampaignMasked) && (
                  <>
                    <hr />
                    <div>
                      <S.Span>{translate.maskedParam}</S.Span>
                      <p>{urlFinalMasked}</p>
                    </div>
                  </>
                )}
                {(shortenedUrl) && (
                  <>
                    <hr />
                    <div>
                      <S.Span>{translate.shortenedParam}</S.Span>
                      <p>{urlFinalShortened}</p>
                    </div>
                  </>
                )}
              </S.UrlSquare>
            </>
          )}

          <ButtonSaveCancel
            disabled={disableButtonOnSave}
            onSave={handleAddUrls}
            goToWhenCancel="/client/urlbuilder"
            smallText={translate.saveText}
          />

        </S.Container>
      )}

      {/* Modal */}
      <Modal
        title={translate.modalTitle}
        onAction={handleSaveCustomParam}
        onClose={handleToogleModal}
        visible={modalVisible}
        btnDisabled={disableButtonModalOnSave}
      >
        <S.ModalContainer>
          <div>
            <div className="label-container">
              <label htmlFor="param">{translate.modalParam}</label>
            </div>
            <input
              type="text"
              id="param"
              disabled
              value={utmCategoriesList?.filter((utmCategory) => Number(utmCategory.id) === Number(utmCategoryToAdd)).map((utmCategory) => (translate.language === 'pt-BR' ? utmCategory.namePt : utmCategory.nameEn))}
            />
          </div>
          <div>
            <div className="label-container">
              <label htmlFor="name">{translate.modalName}</label>
              <img src={IconHelp} alt="Help" title={translate.nameHelperMessage} />
            </div>
            <input
              type="text"
              id="name"
              placeholder={translate.placeholderName}
              value={customParam.name}
              onChange={(event) => {
                setCustomParam((prevState) => ({ ...prevState, name: event.target.value }));
                fillUrlValue(event.target.value);
              }}
            />
          </div>
          <div>
            <div className="label-container">
              <label htmlFor="value">{translate.modalValue}</label>
              <img src={IconHelp} alt="Help" title={translate.urlValueHelperMessage} />
            </div>
            <input
              type="text"
              id="value"
              placeholder={translate.placeholderValue}
              value={customParam.urlValue}
              onChange={(event) => setCustomParam((prevState) => ({ ...prevState, urlValue: event.target.value }))}
            />
          </div>
        </S.ModalContainer>
      </Modal>
    </>
  );
}
