/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { reduceArraysSingle } from '../reduceArraysSingle';
import { numberFormat } from '../../../../../../helpers/numberFormat';
import { isNumberInvalid } from '../../../../../../helpers/isNumberInvalid';
import { groupAndSumByDynamicKey } from '../groupAndSumByDynamicKey';
import { calculateDataArraysByDate } from '../calculateDataArraysByDate';

export function ConvRateEventsUsers(group, leadsGoogleAnalitycsDataCurrent, leadsGoogleAnalitycsDataPrevious, googleAnalyticsGoogleAdsDataCurrent, googleAnalyticsGoogleAdsDataPrevious) {
  const type = 'percentage'; // number, currency or percentage
  const decimal = 2; // 0, 1, 2
  const dataCurrent = {};
  const dataPrevious = {};
  const FirstDataCurrent = reduceArraysSingle(group, 'leads', leadsGoogleAnalitycsDataCurrent);
  const SecondDataCurrent = reduceArraysSingle(group, 'total_users', googleAnalyticsGoogleAdsDataCurrent);
  const FirstDataPrevious = reduceArraysSingle(group, 'leads', leadsGoogleAnalitycsDataPrevious);
  const SecondDataPrevious = reduceArraysSingle(group, 'total_users', googleAnalyticsGoogleAdsDataPrevious);
  const dataByDate1 = groupAndSumByDynamicKey(group, 'leads', leadsGoogleAnalitycsDataCurrent);
  const dataByDate2 = groupAndSumByDynamicKey(group, 'total_users', googleAnalyticsGoogleAdsDataCurrent);
  const dataByDate = calculateDataArraysByDate(dataByDate1, dataByDate2, true);

  // Join values from DataCurrent
  for (let key in FirstDataCurrent) {
    if (SecondDataCurrent.hasOwnProperty(key)) {
      dataCurrent[key] = FirstDataCurrent[key] / SecondDataCurrent[key];
    } else {
      dataCurrent[key] = 0;
    }
  }

  // Join values from DataPrevious
  for (let key in FirstDataPrevious) {
    if (SecondDataPrevious.hasOwnProperty(key)) {
      dataPrevious[key] = FirstDataPrevious[key] / SecondDataPrevious[key];
    } else {
      dataPrevious[key] = 0;
    }
  }

  const kpiInfo = {
    isInverted: false,
    total: {
      current: 0,
      previous: 0,
      delta: '',
    },
    data: [],
    dataByDate,
  };

  // Sets totals
  const totalCurrent = (FirstDataCurrent && SecondDataCurrent)
    ? Object.entries(FirstDataCurrent).reduce((acc, item) => (acc + (isNaN(item[1]) ? 0 : Number(item[1]))), 0)
      / Object.entries(SecondDataCurrent).reduce((acc, item) => (acc + (isNaN(item[1]) ? 0 : Number(item[1]))), 0)
    : 0;
  const totalPrevious = (FirstDataPrevious && SecondDataPrevious)
    ? Object.entries(FirstDataPrevious).reduce((acc, item) => (acc + (isNaN(item[1]) ? 0 : Number(item[1]))), 0)
      / Object.entries(SecondDataPrevious).reduce((acc, item) => (acc + (isNaN(item[1]) ? 0 : Number(item[1]))), 0)
    : 0;
  kpiInfo.total.current = numberFormat(totalCurrent, type, decimal);
  kpiInfo.total.previous = numberFormat(totalPrevious, type, decimal);
  kpiInfo.total.delta = numberFormat(((totalCurrent / totalPrevious) - 1), 'percentage', 1);

  // Percorre as chaves do objeto 'current'
  const arr = [];
  for (let key in dataCurrent) {
  // Verifica se a chave também existe no objeto 'previous'
    if (dataPrevious.hasOwnProperty(key)) {
    // Soma os valores correspondentes das chaves
      arr.push({
        view: key,
        current: numberFormat(dataCurrent[key], type, decimal),
        previous: numberFormat(dataPrevious[key], type, decimal),
        currentNumber: isNumberInvalid(dataCurrent[key]) ? 0 : Number(dataCurrent[key]),
        previousNumber: isNumberInvalid(dataPrevious[key]) ? 0 : Number(dataPrevious[key]),
        delta: numberFormat(((dataCurrent[key] / dataPrevious[key]) - 1), 'percentage', 1),
      });
    } else {
    // Se a chave não existir em 'previous', utiliza apenas o valor de 'current'
      arr.push({
        view: key,
        current: numberFormat(dataCurrent[key], type, decimal),
        previous: numberFormat(0, type, decimal),
        currentNumber: isNumberInvalid(dataCurrent[key]) ? 0 : Number(dataCurrent[key]),
        previousNumber: Number(0),
        delta: numberFormat(((dataCurrent[key] / 0) - 1), 'percentage', 1),
      });
    }
  }

  // Adiciona as chaves de 'previous' que não existem em 'current' (caso existam)
  for (let key in dataPrevious) {
    if (!dataCurrent.hasOwnProperty(key)) {
      arr.push({
        view: key,
        current: numberFormat(0, type, decimal),
        previous: numberFormat(dataPrevious[key], type, decimal),
        currentNumber: Number(0),
        previousNumber: isNumberInvalid(dataPrevious[key]) ? 0 : Number(dataPrevious[key]),
        delta: numberFormat(((0 / dataPrevious[key]) - 1), 'percentage', 1),
      });
    }
  }
  const arrSorted = arr.sort((a, b) => b.currentNumber - a.currentNumber);
  kpiInfo.data = arrSorted;

  return kpiInfo;
}
