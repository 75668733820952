import Square from '../../../../../components/Square';
import ButtonSaveCancel from '../../../../../components/ButtonsSaveCancel';
import { Translate } from './translate';
import { FormInputsContainer, FormContainer, SubtitleContainer } from './style';

export function AccessGroupForm({
  accessGroup, modules, isButtonDisabled, setAccessGroup, saveForm, selectModuleToAdd,
}) {
  const translate = Translate();

  return (
    <form onSubmit={saveForm}>
      <FormInputsContainer>
        <Square size="large">
          <FormContainer>
            <div className="c-fields">
              <label htmlFor="name">{translate.labelName}</label>
              <input
                className="input-large"
                type="text"
                value={accessGroup.name}
                name="name"
                id="name"
                onChange={(e) => setAccessGroup({ name: e.target.value })}
              />
            </div>
          </FormContainer>
        </Square>
        <Square size="large">
          <SubtitleContainer>
            <span>*  {translate.subtitleApis}</span>
            <span>** {translate.subtitleAdmin}</span>
          </SubtitleContainer>
          <FormContainer>
            <div className="c-multiselect-container">
              <label htmlFor="name">{translate.labelModule}</label>
              <div className="c-multiselect-container-fields">
                <div className="c-multiselect-container-fields-options">
                  <label htmlFor="modules">{translate.labelModule}</label>
                  <select multiple="multiple" onClick={selectModuleToAdd} id="modules">
                    {modules.length > 0 && modules.filter((module) => module.selected === false).map((module) => (
                      <option key={module.accessHash} value={module.id}>
                        {translate.language === 'pt-BR' ? module.namePt : module.nameEn}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="c-multiselect-container-fields-options c-color">
                  <label htmlFor="modules-to-add">{translate.labelGroupsModule}</label>
                  <select multiple="multiple" onClick={selectModuleToAdd} id="modules-to-add">
                    {modules.length > 0 && modules.filter((module) => module.selected === true).map((module) => (
                      <option key={module.accessHash} value={module.id}>
                        {translate.language === 'pt-BR' ? module.namePt : module.nameEn}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </FormContainer>
        </Square>
        <ButtonSaveCancel onSave={() => {}} disabled={isButtonDisabled} goToWhenCancel="/admin/access-group" />
      </FormInputsContainer>
    </form>
  );
}
