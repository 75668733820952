export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Lista de Comentários' : 'Comment\'s List',
    active: language === 'pt-BR' ? 'Ativo > ' : 'Active >',
    no: language === 'pt-BR' ? 'Não' : 'No',
    yes: language === 'pt-BR' ? 'Sim' : 'Yes',
    language,
  };
}
