export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Conteúdos selecionados para você...' : 'Content selected for you...',
    like: language === 'pt-BR' ? 'curtida' : 'like',
    likes: language === 'pt-BR' ? 'curtidas' : 'likes',
    likeBtnLike: language === 'pt-BR' ? 'Curtir' : 'Like',
    likeBtnLiked: language === 'pt-BR' ? 'Curtido' : 'Liked',
    messageWholeContent: language === 'pt-BR' ? 'Acesse conteúdo completo em' : 'Access full content at',
    seeMore: language === 'pt-BR' ? 'ver mais' : 'see more',
    seeLess: language === 'pt-BR' ? 'ver menos' : 'see less',
    language,
  };
}
