export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Cliente' : 'Client',
    labelName: language === 'pt-BR' ? 'Nome' : 'Name',
    labelPerfil: language === 'pt-BR' ? 'Perfil Cliente' : 'Client\'s Profile',
    labelCurrency: language === 'pt-BR' ? 'Moeda' : 'Currency',
    labelLanguageTag: language === 'pt-BR' ? 'Etiqueta de Moeda' : 'Language Tag',
    labelClickHere: language === 'pt-BR' ? 'Clique Aqui' : 'Click Here',
    textEcommerce: language === 'pt-BR'
      ? 'Se selecionado com \'Sim\', o cliente visualiza os KPIs de Receta, ROAS, Transações e suas derivações nos Dashboards.'
      : 'If selected with \'Yes\', the client sees the KPIs for Revenue, ROAS, Transactions and their derivations on the Dashboards.',
    textGA: language === 'pt-BR'
      ? 'Quando selecionado VTEX, os KPIs da plataforma VTEX ficam disponíveis nos Dashboards.'
      : 'When VTEX is selected, the VTEX platform KPIs are available on the Dashboards.',
    textCurrency: language === 'pt-BR'
      ? 'Determina qual é a moeda e sua formatação no padrão ISO_4217. Para escolher qual é a melhor para o cliente escolha'
      : 'Determines the currency and its formatting in the ISO_4217 standard. To choose which is best for the customer',
    textLanguageTag: language === 'pt-BR'
      ? 'Determina qual a formatação dos números dentro da plataforma.'
      : 'Determines the formatting of numbers within the platform.',
  };
}
