import React, { useEffect, useState } from 'react';
import CommentsAcademyService from '../../../../services/CommentsAcademy';
import Dates_New from '../../../../helpers/Dates_New';
import TitlePage from '../../../../components/TitlePage';
import Square from '../../../../components/Square';
import Loader from '../../../../components/Loader';
import { NoDataMessage } from '../../../../components/NoDataMessage';
import toast from '../../../../helpers/toast';
import { Badge } from '../../../../components/Badge';
import IconReleaseComment from '../../../../styles/logos/release_comment.svg';
import IconUnreleaseComment from '../../../../styles/logos/unrelease_comment.svg';
import IconTrash from '../../../../styles/logos/trash-alt.svg';
import { Translate } from './translate';
import { Container, CommentItem } from './style';

export default function Comments() {
  const translate = Translate();
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getComments();
  }, []);

  async function getComments() {
    try {
      setIsLoading(true);
      const commentsList = await CommentsAcademyService.findAll();
      setComments(commentsList);
    } catch {
      setComments([]);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateComment(id) {
    const [commentSelected] = comments.filter((comment) => comment.id === id);
    const statusUpdated = commentSelected.active === 'N' ? 'Y' : 'N';
    await CommentsAcademyService.updateStatus(id, statusUpdated);
    const newCommentList = comments.map((comment) => {
      if (comment.id === id) {
        // eslint-disable-next-line no-param-reassign
        comment.active = statusUpdated;
      }
      return { ...comment };
    });
    setComments(newCommentList);
  }

  async function deleteComment(id) {
    try {
      await CommentsAcademyService.delete(id);
      toast('success', 'Delete successfully');
      getComments();
    } catch (error) {
      toast('danger', error.message);
    }
  }

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>
        {isLoading && <Loader loading={isLoading} />}
        {(!isLoading && comments.length === 0) && <NoDataMessage>No comments to show</NoDataMessage>}
        {!isLoading && comments.length > 0 && (
          comments.map((comment) => (
            <Square key={comment.id} size="large">
              <CommentItem className={comment.active === 'N' ? 'inactive' : 'active'}>
                <div className="infos">
                  <Badge>
                    {`${comment.first_name} ${comment.last_name}`}
                  </Badge>
                  <Badge>
                    {Dates_New.formatDatesMask(comment.date_act)}
                  </Badge>
                  {comment.id_client ? <Badge>B2B</Badge> : <Badge>B2C</Badge>}
                  {comment.id_client && <Badge>{comment.client_name}</Badge>}
                  <Badge>
                    {`${translate.language === 'pt-BR' ? comment.videoInfo.productNamePt : comment.videoInfo.productNameEn}`}
                    {(comment.videoInfo?.categoryNamePt && comment.videoInfo?.categoryNameEn) && (
                      ` | Categoria > ${translate.language === 'pt-BR' ? comment.videoInfo?.categoryNamePt : comment.videoInfo?.categoryNameEn}`
                    )}
                    {(comment.videoInfo?.subCategoryNamePt && comment.videoInfo?.subCategoryNameEn) && (
                      ` | SubCategoria > ${translate.language === 'pt-BR' ? comment.videoInfo?.subCategoryNamePt : comment.videoInfo?.subCategoryNameEn}`
                    )}
                    {(comment.videoInfo?.postNamePt && comment.videoInfo?.postNameEn) && (
                      ` | Post > ${translate.language === 'pt-BR' ? comment.videoInfo?.postNamePt : comment.videoInfo?.postNameEn}`
                    )}
                  </Badge>
                </div>
                <div className="content">
                  <div className="content-comment">
                    {comment.comment}
                  </div>
                  <div className="content-action">
                    <div className="content-action-toogle" onClick={() => updateComment(comment.id)}>
                      {comment.active === 'N' && <img src={IconReleaseComment} alt="Release Comment" title="Active" />}
                      {comment.active === 'Y' && <img src={IconUnreleaseComment} alt="Release Comment" title="Inactive" />}
                    </div>
                    <img src={IconTrash} className="icon-trash" alt="Remove comment" onClick={() => deleteComment(comment.id)} />
                  </div>
                </div>
              </CommentItem>
            </Square>
          ))
        )}
      </Container>
    </>
  );
}
