import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import toast from '../../../helpers/toast';
import SettingsService from '../../../services/Settings';
import BackButton from '../../../components/BackButton';
import Square from '../../../components/Square';
import TitlePage from '../../../components/TitlePage';
import ButtonSaveCancel from '../../../components/ButtonsSaveCancel';
import { AuthContext } from '../../../context/AuthContext';
import Translate from './translate';
import * as S from './style';

function EditPassword() {
  const { handleLogout } = useContext(AuthContext);
  const translate = Translate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [errorPassMsg, setErrorPassMsg] = useState(false);
  const smallMsgNewPass = useRef();
  const smallMsgConfirmPass = useRef();
  const { pathname } = window.location;
  const pathNameLocal = pathname.split('/')[1];

  useEffect(() => {
    const isPasswordBetween1And6 = password.length >= 1 && password.length < 6;
    const isConfirmPasswordBetween1And6 = confirmPassword.length >= 1 && confirmPassword.length < 6;
    const isPasswordEmpty = password.length === 0;
    const isConfirmPasswordEmpty = password.length === 0;
    const isBothPassEquals = password === confirmPassword;

    if (!isPasswordEmpty && !isConfirmPasswordEmpty && !isBothPassEquals) {
      setErrorPassMsg(true);
      setDisabled(true);
    } else if (isPasswordBetween1And6 || isConfirmPasswordBetween1And6 || !isBothPassEquals) {
      setDisabled(true);
    } else if (isPasswordEmpty && isConfirmPasswordEmpty) {
      setDisabled(true);
      setErrorPassMsg(false);
    } else {
      setDisabled(false);
      setErrorPassMsg(false);
    }

    return () => {};
  }, [password, confirmPassword]);

  async function handleSavePasswords() {
    try {
      const response = await SettingsService.updatePassword(password);
      toast('success', response.message);
      handleLogout();
    } catch (error) {
      toast('danger', error.message);
    }
  }

  function handleChangePassword(e) {
    if (e.target.value.length >= 1 && e.target.value.length < 6) {
      smallMsgNewPass.current.innerHTML = translate.errorPassword;
    } else {
      smallMsgNewPass.current.innerHTML = '';
    }

    setPassword(e.target.value);
  }

  function handleChangeConfirmPassword(e) {
    if (e.target.value.length >= 1 && e.target.value.length < 6) {
      smallMsgConfirmPass.current.innerHTML = translate.errorPassword;
    } else {
      smallMsgConfirmPass.current.innerHTML = '';
    }

    setConfirmPassword(e.target.value);
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton />
      </TitlePage>

      <S.MainContainer>
        <p>{translate.textMessage}</p>

        <Square size="large">
          <S.Container>
            <h3>{translate.textPassword}</h3>
            <div>
              <input
                type="password"
                placeholder={`${translate.textPassword}...`}
                value={password}
                onChange={handleChangePassword}
                autoComplete="off"
              />
              <small ref={smallMsgNewPass} />
            </div>
          </S.Container>
        </Square>

        <Square size="large">
          <S.Container>
            <h3>{translate.textConfirmPassword}</h3>
            <div>
              <input
                type="password"
                placeholder={`${translate.textConfirmPassword}...`}
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                autoComplete="off"
              />
              <small ref={smallMsgConfirmPass} />
            </div>
          </S.Container>
        </Square>

        {errorPassMsg && (
          <S.AlertMessage>{translate.errorPasswordEquals}</S.AlertMessage>
        )}

        <ButtonSaveCancel
          disabled={disabled}
          onSave={handleSavePasswords}
          goToWhenCancel={`/${pathNameLocal}/settings`}
          smallText={translate.textMessageOnSave}
        />

      </S.MainContainer>
    </>
  );
}

export default EditPassword;
