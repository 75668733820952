import styled from 'styled-components';

export const TextContainer = styled.div`
  margin-top: 16px;
  padding: 20px;
  background-color: ${({ theme }) => theme.grayMenu};
  border-radius: 8px;
  width: 100%;

  h3 {
    margin-bottom: 16px;
  }
`;
