import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import SubCategoryProductsService from '../../../../../services/SubCategoryProduct';
import TitlePage from '../../../../../components/TitlePage';
import Square from '../../../../../components/Square';
import Loader from '../../../../../components/Loader';
import { Badge } from '../../../../../components/Badge';
import { ButtonLink } from '../../../../../components/ButtonLink';
import { NoDataMessage } from '../../../../../components/NoDataMessage';
import toast from '../../../../../helpers/toast';
import FaEditIcon from '../../../../../styles/logos/FaEdit.svg';
import AddVideoIcon from '../../../../../styles/logos/add_video.svg';
import EditVideoIcon from '../../../../../styles/logos/edit_video.svg';
import ListOrderIcon from '../../../../../styles/logos/list_order.svg';
import TrashIcon from '../../../../../styles/logos/trash-alt.svg';
import { Translate } from './translate';
import {
  Container, TitleContainer, SearchContainer, ListContainer,
} from './style';

function SubCategories() {
  const translate = Translate();
  const [subCategories, setSubCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    getSubcategories();
  }, []);

  async function getSubcategories() {
    try {
      setIsLoading(true);
      const subCategoriesProduct = await SubCategoryProductsService.findAll();
      setSubCategories(subCategoriesProduct);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteSubcategory(id) {
    try {
      await SubCategoryProductsService.delete(id);
      toast('success', 'Subcategory deleted');
      getSubcategories();
    } catch (error) {
      toast('danger', error.message);
    }
  }

  function handleSearchInput(e) {
    setSearchInput(e.target.value.toLowerCase());
  }

  const categoriesFiltered = useMemo(() => subCategories?.filter((subCategory) => subCategory.name_en.toLowerCase().includes(searchInput)
                                                                                  || subCategory.name_pt.toLowerCase().includes(searchInput)
                                                                                  || subCategory.category_name_en.toLowerCase().includes(searchInput)
                                                                                  || subCategory.category_name_pt.toLowerCase().includes(searchInput)));

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>
        <SearchContainer>
          <div className="c-search">
            <label htmlFor="search-term">{translate.search}</label>
            <input
              type="text"
              id="search-term"
              placeholder={translate.placeholderSearchTerm}
              value={searchInput}
              onChange={handleSearchInput}
            />
          </div>
          <div className="c-actions">
            <ButtonLink color="blue" to="/admin/sub-categories/add">{translate.addButton}</ButtonLink>
          </div>
        </SearchContainer>

        <ListContainer>
          {isLoading && <Loader loading={isLoading} />}
          {!isLoading && (
            subCategories.length === 0 ? <NoDataMessage>{translate.subCategoryInfo}</NoDataMessage>
              : subCategories && (
                categoriesFiltered.map((subCategoryProduct) => (
                  <Square size="large" key={subCategoryProduct.id}>
                    <TitleContainer>
                      <div className="c-square">
                        <div className="c-square-name">
                          {subCategoryProduct.name_pt } | {subCategoryProduct.name_en }
                        </div>
                        <Badge color={subCategoryProduct.product_color}>
                          {' '}
                          {translate.language === 'pt-BR' ? subCategoryProduct.product_name_pt : subCategoryProduct.product_name_en }
                        </Badge>
                        <Badge>
                          Category {' > '}
                          {translate.language === 'pt-BR' ? subCategoryProduct.category_name_pt : subCategoryProduct.category_name_en }
                        </Badge>
                        <Badge>
                          Posts? {' '}
                          {subCategoryProduct.hasPosts}
                        </Badge>
                      </div>
                      <div className="c-square-action">
                        <input type="text" id="order" value={subCategoryProduct.ordering} disabled />
                        {(subCategoryProduct.hasPosts === 'N' && !subCategoryProduct.video_id) && (
                          <Link to={`/admin/videos/add?type=subcategory&title_pt=${subCategoryProduct.name_pt}&title_en=${subCategoryProduct.name_en}&product=${subCategoryProduct.product_id}&subcategory=${subCategoryProduct.id}`} title="Add Video">
                            <img src={AddVideoIcon} alt="Add Video" />
                          </Link>
                        )}
                        { (subCategoryProduct.hasPosts === 'N' && subCategoryProduct.video_id) && (
                          <Link to={`/admin/videos/edit/${subCategoryProduct.video_id}`} title="Edit Video">
                            <img src={EditVideoIcon} alt="Edit Video" />
                          </Link>
                        )}
                        { subCategoryProduct.hasPosts === 'Y' && (
                          <Link to={`/admin/posts/by-sub-category/${subCategoryProduct.id}?name=${translate.language === 'pt-BR' ? subCategoryProduct.name_pt : subCategoryProduct.name_en}`} title="List Posts">
                            <img src={ListOrderIcon} alt="List Posts" />
                          </Link>
                        )}
                        <Link to={`/admin/sub-categories/${subCategoryProduct.id}`} title="Edit User">
                          <img src={FaEditIcon} alt="Edit User" />
                        </Link>
                        <img className="icon-trash" onClick={() => deleteSubcategory(subCategoryProduct.id)} title="Delete Subcategory" src={TrashIcon} alt="Delete Subcategory" />
                      </div>
                    </TitleContainer>
                  </Square>

                ))
              )
          )}
        </ListContainer>
      </Container>
    </>
  );
}

export default SubCategories;
