import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Translate } from './translate';

function PaidUnpaid({ value, setValue }) {
  const translate = Translate();

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, paidUnpaid: event.target.value }));
  }

  return (
    <div>
      <label htmlFor="paid-unpaid">{translate.label}</label>
      <select value={value} onChange={handleSelect} name="paid-unpaid">
        <option value="" className="noneParam">None</option>
        <option value="paid">paid</option>
        <option value="unpaid">unpaid</option>
      </select>
    </div>
  );
}

PaidUnpaid.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default PaidUnpaid;
