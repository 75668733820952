import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import PostsProduct from '../../../../../services/PostsProduct';
import TitlePage from '../../../../../components/TitlePage';
import Loader from '../../../../../components/Loader';
import { Button } from '../../../../../components/Button';
import { NoDataMessage } from '../../../../../components/NoDataMessage';
import toast from '../../../../../helpers/toast';
import { Translate } from './translate';
import { Container, ListContainer, AreaDragDrop } from './style';
import { PostItem } from './PostItem';

function PostsBySubCategory() {
  const translate = Translate();
  const { id } = useParams();
  const location = useLocation();
  const subCategoryName = new URLSearchParams(location.search).get('name') || '';
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [btnOrderingIsDisabled, setBtnOrderingIsDisabled] = useState(false);

  useEffect(() => {
    getPosts();
  }, []);

  async function getPosts() {
    try {
      setIsLoading(true);
      const posts = await PostsProduct.findAll(id, 'subcategory');
      setPosts(posts);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  async function deletePost(id) {
    try {
      await PostsProduct.delete(id);
      toast('success', 'Post deleted successfully');
      getPosts();
    } catch (error) {
      toast('danger', error.message);
    }
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const newList = reorder(posts, startIndex, endIndex);
    setPosts(newList);
  }

  async function updateOrdering() {
    try {
      setBtnOrderingIsDisabled(true);
      await PostsProduct.updateOrdering(posts);
      toast('success', 'Ordering updated');
      getPosts();
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setBtnOrderingIsDisabled(false);
    }
  }

  return (
    <>
      <TitlePage title={`${translate.title} (${translate.titleSubcategory}: ${subCategoryName})`}>
        <Button color="blue" onClick={updateOrdering} disabled={btnOrderingIsDisabled}>{btnOrderingIsDisabled ? 'Loading Ordering' : translate.btnUpdateOrdering}</Button>
      </TitlePage>
      <Container>
        <ListContainer>
          {isLoading && <Loader loading={isLoading} />}
          {!isLoading && (
            posts.length === 0
              ? <NoDataMessage>{translate.info}</NoDataMessage>
              : posts && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="postDragDrop" direction="vertical" type="list">
                    {(provided) => (
                      <AreaDragDrop
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {posts.map((post, index) => (
                          <PostItem key={post.id} post={post} index={index} deletePost={deletePost} />
                        ))}
                        {provided.placeholder}
                      </AreaDragDrop>
                    )}
                  </Droppable>
                </DragDropContext>
              )
          )}
        </ListContainer>
      </Container>
    </>
  );
}

export default PostsBySubCategory;
