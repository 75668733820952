export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    placeholderUrl: language === 'pt-BR' ? 'Filtre por url ou url masked' : 'Filter by url or url masked',
    buttonAdd: language === 'pt-BR' ? 'Parâmetros personalizados' : 'Custom parameters',
    buttonCreateURL: language === 'pt-BR' ? 'Criar URL' : 'Create URL',
    modalTextTitle: language === 'pt-BR' ? 'Tem certeza que deseja deletar URL?' : 'Are you sure you want to delete Url?',
    messageNoUrl: language === 'pt-BR'
      ? 'Não existe urls cadastradas. Clique no Botão "URL Builder" para cadastramento.'
      : 'There are no registered urls. Click the "URL Builder" button for registration.',
    clone: language === 'pt-BR' ? 'Clonar' : 'Clone',
    messageNoUrlSearched: language === 'pt-BR' ? 'Sua pesquisa não achou urls com o termo.' : 'Your search did not match urls with the term." button for registration.',
  };
}
