import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border: 1px solid ${({ theme }) => theme.grayBackground};
  border-radius: 4px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
    overflow: auto;
  }


  thead, tbody, tfoot {
    display: block;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
      display: inline-block;
    }
  }

  thead > tr > td,
  tfoot > tr > td {
    font-weight: 500;
    background-color: ${({ theme }) => theme.grayBackground};
  }

  thead > tr > td,
  tbody > tr > td,
  tfoot > tr > td {
    font-size: 14px;
    padding: 8px;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
      font-size: 11px;
    }
  }

  .column-main {
    width: 100%;
    max-width: 270px;
    word-break: break-all;
  }

  tbody {
    min-height: 50px;
    height: 220px;
    max-height: 220px;
    overflow-y: auto; /* Usar 'auto' para mostrar a barra de rolagem apenas quando necessário */
    overflow-x: none;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
      overflow-y: none; /* Usar 'auto' para mostrar a barra de rolagem apenas quando necessário */
      overflow-x: auto;
      height: 100%;
      width: 100%;
    }
  }

  tr:hover {
    background-color: ${({ theme }) => theme.grayBackground};
  }

  td {
    padding: 2px;
    min-width: 75px;
  }

  td.delta {
    color: ${({ theme }) => theme.grayLight};
    width: 30px;
  }

  td.delta.red {
    color: ${({ theme }) => theme.red};
  }
  td.delta.green {
    color: ${({ theme }) => theme.green};
  }

  td.l-top {
    color: ${({ theme }) => theme.red};
  }

  td.l-mid {
    color: ${({ theme }) => theme.yellow};
  }

  td.l-lower {
    color: ${({ theme }) => theme.green};
  }

  td.l-return {
    color: ${({ theme }) => theme.blue};
  }

`;
