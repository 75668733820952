import styled from 'styled-components';

export const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between ;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }

`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }

  h2 {
    margin-bottom: ${(props) => props.theme.sizes.s24};
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h3 {
    margin-bottom: 8px;
  }
`;
