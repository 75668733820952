import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { DataContext } from '../../../../../context/Data';
import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { Translate } from './translate';

const InvestmentTr = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    googleAdwordsCurrent, googleAdwordsPrevious, googleAdwordsYoY, googleAdwordsPreviousComplete, googleAdwordsForecast,
    googleAnalyticsOthersPlatformsCurrent, googleAnalyticsOthersPlatformsPrevious, googleAnalyticsOthersPlatformsYoY, googleAnalyticsOthersPlatformsPreviousComplete, googleAnalyticsOthersPlatformsForecast,
    metaAdsCurrent, metaAdsPrevious, metaAdsYoY, metaAdsPreviousComplete, metaAdsForecast,
    linkedinAdsCurrent, linkedinAdsPrevious, linkedinAdsYoY, linkedinAdsPreviousComplete, linkedinAdsForecast,
  } = useContext(DataContext);

  const object = 'cost';

  const current = (Number(googleAdwordsCurrent?.[object]) || 0)
                  + (Number(metaAdsCurrent?.[object]) || 0)
                  + (Number(linkedinAdsCurrent?.[object]) || 0)
                  + (Number(googleAnalyticsOthersPlatformsCurrent?.[object]) || 0);

  const previous = (Number(googleAdwordsPrevious?.[object]) || 0)
                   + (Number(metaAdsPrevious?.[object]) || 0)
                   + (Number(linkedinAdsPrevious?.[object]) || 0)
                   + (Number(googleAnalyticsOthersPlatformsPrevious?.[object]) || 0);

  const yoy = (Number(googleAdwordsYoY?.[object]) || 0)
              + (Number(metaAdsYoY?.[object]) || 0)
              + (Number(linkedinAdsYoY?.[object]) || 0)
              + (Number(googleAnalyticsOthersPlatformsYoY?.[object]) || 0);

  const completeMonth = (Number(googleAdwordsPreviousComplete?.[object]) || 0)
                        + (Number(metaAdsPreviousComplete?.[object]) || 0)
                        + (Number(linkedinAdsPreviousComplete?.[object]) || 0)
                        + (Number(googleAnalyticsOthersPlatformsPreviousComplete?.[object]) || 0);

  const forecastGoogleAdwords = googleAdwordsForecast?.length > 0 && googleAdwordsForecast?.map((data) => ({ date: data.date, value: Number(data[object]) }));
  const forecastMetaAds = metaAdsForecast?.length > 0 && metaAdsForecast?.map((data) => ({ date: data.date, value: Number(data[object]) }));
  const forecastLinkedinAds = linkedinAdsForecast?.length > 0 && linkedinAdsForecast?.map((data) => ({ date: data.date, value: Number(data[object]) }));
  const forecastGoogleAnalyticsOthersPlatforms = googleAnalyticsOthersPlatformsForecast?.length > 0 && googleAnalyticsOthersPlatformsForecast?.map((data) => ({ date: data.date, value: Number(data[object]) }));

  const forecast = (forecastGoogleAdwords && (ForecastMonthly(forecastGoogleAdwords) || 0))
                   + (forecastMetaAds && (ForecastMonthly(forecastMetaAds) || 0))
                   + (forecastLinkedinAds && (ForecastMonthly(forecastLinkedinAds) || 0))
                   + (forecastLinkedinAds && (ForecastMonthly(forecastGoogleAnalyticsOthersPlatforms) || 0));

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta green';
    }
    return 'delta red';
  }

  return (
    <tr>
      {showLevelColumn ? <td>1st & 2nd</td> : null}
      {showSourceColumn ? <td>APIs/GA</td> : null}
      <td>{translate.kpi}</td>
      <td>{numberFormat(current, 'currency', 0)}</td>
      <td>{numberFormat(previous, 'currency', 0)}</td>
      <td className={classNameAtrr(deltaNormal)}>
        {numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(yoy, 'currency', 0)}</td>
      <td className={classNameAtrr(deltaYoY)}>
        {numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(forecast, 'currency', 0)}</td>
      <td>{numberFormat(completeMonth, 'currency', 0)}</td>
      <td className={classNameAtrr(deltaForecast)}>
        {numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

InvestmentTr.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default InvestmentTr;
