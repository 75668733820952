export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    language,
    title: language === 'pt-BR' ? 'Novo grupo de acesso' : 'New access group',
    labelName: language === 'pt-BR' ? 'Nome' : 'Name',
    labelModule: language === 'pt-BR' ? 'Módulos' : 'Modules',
    labelGroupsModule: language === 'pt-BR' ? 'Módulos do grupo' : 'Group\'s Module',
    subtitleApis: language === 'pt-BR'
      ? 'Para acessar a tela/módulo, também é necessário o cadastro do usuário ser B2B com APIs conectadas (ID CLIENT associado ao cliente)'
      : 'To access the screen/module, the user must also be registered as a B2B user with APIs connected (CLIENT ID associated with the customer)',
    subtitleAdmin: language === 'pt-BR'
      ? 'Para acessar a tela/módulo, também é necessário o cadastro do usuário ser do tipo Administrador = YES(Y)'
      : 'To access the screen/module, the user must also be registered as Administrator = YES(Y)',
  };
}
