import { format } from 'date-fns';

export function formatDate(date) {
  const language = JSON.parse(localStorage.getItem('language'));
  const [year, month, day] = date.split('-');
  const formatCountry = language === 'en-US' ? 'MMM/dd/yyyy' : 'dd/MMM/yyyy';
  return format(new Date(year, month - 1, day), formatCountry);
}

export function startDateCurrentMonth() {
  const currentDate = new Date();
  const database = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');
  const usFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'MMM/dd/yyyy');
  const brFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'dd/MMM/yyyy');

  return {
    database,
    en: usFormat,
    br: brFormat,
  };
}

export function endDateCurrentMonth() {
  const currentDate = new Date();
  const day = currentDate.getDate();

  let database;
  let usFormat;
  let brFormat;

  if (day === 1) {
    database = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');
    usFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'MMM/dd/yyyy');
    brFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'dd/MMM/yyyy');
  } else {
    database = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1), 'yyyy-MM-dd');
    usFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1), 'MMM/dd/yyyy');
    brFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1), 'dd/MMM/yyyy');
  }

  return {
    database,
    en: usFormat,
    br: brFormat,
  };
}

export function startDatePreviousMonth() {
  const currentDate = new Date();
  const database = format(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), 'yyyy-MM-dd');
  const usFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), 'MMM/dd/yyyy');
  const brFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), 'dd/MMM/yyyy');

  return {
    database,
    en: usFormat,
    br: brFormat,
  };
}

export function endDatePreviousMonth() {
  const currentDate = new Date();
  const day = currentDate.getDate();

  let database;
  let usFormat;
  let brFormat;

  if (day === 1) {
    database = format(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), 'yyyy-MM-dd');
    usFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), 'MMM/dd/yyyy');
    brFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), 'dd/MMM/yyyy');
  } else {
    database = format(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate() - 1), 'yyyy-MM-dd');
    usFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate() - 1), 'MMM/dd/yyyy');
    brFormat = format(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate() - 1), 'dd/MMM/yyyy');
  }

  return {
    database,
    en: usFormat,
    br: brFormat,
  };
}
