import { useContext } from 'react';
import { FilterContext } from '../../../../../context/Filters';

export function Translate() {
  const { leadType } = useContext(FilterContext);
  const leadName = leadType === null || leadType === '' ? '- All' : `- ${leadType}`;

  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? `CPL Eventos ${leadName}` : `CPL Events ${leadName}`,
    tip: language === 'pt-BR'
      ? `Investimento Perf / Eventos ${leadName}`
      : `Investment Perf / Events ${leadName}`,
  };
}
