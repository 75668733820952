/* eslint-disable no-restricted-syntax */
import { isNumberInvalid } from '../../../../../helpers/isNumberInvalid';

export function groupAndSumByDynamicKey(groupByKey, sumByKey, ...dataArrays) {
  if (dataArrays.lenght === 0 || !dataArrays) return [];

  const groupedData = {};

  function processItem(item) {
    const groupValue = item[groupByKey];
    const date = item['date'];
    const sumValue = Number(item[sumByKey]);

    if (!groupedData[date]) {
      groupedData[date] = {
        date,
        data: [],
      };
    }

    // Verifica se o "canal" já está presente para essa data
    const channelItem = groupedData[date].data.find((d) => d.view === groupValue);

    if (channelItem) {
      // "Canal" já existe para essa data, incrementa o total
      channelItem.total += isNumberInvalid(sumValue) ? 0 : sumValue;
    } else {
      // "Canal" não existe para essa data, adiciona novo "canal" com o total
      groupedData[date].data.push({ view: groupValue, total: isNumberInvalid(sumValue) ? 0 : sumValue });
    }
  }

  // Processa cada array de dados fornecido
  dataArrays?.forEach((data) => {
    data?.forEach((item) => {
      processItem(item);
    });
  });

  // Converte o objeto em array de resultados no formato desejado
  const result = Object.values(groupedData).map(({ date, data }) => ({
    date,
    data,
  }));

  // Ordering by date
  result.sort((a, b) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  });

  return result;
}
