import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from './translate';
import BackArrow from '../../styles/logos/back_arrow.svg';

const backArrowStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  textDecoration: 'none',
};

export default function BackButton({ to = '..' }) {
  const translate = Translate();

  return (
    <Link to={to} relative="path" style={backArrowStyle}>
      <img src={BackArrow} alt="Back" />
      <span>{translate}</span>
    </Link>
  );
}
