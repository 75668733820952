import styled from 'styled-components';

export const FormInputsContainer = styled.div`
  > div {
    margin-top: 16px;
    padding: 16px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .c-fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    select, input {
      border-color: ${({ theme }) => theme.grayLight};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
        width: 100%;
      }
    }
  }

  .input-large {
    width: 400px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }
  }

  .c-explanation {
    font-size: 13px;
    margin-top: 16px;
    color: ${({ theme }) => theme.grayLight};
  }

  .c-multiselect-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > label {
      flex: 1;
    }

    .c-multiselect-container-fields {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 2;
      width: 100%;
      gap: 8px;

      .c-multiselect-container-fields-options {
        display: flex;
        flex-direction: column;
        width: 100%;

        select {
          width: 100%;
          height: 300px;
          border: 1px solid ${({ theme }) => theme.grayLight};
        }
      }

      .c-multiselect-container-fields-options.c-color {
        label {
          color: ${({ theme }) => theme.yellow};
        }
        select {
          border: 1px solid ${({ theme }) => theme.yellow};
        }
      }

      .c-multiselect-container-fields-buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.grayLight};
  }
`;
