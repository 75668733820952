import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-top: 16px;

  a {
    width: 100%;
    text-align: center;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .c-explanation {
    font-size: 13px;
    margin-top: 16px;
    color: ${({ theme }) => theme.grayLight};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    width: 100%;
  }

  .c-left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-bottom: 8px;
      width: 100%;
      align-self: flex-start;
    }
  }

  .c-right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }
  }

  .c-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
      }

    input, select {
      border: 1px solid ${({ theme }) => theme.grayLight};
      max-width: 100%;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
      }
    }

    .l-input-large {
      width: 300px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
      }
    }
  }

  .l-input-w {
    width: 250px;
    max-width: 100%;
  }
`;
