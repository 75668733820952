import React from 'react';
import { Link } from 'react-router-dom';
import { Draggable } from '@hello-pangea/dnd';
import { Badge } from '../../../../../components/Badge';
import FaEditIcon from '../../../../../styles/logos/FaEdit.svg';
import AddVideoIcon from '../../../../../styles/logos/add_video.svg';
import EditVideoIcon from '../../../../../styles/logos/edit_video.svg';
import ListOrderIcon from '../../../../../styles/logos/list_order.svg';
import TrashIcon from '../../../../../styles/logos/trash-alt.svg';
import IconDragDrop from '../../../../../styles/logos/icon_dragdrop.svg';
import { Translate } from './translate';
import { SquareContainer, TitleContainer } from './style';

export function SubCategoryItem({ subCategory, deleteSubcategory, index }) {
  const translate = Translate();

  return (
    <Draggable draggableId={subCategory.id.toString()} index={index}>
      {(provided) => (
        <SquareContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <TitleContainer>
            <div className="c-square">
              <div className="c-square-name">
                <img className="c-icon-drag" src={IconDragDrop} alt="Drag and drop" />
                {subCategory.name_pt } | {subCategory.name_en }
              </div>
              <Badge color={subCategory.product_color}>
                {' '}
                {translate.language === 'pt-BR' ? subCategory.product_name_pt : subCategory.product_name_en }
              </Badge>
              <Badge>
                Category {' > '}
                {translate.language === 'pt-BR' ? subCategory.category_name_pt : subCategory.category_name_en }
              </Badge>
              <Badge>
                Posts? {' '}
                {subCategory.hasPosts}
              </Badge>
            </div>
            <div className="c-square-action">
              <input type="text" id="order" value={subCategory.ordering} disabled />
              {(subCategory.hasPosts === 'N' && !subCategory.video_id) && (
              <Link to={`/admin/videos/add?type=subcategory&title_pt=${subCategory.name_pt}&title_en=${subCategory.name_en}&product=${subCategory.product_id}&subcategory=${subCategory.id}`} title="Add Video">
                <img src={AddVideoIcon} alt="Add Video" />
              </Link>
              )}
              { (subCategory.hasPosts === 'N' && subCategory.video_id) && (
              <Link to={`/admin/videos/edit/${subCategory.video_id}`} title="Edit Video">
                <img src={EditVideoIcon} alt="Edit Video" />
              </Link>
              )}
              { subCategory.hasPosts === 'Y' && (
              <Link to={`/admin/posts/by-sub-category/${subCategory.id}?name=${translate.language === 'pt-BR' ? subCategory.name_pt : subCategory.name_en}`} title="List Posts">
                <img src={ListOrderIcon} alt="List Posts" />
              </Link>
              )}
              <Link to={`/admin/sub-categories/${subCategory.id}`} title="Edit User">
                <img src={FaEditIcon} alt="Edit User" />
              </Link>
              <img className="icon-trash" onClick={() => deleteSubcategory(subCategory.id)} title="Delete Subcategory" src={TrashIcon} alt="Delete Subcategory" />
            </div>
          </TitleContainer>
        </SquareContainer>
      )}
    </Draggable>
  );
}
