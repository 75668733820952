/* eslint-disable no-restricted-syntax */
import {
  format, add, startOfMonth, endOfMonth, sub,
  startOfYear,
} from 'date-fns';

class Dates_New {
  generateListMonthly(optionValue) {
    const today = new Date();
    const months = [];
    const language = JSON.parse(localStorage.getItem('language'));

    if (optionValue === 'last12') {
      for (let i = 0; i < 12; i++) {
        const monthAdded = { months: 0 - i };
        const date = format(add(startOfMonth(today), monthAdded), 'yyyy-MMM');
        const dateDataBase = format(add(startOfMonth(today), monthAdded), 'yyyyMM');
        const dateUser = language === 'pt-BR'
          ? format(add(startOfMonth(today), monthAdded), 'MMM/yyyy')
          : format(add(startOfMonth(today), monthAdded), 'yyyy/MMM');
        months.push({ date, dateDataBase, dateUser });
      }
    }

    if (optionValue === 'currentYear') {
      for (let i = 0; i < 12; i++) {
        const monthAdded = { months: i };
        const date = format(add(startOfYear(today), monthAdded), 'yyyy-MMM');
        const dateDataBase = format(add(startOfYear(today), monthAdded), 'yyyyMM');
        const dateUser = language === 'pt-BR'
          ? format(add(startOfYear(today), monthAdded), 'MMM/yyyy')
          : format(add(startOfYear(today), monthAdded), 'yyyy/MMM');
        months.push({ date, dateDataBase, dateUser });
      }
    }

    if (optionValue === 'lastYear') {
      for (let i = 0; i < 12; i++) {
        const monthAdded = { years: -1, months: i };
        const date = format(add(startOfYear(today), monthAdded), 'yyyy-MMM');
        const dateDataBase = format(add(startOfYear(today), monthAdded), 'yyyyMM');
        const dateUser = language === 'pt-BR'
          ? format(add(startOfYear(today), monthAdded), 'MMM/yyyy')
          : format(add(startOfYear(today), monthAdded), 'yyyy/MMM');
        months.push({ date, dateDataBase, dateUser });
      }
    }

    const returnMonths = optionValue === 'last12' ? months.reverse() : months;
    return returnMonths;
  }

  formatDatesMask(date) {
    if (!date) return null;
    const language = JSON.parse(localStorage.getItem('language'));
    const dateLenght = new Date(`${date}T00:00:00`);
    const day = dateLenght.getDate() < 10 ? `0${dateLenght.getDate()}` : dateLenght.getDate();
    const month = (dateLenght.getMonth() + 1) < 10 ? `0${dateLenght.getMonth() + 1}` : dateLenght.getMonth() + 1;
    const year = dateLenght.getFullYear();
    return language === 'pt-BR' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`;
  }

  formatDatesMaskWithHour(date) {
    if (!date) return null;
    const dateLenght = new Date(date);
    const language = JSON.parse(localStorage.getItem('language'));
    const day = dateLenght.getDate() < 10 ? `0${dateLenght.getDate()}` : dateLenght.getDate();
    const month = (dateLenght.getMonth() + 1) < 10 ? `0${dateLenght.getMonth() + 1}` : dateLenght.getMonth() + 1;
    const year = dateLenght.getFullYear();
    const hour = dateLenght.getHours();
    const minute = dateLenght.getMinutes() < 10 ? `0${dateLenght.getMinutes()}` : dateLenght.getMinutes();
    return language === 'pt-BR' ? `${day}/${month}/${year} ${hour}:${minute}` : `${month}/${day}/${year} ${hour}:${minute}`;
  }

  formatDatesMonthly(date) {
    if (!date) return null;
    const language = JSON.parse(localStorage.getItem('language'));

    function translate(date) {
      const monthMap = {
        Feb: 'Fev',
        Apr: 'Abr',
        May: 'Mai',
        Aug: 'Ago',
        Sep: 'Set',
        Oct: 'Out',
        Dec: 'Dez',
      };

      let res = date;

      for (let [eng, pt] of Object.entries(monthMap)) {
        res = res.replaceAll(eng, pt);
      }

      return res;
    }

    const respondeDate = language === 'pt-BR' ? translate(date) : date;
    return respondeDate;
  }

  getDateSelected() {
    const setDate = this.getDatesDescription()[6];
    return setDate;
  }

  getListDatesInfo() {
    const datesInfo = this.getDatesDescription().map((date) => ({ date, ...this.getInfoDateByDescription(date) }));
    return datesInfo;
  }

  getDatesDescription() {
    const language = JSON.parse(localStorage.getItem('language'));
    const listOfDatesPT = [
      'Período Personalizado',
      'Hoje vs Ontem',
      'Ontem vs Anteontem',
      'Mês atual vs Mês passado (Hoje - Mesmo período)',
      'Mês atual vs Mês passado (Hoje - Mesmo dia da semana)',
      'Mês atual vs Mês passado (Ontem - Mesmo período)',
      'Mês atual vs Mês passado (Ontem - Mesmo dia da semana)',
      'Mês passado vs Mês anterior',
      'Últimos 7 dias',
      'Últimos 14 dias',
      'Últimos 30 dias',
      'Últimos 60 dias',
      'Últimos 90 dias',
      'Últimos 180 dias'];

    const listOfDatesEN = [
      'Custom Period',
      'Today vs Yesterday',
      'Yesterday vs Day before yesterday',
      'Current month vs Last month (Today - Same period)',
      'Current month vs Last month (Today - Week day period)',
      'Current month vs Last month (Yesterday - Same period)',
      'Current month vs Last month (Yesterday - Week day period)',
      'Last month vs Previous month',
      'Last 7 days',
      'Last 14 days',
      'Last 30 days',
      'Last 60 days',
      'Last 90 days',
      'Last 180 days'];

    return language === 'pt-BR' ? listOfDatesPT : listOfDatesEN;
  }

  getLastDayOfTheMonth() {
    const currentDay = new Date();
    const firstDayNextMonth = new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 1);
    const lastDayOfTheMonth = new Date(firstDayNextMonth - 1).getDate();
    return lastDayOfTheMonth;
  }

  getInfoDateCompletePreviousMonth() {
    const language = JSON.parse(localStorage.getItem('language'));
    const formatDateDB = 'yyyy-MM-dd';
    const formatDateForDescription = language === 'pt-BR' ? 'dd/MMM/yyyy' : 'MMM/dd/yyyy';
    const today = new Date();

    const init = format(add(startOfMonth(today), { months: -1 }), formatDateDB);
    const initDescription = format(add(startOfMonth(today), { months: -1 }), formatDateForDescription);

    const end = format(endOfMonth(sub(today, { months: 1 })), formatDateDB);
    const endDescription = format(endOfMonth(sub(today, { months: 1 })), formatDateForDescription);

    return {
      init: { value: init, description: initDescription },
      end: { value: end, description: endDescription },
      description: format(add(startOfMonth(today), { months: -1 }), 'MMMM'),
    };
  }

  getInfoDateForecast() {
    const language = JSON.parse(localStorage.getItem('language'));
    const formatDateDB = 'yyyy-MM-dd';
    const formatDateForDescription = language === 'pt-BR' ? 'dd/MMM/yyyy' : 'MMM/dd/yyyy';
    const today = new Date();

    const init = format(startOfMonth(today), formatDateDB);
    const initDescription = format(startOfMonth(today), formatDateForDescription);

    const end = format(today, formatDateDB);
    const endDescription = format(today, formatDateForDescription);

    return {
      init: { value: init, description: initDescription },
      end: { value: end, description: endDescription },
      description: format(startOfMonth(today), 'MMMM'),
    };
  }

  getInfoDateByDescription(description) {
    const language = JSON.parse(localStorage.getItem('language'));
    const formatDateDB = 'yyyy-MM-dd';
    const formatDateForDescription = language === 'pt-BR' ? 'dd/MMM/yyyy' : 'MMM/dd/yyyy';
    const today = new Date();
    const todayIsDayOne = today.getDate() === 1;

    switch (description) {
      case 'Hoje vs Ontem': {
        const showDatesOnDescription = false;

        const init = format(today, formatDateDB);
        const initDescription = format(today, formatDateForDescription);

        const end = format(today, formatDateDB);
        const endDescription = format(today, formatDateForDescription);

        const initPrev = format(add(today, { days: -1 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -1 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initYoY = format(add(today, { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Today vs Yesterday': {
        const showDatesOnDescription = false;

        const init = format(today, formatDateDB);
        const initDescription = format(today, formatDateForDescription);

        const end = format(today, formatDateDB);
        const endDescription = format(today, formatDateForDescription);

        const initPrev = format(add(today, { days: -1 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -1 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initYoY = format(add(today, { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Ontem vs Anteontem': {
        const showDatesOnDescription = false;

        const init = format(add(today, { days: -1 }), formatDateDB);
        const initDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -2 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -2 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -2 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -2 }), formatDateForDescription);

        const initYoY = format(add(add(today, { days: -1 }), { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(add(today, { days: -1 }), { years: -1 }), formatDateForDescription);

        const endYoY = format(add(add(today, { days: -1 }), { years: -1 }), formatDateDB);
        const endYoYDescription = format(add(add(today, { days: -1 }), { years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Yesterday vs Day before yesterday': {
        const showDatesOnDescription = false;

        const init = format(add(today, { days: -1 }), formatDateDB);
        const initDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -2 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -2 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -2 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -2 }), formatDateForDescription);

        const initYoY = format(add(add(today, { days: -1 }), { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(add(today, { days: -1 }), { years: -1 }), formatDateForDescription);

        const endYoY = format(add(add(today, { days: -1 }), { years: -1 }), formatDateDB);
        const endYoYDescription = format(add(add(today, { days: -1 }), { years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Mês atual vs Mês passado (Hoje - Mesmo período)': {
        const showDatesOnDescription = true;

        const init = format(startOfMonth(today), formatDateDB);
        const initDescription = format(startOfMonth(today), formatDateForDescription);

        const end = format(today, formatDateDB);
        const endDescription = format(today, formatDateForDescription);

        const initPrev = format(add(startOfMonth(today), { months: -1 }), formatDateDB);
        const initPrevDescription = format(add(startOfMonth(today), { months: -1 }), formatDateForDescription);

        const endPrev = format(add(today, { months: -1 }), formatDateDB);
        const endPrevDescription = format(add(today, { months: -1 }), formatDateForDescription);

        const initYoY = format(add(startOfMonth(today), { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(startOfMonth(today), { years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Current month vs Last month (Today - Same period)': {
        const showDatesOnDescription = true;

        const init = format(startOfMonth(today), formatDateDB);
        const initDescription = format(startOfMonth(today), formatDateForDescription);

        const end = format(today, formatDateDB);
        const endDescription = format(today, formatDateForDescription);

        const initPrev = format(add(startOfMonth(today), { months: -1 }), formatDateDB);
        const initPrevDescription = format(add(startOfMonth(today), { months: -1 }), formatDateForDescription);

        const endPrev = format(add(today, { months: -1 }), formatDateDB);
        const endPrevDescription = format(add(today, { months: -1 }), formatDateForDescription);

        const initYoY = format(add(startOfMonth(today), { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(startOfMonth(today), { years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Mês atual vs Mês passado (Hoje - Mesmo dia da semana)': {
        const showDatesOnDescription = true;

        const init = format(startOfMonth(today), formatDateDB);
        const initDescription = format(startOfMonth(today), formatDateForDescription);

        const end = format(today, formatDateDB);
        const endDescription = format(today, formatDateForDescription);

        const initPrev = format(add(startOfMonth(today), { weeks: -4 }), formatDateDB);
        const initPrevDescription = format(add(startOfMonth(today), { weeks: -4 }), formatDateForDescription);

        const endPrev = format(add(today, { weeks: -4 }), formatDateDB);
        const endPrevDescription = format(add(today, { weeks: -4 }), formatDateForDescription);

        const initYoY = format(add(startOfMonth(today), { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(startOfMonth(today), { years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Current month vs Last month (Today - Week day period)': {
        const showDatesOnDescription = true;

        const init = format(startOfMonth(today), formatDateDB);
        const initDescription = format(startOfMonth(today), formatDateForDescription);

        const end = format(today, formatDateDB);
        const endDescription = format(today, formatDateForDescription);

        const initPrev = format(add(startOfMonth(today), { weeks: -4 }), formatDateDB);
        const initPrevDescription = format(add(startOfMonth(today), { weeks: -4 }), formatDateForDescription);

        const endPrev = format(add(today, { weeks: -4 }), formatDateDB);
        const endPrevDescription = format(add(today, { weeks: -4 }), formatDateForDescription);

        const initYoY = format(add(startOfMonth(today), { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(startOfMonth(today), { years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Mês atual vs Mês passado (Ontem - Mesmo período)': {
        const showDatesOnDescription = true;

        const init = format(startOfMonth(today), formatDateDB);
        const initDescription = format(startOfMonth(today), formatDateForDescription);

        const end = format(add(today, { days: todayIsDayOne ? 0 : -1 }), formatDateDB);
        const endDescription = format(add(today, { days: todayIsDayOne ? 0 : -1 }), formatDateForDescription);

        const initPrev = format(add(startOfMonth(today), { months: -1 }), formatDateDB);
        const initPrevDescription = format(add(startOfMonth(today), { months: -1 }), formatDateForDescription);

        const endPrev = format(add(today, { days: todayIsDayOne ? 0 : -1, months: -1 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: todayIsDayOne ? 0 : -1, months: -1 }), formatDateForDescription);

        const initYoY = format(add(startOfMonth(today), { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(startOfMonth(today), { years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: todayIsDayOne ? 0 : -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: todayIsDayOne ? 0 : -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Current month vs Last month (Yesterday - Same period)': {
        const showDatesOnDescription = true;

        const init = format(startOfMonth(today), formatDateDB);
        const initDescription = format(startOfMonth(today), formatDateForDescription);

        const end = format(add(today, { days: todayIsDayOne ? 0 : -1 }), formatDateDB);
        const endDescription = format(add(today, { days: todayIsDayOne ? 0 : -1 }), formatDateForDescription);

        const initPrev = format(add(startOfMonth(today), { months: -1 }), formatDateDB);
        const initPrevDescription = format(add(startOfMonth(today), { months: -1 }), formatDateForDescription);

        const endPrev = format(add(today, { days: todayIsDayOne ? 0 : -1, months: -1 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: todayIsDayOne ? 0 : -1, months: -1 }), formatDateForDescription);

        const initYoY = format(add(startOfMonth(today), { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(startOfMonth(today), { years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: todayIsDayOne ? 0 : -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: todayIsDayOne ? 0 : -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Mês atual vs Mês passado (Ontem - Mesmo dia da semana)': {
        const showDatesOnDescription = true;
        const favorite = true;

        const init = format(startOfMonth(today), formatDateDB);
        const initDescription = format(startOfMonth(today), formatDateForDescription);

        const end = format(add(today, { days: todayIsDayOne ? 0 : -1 }), formatDateDB);
        const endDescription = format(add(today, { days: todayIsDayOne ? 0 : -1 }), formatDateForDescription);

        const initPrev = format(add(startOfMonth(today), { weeks: -4 }), formatDateDB);
        const initPrevDescription = format(add(startOfMonth(today), { weeks: -4 }), formatDateForDescription);

        const endPrev = format(add(today, { days: todayIsDayOne ? 0 : -1, weeks: -4 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: todayIsDayOne ? 0 : -1, weeks: -4 }), formatDateForDescription);

        const initYoY = format(add(startOfMonth(today), { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(startOfMonth(today), { years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: todayIsDayOne ? 0 : -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: todayIsDayOne ? 0 : -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Current month vs Last month (Yesterday - Week day period)': {
        const showDatesOnDescription = true;
        const favorite = true;

        const init = format(startOfMonth(today), formatDateDB);
        const initDescription = format(startOfMonth(today), formatDateForDescription);

        const end = format(add(today, { days: todayIsDayOne ? 0 : -1 }), formatDateDB);
        const endDescription = format(add(today, { days: todayIsDayOne ? 0 : -1 }), formatDateForDescription);

        const initPrev = format(add(startOfMonth(today), { weeks: -4 }), formatDateDB);
        const initPrevDescription = format(add(startOfMonth(today), { weeks: -4 }), formatDateForDescription);

        const endPrev = format(add(today, { days: todayIsDayOne ? 0 : -1, weeks: -4 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: todayIsDayOne ? 0 : -1, weeks: -4 }), formatDateForDescription);

        const initYoY = format(add(startOfMonth(today), { years: -1 }), formatDateDB);
        const initYoYDescription = format(add(startOfMonth(today), { years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: todayIsDayOne ? 0 : -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: todayIsDayOne ? 0 : -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Mês passado vs Mês anterior': {
        const showDatesOnDescription = false;
        const favorite = true;

        const init = format(add(startOfMonth(today), { months: -1 }), formatDateDB);
        const initDescription = format(add(startOfMonth(today), { months: -1 }), formatDateForDescription);

        const end = format(endOfMonth(sub(today, { months: 1 })), formatDateDB);
        const endDescription = format(endOfMonth(sub(today, { months: 1 })), formatDateForDescription);

        const initPrev = format(add(startOfMonth(today), { months: -2 }), formatDateDB);
        const initPrevDescription = format(add(startOfMonth(today), { months: -2 }), formatDateForDescription);

        const endPrev = format(endOfMonth(sub(today, { months: 2 })), formatDateDB);
        const endPrevDescription = format(endOfMonth(sub(today, { months: 2 })), formatDateForDescription);

        const initYoY = format(add(startOfMonth(today), { months: -1, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(startOfMonth(today), { months: -1, years: -1 }), formatDateForDescription);

        const endYoY = format(endOfMonth(sub(today, { months: 1, years: 1 })), formatDateDB);
        const endYoYDescription = format(endOfMonth(sub(today, { months: 1, years: 1 })), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Last month vs Previous month': {
        const showDatesOnDescription = false;
        const favorite = true;

        const init = format(add(startOfMonth(today), { months: -1 }), formatDateDB);
        const initDescription = format(add(startOfMonth(today), { months: -1 }), formatDateForDescription);

        const end = format(endOfMonth(sub(today, { months: 1 })), formatDateDB);
        const endDescription = format(endOfMonth(sub(today, { months: 1 })), formatDateForDescription);

        const initPrev = format(add(startOfMonth(today), { months: -2 }), formatDateDB);
        const initPrevDescription = format(add(startOfMonth(today), { months: -2 }), formatDateForDescription);

        const endPrev = format(endOfMonth(sub(today, { months: 2 })), formatDateDB);
        const endPrevDescription = format(endOfMonth(sub(today, { months: 2 })), formatDateForDescription);

        const initYoY = format(add(startOfMonth(today), { months: -1, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(startOfMonth(today), { months: -1, years: -1 }), formatDateForDescription);

        const endYoY = format(endOfMonth(sub(today, { months: 1, years: 1 })), formatDateDB);
        const endYoYDescription = format(endOfMonth(sub(today, { months: 1, years: 1 })), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Últimos 7 dias': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -7 }), formatDateDB);
        const initDescription = format(add(today, { days: -7 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -14 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -14 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -8 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -8 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -7, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -7, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Last 7 days': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -7 }), formatDateDB);
        const initDescription = format(add(today, { days: -7 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -14 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -14 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -8 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -8 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -7, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -7, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Últimos 14 dias': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -14 }), formatDateDB);
        const initDescription = format(add(today, { days: -14 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -28 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -28 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -15 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -15 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -14, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -14, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Last 14 days': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -14 }), formatDateDB);
        const initDescription = format(add(today, { days: -14 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -28 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -28 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -15 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -15 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -14, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -14, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Últimos 30 dias': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -30 }), formatDateDB);
        const initDescription = format(add(today, { days: -30 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -60 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -60 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -31 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -31 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -30, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -30, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Last 30 days': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -30 }), formatDateDB);
        const initDescription = format(add(today, { days: -30 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -60 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -60 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -31 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -31 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -30, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -30, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Últimos 60 dias': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -60 }), formatDateDB);
        const initDescription = format(add(today, { days: -60 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -120 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -120 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -61 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -61 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -60, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -60, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Last 60 days': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -60 }), formatDateDB);
        const initDescription = format(add(today, { days: -60 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -120 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -120 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -61 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -61 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -60, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -60, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Últimos 90 dias': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -90 }), formatDateDB);
        const initDescription = format(add(today, { days: -90 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -180 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -180 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -91 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -91 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -90, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -90, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Last 90 days': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -90 }), formatDateDB);
        const initDescription = format(add(today, { days: -90 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -180 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -180 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -91 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -91 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -90, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -90, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Últimos 180 dias': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -180 }), formatDateDB);
        const initDescription = format(add(today, { days: -180 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -360 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -360 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -181 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -181 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -180, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -180, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      case 'Last 180 days': {
        const showDatesOnDescription = false;
        const favorite = false;

        const init = format(add(today, { days: -180 }), formatDateDB);
        const initDescription = format(add(today, { days: -180 }), formatDateForDescription);

        const end = format(add(today, { days: -1 }), formatDateDB);
        const endDescription = format(add(today, { days: -1 }), formatDateForDescription);

        const initPrev = format(add(today, { days: -360 }), formatDateDB);
        const initPrevDescription = format(add(today, { days: -360 }), formatDateForDescription);

        const endPrev = format(add(today, { days: -181 }), formatDateDB);
        const endPrevDescription = format(add(today, { days: -181 }), formatDateForDescription);

        const initYoY = format(add(today, { days: -180, years: -1 }), formatDateDB);
        const initYoYDescription = format(add(today, { days: -180, years: -1 }), formatDateForDescription);

        const endYoY = format(add(today, { days: -1, years: -1 }), formatDateDB);
        const endYoYDescription = format(add(today, { days: -1, years: -1 }), formatDateForDescription);

        return {
          showDatesOnDescription,
          favorite,
          init: { value: init, description: initDescription },
          end: { value: end, description: endDescription },
          initPrev: { value: initPrev, description: initPrevDescription },
          endPrev: { value: endPrev, description: endPrevDescription },
          initYoY: { value: initYoY, description: initYoYDescription },
          endYoY: { value: endYoY, description: endYoYDescription },
        };
      }
      default:
        break;
    }
  }

  getinfoDateByCustomPeriod({
    startCurrent, endCurrent, startPrevious, endPrevious,
  }) {
    const language = JSON.parse(localStorage.getItem('language'));
    const formatDateDB = 'yyyy-MM-dd';
    const formatDateForDescription = language === 'pt-BR' ? 'dd/MMM/yyyy' : 'MMM/dd/yyyy';

    const init = format(startCurrent, formatDateDB);
    const initDescription = format(startCurrent, formatDateForDescription);

    const end = format(endCurrent, formatDateDB);
    const endDescription = format(endCurrent, formatDateForDescription);

    const initPrev = format(startPrevious, formatDateDB);
    const initPrevDescription = format(startPrevious, formatDateForDescription);

    const endPrev = format(endPrevious, formatDateDB);
    const endPrevDescription = format(endPrevious, formatDateForDescription);

    const initYoY = format(add(startCurrent, { years: -1 }), formatDateDB);
    const initYoYDescription = format(add(startCurrent, { years: -1 }), formatDateForDescription);

    const endYoY = format(add(endCurrent, { years: -1 }), formatDateDB);
    const endYoYDescription = format(add(endCurrent, { years: -1 }), formatDateForDescription);

    return {
      init: { value: init, description: initDescription },
      end: { value: end, description: endDescription },
      initPrev: { value: initPrev, description: initPrevDescription },
      endPrev: { value: endPrev, description: endPrevDescription },
      initYoY: { value: initYoY, description: initYoYDescription },
      endYoY: { value: endYoY, description: endYoYDescription },
    };
  }
}

export default new Dates_New();
