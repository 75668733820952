export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? 'T. Conversão (S) - Return' : 'Conv. Rate (S) - Return',
    tip: language === 'pt-BR'
      ? 'Transações - Return / Sessões - Return'
      : 'Transactions - Return / Sessions - Return',
  };
}
