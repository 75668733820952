export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Usuários por categoria' : 'Users by category',
    search: language === 'pt-BR' ? 'Persquisar' : 'Search',
    clear: language === 'pt-BR' ? 'Limpar' : 'Clear',
    placeholder: language === 'pt-BR' ? 'E-mail, nome ou empresa' : 'E-mail, name or company ',
    language,
  };
}
