// Itens que estão comentados para o futuro modal que está já feito, basta implementar na nova versão.
/* eslint-disable no-console */
import React from 'react';
import { Link } from 'react-router-dom';
import { useURLBuilderAddParams } from './useURLBUilderAddParams';
import TitlePage from '../../../../components/TitlePage';
import { ButtonLink } from '../../../../components/ButtonLink';
import BackButton from '../../../../components/BackButton';
import Loader from '../../../../components/Loader';
import FormGroup from '../../../../components/FormGroup';
import Modal from '../../../../components/Modal';
import { NoDataMessage } from '../../../../components/NoDataMessage';
import { Badge } from '../../../../components/Badge';
import { formatDate } from '../../../../helpers/dates';
import trash from '../../../../styles/logos/trash-alt.svg';
import edit from '../../../../styles/logos/edit.svg';
import { Translate } from './translate';

import * as S from './styles';

export default function URLBUilderAddParams() {
  const translate = Translate();

  const {
    categories,
    categorySelected,
    closeModal,
    deleteParam,
    isCategoryLoading,
    isParamsLoading,
    toogleModal,
    paramToDelete,
    handleOpenModalDeleteUrl,
    setCategorySelected,
    paramsList,
  } = useURLBuilderAddParams();

  return (
    <>
      <Modal
        danger
        visible={toogleModal}
        onClose={closeModal}
        onAction={deleteParam}
        title={translate.modalTextTitle}
        btnMessage={translate.modalTextDelete}
      >
        <>
          <p>
            <strong>
              {translate.labelUrl}
              :
              {' '}
            </strong>
            {paramToDelete?.name}
          </p>
          <p>
            <strong>
              {translate.labelUrlValue}
              :
              {' '}
            </strong>
            {paramToDelete?.value}
          </p>
        </>
      </Modal>

      <TitlePage title={translate.titlePage}>
        <BackButton />
      </TitlePage>

      <S.ActionContainer>

        <FormGroup>
          <label htmlFor="params">{translate.labelParam}</label>
          <select id="params" value={categorySelected} onChange={(event) => setCategorySelected(event.target.value)}>
            {(categories.length > 0) && categories.map((category) => (
              <option
                key={+category.id}
                value={`${category.id}`}
              >
                {translate.language === 'pt-BR' ? category.namePt : category.nameEn}
              </option>
            ))}
          </select>
          {isCategoryLoading && <Loader loading tofield /> }
        </FormGroup>

        <ButtonLink color="blue" to={`/client/urlbuilder/addparams/new/${categorySelected}`}>
          {' '}
          {translate.buttonNewParam}
          {' '}
        </ButtonLink>

      </S.ActionContainer>

      <S.ListContainer>
        {isParamsLoading && <Loader loading={isParamsLoading} />}
        {(!isParamsLoading && paramsList?.length === 0) && (<NoDataMessage>{translate.messageInfo}</NoDataMessage>)}
        {(!isParamsLoading && paramsList?.length > 0)
          && (paramsList.map((item, index) => (
            /* eslint-disable react/no-array-index-key */
            <div className="container" key={index}>
              <div className="infos-area">
                <Badge>{formatDate(item.date_act)}</Badge>
                <div className="item-area">
                  <p>
                    <strong>
                      {translate.labelUrl}
                      :
                      {' '}
                    </strong>
                    {item.name}
                  </p>
                  <p>
                    <strong>
                      {translate.labelUrlValue}
                      :
                      {' '}
                    </strong>
                    {item.value}
                  </p>
                </div>
              </div>
              <div className="btns-area">
                <div>
                  <Link to={`/client/urlbuilder/addparams/edit/${item.id}`}>
                    <img src={edit} alt="Edit" className="edit-icon" />
                  </Link>
                </div>
                <div>
                  <img src={trash} alt="Delete" className="delete-icon" onClick={() => handleOpenModalDeleteUrl(item)} />
                </div>
              </div>
            </div>
          )))}
      </S.ListContainer>
    </>
  );
}
