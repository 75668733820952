import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CategoryProductsService from '../../../../../services/CategoryProduct';
import ProductsService from '../../../../../services/Products';
import TitlePage from '../../../../../components/TitlePage';
import BackButton from '../../../../../components/BackButton';
import { Button } from '../../../../../components/Button';
import Square from '../../../../../components/Square';
import ButtonsSaveCancel from '../../../../../components/ButtonsSaveCancel';
import toast from '../../../../../helpers/toast';
import { generateSlug } from '../../../../../helpers/generateSlug';
import { Translate } from './translate';
import {
  Container, InfoContainer, ListContainer,
} from './style';
import { CategoryForm } from '../components/CategoryForm';

function CategoryEdit() {
  const translate = Translate();
  const { id } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState({
    namePt: '', nameEn: '', ordering: 0, slug: '', hasSubCategory: 'Y', typeCategory: '', productId: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);

  async function handleSave() {
    const {
      namePt, nameEn, ordering, hasSubCategory, slug, typeCategory, idProduct,
    } = category;

    try {
      await CategoryProductsService.update({
        id, namePt, nameEn, ordering, hasSubCategory, slug, typeCategory, idProduct,
      });

      toast('success', 'Update successfully');
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsDisabled(true);
      navigate('/admin/categories');
    }
  }

  return (
    <>
      <TitlePage
        title={translate.title}
      >
        <BackButton />
      </TitlePage>
      <CategoryForm
        category={category}
        disabled={isDisabled}
        setCategory={setCategory}
        setDisabled={setIsDisabled}
        onSave={handleSave}
      />
    </>
  );
}

export default CategoryEdit;
