import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PostsProduct from '../../../../../services/PostsProduct';
import TitlePage from '../../../../../components/TitlePage';
import Square from '../../../../../components/Square';
import Loader from '../../../../../components/Loader';
import { Badge } from '../../../../../components/Badge';
import { ButtonLink } from '../../../../../components/ButtonLink';
import { NoDataMessage } from '../../../../../components/NoDataMessage';
import toast from '../../../../../helpers/toast';
import FaEditIcon from '../../../../../styles/logos/FaEdit.svg';
import AddVideoIcon from '../../../../../styles/logos/add_video.svg';
import EditVideoIcon from '../../../../../styles/logos/edit_video.svg';
import TrashIcon from '../../../../../styles/logos/trash-alt.svg';
import { Translate } from './translate';
import {
  Container, TitleContainer, SearchContainer, ListContainer,
} from './style';

function Posts() {
  const translate = Translate();
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    getPosts();
  }, []);

  async function getPosts() {
    try {
      setIsLoading(true);
      const posts = await PostsProduct.findAll();
      setPosts(posts);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  async function deletePost(id) {
    try {
      await PostsProduct.delete(id);
      getPosts();
    } catch (error) {
      toast('danger', error.message);
    }
  }

  function handleSearchInput(e) {
    setSearchInput(e.target.value.toLowerCase());
  }

  const postsFiltered = useMemo(() => posts?.filter((post) => post.name_en.toLowerCase().includes(searchInput)
                                                              || post.name_pt.toLowerCase().includes(searchInput)
                                                              || post.subcategory_name_pt.toLowerCase().includes(searchInput)
                                                              || post.subcategory_name_en.toLowerCase().includes(searchInput)
                                                              || post.product_name_pt.toLowerCase().includes(searchInput)
                                                              || post.product_name_en.toLowerCase().includes(searchInput)));

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>
        <SearchContainer>
          <div className="c-search">
            <label htmlFor="search-term">{translate.search}</label>
            <input
              type="text"
              id="search-term"
              placeholder={translate.placeholderSearchTerm}
              value={searchInput}
              onChange={handleSearchInput}
            />
          </div>
          <div className="c-actions">
            <ButtonLink color="blue" to="/admin/posts/add">{translate.addButton}</ButtonLink>
          </div>
        </SearchContainer>

        <ListContainer>
          {isLoading && <Loader loading={isLoading} />}
          {!isLoading && (
            posts.length === 0 ? <NoDataMessage>{translate.info}</NoDataMessage>
              : posts && (
                postsFiltered.map((post) => (
                  <Square size="large" key={post.id}>
                    <TitleContainer>
                      <div className="c-square">
                        <div className="c-square-name">
                          {post.name_pt } | {post.name_en }
                        </div>
                        <Badge color={post.product_color}>
                          {translate.language === 'pt-BR' ? post.product_name_pt : post.product_name_en}
                        </Badge>
                        <Badge>
                          SubCategory {'>'} {translate.language === 'pt-BR' ? post.subcategory_name_pt : post.subcategory_name_en}
                        </Badge>
                      </div>
                      <div className="c-square-action">
                        <input type="text" id="order" value={post.ordering} disabled />
                        {post.video_id ? (
                          <Link to={`/admin/videos/edit/${post.video_id}`} title="Edit Video">
                            <img src={EditVideoIcon} alt="Edit Video" />
                          </Link>
                        ) : (
                          <Link to={`/admin/videos/add?type=post&title_pt=${post.name_pt}&title_en=${post.name_en}&product=${post.product_id}&post=${post.id}`} title="Add Video">
                            <img src={AddVideoIcon} alt="Add Video" />
                          </Link>
                        )}
                        <Link to={`/admin/posts/${post.id}`} title="Edit Post">
                          <img src={FaEditIcon} alt="Edit Post" />
                        </Link>
                        <img className="icon-trash" onClick={() => deletePost(post.id)} title="Delete Post" src={TrashIcon} alt="Delete Post" />
                      </div>
                    </TitleContainer>
                  </Square>
                ))
              )
          )}
        </ListContainer>
      </Container>
    </>
  );
}

export default Posts;
