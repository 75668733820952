import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import CategoryProductsService from '../../../../../services/CategoryProduct';
import TitlePage from '../../../../../components/TitlePage';
import Square from '../../../../../components/Square';
import Loader from '../../../../../components/Loader';
import { Badge } from '../../../../../components/Badge';
import { ButtonLink } from '../../../../../components/ButtonLink';
import { NoDataMessage } from '../../../../../components/NoDataMessage';
import toast from '../../../../../helpers/toast';
import FaEditIcon from '../../../../../styles/logos/FaEdit.svg';
import AddVideoIcon from '../../../../../styles/logos/add_video.svg';
import EditVideoIcon from '../../../../../styles/logos/edit_video.svg';
import ListOrderIcon from '../../../../../styles/logos/list_order.svg';
import TrashIcon from '../../../../../styles/logos/trash-alt.svg';
import FaUsers from '../../../../../styles/logos/FaUsers.svg';
import { Translate } from './translate';
import {
  Container, TitleContainer, SearchContainer, ListContainer,
} from './style';

function Category() {
  const translate = Translate();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    getCategoriesProduct();
  }, []);

  async function getCategoriesProduct() {
    try {
      setIsLoading(true);
      const categoriesProduct = await CategoryProductsService.findAll();
      setCategories(categoriesProduct);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDelete(id) {
    try {
      await CategoryProductsService.delete(id);
      getCategoriesProduct();
    } catch (error) {
      toast('danger', error.message);
    }
  }

  function handleSearchInput(e) {
    setSearchInput(e.target.value.toLowerCase());
  }

  const categoriesFiltered = useMemo(() => categories?.filter((category) => category.nameEn.toLowerCase().includes(searchInput)
                                                                            || category.namePt.toLowerCase().includes(searchInput)
                                                                            || category.productNameEn.toLowerCase().includes(searchInput)
                                                                            || category.productNamePt.toLowerCase().includes(searchInput)));

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>
        <SearchContainer>
          <div className="c-search">
            <label htmlFor="search-term">{translate.search}</label>
            <input
              type="text"
              id="search-term"
              placeholder={translate.placeholderSearchTerm}
              value={searchInput}
              onChange={handleSearchInput}
            />
          </div>
          <div className="c-actions">
            <ButtonLink color="blue" to="/admin/categories/add">{translate.addButton}</ButtonLink>
          </div>
        </SearchContainer>

        <ListContainer>
          <Loader loading={isLoading} />
          {!isLoading && (
            categories.length === 0 ? <NoDataMessage>{translate.categoryInfo}</NoDataMessage>
              : categories && (
                categoriesFiltered.map((categoryProduct) => (
                  <Square size="large" key={categoryProduct.id}>
                    <TitleContainer>
                      <div className="c-square">
                        <div className="c-square-name">
                          {categoryProduct.namePt } | {categoryProduct.nameEn }
                        </div>
                        <Badge>
                          {categoryProduct.typeCategory === 'multi' ? 'Multi-User' : 'Single-User' }
                        </Badge>
                        <Badge color={categoryProduct.productColor}>
                          {translate.language === 'pt-BR' ? categoryProduct.productNamePt : categoryProduct.productNameEn }
                        </Badge>
                        <Badge>
                          {translate.textSubCategory}
                          {' '}
                          {categoryProduct.hasSubCategory}
                        </Badge>
                      </div>
                      <div className="c-square-action">
                        {categoryProduct.typeCategory === 'single' && (
                          <Link to={`/admin/categories/users/${categoryProduct.id}`} title="User's Category">
                            <img src={FaUsers} alt="User's Category" />
                          </Link>
                        )}
                        <input type="text" id="order" value={categoryProduct.ordering} disabled />
                        {(categoryProduct.hasSubCategory === 'N' && categoryProduct.videoID) && (
                          <Link to={`/admin/videos/edit/${categoryProduct.videoID}`} title="Edit Video">
                            <img src={EditVideoIcon} alt="Edit Video" />
                          </Link>
                        )}
                        {(categoryProduct.hasSubCategory === 'N' && !categoryProduct.videoID) && (
                          <Link to={`/admin/videos/add?type=category&title_pt=${categoryProduct.namePt}&title_en=${categoryProduct.nameEn}&product=${categoryProduct.productID}&category=${categoryProduct.id}`} title="Add Video">
                            <img src={AddVideoIcon} alt="Add video" />
                          </Link>
                        )}
                        {(categoryProduct.hasSubCategory === 'Y') && (
                          <Link to={`/admin/sub-categories/by-category/${categoryProduct.id}?name=${translate.language === 'pt-BR' ? categoryProduct.namePt : categoryProduct.nameEn}`} title="List SubCategories">
                            <img src={ListOrderIcon} alt="List SubCategories" />
                          </Link>
                        )}
                        <Link to={`/admin/categories/${categoryProduct.id}`} title="Edit Category">
                          <img src={FaEditIcon} alt="Edit Category" />
                        </Link>
                        <img className="icon-trash" onClick={() => handleDelete(categoryProduct.id)} title="Delete Category" src={TrashIcon} alt="Delete Category" />
                      </div>
                    </TitleContainer>
                  </Square>

                ))
              )
          )}
        </ListContainer>
      </Container>
    </>
  );
}

export default Category;
