import { useContext } from 'react';
import { ThemeAppContext } from '../context/ThemeAppContext';

export function defaultTheme() {
  const { theme } = useContext(ThemeAppContext);

  return {
    grayBackground: theme === 'dark' ? '#363636' : '#FFFFFF',
    grayMenu: theme === 'dark' ? '#2A2A2A' : '#F7F7F5',
    grayDark: theme === 'dark' ? '#656669' : '#e8e8e8',
    grayLight: theme === 'dark' ? '#a2a2a2' : '#262626',
    graySubMenu: theme === 'dark' ? '#2D2D2D' : '#a2a2a2',
    red: '#FC4C02',
    redDark: '#7f2500',
    redHover: 'rgba(252, 76, 2, 0.6)',
    yellow: '#FFD100',
    yellowHover: 'rgba(255, 209, 0, 0.6)',
    green: '#00D689',
    greenHover: 'rgba(0, 214, 137, 0.6)',
    blue: '#6EDCE6',
    blueHover: 'rgba(110, 220, 230, 0.6)',
    fontWhite: theme === 'dark' ? '#f1f1f1' : '#37352F',
    fontButtons: '#363636',
    gray1: '#404040',
    gray2: '#595959',
    gray3: '#737373',
    gray4: '#a6a6a6',
    fonts: {
      small: '12px',
      normal: '16px',
      medium: '20px',
      big: '24px',
    },
    sizes: {
      s6: '6px',
      s8: '8px',
      s10: '10px',
      s12: '12px',
      s14: '14px',
      s16: '16px',
      s20: '20px',
      s24: '24px',
      s32: '32px',
      s40: '40px',
      s48: '48px',
    },
    radius: '4px',
    breakpoints: {
      smartphone: '425px',
      tablet: '768px',
      smallNotebook: '1000px',
    },
    transitionTime: '0.25s',
  };
}
