import React, { useEffect, useState } from 'react';
import ApexChart from 'react-apexcharts';
import TrackerService from '../../../../../services/Tracker';
import Loader from '../../../../../components/Loader';
import { ContainerFunnel } from './style';
import { Translate } from './translate';

export function FunnelChart() {
  const translate = Translate();
  const [clientInfo, setClientInfo] = useState(null);
  const [isLoadingClientInfo, setIsLoadingClientInfo] = useState(true);

  useEffect(() => {
    async function init() {
      await getCompanyInfo();
    }
    init();
  }, []);

  async function getCompanyInfo() {
    try {
      setIsLoadingClientInfo(true);
      const infos = await TrackerService.getCompanyInfo();
      setClientInfo(infos);
    } catch (error) {

    } finally {
      setIsLoadingClientInfo(false);
    }
  }

  const dataClientInfo = clientInfo
    ? [
      clientInfo.quantityUsersRegistered || 0,
      clientInfo.quantityUsersLogin || 0,
      clientInfo.quantityUsersWatchedVideos || 0,
      clientInfo.quantityUsersNotEngaged || 0,
    ]
    : [0, 0, 0, 0];

  const confs = {
    series: [{
      // name: 'User',
      data: dataClientInfo,
    }],
    options: {
      chart: {
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter(val) {
          return `${val}`;
        },
        offsetY: -20,
        style: {
          fontSize: '13px',
          fontWeight: 600,
          colors: ['#FFF'],
        },
      },
      xaxis: {
        categories: [translate.desc1, translate.desc2, translate.desc3, translate.desc4],
        position: 'bottom',
        labels: {
          rotate: -45,
          style: {
            colors: '#D8E3F0',
            fontSize: '12px',
            fontFamily: 'noway, Helvetica, Arial, sans-serif',
            fontWeight: 100,
            cssClass: 'apexcharts-xaxis-label',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 120],
              opacityFrom: 0.2,
              opacityTo: 0.8,

            },
          },
        },
        tooltip: {
          enabled: false,
          enabledOnSeries: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
          color: '#78909C',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          show: true,
          formatter(val) {
            return `${val}`;
          },
          style: {
            colors: '#D8E3F0',
            fontSize: '10px',
            fontFamily: 'noway, Helvetica, Arial, sans-serif',
            fontWeight: 100,
            cssClass: 'apexcharts-xaxis-label',
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        labels: {
          colors: '#000',
          useSeriesColors: true,
        },
      },
      grid: {
        show: true,
        borderColor: '#4a4a4a',
      },
      theme: {
        mode: 'dark',
      },
    },
  };
  return (
    <ContainerFunnel>
      {isLoadingClientInfo && <Loader loading={isLoadingClientInfo} />}
      {!isLoadingClientInfo && (
        <ApexChart
          options={confs.options}
          series={confs.series}
          type={confs.options.chart.type}
          height="100%"
          width="100%"
        />
      )}
    </ContainerFunnel>
  );
}

