export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Lista de Subcategorias' : 'List of Subcategories',
    search: language === 'pt-BR' ? 'Pesquisar' : 'Search',
    placeholderSearchTerm: language === 'pt-BR' ? 'Nome ou categoria' : 'Name or category',
    addButton: language === 'pt-BR' ? 'Adicionar Novo' : 'Add New',
    subCategoryInfo: language === 'pt-BR' ? 'Sem Sub-Categorias para listar' : 'No Sub-Categories to list',
    language,
  };
}
