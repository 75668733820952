/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TitlePage from '../../../../components/TitlePage';
import Loader from '../../../../components/Loader';
import BackButton from '../../../../components/BackButton';
import ButtonsSaveCancel from '../../../../components/ButtonsSaveCancel';
import UTMService from '../../../../services/UTM';
import * as S from './style';
import { Translate } from './translate';
import toast from '../../../../helpers/toast';

export default function URLBUilderAddParamsEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const translate = Translate();
  const [name, setName] = useState();
  const [nameUrl, setNameUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getInfo(id);
    return () => {};
  }, [id]);

  async function getInfo(id) {
    setIsLoading(true);

    try {
      const data = await UTMService.getOne(id);
      setName(data.name);
      setNameUrl(data.value);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleEdit() {
    try {
      const response = await UTMService.update(id, name, nameUrl);
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      navigate('/client/urlbuilder/addparams');
    }
  }

  return (
    <>
      <TitlePage title={translate.titlePage}>
        <BackButton to="/client/urlbuilder/addparams" />
      </TitlePage>
      { isLoading ? <Loader loading={isLoading} />
        : (
          <>
            <S.FormItem>
              <label htmlFor="name">{translate.nameLabel}</label>
              <input
                type="text"
                placeholder={translate.namePlaceholder}
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </S.FormItem>
            <S.FormItem>
              <label htmlFor="value_url">{translate.urlValueLabel}</label>
              <input
                type="text"
                placeholder={translate.urlValuePlaceholder}
                name="value_url"
                value={nameUrl}
                onChange={(e) => setNameUrl(e.target.value)}
              />
            </S.FormItem>
            <ButtonsSaveCancel
              onSave={handleEdit}
              goToWhenCancel="/client/urlbuilder/addparams"
              disabled={(!name || !nameUrl)}
            />
          </>
        )}
    </>
  );
}
