export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    perfilPerformance: language === 'pt-BR' ? 'Perfil Performance' : 'Performance People',
    crescimentoCruzado: language === 'pt-BR' ? 'Crescimento Cruzado' : 'Gravity Growth',
    pipePerformance: language === 'pt-BR' ? 'Pipe Performance' : 'Pipe Performance',
    favorite: language === 'pt-BR' ? 'Favoritos' : 'Favorites',
    transformation: language === 'pt-BR' ? 'Imersão' : 'Transformation',
    accelerators: language === 'pt-BR' ? 'Aceleradores' : 'Accelerators',
    groupMettings: language === 'pt-BR' ? 'Reuniões em Grupo' : 'Group Meetings',
    accelerationProgram: language === 'pt-BR' ? 'Programa de Aceleração' : 'Acceleration Program',
    dashboardExecutivoMacro: language === 'pt-BR' ? 'Executivo Macro' : 'Macro Executive',
    dashboardExecutivoMicro: language === 'pt-BR' ? 'Executivo Micro' : 'Micro Executive',
    digitalConsulting: language === 'pt-BR' ? 'Consultor Digital' : 'Digital Consultant',
    consulting: language === 'pt-BR' ? 'Aceleração' : 'Consulting',
    reports: language === 'pt-BR' ? 'Relatórios' : 'Reports',
    companyTracker: language === 'pt-BR' ? 'RH Tracker' : 'HR Tracker',
    userTracker: language === 'pt-BR' ? 'Tracker' : 'Tracker',
    language,
  };
}
