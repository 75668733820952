import styled from 'styled-components';

export const Container = styled.div`
  margin-top: ${(props) => props.theme.sizes.s24};
  margin-bottom: ${(props) => props.theme.sizes.s24};

  form {
    margin-bottom: ${(props) => props.theme.sizes.s24};

    p {
      margin-bottom: ${(props) => props.theme.sizes.s16};
    }

    .noneParam {
      color: ${(props) => props.theme.grayDark};
    }

    .customParam {
      color: ${(props) => props.theme.yellow};
    }
  }
`;

export const UrlSquare = styled.div`
  padding: 8px;
  border-radius: 4px;
  background-color:  ${(props) => props.theme.grayMenu};

  div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    span {
      flex: 1;
      display: block;
    }

    p {
      word-break: break-all;
      /* flex: 2; */
    }

  }

  hr {
    border: none;
    height: 1px;
    background-color: ${(props) => props.theme.grayBackground};
    margin: ${(props) => props.theme.sizes.s8} 0px;
  }
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.grayLight};
  font-size: 13px;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${(props) => props.theme.sizes.s40};
  width: 100%;

  div {
    margin-bottom: ${(props) => props.theme.sizes.s20};
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.grayLight}
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  .row-1 {
    margin-bottom: 16px;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    gap: 16px;

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        cursor: pointer;
      }

      img:hover {
        filter: invert(67%) sepia(99%) saturate(433%) hue-rotate(360deg) brightness(190%) contrast(109%);
      }
    }

    .form-item {
      position: relative;
    }
  }

  fieldset {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.grayDark};

      legend {
        color: ${({ theme }) => theme.grayLight};
      }
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const FormItem = styled.div`
  flex: 1;
  position: relative;

    div {
      top: 25px;
    }

    select,
    input{
      flex: 1;
      width: 100%;
      margin-top: ${(props) => props.theme.sizes.s6};
      display: flex;
      align-content: center;
    }

    select option:first-child {
    color: ${(props) => props.theme.grayLight}
    }

    span {
      flex: 1;
    }
`;

export const ListContainer = styled.div`
  margin-top: ${(props) => props.theme.sizes.s8};

  h3{
    margin-bottom: ${(props) => props.theme.sizes.s8};
  }

  .message {
    font-size: ${(props) => props.theme.sizes.s16};

    strong {
      font-weight: 500;
    }
  }
`;

export const HiddenItens = styled.div`
  margin-top: ${(props) => props.theme.sizes.s24};
`;

export const ListUrl = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.grayMenu};
  padding: ${(props) => props.theme.sizes.s16};
  border-radius: ${(props) => props.theme.radius};
  min-height: 68px;
  margin-bottom: ${(props) => props.theme.sizes.s8};

  hr {
    border: none;
    height: 1px;
    background-color: ${(props) => props.theme.grayBackground};
    margin: ${(props) => props.theme.sizes.s24} 0px;
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    width: 100px;
    text-align: left;

    @media (max-width:  ${(props) => props.theme.breakpoints.tablet}) {
      display: none;
    }
  }

  textarea {
    flex: 1;
    resize: none;
    border: none;
    font-size: ${(props) => props.theme.sizes.s16};
    overflow: hidden;
    padding-left: ${(props) => props.theme.sizes.s8};
    padding-right: ${(props) => props.theme.sizes.s8};
    height: 100%;
    background-color: ${(props) => props.theme.grayMenu};
    outline: none;
    font-family: 'Noway';
    word-break: break-all;
  }
`;

export const ModalContainer = styled.div`
  div {
    width: 100%;
  }

  .label-container {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      cursor: help;
    }
  }

  input {
    width: 100%;
    margin-bottom: 16px;
    border: 1px solid ${({ theme }) => theme.grayDark};
  }
`;
