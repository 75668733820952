export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? 'CPC Estimado' : 'Estimated CPC',
    tip: language === 'pt-BR'
      ? 'Investimento Perf / Sessões'
      : 'Investment Perf / Sessions',
  };
}
