import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CategoryProductsService from '../../../../../services/CategoryProduct';
import TitlePage from '../../../../../components/TitlePage';
import BackButton from '../../../../../components/BackButton';
import { CategoryForm } from '../components/CategoryForm';
import toast from '../../../../../helpers/toast';
import { Translate } from './translate';

function CategoryAdd() {
  const translate = Translate();
  const navigate = useNavigate();
  const [category, setCategory] = useState({
    namePt: '', nameEn: '', ordering: 0, slug: '', hasSubCategory: 'N', typeCategory: 'multi', idProduct: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);

  async function handleSave() {
    const {
      namePt, nameEn, ordering, hasSubCategory, slug, typeCategory, idProduct,
    } = category;

    try {
      await CategoryProductsService.store({
        namePt, nameEn, ordering, hasSubCategory, slug, typeCategory, idProduct,
      });
      toast('success', 'Update successfully');
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsDisabled(true);
      navigate('/admin/categories');
    }
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton />
      </TitlePage>
      <CategoryForm
        category={category}
        setCategory={setCategory}
        disabled={isDisabled}
        setDisabled={setIsDisabled}
        onSave={handleSave}
      />
    </>
  );
}

export default CategoryAdd;
