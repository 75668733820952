import React from 'react';
import { Container } from './style';
import TitlePage from '../../../components/TitlePage';

function Home() {
  return (
    <>
      <TitlePage title="Home" />
      <Container>
        Área Administrativa da Shifters.
      </Container>
    </>
  );
}

export default Home;
