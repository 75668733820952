import React from 'react';
import { Container, ProgressBarContainer, ProgressBarFiller } from './style';
import { Translate } from './translate';

export function ProgressBar({ value, color, showPercentage }) {
  const translate = Translate();
  return (
    <Container>
      <ProgressBarContainer>
        <ProgressBarFiller value={value} color={color} />
      </ProgressBarContainer>
      {showPercentage && (
        // Show Percentage
        <span><strong>{(value || 0)}%</strong> {translate.completed}</span>
      )}
    </Container>
  );
}
