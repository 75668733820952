import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { DataContext } from '../../../../../context/Data';
import { Translate } from './translate';

const RevenueFINSessionsGATr = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    vtexRevenueCurrent, vtexRevenuePrevious, vtexRevenueYoY, vtexRevenuePreviousComplete, vtexRevenueForecast,
    googleAnalyticsCurrent, googleAnalyticsPrevious, googleAnalyticsYoY, googleAnalyticsPreviousComplete, googleAnalyticsForecast,
  } = useContext(DataContext);

  const objectDataFirst = 'income';
  const objectDataSecond = 'session';

  const current = vtexRevenueCurrent?.reduce((acc, arr) => acc + Number(arr?.[objectDataFirst]), 0)
                  / (Number(googleAnalyticsCurrent?.[objectDataSecond]) || 0);

  const previous = vtexRevenuePrevious?.reduce((acc, arr) => acc + Number(arr?.[objectDataFirst]), 0)
                   / (Number(googleAnalyticsPrevious?.[objectDataSecond]) || 0);

  const yoy = vtexRevenueYoY?.reduce((acc, arr) => acc + Number(arr?.[objectDataFirst]), 0)
              / (Number(googleAnalyticsYoY?.[objectDataSecond]) || 0);

  const completeMonth = vtexRevenuePreviousComplete?.reduce((acc, arr) => acc + Number(arr?.[objectDataFirst]), 0)
                        / (Number(googleAnalyticsPreviousComplete?.[objectDataSecond]) || 0);

  const forecastVtexDataFirst = vtexRevenueForecast?.length > 0 && vtexRevenueForecast?.map((data) => ({ date: data.date, income: Number(data[objectDataFirst]) }));
  const forecastVtexDataSecond = googleAnalyticsForecast?.length > 0 && googleAnalyticsForecast?.map((data) => ({ date: data.date, income: Number(data[objectDataSecond]) }));
  const forecastDataFirst = forecastVtexDataFirst && (ForecastMonthly(forecastVtexDataFirst) || 0);
  const forecastDataSecond = forecastVtexDataSecond && (ForecastMonthly(forecastVtexDataSecond) || 0);
  const forecast = forecastDataFirst / forecastDataSecond;

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta red';
    }
    return 'delta green';
  }

  return (
    <tr title={translate.tip}>
      {showLevelColumn ? <td>2nd & 3rd</td> : null}
      {showSourceColumn ? <td>Calc</td> : null}
      <td>{translate.kpi}</td>
      <td>{numberFormat(current, 'currency', 2)}</td>
      <td>{numberFormat(previous, 'currency', 2)}</td>
      <td className={classNameAtrr(deltaNormal)}>
        {numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(yoy, 'currency', 2)}</td>
      <td className={classNameAtrr(deltaYoY)}>
        {numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(forecast, 'currency', 2)}</td>
      <td>{numberFormat(completeMonth, 'currency', 2)}</td>
      <td className={classNameAtrr(deltaForecast)}>
        {numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

RevenueFINSessionsGATr.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default RevenueFINSessionsGATr;
