export default function Transalate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR'
      ? 'Configurações do usuário'
      : 'User\'s settings',
    labelName: language === 'pt-BR' ? 'Nome' : 'Name',
    labelLastName: language === 'pt-BR' ? 'Sobrenome' : 'Last Name',
    profile: language === 'pt-BR' ? 'Usuário' : 'User',
    errorNamesMsg: language === 'pt-BR'
      ? 'Deve conter mais de 3 caracteres'
      : 'Must contain more than 3 characters',
    placeholderName: language === 'pt-BR'
      ? 'Nome...'
      : 'Name...',
    placeholderLastName: language === 'pt-BR'
      ? 'Sobrenome...'
      : 'Last Name...',
    textMessageOnSave: language === 'pt-BR' ? 'Ao salvar, será redirecionado a fazer o login novamente' : 'After click on save button you will be redirected to login page again',
    language: language === 'pt-BR' ? 'Idioma' : 'Language',
    password: language === 'pt-BR' ? 'Senha' : 'Password',
    editButton: language === 'pt-BR' ? 'Editar' : 'Edit',
    saveButton: language === 'pt-BR' ? 'Salvar' : 'Save',
    cancelButton: language === 'pt-BR' ? 'Cancelar' : 'Cancel',
  };
}
