import Dates from './Dates_New';

export function ForecastMonthly(arrayValues = []) {
  if (arrayValues.length === 0 || !arrayValues) return 0;

  const sample = arrayValues[0];
  const nameOfValue = sample && Object.keys(sample)[1];

  if (!sample && !nameOfValue) return 0;

  // ExemploarrayValues = [{date: "2023-09-01", value: "26000"},{date: 2023-09-02, value: "32000"},{date: 2023-09-03, value: "15000"}]
  const values = arrayValues?.map((data) => Number(data[nameOfValue]));

  const today = new Date().getDate();
  const lastDayOfTheMonth = Dates.getLastDayOfTheMonth();
  const totalSoFar = values.reduce((acc, value) => acc + value, 0);
  const media = totalSoFar / today;

  const restDaysOnMonth = lastDayOfTheMonth - today;
  const result = totalSoFar + (media * restDaysOnMonth);

  return result;
}

// TODO: Enhance this algorythm, its based on Regression Line
// export function ForecastMonthlyOlder(arrayValues = []) {
//   const sample = arrayValues[0];
//   const nameOfValue = Object.keys(sample)[1];

//   // ExemploarrayValues = [{date: "2023-09-01", value: "26000"},{date: 2023-09-02, value: "32000"},{date: 2023-09-03, value: "15000"}]
//   const days = arrayValues.map((data) => Number(data.date.split('-')[2]));
//   const values = arrayValues.map((data) => Number(data[nameOfValue]));

//   /*
//     Calcular a linha de Regressão.
//     Onde n é o número de pontos de dados, E denota a soma, X é o dia e Y é o valor.

//     Calculation of Regression Line
//     Where b is the number of points, Σ detones the sum, X is the day and Y is the value.
//   */

//   const n = arrayValues.length;
//   const X = days.reduce((acc, day) => acc + day, 0);
//   const Y = values.reduce((acc, value) => acc + value, 0);
//   let EXY = 0;
//   let EX2 = 0;
//   let finalValue = 0;

//   for (let i = 0; i < n; i++) {
//     EXY += (days[i] * values[i]);
//   }

//   for (let i = 0; i < n; i++) {
//     EX2 += (days[i] ** 2);
//   }

//   // Inclinação, Inclination | m = (nΣXY - ΣXΣY) / (nΣX^2 - (ΣX)^2)
//   const m = ((n * EXY) - (X * Y)) / ((n * EX2) - (X ** 2));

//   // Intercepção,Interception | b = (ΣY - mΣX) /
//   const b = (Y - ((m) * X)) / n;

//   let tomorrow = new Date().getDate() + 1 === 1 ? (new Date().getDate()) : (new Date().getDate() + 1);
//   let lastDayOfTheMonth = Dates.getLastDayOfTheMonth();

//   // Calculation of Regression Line / Calcular a linha de Regressão.
//   for (let i = tomorrow; i < lastDayOfTheMonth; i++) {
//     finalValue += ((m * i) + b);
//   }

//   finalValue = finalValue < 0 ? finalValue - (finalValue * 2) : finalValue;

//   const result = Number(Y) + Number(finalValue);
//   /* eslint-disable-next-line no-restricted-globals */
//   if (isNaN(result)) {
//     return Y;
//   }
//   return result;
// }
