import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TrackerService from '../../../../../services/Tracker';
import TitlePage from '../../../../../components/TitlePage';
import BackButton from '../../../../../components/BackButton';
import { ProductList } from '../../components/ProductList';
import { ListViews } from '../../components/ListViews';
import { Translate } from './translate';
import { Container, ContainerTitle, ContainerInfo } from './style';


export function TrackerCompanyClientInfo() {
  const translate = Translate();
  const { id } = useParams();
  const [userInfo, serUserInfo] = useState({ firstName: '', lastName: '', email: '' });
  const [videosWatchedList, setVideosWatchedList] = useState([]);
  const [productsStatusList, setProductsStatusList] = useState([]);
  const [isLoadingVideosWatchedList, setIsLoadingVideosWatchedList] = useState(true);
  const [isLoadingProductsStatusList, setIsLoadingProductsStatusList] = useState(true);

  useEffect(() => {
    getWatchedVideosList();
    getUserInfoMonthAndUserInfo();
  }, []);

  async function getUserInfoMonthAndUserInfo() {
    try {
      setIsLoadingProductsStatusList(true);
      const response = await TrackerService.getCompanyInfoProductsStatusByUser({ idUser: id });
      setProductsStatusList(response.products);
      serUserInfo(response.userInfo);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingProductsStatusList(false);
    }
  }

  async function getWatchedVideosList() {
    try {
      setIsLoadingVideosWatchedList(true);
      const list = await TrackerService.getCompanyInfoVideosWatchedByUser({ idUser: id });
      setVideosWatchedList(list);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingVideosWatchedList(false);
    }
  }

  return (
    <Container>
      <ContainerTitle>
        <TitlePage title="Individual Tracker">
          <BackButton to="/client/company-tracker" />
        </TitlePage>
        {!isLoadingProductsStatusList && (
        <h3>{`${userInfo.firstName} ${userInfo.lastName} (${userInfo.email})`}</h3>
        )}
      </ContainerTitle>
      <ContainerInfo>
        <ProductList productsList={productsStatusList} isLoading={isLoadingProductsStatusList} />
        <ListViews videoList={videosWatchedList} isLoading={isLoadingVideosWatchedList} />
      </ContainerInfo>
    </Container>
  );
}
