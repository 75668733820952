export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Editar Post' : 'Edit Post',
    textName: language === 'pt-BR' ? 'Nomes' : 'Names',
    textProduct: language === 'pt-BR' ? 'Produto | Categoria | SubCategoria' : 'Product | Category | SubCategory',
    textOrdering: language === 'pt-BR' ? 'Ordem' : 'Order',
    warningProduct: language === 'pt-BR'
      ? 'Selecione o Produto, Categoria e SubCategoria para o Post ser vinculado. Caso a Categoria selecionada não possuir uma SubCategoria vinculada, o campo irá desaparecer'
      : 'Select the Product, Category and SubCategory for the Post to be linked. If the selected Category does not have a linked SubCategory, the field will disappear',
    labelNamePt: language === 'pt-BR' ? 'Nome PT' : 'Name PT',
    labelNameEn: language === 'pt-BR' ? 'Nome EN' : 'Name EN',
    labelSlug: language === 'pt-BR' ? 'Slug' : 'Slug',
    labelProduct: language === 'pt-BR' ? 'Produto' : 'Product',
    labelCategory: language === 'pt-BR' ? 'Categoria' : 'Category',
    labelSubCategory: language === 'pt-BR' ? 'SubCategoria' : 'SubCategory',
    warnText: language === 'pt-BR' ? 'Todos os campos preenchidos e SubCategoria associada para habilitar o cadastro' : 'All fields completed and associated SubCategory to enable registration',
    language,
  };
}
