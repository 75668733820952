export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Adicionando Usuário' : 'Add User',
    labelName: language === 'pt-BR' ? 'Nome' : 'Name',
    labelAdmin: language === 'pt-BR' ? 'Administrador?' : 'Admin?',
    labelLanguage: language === 'pt-BR' ? 'Idioma' : 'Language',
    labelProfile: language === 'pt-BR' ? 'Perfil' : 'Profile',
    labelAcessGroup: language === 'pt-BR' ? 'Grupo de Acesso' : 'Access Group',
    placeholderYes: language === 'pt-BR' ? 'Sim' : 'Yes',
    placeholderNo: language === 'pt-BR' ? 'Não' : 'No',
    placeholderFirstName: language === 'pt-BR' ? 'Nome' : 'Name',
    placeholderLastName: language === 'pt-BR' ? 'Sobrenome' : 'LastName',
    placeholderLanguagePt: language === 'pt-BR' ? 'Português (BR)' : 'Portuguese (BR)',
    placeholderLanguageEn: language === 'pt-BR' ? 'Inglês (US)' : 'English (US)',
    textAdmin: language === 'pt-BR'
      ? 'Usuário terá privilégios de administração da conta, como cadastro de usuários, visualizar LOGS do sistema e etc...'
      : 'User will have account administration privileges, such as registering users, viewing system LOGS, etc...',
  };
}
