import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    flex-direction: column;
    gap: 24px;
    text-align: center;
  }

  small {
    color: ${({ theme }) => theme.grayLight};
  }

  .filters-container {
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
      text-align: center;
      width: 100%;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
      }
    }

    select {
      border-color: ${({ theme }) => theme.grayLight};
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
      }
    }
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border: 1px solid ${({ theme }) => theme.grayBackground};
  border-radius: 4px;
  overflow-x: auto;

  thead > tr > td {
    font-weight: 500;
    background-color: ${({ theme }) => theme.grayBackground};
  }

  thead > tr > td,
  tbody > tr > td {
    font-size: 14px;
    padding: 8px 4px;
  }

  tr:hover {
    background-color: ${({ theme }) => theme.grayBackground};
  }

  // first column
  td:nth-child(1) {
    width: 200px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 400px;
    }
  }
  tbody > tr > td:nth-child(1) a {
    text-decoration: none;
  }

  tbody > tr > td:nth-child(1) a:hover{
    text-decoration: underline;
    color: ${({ theme }) => theme.yellow};
  }

  // first column
  td:nth-child(n+2) {
    text-align: center;
  }

  .l-red {
    color: ${({ theme }) => theme.red};
  }

  .l-yellow {
    color: ${({ theme }) => theme.yellow};
  }

  .l-green {
    color: ${({ theme }) => theme.green};
  }

  .l-blue {
    color: ${({ theme }) => theme.blue};
  }
`;
