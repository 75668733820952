export function generateSlug(string) {
  const newString = string.toLowerCase()
    .replaceAll('-', '')
    .replaceAll('  ', ' ')
    .replaceAll(' ', '-')
    .replaceAll('.', '')
    .replaceAll(',', '')
    .replaceAll('#', '')
    .replaceAll('?', '')
    .replaceAll('&', '')
    .replaceAll('ã', 'a')
    .replaceAll('õ', 'o')
    .replaceAll('ç', 'c')
    .replaceAll('á', 'a')
    .replaceAll('é', 'e')
    .replaceAll('í', 'i')
    .replaceAll('ó', 'o')
    .replaceAll('ú', 'u')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll(':', '');

  return newString;
}
