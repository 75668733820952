import React, {
  useState, useEffect, useMemo, useTransition,
} from 'react';
import { useParams } from 'react-router-dom';
import UsersService from '../../../../services/Users';
import ClientsService from '../../../../services/Clients';
import toast from '../../../../helpers/toast';
import { Translate } from './translate';
import ListUser from '../components/ListUser';

function UsersByClient() {
  const translate = Translate();
  const { id } = useParams();
  const [nameClient, setNameClient] = useState('');
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getUsers();
    getCompanyInfos();
  }, []);

  async function getUsers() {
    try {
      const userList = await UsersService.getAll(id);
      setUsers(userList);
    } catch (err) {
      toast('danger', 'Users not found');
    }
  }

  async function getCompanyInfos() {
    const { name } = await ClientsService.getOne(id);
    setNameClient(name);
  }

  function handleSearchTerm(e) {
    setSearchTerm(e.target.value.toLowerCase());
  }

  const usersListFiltered = useMemo(() => users?.filter((user) => (user.first_name.toLowerCase().includes(searchTerm) || user.last_name.toLowerCase().includes(searchTerm) || user.email.toLowerCase().includes(searchTerm))));

  return (
    <ListUser
      pageTitle={`${translate.title} (${nameClient})`}
      userList={users}
      userListFiltered={usersListFiltered}
      placeholderSearchTerm={translate.placeholderSearch}
      linkAddButton={`/admin/users/add/client/${id}`}
      linkToBackButton="/admin/clients"
      handleSearchTerm={handleSearchTerm}
    />
  );
}

export default UsersByClient;
