import {
  createContext, useState, useEffect, useMemo,
} from 'react';
import { PropTypes } from 'prop-types';
import Dates_New from '../../helpers/Dates_New';

const DateContext = createContext();
function DateProvider({ children }) {
  const [selectedValue, setSelectValue] = useState(Dates_New.getDateSelected());
  const [infoDateSelected, setInfoDateSelected] = useState({});
  const [listOfDates] = useState(Dates_New.getListDatesInfo());
  const [isCustomPeriod, setIsCustomPeriod] = useState(false);
  const [disableSelectDates, setDisableSelectDates] = useState(false);
  const [disableApplyButtonCustomPeriod, setDisableApplyButtonCustomPeriod] = useState(true);

  // CustomPeriod
  const [dateRangeCurrent, setDateRangeCurrent] = useState([null, null]);
  const [dateRangePrev, setDateRangePrev] = useState([null, null]);
  const [startDateCurrent, endDateCurrent] = dateRangeCurrent;
  const [startDatePrev, endDatePrev] = dateRangePrev;

  useEffect(() => {
    getInfoDatesSelected(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (startDateCurrent && endDateCurrent && startDatePrev && endDatePrev) {
      setDisableApplyButtonCustomPeriod(false);
    } else {
      setDisableApplyButtonCustomPeriod(true);
    }
  }, [startDateCurrent, endDateCurrent, startDatePrev, endDatePrev]);

  function getInfoDatesSelected(date) {
    const infoDate = Dates_New.getInfoDateByDescription(date);
    setInfoDateSelected(infoDate);
  }

  function selectedData(event) {
    const datePicked = getDateSelected(event);
    const $optionsViewButton = document.getElementById('options-view-button');
    $optionsViewButton.click();
    isCustomPeriodSelected(datePicked);
    setSelectValue(datePicked);
  }

  function getDateSelected(event) {
    const $tagName = event.target.tagName;
    const $optionCliked = event.target.classList.contains('option');
    const $optionStarContainerClicked = event.target.classList.contains('option-star-container');
    const $optionConteinerText = event.target.classList.contains('option-text-container');
    const $optionStarClicked = event.target.classList.contains('option-star');
    const $optionDescriptionClicked = event.target.classList.contains('option-description');
    const $smallTagClicked = $tagName === 'SMALL';

    let dataPicked = '';

    if ($optionCliked) {
      dataPicked = event.target.children[1].children[0].dataset.label;
      return dataPicked;
    }

    if ($optionStarContainerClicked) {
      dataPicked = event.target.nextElementSibling.children[0].dataset.label;
      return dataPicked;
    }

    if ($optionConteinerText) {
      dataPicked = event.target.children[0].dataset.label;
      return dataPicked;
    }

    if ($optionStarClicked) {
      dataPicked = event.target.parentElement.nextElementSibling.children[0].dataset.label;
      return dataPicked;
    }

    if ($optionDescriptionClicked || $smallTagClicked) {
      dataPicked = event.target.parentElement.children[0].dataset.label;
      return dataPicked;
    }
  }

  function isCustomPeriodSelected(dateSelected) {
    if (dateSelected === 'Custom Period' || dateSelected === 'Período Personalizado') {
      setDateRangeCurrent([null, null]);
      setDateRangePrev([null, null]);
      setIsCustomPeriod(true);
    } else {
      setIsCustomPeriod(false);
    }
  }

  function showSelectCustomPeriods() {
    const selectedValue = Dates_New.getDateSelected();
    setSelectValue(selectedValue);
    isCustomPeriodSelected(selectedValue);
  }

  function handleCustomPeriod() {
    const infoDate = Dates_New.getinfoDateByCustomPeriod({
      startCurrent: startDateCurrent, endCurrent: endDateCurrent, startPrevious: startDatePrev, endPrevious: endDatePrev,
    });
    setInfoDateSelected(infoDate);
  }

  const contextValues = useMemo(
    () => ({
      selectedData,
      selectedValue,
      listOfDates,
      infoDateSelected,
      isCustomPeriod,
      showSelectCustomPeriods,
      setDisableSelectDates,
      disableSelectDates,
      startDateCurrent,
      endDateCurrent,
      startDatePrev,
      endDatePrev,
      setDateRangeCurrent,
      setDateRangePrev,
      handleCustomPeriod,
      disableApplyButtonCustomPeriod,
    }),
    [selectedData,
      selectedValue,
      listOfDates,
      infoDateSelected,
      isCustomPeriod,
      showSelectCustomPeriods,
      setDisableSelectDates,
      disableSelectDates,
      startDateCurrent,
      endDateCurrent,
      startDatePrev,
      endDatePrev,
      setDateRangeCurrent,
      setDateRangePrev,
      handleCustomPeriod,
      disableApplyButtonCustomPeriod],
  );

  return (
    <DateContext.Provider value={contextValues}>
      {children}
    </DateContext.Provider>
  );
}

DateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { DateContext, DateProvider };
