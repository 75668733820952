export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? 'T. Conversão (S) - Lower' : 'Conv. Rate (S) - Lower',
    tip: language === 'pt-BR'
      ? 'Transações - Lower / Sessões - Lower'
      : 'Transactions - Lower / Sessions - Lower',
  };
}
