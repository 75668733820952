import React from 'react';
import TitlePage from '../../../../../components/TitlePage';
import BackButton from '../../../../../components/BackButton';
import Square from '../../../../../components/Square';
import ButtonsSaveCancel from '../../../../../components/ButtonsSaveCancel';
import { Button } from '../../../../../components/Button';
import { Translate } from './translate';
import {
  Container, InfoContainer, ListContainer,
} from './style';
import usePostAdd from './usePostAdd';

function PostsAdd() {
  const translate = Translate();
  const {
    post,
    setPost,
    productsList,
    productSelected,
    setProductSelected,
    categoryList,
    categorySelected,
    setCategorySelected,
    subCategoryList,
    subCategorySelected,
    setSubCategorySelected,
    isDisabled,
    verifyFormToSave,
    handleGenerateSlug,
    save,
    isLoadingContent,
  } = usePostAdd();

  return (
    <>
      {isLoadingContent ? 'Loading Infos' : (
        <>
          <TitlePage title={translate.title}>
            <BackButton />
          </TitlePage>
          <Container>
            <ListContainer>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textName}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <label htmlFor="name_pt">{translate.labelNamePt}</label>
                      <input
                        className="l-input-large"
                        type="text"
                        id="name_pt"
                        value={post.namePt}
                        onChange={(e) => {
                          setPost({ ...post, namePt: e.target.value });
                        }}
                      />
                    </div>
                    <div className="c-input">
                      <label htmlFor="name_en">{translate.labelNameEn}</label>
                      <input
                        type="text"
                        className="l-input-large"
                        id="name_en"
                        value={post.nameEn}
                        onChange={(e) => {
                          setPost({ ...post, nameEn: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </InfoContainer>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    Slug
                  </div>
                  <div className="c-right">
                    <Button color="yellow" onClick={handleGenerateSlug}>Gerar slug</Button>
                    <div className="c-input">
                      <input
                        className="l-input-w"
                        type="text"
                        id="slug"
                        value={post.slug}
                        onChange={(e) => {
                          setPost({ ...post, slug: e.target.value });
                          verifyFormToSave();
                        }}
                      />
                    </div>
                  </div>
                </InfoContainer>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textOrdering}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <input
                        type="text"
                        id="ordering"
                        value={post.ordering}
                        onChange={(e) => setPost({ ...post, ordering: e.target.value })}
                      />
                    </div>
                  </div>
                </InfoContainer>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textProduct}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <label htmlFor="products">{translate.labelProduct}</label>
                      <select
                        type="text"
                        id="products"
                        value={productSelected}
                        onChange={(e) => {
                          setProductSelected(e.target.value);
                        }}
                      >
                        {productsList?.map((product) => (
                          <option value={product.id} key={product.id}>
                            {translate.language === 'pt-BR' ? product.namePt : product.nameEn}
                          </option>
                        ))}
                      </select>
                    </div>
                    {categoryList.length > 0 ? (
                      <div className="c-input">
                        <label htmlFor="categories">{translate.labelCategory}</label>
                        <select
                          type="text"
                          id="categories"
                          value={categorySelected}
                          onChange={(e) => setCategorySelected(e.target.value)}
                        >
                          {categoryList.length > 0 && categoryList?.map((category) => <option value={category.id} key={category.id}>{category.namePt}</option>)}
                        </select>
                      </div>
                    ) : null}
                    {subCategoryList.length > 0 ? (
                      <div className="c-input">
                        <label htmlFor="sub_category">{translate.labelSubCategory}</label>
                        <select
                          type="text"
                          id="sub_category"
                          value={subCategorySelected}
                          onChange={(e) => {
                            setSubCategorySelected(e.target.value);
                            setPost({ ...post, idSubCategory: e.target.value });
                          }}
                        >
                          {subCategoryList.length > 0 && subCategoryList?.map((subCategory) => <option value={subCategory.id} key={subCategory.id}>{subCategory.name_pt}</option>)}
                        </select>
                      </div>
                    ) : null }
                  </div>
                </InfoContainer>
                <div className="c-explanation">{translate.warningProduct}</div>
              </Square>
            </ListContainer>
            <ButtonsSaveCancel
              onSave={save}
              goToWhenCancel="/admin/posts"
              disabled={isDisabled}
              smallText={isDisabled ? translate.warnText : ''}
            />
          </Container>
        </>
      )}
    </>
  );
}

export default PostsAdd;
