import TitlePage from '../../../../../components/TitlePage';
import BackButton from '../../../../../components/BackButton';
import Loader from '../../../../../components/Loader';
import Square from '../../../../../components/Square';
import ButtonsSaveCancel from '../../../../../components/ButtonsSaveCancel';
import IconTrash from '../../../../../styles/logos/trash-alt.svg';
import { Editor } from '../component/Editor';
import { Translate } from './translate';
import {
  Container, InfoContainer, ColumnContainer, TextArea, Explanation, ImageContainer,
} from './style';
import { useVideoEdit } from './useVideoEdit';

function VideoEdit() {
  const translate = Translate();
  const {
    video,
    setVideo,
    productsList,
    categoriesList,
    subCategoriesList,
    postsList,
    productSelected,
    setProductSelected,
    categorySelected,
    subCategorySelected,
    postSelected,
    handleProductOnChange,
    handleAddToInputOnChange,
    handleOptionsSelectedOnChange,
    handleDescriptionPt,
    handleDescriptionEn,
    handleFiles,
    saveVideo,
    isSavingInfo,
    isDisableButtom,
    assetsList,
    addFilesToDelete,
    isLoadingInfo,
  } = useVideoEdit();

  return (
    <>
      {isLoadingInfo && <>Loading Infos</>}
      {!isLoadingInfo && (
        <>
          <TitlePage title={translate.title}>
            <BackButton to="/admin/videos" />
          </TitlePage>
          <Container>
            <Square size="large">
              <InfoContainer>
                <div className="c-left">
                  {translate.labelName}
                </div>
                <div className="c-right">
                  <div className="c-input-large">
                    <label htmlFor="title_pt">{translate.namePt}</label>
                    <input
                      type="text"
                      id="title_pt"
                      value={video.titlePt}
                      onChange={(e) => {
                        setVideo({ ...video, titlePt: e.target.value });
                      }}
                    />
                  </div>
                  <div className="c-input-large">
                    <label htmlFor="name_en">{translate.nameEn}</label>
                    <input
                      type="text"
                      id="name_en"
                      value={video.titleEn}
                      onChange={(e) => {
                        setVideo({ ...video, titleEn: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </InfoContainer>
            </Square>
            <Square size="large">
              <InfoContainer>
                <div className="c-left">
                  {translate.labelVideo}
                </div>
                <div className="c-right">
                  <div className="c-input-large">
                    <label htmlFor="title_pt">{translate.videoType}</label>
                    <select
                      value={video.typeVideo}
                      onChange={(e) => setVideo({ ...video, typeVideo: e.target.value })}
                    >
                      <option value="vimeo">Vimeo</option>
                      <option value="youtube">Youtube</option>
                      <option value="outro">{translate.language === 'pt-BR' ? 'Outro' : 'Other'}</option>
                      <option value="sem video">Sem vídeo</option>
                    </select>
                  </div>
                  {video.typeVideo !== 'sem video' && (
                  <div className="c-input-large">
                    <label htmlFor="title_pt">Link</label>
                    <input
                      type="text"
                      id="title_pt"
                      value={video.linkVideo}
                      onChange={(e) => {
                        setVideo({ ...video, linkVideo: e.target.value });
                      }}
                    />
                  </div>
                  )}
                </div>
              </InfoContainer>
            </Square>
            <Square size="large">
              <InfoContainer>
                <div className="c-left">Add to</div>
                <div className="c-right">
                  <div className="c-up">
                    <div className="c-input l-left">
                      <label htmlFor="product">{translate.product}</label>
                      <select
                        type="text"
                        id="product"
                        value={productSelected}
                        onChange={(e) => setProductSelected(e.target.value)}
                      >
                        {productsList.length > 0 && productsList.map(((product) => (
                          <option value={product.id} key={product.id}>{product.namePt} | {product.nameEn}</option>
                        )))}
                      </select>
                    </div>
                    <div className="c-input l-right">
                      <label htmlFor="add_to">Add to</label>
                      <select
                        type="text"
                        id="add_to"
                        value={video.relationType}
                        onChange={(e) => {
                          handleAddToInputOnChange(e.target.value);
                        }}
                      >
                        <option value="category">{translate.category}</option>
                        <option value="subcategory">{translate.subCategory}</option>
                        <option value="post">Post</option>
                      </select>
                    </div>
                  </div>
                  <div className="c-down">
                    {(video?.relationType === 'category' && categoriesList.length > 0)
                      ? (
                        <div className="c-input">
                          <label htmlFor="add_to">Categoria</label>
                          <select
                            type="text"
                            id="add_to"
                            value={categorySelected}
                            onChange={(e) => handleOptionsSelectedOnChange('category', e.target.value)}
                          >
                            {categoriesList.length > 0 && categoriesList.map((category) => <option key={category.id} value={category.id}>{category.namePt}</option>)}
                          </select>
                        </div>
                      ) : null}
                    {(video?.relationType === 'subcategory' && subCategoriesList.length > 0)
                      ? (
                        <div className="c-input">
                          <label htmlFor="add_to">SubCategoria</label>
                          <select
                            type="text"
                            id="add_to"
                            value={subCategorySelected}
                            onChange={(e) => handleOptionsSelectedOnChange('subcategory', e.target.value)}
                          >
                            {subCategoriesList.length > 0 && subCategoriesList.map((subCategory) => <option key={subCategory.id} value={subCategory.id}>{subCategory.name_pt}</option>)}
                          </select>
                        </div>
                      ) : null}
                    {(video?.relationType === 'post' && postsList.length > 0)
                      ? (
                        <div className="c-input">
                          <label htmlFor="add_to">Post</label>
                          <select
                            type="text"
                            id="add_to"
                            value={postSelected}
                            onChange={(e) => handleOptionsSelectedOnChange('post', e.target.value)}
                          >
                            {postsList && postsList.map((post) => <option key={post.id} value={post.id}>{post.name_pt}</option>)}
                          </select>
                        </div>
                      ) : null}
                  </div>
                </div>
              </InfoContainer>
              <Explanation>{translate.warningAddTo}</Explanation>
            </Square>

            <ColumnContainer>
              <p className="title">{translate.descriptionPt}</p>
              <Editor onHandleDescription={handleDescriptionPt} contentFromDb={video.descriptionPt || ''} />
            </ColumnContainer>
            <ColumnContainer>
              <p className="title">{translate.descriptionEn}</p>
              <Editor onHandleDescription={handleDescriptionEn} contentFromDb={video.descriptionEn || ''} />
            </ColumnContainer>
            <Square size="large">
              <InfoContainer>
                <div className="c-left">
                  {translate.transcriptionOpt}
                </div>
                <div className="c-right">
                  <div className="c-input">
                    <select
                      value={video.allowTranscript}
                      onChange={(e) => setVideo({ ...video, allowTranscript: e.target.value })}
                    >
                      <option value="Y">Y</option>
                      <option value="N">N</option>
                    </select>
                  </div>
                </div>
              </InfoContainer>
            </Square>
            <ColumnContainer>
              <label htmlFor="title_pt">{translate.transcriptionPt}</label>
              <TextArea
                value={video.transcriptPt}
                onChange={(e) => setVideo({ ...video, transcriptPt: e.target.value })}
              />
            </ColumnContainer>
            <ColumnContainer>
              <label htmlFor="title_pt">{translate.transcriptionEn}</label>
              <TextArea
                value={video.transcriptEn}
                onChange={(e) => setVideo({ ...video, transcriptEn: e.target.value })}
              />
            </ColumnContainer>
            {assetsList.length > 0 && (
              assetsList.map((asset) => (
                <Square size="large" key={asset.id}>
                  <ImageContainer>
                    <div className="info-container">
                      {asset.mymetype?.includes('image') && <img src={asset.url} alt={asset.original_name} />}
                      <p>{asset.original_name}</p>
                    </div>
                    <div className="actions-container">
                      <img className="icon-trash" src={IconTrash} alt="Delete" onClick={() => addFilesToDelete(asset)} />
                    </div>
                  </ImageContainer>
                </Square>
              ))
            )}
            <Square size="large">
              <InfoContainer>
                <div className="c-left">
                  Arquivos
                </div>
                <div className="c-right">
                  <div className="c-input">
                    <label htmlFor="video-files">Arraste e solte aqui</label>
                    <input
                      type="file"
                      name="video-files"
                      id="video-files"
                      multiple
                      className="input-file"
                      onChange={(e) => handleFiles(e.target.files)}
                    />
                  </div>
                </div>
              </InfoContainer>
            </Square>

          </Container>
          {isSavingInfo && <Loader loading={isSavingInfo} />}
          {!isSavingInfo && (
          <ButtonsSaveCancel
            onSave={saveVideo}
            goToWhenCancel="/admin/videos"
            disabled={isDisableButtom}
          />
          )}
        </>
      )}
    </>
  );
}

export default VideoEdit;
