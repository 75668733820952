import styled from 'styled-components';

export const TranscriptContainer = styled.div`
  margin-top: 16px;
  padding: 20px;
  background-color: ${({ theme }) => theme.grayMenu};
  border-radius: 8px;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      cursor: pointer;
      transition: 0.2s ease;
    }
  }

  .content {
    margin-top: 24px;
    display: none;
  }
`;
