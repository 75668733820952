export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    titleAll: language === 'pt-BR' ? 'Usuários convidados' : 'Guest users',
    subtitleAll: language === 'pt-BR' ? 'Cadastrado na plataforma' : 'Registered on the platform',
    titleUserEngaged: language === 'pt-BR' ? 'Usuários engajados' : 'Engaged users',
    subtitleUserEngaged: language === 'pt-BR' ? 'Marcou vídeo como concluído' : 'Marked video as completed',
    titleUserNotEngaged: language === 'pt-BR' ? 'Usuários não engajados' : 'Unengaged users',
    subtitleUserNotEngaged: language === 'pt-BR' ? 'Não marcou nenhum vídeo como concluído' : 'Didn\'t mark any video as complete',
    subtitle: language === 'pt-BR' ? 'Total de vídeos completados por mês' : 'Total videos completed by month',
    labelPeriod: language === 'pt-BR' ? 'Período' : 'Period',
    last12Months: language === 'pt-BR' ? 'Últimos 12 meses' : 'Last 12 months',
    tableTitleName: language === 'pt-BR' ? 'Nome' : 'Name',
  };
}

/*
  desc1: language === 'pt-BR' ? 'Usuários convidados (Cadastrado na plataforma)' : 'Guest users (Registered on the platform)',
  desc2: language === 'pt-BR' ? 'Usuários Logados (Fez login)' : 'Logged in users (Logged in)',
  desc3: language === 'pt-BR' ? 'Usuários engajados (Marcou vídeo como concluído)' : 'Engaged users (Marked video as completed)',
  desc4: language === 'pt-BR' ? 'Usuários não engajados (Não marcou nenhum vídeo como concluído) ' : 'Unengaged users (Didn\'t mark video as complete)'

 */
