import React from 'react';
// import PlayerVimeo from '@vimeo/player';
import parse from 'html-react-parser';
import { PropTypes } from 'prop-types';

export function Player({ video }) {
  return (
    <>
      {parse(`${video}`)}
    </>
  );
}

Player.propTypes = {
  video: PropTypes.string,
};
