import styled from 'styled-components';

export const Container = styled.div`

  display: flex;
  align-items: center;
  margin-top: 50px;
  flex-direction: column;
  height: 100vh;

  .message {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      height: 30px;
    }

    p {
      font-size: 25px;
      margin-top: 20px;
      margin-bottom: 25px;
    }
  }

  .image {
    width: 300px;
    border-radius: 16px;
    margin-bottom: 30px;
  }
`;
