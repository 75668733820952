import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { DataContext } from '../../../../../context/Data';
import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { Translate } from './translate';

const AOVTr = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    googleAnalyticsCurrent, googleAnalyticsPrevious, googleAnalyticsYoY, googleAnalyticsPreviousComplete, googleAnalyticsForecast,
  } = useContext(DataContext);

  const objectDataFirst = 'revenue';
  const objectDataSecond = 'transactions';

  // Current Date
  const currentDataFirst = Number(googleAnalyticsCurrent?.[objectDataFirst]) || 0;
  const currentDataSecond = Number(googleAnalyticsCurrent?.[objectDataSecond]) || 0;
  const current = accountKPI(currentDataFirst, currentDataSecond);

  // Previous Date
  const previousDataFirst = Number(googleAnalyticsPrevious?.[objectDataFirst]) || 0;
  const previousDataSecond = Number(googleAnalyticsPrevious?.[objectDataSecond]) || 0;
  const previous = accountKPI(previousDataFirst, previousDataSecond);

  // YoY
  const yoyDataFirst = Number(googleAnalyticsYoY?.[objectDataFirst]) || 0;
  const yoyDataSecond = Number(googleAnalyticsYoY?.[objectDataSecond]) || 0;
  const yoy = accountKPI(yoyDataFirst, yoyDataSecond);

  // Complete Month
  const completeMonthDataFirst = Number(googleAnalyticsPreviousComplete?.[objectDataFirst]) || 0;
  const completeMonthDataSecond = Number(googleAnalyticsPreviousComplete?.[objectDataSecond]) || 0;
  const completeMonth = accountKPI(completeMonthDataFirst, completeMonthDataSecond);

  // Forecast
  const forecastGoogleAnalyticsDataFirst = googleAnalyticsForecast?.length > 0 && googleAnalyticsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataFirst]) }));
  const forecastGoogleAnalyticsDataSecond = googleAnalyticsForecast?.length > 0 && googleAnalyticsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataSecond]) }));
  const forecastDataFirst = (forecastGoogleAnalyticsDataFirst && (ForecastMonthly(forecastGoogleAnalyticsDataFirst) || 0));
  const forecastDataSecond = (forecastGoogleAnalyticsDataFirst && (ForecastMonthly(forecastGoogleAnalyticsDataSecond) || 0));
  const forecast = accountKPI(forecastDataFirst, forecastDataSecond);

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function accountKPI(num1, num2) {
    return num1 / num2;
  }

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta red';
    }
    return 'delta green';
  }

  return (
    <tr title={translate.tip}>
      {showLevelColumn ? <td>2nd</td> : null}
      {showSourceColumn ? <td>Calc</td> : null}
      <td>{translate.kpi}</td>
      <td>{numberFormat(current, 'currency', 2)}</td>
      <td>{numberFormat(previous, 'currency', 2)}</td>
      <td className={classNameAtrr(deltaNormal)}>
        {numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(yoy, 'currency', 2)}</td>
      <td className={classNameAtrr(deltaYoY)}>
        {numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(forecast, 'currency', 2)}</td>
      <td>{numberFormat(completeMonth, 'currency', 2)}</td>
      <td className={classNameAtrr(deltaForecast)}>
        {numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

AOVTr.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default AOVTr;
