import React from 'react';
import Square from '../../../../../components/Square';
import Loader from '../../../../../components/Loader';
import { NoDataMessage } from '../../../../../components/NoDataMessage';
import { ProgressBar } from '../../../../../components/ProgressBar';
import { Translate } from './translate';
import { Container } from './style';

export function ProductList({ productsList, isLoading }) {
  const translate = Translate();
  return (
    <Container>
      <h3>{translate.titleProducts}</h3>
      {(productsList.length === 0 && !isLoading) && (
      <NoDataMessage>{translate.msgNoDataList}</NoDataMessage>
      )}
      {(productsList.length === 0 && isLoading) && (
      <Loader loading={isLoading} />
      )}
      <div className="container-academy">
        {productsList.length > 0 && (
          productsList.map((product) => (
            <Square size="medium" key={product.id}>
              <div style={{ color: product.color }}>{product.name_pt}</div>
              <div><ProgressBar showPercentage value={product.percentage} color={product.color} /></div>
            </Square>
          ))
        )}
      </div>
    </Container>
  );
}
