import styled, { css } from 'styled-components';

export const Button = styled.button`
  padding: 8px 32px;
  word-wrap: none;
  transition: .3s ease;
  border: 1px solid transparent;
  border-radius: ${(props) => props.theme.radius};
  font-size: ${(props) => props.theme.sizes.s16};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: transparent;

  &:active {
      transform: scale(0.9);
    }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
    width: 100%;
  }


  ${({ color, theme, disabled }) => color?.includes('#') && css`
    background-color: ${disabled ? 'transparent' : color};
    border-color: ${disabled ? theme.grayDark : color};
    color: ${disabled ? theme.grayLight : theme.grayMenu};

    &:hover {
      background-color: transparent;
      border-color: ${disabled ? theme.grayLight : color};
      color: ${disabled ? theme.grayLight : color};
    }
  `}

  ${({ color, theme, disabled }) => color === 'ghost' && css`
    background-color: ${disabled ? theme.grayLight : 'transparent'};
    border-color: ${disabled ? theme.grayLight : theme.grayDark};
    color: ${disabled ? theme.grayLight : theme.grayLight};

    &:hover {
      background-color: transparent;
      border-color: ${disabled ? theme.grayLight : theme.grayLight};
      color: ${disabled ? theme.grayLight : theme.grayLight};
    }
  `}

  ${({ color, theme, disabled }) => color === 'white' && css`
    background-color: ${disabled ? theme.grayLight : theme.fontWhite};
    color: ${theme.grayBackground};

    &:hover {
      background-color: transparent;
      border-color: ${disabled ? theme.grayLight : theme.fontWhite};
      color:${disabled ? theme.grayLight : theme.fontWhite};
    }
  `}

  ${({ color, theme, disabled }) => color === 'green' && css`
    background-color: ${disabled ? theme.grayLight : theme.green};
    color: ${theme.grayBackground};

    &:hover {
      background-color: transparent;
      border-color: ${disabled ? theme.grayLight : theme.green};
      color:${disabled ? theme.grayLight : theme.green};
    }
  `}

  ${({ color, theme, disabled }) => color === 'red' && css`
    background-color: ${disabled ? theme.grayLight : theme.red};

    &:hover {
      background-color: transparent;
      border-color: ${disabled ? theme.grayLight : theme.red};
      color: ${disabled ? theme.grayLight : theme.red};
    }
  `}

  ${({ color, theme, disabled }) => color === 'yellow' && css`
    background-color: ${disabled ? theme.grayLight : theme.yellow};
    color: ${theme.grayBackground};

    &:hover {
      background-color: transparent;
      border-color: ${disabled ? theme.grayLight : theme.yellow};
      color: ${disabled ? theme.grayLight : theme.yellow};
    }
  `}

  ${({ color, theme, disabled }) => color === 'blue' && css`
    background-color: ${disabled ? theme.grayLight : theme.blue};
    color: ${theme.fontButtons};

    &:hover {
      background-color: transparent;
      border-color: ${disabled ? theme.grayLight : theme.blue};
      color: ${disabled ? theme.grayLight : theme.blue};
    }
  `}
`;
