import styled from 'styled-components';

export const Container = styled.div`
`;

export const LinksContainer = styled.div`
  margin-top: 16px;
  padding: 20px;
  background-color: ${({ theme }) => theme.grayMenu};
  border-radius: 8px;
  width: 100%;

  h3 {
    margin-bottom: 16px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    gap: 4px
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }

    a {
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;

    img {
      width: 14px;
    }
  }
`;
