export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    language,
    maskedParam: language === 'pt-BR' ? 'Mascarar UTMs?' : 'Mask UTMs?',
    shortenedParam: language === 'pt-BR' ? 'Encurtar URL?' : 'Shorten URL?',
    shortenedParamLabel: language === 'pt-BR' ? 'Ativar' : 'Active',
  };
}
