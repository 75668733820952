import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    textarea {
      border: 1px solid ${({ theme }) => theme.grayDark};
      color: ${({ theme }) => theme.fontWhite};
      resize: none;
      height: 200px;
    }
  }
`;

export const ContainerRating = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .score {
    font-size: 26px;
  }
`;
