import React, { useContext } from 'react';
import { DataChannelsOverviewContext } from '../../../context/DataCardChannelsOverview';
import { DateContext } from '../../../../../../context/DateContext';
import { Translate } from './translate';
import { Table } from './style';

export function TableByUserView() {
  const { infoDateSelected } = useContext(DateContext);
  const { kpiSelected, kpisInfo, userViewSelected } = useContext(DataChannelsOverviewContext);

  function defineDeltaClass(deltaValue) {
    if (deltaValue === '0%') return 'delta';
    if (!kpisInfo.isInverted) {
      if (deltaValue.includes('-')) return 'delta red';
      return 'delta green';
    }
    if (deltaValue.includes('-')) return 'delta green';
    return 'delta red';
  }

  return (
    <Table>
      <thead>
        <tr>
          <td className="column-main">{`${kpiSelected.name} (${userViewSelected?.name})`}</td>
          <td>
            <small>
              {infoDateSelected?.init?.description}
              {' '}
              -
              {' '}
              {infoDateSelected?.end?.description}
            </small>
          </td>
          <td>
            <small>
              {infoDateSelected?.initPrev?.description}
              {' '}
              -
              {' '}
              {infoDateSelected?.endPrev?.description}
            </small>
          </td>
          <td>Delta</td>
        </tr>
      </thead>
      <tbody>
        {kpisInfo
            && kpisInfo.data?.map((data) => (
              <tr key={data.view}>
                <td className="column-main">{data.view}</td>
                <td className="column-value">{data.current}</td>
                <td className="column-value">{data.previous}</td>
                <td className={defineDeltaClass(data.delta)}>{data.delta}</td>
              </tr>
            ))}
      </tbody>
      <tfoot>
        {kpisInfo
          && (
          <tr>
            <td className="column-main">Total</td>
            <td className="column-value">{kpisInfo.total.current}</td>
            <td className="column-value">{kpisInfo.total.previous}</td>
            <td className={defineDeltaClass(kpisInfo.total.delta)}>{kpisInfo.total.delta}</td>
          </tr>
          )}
      </tfoot>
    </Table>

  );
}
