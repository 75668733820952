import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SubCategoryProductService from '../../../../../services/SubCategoryProduct';
import CategoryProductService from '../../../../../services/CategoryProduct';
import TitlePage from '../../../../../components/TitlePage';
import { Button } from '../../../../../components/Button';
import BackButton from '../../../../../components/BackButton';
import Square from '../../../../../components/Square';
import ButtonsSaveCancel from '../../../../../components/ButtonsSaveCancel';
import { Badge } from '../../../../../components/Badge';
import toast from '../../../../../helpers/toast';
import { generateSlug } from '../../../../../helpers/generateSlug';
import { Translate } from './translate';
import {
  Container, InfoContainer, ListContainer,
} from './style';

function SubCategoryEdit() {
  const translate = Translate();
  const { id } = useParams();
  const navigate = useNavigate();
  const [subCategory, setSubCategory] = useState({
    namePt: '', nameEn: '', ordering: '', slug: '', hasPosts: '', idProductCategory: 0, categoryNamePt: '', categoryNameEn: '', productId: '', productNamePt: '', productNameEn: '', productColor: '',
  });
  const [categories, setCategories] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    getSubCategoryProduct();
    verifyEnableSaveButton();
  }, []);

  useEffect(() => {
    getCategories();
    verifyEnableSaveButton();
  }, [subCategory]);

  async function getSubCategoryProduct() {
    const subCategoryProductRes = await SubCategoryProductService.findById(id);
    setSubCategory({
      namePt: subCategoryProductRes.name_pt,
      nameEn: subCategoryProductRes.name_en,
      ordering: subCategoryProductRes.ordering,
      hasPosts: subCategoryProductRes.hasPosts,
      slug: subCategoryProductRes.slug,
      idProductCategory: subCategoryProductRes.id_product_category,
      categoryNamePt: subCategoryProductRes.category_namePt,
      categoryNameEn: subCategoryProductRes.category_nameEn,
      productId: subCategoryProductRes.product_id,
      productNamePt: subCategoryProductRes.product_name_pt,
      productNameEn: subCategoryProductRes.product_name_en,
      productColor: subCategoryProductRes.product_color,
    });
  }

  async function getCategories() {
    const categories = await CategoryProductService.findAll(subCategory.productId);
    const categoriesFiltered = categories.filter((category) => category.hasSubCategory === 'Y');
    setCategories(categoriesFiltered);
  }

  function verifyEnableSaveButton() {
    const hasNamePt = subCategory.namePt.length >= 3;
    const hasNameEn = subCategory.nameEn.length >= 3;
    const hasSlug = subCategory.slug.length >= 3;

    if (hasNamePt && hasNameEn && hasSlug) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  async function saveSubCategoryProduct() {
    const {
      namePt, nameEn, ordering, hasPosts, slug, idProductCategory,
    } = subCategory;
    try {
      setIsDisabled(true);
      await SubCategoryProductService.update(id, {
        namePt, nameEn, ordering, hasPosts, slug, idProductCategory,
      });
      toast('success', 'Update successfully');
    } catch (error) {
      toast('danger', error.message);
    } finally {
      navigate('/admin/sub-categories');
    }
  }

  function handleGenerateSlug() {
    setSubCategory({ ...subCategory, slug: generateSlug(subCategory?.namePt) });
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton />
      </TitlePage>
      <Container>
        <ListContainer>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textName}
              </div>
              <div className="c-right">
                <div className="c-input">
                  <label htmlFor="name_pt">{translate.labelNamePt}</label>
                  <input
                    className="l-input-large"
                    type="text"
                    id="name_pt"
                    value={subCategory.namePt}
                    onChange={(e) => {
                      setSubCategory({ ...subCategory, namePt: e.target.value });
                      verifyEnableSaveButton();
                    }}
                  />
                </div>
                <div className="c-input">
                  <label htmlFor="name_en">{translate.labelNameEn}</label>
                  <input
                    type="text"
                    className="l-input-large"
                    id="name_en"
                    value={subCategory.nameEn}
                    onChange={(e) => {
                      setSubCategory({ ...subCategory, nameEn: e.target.value });
                      verifyEnableSaveButton();
                    }}
                  />
                </div>
              </div>
            </InfoContainer>
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textPost}
              </div>
              <div className="c-right">
                <div className="c-input">
                  <select
                    type="text"
                    id="has_post"
                    value={subCategory.hasPosts}
                    onChange={(e) => setSubCategory({ ...subCategory, hasPosts: e.target.value })}
                  >
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </div>
              </div>
            </InfoContainer>
            <div className="c-explanation">{translate.warningPosts}</div>
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                Slug
              </div>
              <div className="c-right">
                <Button color="yellow" onClick={handleGenerateSlug}>Gerar slug</Button>
                <div className="c-input">
                  <input
                    className="l-input-w"
                    type="text"
                    id="slug"
                    value={subCategory.slug}
                    onChange={(e) => {
                      setSubCategory({ ...subCategory, slug: e.target.value });
                      verifyEnableSaveButton();
                    }}
                  />
                </div>
              </div>
            </InfoContainer>
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textOrdering}
              </div>
              <div className="c-right">
                <div className="c-input">
                  <input
                    type="text"
                    id="ordering"
                    value={subCategory.ordering}
                    onChange={(e) => {
                      setSubCategory({ ...subCategory, ordering: e.target.value });
                      verifyEnableSaveButton();
                    }}
                  />
                </div>
              </div>
            </InfoContainer>
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textCategory}
              </div>
              <div className="c-right">
                <Badge color={subCategory.productColor} size="l">{translate.language === 'pt-BR' ? subCategory.productNamePt : subCategory.productNameEn }</Badge>
                <div className="c-input">
                  <select
                    type="text"
                    className="l-input-large"
                    id="product"
                    value={subCategory.idProductCategory}
                    onChange={(e) => {
                      setSubCategory({ ...subCategory, idProductCategory: e.target.value });
                      verifyEnableSaveButton();
                    }}
                  >
                    {categories && (
                      categories?.map((category) => (
                        <option value={category.id} key={category.id}>
                          {translate.language === 'pt-BR' ? category.namePt : category.nameEn }
                        </option>
                      )))}
                  </select>
                </div>
              </div>
            </InfoContainer>
            <div className="c-explanation">{translate.warningProduct}</div>
          </Square>
        </ListContainer>
        <ButtonsSaveCancel
          onSave={saveSubCategoryProduct}
          goToWhenCancel="/admin/sub-categories"
          disabled={isDisabled}
          smallText={isDisabled ? translate.warnText : ''}
        />
      </Container>
    </>
  );
}

export default SubCategoryEdit;
