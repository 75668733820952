import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;

  .container-last-videos {
    h3 {
      margin-bottom: 16px;
    }

    .container-last-videos-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      //square
      > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        }

        .container-video-date {
          min-width: 120px;
        }

        .container-video-path {

          @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
          }
        }

         .l-video {
          background-color: ${({ theme }) => theme.gray1};
          padding: 4px 14px 4px 8px;
          border-top-right-radius: 45px;
          border-bottom-right-radius: 45px;
          position: relative;

          @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
            font-size: 13px;
            width: 100%;
          }
        }

        .video-product {
          z-index: 4 !important;
        }

        .video-category {
          background-color: ${({ theme }) => theme.gray2};
          padding: 4px 14px 4px 16px;
          margin-left: -10px;
          z-index: 3 !important;

          @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
            margin-left: 0px;
          }
        }

        .video-subcategory {
          background-color: ${({ theme }) => theme.gray3};
          color: ${({ theme }) => theme.fontWhite};
          padding: 4px 8px 4px 16px;
          margin-left: -10px;
          z-index: 2 !important;

          @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
            margin-left: 0px;
          }
        }

        .video-post {
          background-color: ${({ theme }) => theme.gray4};
          color: ${({ theme }) => theme.gray1};
          padding: 4px 8px 4px 16px;
          margin-left: -10px;
          z-index: 1 !important;

          @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
            margin-left: 0px;
          }
        }
      }
    }
  }
`;
