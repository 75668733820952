import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-top: 16px;

  a {
    width: 100%;
    text-align: center;
  }

  button {
    align-self: flex-end;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 19px;
  }

  img:hover {
    cursor: pointer;
    filter: invert(40%) sepia(6%) saturate(233%) hue-rotate(187deg) brightness(95%) contrast(84%)
  }

  .c-products {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  .c-products-name {
    width: 200px;
    padding-left: 8px;
  }

  .c-products-actions {
    display: flex;
    gap: 8px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  .c-left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    flex: 1;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .c-right {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    flex: 2;
    width: 100%;

    .c-up, .c-down {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 8px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-wrap: wrap;
        flex-direction: column;
      }

      .l-left {
         flex: 3
       }

      .l-right {
         flex: 1
       }
    }

    .input-file {
      height: 100px;
    }
  }

  .c-input {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-self: flex-end;
      width: 100%;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
      }

      input, select {
        border: 1px solid ${({ theme }) => theme.grayLight};

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
          width: 100%;
        }
      }


    }

  .c-input-large {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;

      input, select {
        border: 1px solid ${({ theme }) => theme.grayLight};
        max-width: 100%;
      }
    }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  gap: 8px;
`;

export const TextArea = styled.textarea`
  height: 300px;
`;

export const Explanation = styled.div`
  font-size: 13px;
  margin-top: 16px;
  color: ${({ theme }) => theme.grayLight};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .info-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    img {
      width: 100px;
      height: 80px;
    }
  }

  .actions-container {
    .icon-trash {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
`;
