export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Cadastro de produto' : 'Product registration',
    labelName: language === 'pt-BR' ? 'Infos' : 'Infos',
    placeholderPt: language === 'pt-BR' ? 'Nome PT' : 'Name PT',
    placeholderEn: language === 'pt-BR' ? 'Nome EN' : 'Name EN',
    placeholderOrder: language === 'pt-BR' ? 'Ordem' : 'Order',
    textName: language === 'pt-BR' ? 'Nome' : 'Name',
    textColor: language === 'pt-BR' ? 'Cor' : 'Color',
    textActive: language === 'pt-BR' ? 'Ativo?' : 'Active?',
    textOrdering: language === 'pt-BR' ? 'Ordem' : 'Ordering',
    textType: language === 'pt-BR' ? 'Tipo' : 'Type',
    labelNamePt: language === 'pt-BR' ? 'Nome PT' : 'Name PT',
    labelNameEn: language === 'pt-BR' ? 'Nome EN' : 'Name EN',
    labelYes: language === 'pt-BR' ? 'Sim' : 'Yes',
    labelNo: language === 'pt-BR' ? 'Não' : 'No',

  };
}
