import styled from 'styled-components';

export const Container = styled.div`
  div {
    margin-bottom: 8px;
  }
`;

export const HeaderSquare = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.grayLight};
`;
