import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-top: 16px;

  a {
    width: 100%;
    text-align: center;
  }
`;

export const CommentItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;

  &.inactive {
    border-left: 2px solid ${({ theme }) => theme.red};
  }

  &.active {
    border-left: 2px solid ${({ theme }) => theme.green}
  }

  .infos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding-left: 16px;
  }

  .path {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
    }

    .content-comment {
      align-self: flex-start;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
        margin-bottom: 16px;
      }
    }

    .content-action {
      display: flex;
      align-items: center;
      gap: 16px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        align-self: flex-end;
      }

      .content-action-toogle{
        width: 20px;
        height: 20px;
      }

      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }

      img:hover {
        filter: invert(71%) sepia(2%) saturate(19%) hue-rotate(339deg) brightness(91%) contrast(87%);
      }
    }


  }
`;
