import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { filterArrOfValues, filterArrOfValuesByDate } from '../../../../../../../../helpers/filterArrayByFunnel';
import { DataContext } from '../../../../../context/Data';

import { Translate } from './translate';

const ConversionRateSessionsTrMid = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    googleAnalyticsByFunnelCurrent, googleAnalyticsByFunnelPrevious, googleAnalyticsByFunnelYoY, googleAnalyticsByFunnelPreviousComplete, googleAnalyticsByFunnelForecast,
  } = useContext(DataContext);

  const funnelFilter = '2';

  const objectOne = 'transactions';
  const objectTwo = 'session';

  const current = (Number(filterArrOfValues(googleAnalyticsByFunnelCurrent, funnelFilter)?.[objectOne])
                  / Number(filterArrOfValues(googleAnalyticsByFunnelCurrent, funnelFilter)?.[objectTwo])) || 0;

  const previous = (Number(filterArrOfValues(googleAnalyticsByFunnelPrevious, funnelFilter)?.[objectOne])
                   / Number(filterArrOfValues(googleAnalyticsByFunnelPrevious, funnelFilter)?.[objectTwo])) || 0;

  const yoy = (Number(filterArrOfValues(googleAnalyticsByFunnelYoY, funnelFilter)?.[objectOne])
              / Number(filterArrOfValues(googleAnalyticsByFunnelYoY, funnelFilter)?.[objectTwo])) || 0;

  const completeMonth = (Number(filterArrOfValues(googleAnalyticsByFunnelPreviousComplete, funnelFilter)?.[objectOne])
                        / Number(filterArrOfValues(googleAnalyticsByFunnelPreviousComplete, funnelFilter)?.[objectTwo])) || 0;

  const forecastDataFirst = filterArrOfValuesByDate(googleAnalyticsByFunnelForecast, funnelFilter)?.length > 0 && filterArrOfValuesByDate(googleAnalyticsByFunnelForecast, funnelFilter)?.map((data) => ({ date: data.date, value: Number(data[objectOne]) }));
  const forecastDataSecond = filterArrOfValuesByDate(googleAnalyticsByFunnelForecast, funnelFilter)?.length > 0 && filterArrOfValuesByDate(googleAnalyticsByFunnelForecast, funnelFilter)?.map((data) => ({ date: data.date, value: Number(data[objectTwo]) }));
  const forecastFirst = forecastDataFirst && (ForecastMonthly(forecastDataFirst) || 0);
  const forecastSecond = forecastDataFirst && (ForecastMonthly(forecastDataSecond) || 0);
  const forecast = forecastFirst / forecastSecond;

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta red';
    }
    return 'delta green';
  }

  return (
    <tr title={translate.tip}>
      {showLevelColumn ? <td>2nd</td> : null}
      {showSourceColumn ? <td>Calc</td> : null}
      <td className="l-mid">{translate.kpi}</td>
      <td>{numberFormat(current, 'percentage', 2)}</td>
      <td>{numberFormat(previous, 'percentage', 2)}</td>
      <td className={classNameAtrr(deltaNormal)}>
        {numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(yoy, 'percentage', 2)}</td>
      <td className={classNameAtrr(deltaYoY)}>
        {numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(forecast, 'percentage', 2)}</td>
      <td>{numberFormat(completeMonth, 'percentage', 2)}</td>
      <td className={classNameAtrr(deltaForecast)}>
        {numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

ConversionRateSessionsTrMid.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default ConversionRateSessionsTrMid;
