export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    desc1: language === 'pt-BR' ? 'Usuários convidados' : 'Guest users',
    desc2: language === 'pt-BR' ? 'Usuários Logados' : 'Logged in users',
    desc3: language === 'pt-BR' ? 'Usuários engajados' : 'Engaged users',
    desc4: language === 'pt-BR' ? 'Usuários não engajados ' : 'Unengaged users',
  };
}

/*
  desc1: language === 'pt-BR' ? 'Usuários convidados (Cadastrado na plataforma)' : 'Guest users (Registered on the platform)',
  desc2: language === 'pt-BR' ? 'Usuários Logados (Fez login)' : 'Logged in users (Logged in)',
  desc3: language === 'pt-BR' ? 'Usuários engajados (Marcou vídeo como concluído)' : 'Engaged users (Marked video as completed)',
  desc4: language === 'pt-BR' ? 'Usuários não engajados (Não marcou vídeo como concluído) ' : 'Unengaged users (Didn\'t mark video as complete)'

 */
