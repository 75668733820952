class UTM {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async add(name, urlValue, paramSelected) {
    if (!name || !urlValue || !paramSelected) throw new Error('Name, url and param are required');

    const response = await fetch(`${this.baseUrl}/utm`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({ name, value: urlValue, idUtmcategory: paramSelected }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(body.message);
    return body;
  }

  async getAllCategories() {
    const response = await fetch(`${this.baseUrl}/utmcategory`, {
      headers: {
        authorization: localStorage.getItem('token'),
      },
    });
    const body = await response.json();
    if (!response.ok) throw new Error(body.message);
    return body;
  }

  async getOne(id) {
    if (!id) throw Error('ID is required');

    const response = await fetch(`${this.baseUrl}/utm/${id}`, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();
    if (!response.ok) {
      throw new Error(response.message);
    }

    return body;
  }

  async getAllBYCategoryUTM(id) {
    if (!id) throw Error('ID is required');

    const response = await fetch(`${this.baseUrl}/utm/all/${id}`, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
    });
    const body = await response.json();
    if (!response.ok) throw new Error(body.message);
    return body;
  }

  async getAllByCategoryId(idParam) {
    if (!idParam) throw Error('ID is required');

    const response = await fetch(`${this.baseUrl}/utm/all/${idParam}`, {
      headers: {
        authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    });

    const body = await response.json();
    if (!response.ok) throw new Error(body.message);
    return body;
  }

  async getCountries() {
    const response = await fetch(`${this.baseUrl}/utmcountry`, {
      headers: {
        authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    });
    const body = await response.json();
    if (!response.ok) throw new Error(body.message);
    return body;
  }

  async getStates(countryId) {
    if (!countryId) throw Error('Country is required');

    const response = await fetch(`${this.baseUrl}/utmstates/${countryId}`, {
      headers: {
        authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    });
    const body = await response.json();
    if (!response.ok) throw new Error(body.message);
    return body;
  }

  async delete(idUTM) {
    if (!idUTM) throw Error('UTM is required');

    const response = await fetch(`${this.baseUrl}/utm/${idUTM}`, {
      method: 'DELETE',
      headers: {
        authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    });

    const body = await response.json();

    if (!response.ok) throw new Error(body.message);

    return body;
  }

  async update(idUTM, name, value) {
    if (!idUTM || !name || !value) throw Error('UTM , name and value are required');

    const response = await fetch(`${this.baseUrl}/utm/${idUTM}`, {
      method: 'PATCH',
      headers: {
        authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, value }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status}-${response.message}`);

    return body;
  }
}

export default new UTM();
