class LinkedInAds {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async getTotal(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign) {
    if (!accountName || !dateInit || !dateEnd) throw new Error('No data to fetch');

    const path = `${this.baseUrl}/linkedin-ads/get-total`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async getTotalOnly(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign) {
    if (!accountName || !dateInit || !dateEnd) throw new Error('No data to fetch');

    const path = `${this.baseUrl}/linkedin-ads/get-total`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async getTotalByDate(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign) {
    if (!accountName || !dateInit || !dateEnd) throw new Error('No data to fetch');

    const path = `${this.baseUrl}/linkedin-ads/get-total-by-date`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async getTotalByFunnel(accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign) {
    if (!accountName || !dateInit || !dateEnd) throw new Error('No data to fetch');

    const path = `${this.baseUrl}/linkedin-ads/get-total-by-funnel`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({
        accountName, dateInit, dateEnd, platform, region, strategy, channel, utmCampaign,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }
}

export default new LinkedInAds();
