import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 32px;

  div > small {
    color: ${({ theme }) => theme.grayLight};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
    flex-direction: column;
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;

  .c-checkbox__area {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
  }

  .c-checkbox__container {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 13px;
  }

  .c-checkbox__container input:hover,
  .c-checkbox__container label:hover {
    cursor: pointer;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border: 1px solid ${({ theme }) => theme.grayBackground};
  border-radius: 4px;

  thead > tr > td {
    font-weight: 500;
    background-color: ${({ theme }) => theme.grayBackground};
  }

  thead > tr > td,
  tbody > tr > td {
    font-size: 14px;
    padding: 8px;
  }

  tr:hover {
    background-color: ${({ theme }) => theme.grayBackground};
  }

  td {
    padding: 2px;
    min-width: 75px;
    position: relative;
  }

  td > div {
    top: 0px !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  td.delta {
    color: ${({ theme }) => theme.grayLight};
  }

  td.delta.red {
    color: ${({ theme }) => theme.red};
  }
  td.delta.green {
    color: ${({ theme }) => theme.green};
  }

  td.l-top {
    color: ${({ theme }) => theme.red};
  }

  td.l-mid {
    color: ${({ theme }) => theme.yellow};
  }

  td.l-lower {
    color: ${({ theme }) => theme.green};
  }

  td.l-return {
    color: ${({ theme }) => theme.blue};
  }

`;
