class VideosService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findAll() {
    const path = `${this.baseUrl}/videos`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async findById(id) {
    const path = `${this.baseUrl}/videos/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async store(video, files) {
    const path = `${this.baseUrl}/videos`;

    // Crie um objeto FormData
    const formData = new FormData();
    // Adicione os campos do vídeo ao FormData
    formData.append('titlePt', video.titlePt);
    formData.append('titleEn', video.titleEn);
    formData.append('typeVideo', video.typeVideo);
    formData.append('linkVideo', video.linkVideo);
    formData.append('descriptionPt', video.descriptionPt);
    formData.append('descriptionEn', video.descriptionEn);
    formData.append('allowTranscript', video.allowTranscript);
    formData.append('transcriptPt', video.transcriptPt);
    formData.append('transcriptEn', video.transcriptEn);
    formData.append('relationType', video.relationType);
    formData.append('categoryId', video.categoryId);
    formData.append('subCategoryId', video.subCategoryId);
    formData.append('postId', video.postId);

    for (let i = 0; i < files.length; i++) {
      formData.append('video-files', files[i]);
    }

    const response = await fetch(path, {
      method: 'POST',
      body: formData,
      headers: {
        authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async update(id, video, filesToDelete, files) {
    const path = `${this.baseUrl}/videos/${id}`;

    // Crie um objeto FormData
    const formData = new FormData();
    // Adicione os campos do vídeo ao FormData
    formData.append('titlePt', video.titlePt);
    formData.append('titleEn', video.titleEn);
    formData.append('typeVideo', video.typeVideo);
    formData.append('linkVideo', video.linkVideo);
    formData.append('descriptionPt', video.descriptionPt);
    formData.append('descriptionEn', video.descriptionEn);
    formData.append('allowTranscript', video.allowTranscript);
    formData.append('transcriptPt', video.transcriptPt);
    formData.append('transcriptEn', video.transcriptEn);
    formData.append('relationType', video.relationType);
    formData.append('categoryId', video.categoryId);
    formData.append('subCategoryId', video.subCategoryId);
    formData.append('postId', video.postId);
    formData.append('filesToDelete', JSON.stringify(filesToDelete));

    for (let i = 0; i < files.length; i++) {
      formData.append('video-files', files[i]);
    }

    const response = await fetch(path, {
      method: 'PATCH',
      body: formData,
      headers: {
        authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async delete(id) {
    const path = `${this.baseUrl}/videos/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'DELETE',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }
}

export default new VideosService();
