import React, { useEffect, useRef, useState } from 'react';
import IconHeart from '../../../styles/logos/icon_heart.svg';
import IconHeartFill from '../../../styles/logos/icon_heartFill.svg';
import VideoFavoriteService from '../../../services/VideosFavorites';
import toast from '../../../helpers/toast';
import { Icon } from './style';

export default function FavoriteIcon({ idVideo }) {
  const iconRef = useRef(null);
  const [videoIsFavorite, setVideoIsFavorite] = useState(false);
  const [videoInfo, setVideoInfo] = useState({});

  useEffect(() => {
    fillIcon();
  }, []);

  useEffect(() => {
    if (videoIsFavorite) {
      iconRef.current.setAttribute('src', IconHeartFill);
    } else {
      iconRef.current.setAttribute('src', IconHeart);
    }
  }, [videoIsFavorite]);

  async function fillIcon() {
    if (idVideo) {
      const isFavorite = await VideoFavoriteService.findOneByIdVideo(idVideo);
      if (isFavorite.length > 0) {
        setVideoIsFavorite(true);
        setVideoInfo(isFavorite[0]);
      } else {
        setVideoIsFavorite(false);
        setVideoInfo({});
      }
    }
  }

  async function handleToogleFavoriteDB() {
    if (videoIsFavorite) {
      try {
        await VideoFavoriteService.delete(videoInfo?.id);
        setVideoIsFavorite(false);
        toast('success', 'Deleted successfully');
        setVideoInfo({});
      } catch (error) {
        toast('danger', 'Error to delete from favorite');
      }
    } else {
      try {
        const { favorite } = await VideoFavoriteService.store(idVideo);
        setVideoIsFavorite(true);
        toast('success', 'Added favorite successfully');
        setVideoInfo(favorite[0]);
      } catch {
        toast('danger', 'Error to add favorites');
      }
    }
  }

  return (
    <Icon
      ref={iconRef}
      src={IconHeartFill}
      alt="Favoritos"
      title="Add to Favorites"
      onClick={handleToogleFavoriteDB}
    />
  );
}
