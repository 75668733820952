import React, {
  useState, useEffect, useMemo, useTransition,
} from 'react';
import UsersService from '../../../../services/Users';
import toast from '../../../../helpers/toast';
import { Translate } from './translate';
import ListUser from '../components/ListUser';

function Users() {
  const translate = Translate();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    try {
      const userList = await UsersService.getAll();
      setUsers(userList);
    } catch (err) {
      toast('danger', 'Users not found');
    }
  }

  function handleSearchTerm(e) {
    setSearchTerm(e.target.value.toLowerCase());
  }

  const usersListFiltered = useMemo(() => users?.filter((user) => {
    const listFiltered = user.first_name.toLowerCase().includes(searchTerm)
                   || user.last_name.toLowerCase().includes(searchTerm)
                   || user.email.toLowerCase().includes(searchTerm)
                   || user.profile.toLowerCase().includes(searchTerm)
                   || user.client_name?.toLowerCase().includes(searchTerm);
    return listFiltered;
  }));

  return (
    <ListUser
      pageTitle={`${translate.title}`}
      userList={users}
      userListFiltered={usersListFiltered}
      placeholderSearchTerm={translate.placeholderSearch}
      linkAddButton="/admin/users/add"
      linkToBackButton="/admin/users"
      handleSearchTerm={handleSearchTerm}
    />
  );
}

export default Users;
