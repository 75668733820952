export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    titlePage: language === 'pt-BR' ? 'Novo parâmetro' : 'New parameter',
    parameterLabel: language === 'pt-BR' ? 'Parâmetro' : 'Parameter',
    nameLabel: language === 'pt-BR' ? 'Nome' : 'Name',
    namePlaceholder: language === 'pt-BR' ? 'Insira um nome...' : 'Enter name....',
    urlValueLabel: language === 'pt-BR' ? 'Valor Url' : 'Url Value',
    urlPlaceholder: language === 'pt-BR' ? 'Insira o valor que aparecerá na URL.....' : 'Enter the value who will appear in the Url.....',
    nameHelperMessage: language === 'pt-BR'
      ? 'Nome que aparecerá no campo de escolha' : 'Name that will appear in the field of choice',
    urlValueHelperMessage: language === 'pt-BR'
      ? 'Valor que será inserido na URL' : 'Value that will be inserted in the URL',
    language,
  };
}
