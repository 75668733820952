import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import VideosService from '../../services/Videos';
import VideosAssetsService from '../../services/VideosAssets';
import CategoryProductService from '../../services/CategoryProduct';
import CategoryUsersService from '../../services/CategoryUsers';
import Loader from '../Loader';
import { Transcript } from './Transcript';
import { ButtonFinish } from './ButtonFinish';
import { Comments } from './Comments';
import { Files } from './Files';
import { Description } from './Description';
import { Ratings } from './Ratings';
import { VideoContainer } from './style';
import { Player } from './Player';
import { Translate } from './translate';
import { Images } from './Images';
import FavoriteIcon from './FavoritesIcon';

export function Video() {
  const translate = Translate();
  const { video } = useParams();
  const navigate = useNavigate();
  const [videoSelected, setVideoSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [imagesList, setImageList] = useState([]);
  const [filesList, setFilesList] = useState([]);

  useEffect(() => {
    async function init() {
      await getVideo();
      await getVideoAssets();
    }
    init();

    return () => {
      setVideoSelected(null);
      setImageList([]);
      setFilesList([]);
    };
  }, [video]);

  async function getVideo() {
    if (video !== 'null') {
      try {
        setIsLoading(true);
        const videoDb = await VideosService.findById(video);
        const isUserAllowedToAccess = await isUserAllowedToAccessThisContent({ categoryID: videoDb.belongs_to_category_id });
        if (!isUserAllowedToAccess) {
          navigate('/client/not-allowed');
        } else {
          setVideoSelected(videoDb);
        }
      } catch {
      } finally {
        setIsLoading(false);
      }
    } else {
      setVideoSelected(null);
      setIsLoading(false);
    }
  }

  async function getVideoAssets() {
    if (video !== 'null') {
      const assets = await VideosAssetsService.findAll(video);
      setImageList(assets.filter((video) => video.mymetype.includes('image')));
      setFilesList(assets.filter((video) => !video.mymetype.includes('image')));
    } else {
      setImageList([]);
      setFilesList([]);
    }
  }

  async function isUserAllowedToAccessThisContent({ categoryID }) {
    const categoryInfos = await CategoryProductService.findById(categoryID);
    if (categoryInfos.typeCategory === 'single') {
      const responseUserAuthorization = await CategoryUsersService.userAuthorization({ idCategory: categoryID });
      const { isAllowed } = responseUserAuthorization;
      return isAllowed;
    }
    return true;
  }

  return (
    <VideoContainer>
      {(video === 'null') && <p>{translate.language === 'pt-BR' ? 'Página em construção' : 'Page under construction'}</p>}
      {isLoading && <Loader loading={isLoading} />}
      {(!isLoading && !videoSelected && video !== 'null') && <p>{translate.language === 'pt-BR' ? 'Vídeo retirado do ar...' : 'Video deleted'}</p>}
      {(!isLoading && videoSelected) && (
        <div>

          {/* Title and favorite icon */}
          <div className="head">
            <h2>{translate.language === 'pt-BR' ? videoSelected.title_pt : videoSelected.title_en}</h2>
            <FavoriteIcon idVideo={videoSelected.id} />
          </div>

          {/* Player do vídeo */}
          {videoSelected.link_video.length > 0 && <Player video={videoSelected.link_video} />}

          {/* Ratting and Mark as complete btn */}
          <div className="video-actions-container">
            <Ratings idVideo={videoSelected.id} />
            <ButtonFinish idVideo={videoSelected.id} />
          </div>

          {/* Description */}
          {(videoSelected.description_pt.length === 0 && videoSelected.description_en.length === 0)
            ? null
            : <Description description={translate.language === 'pt-BR' ? videoSelected.description_pt : videoSelected.description_en} />}

          {/* Images list */}
          {imagesList?.length > 0 && <Images images={imagesList} />}

          {/* Files list */}
          {filesList?.length > 0 && <Files files={filesList} />}

          {/* Transcript */}
          {videoSelected.allow_transcript === 'Y'
            ? <Transcript transcript={translate.language === 'pt-BR' ? videoSelected.transcript_pt : videoSelected.transcript_en} />
            : null}

          {/* Comments */}
          <Comments idVideo={video} />
        </div>
      )}
    </VideoContainer>
  );
}
