import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { Link } from 'react-router-dom';
import FaEditIcon from '../../../../../styles/logos/FaEdit.svg';
import AddVideoIcon from '../../../../../styles/logos/add_video.svg';
import EditVideoIcon from '../../../../../styles/logos/edit_video.svg';
import ListOrderIcon from '../../../../../styles/logos/list_order.svg';
import TrashIcon from '../../../../../styles/logos/trash-alt.svg';
import IconDragDrop from '../../../../../styles/logos/icon_dragdrop.svg';
import { Badge } from '../../../../../components/Badge';
import { SquareContainer, TitleContainer } from './style';
import { Translate } from './translate';

export default function CategoryItem({ category, index, onDelete }) {
  const translate = Translate();

  return (
    <Draggable draggableId={category.id.toString()} index={index}>
      {(provided) => (
        <SquareContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <TitleContainer>
            <div className="c-square">
              <div className="c-square-name">
                <img className="c-icon-drag" src={IconDragDrop} alt="Drag and drop" />
                {category.namePt } | {category.nameEn }
              </div>
              <Badge>
                {translate.textSubCategory}
                {' '}
                {category.hasSubCategory}
              </Badge>
            </div>
            <div className="c-square-action">
              <input type="text" id="order" value={category.ordering} disabled />
              {(category.hasSubCategory === 'N' && category.videoID) && (
              <Link to={`/admin/videos/edit/${category.videoID}`} title="Edit Video">
                <img src={EditVideoIcon} alt="Edit User" />
              </Link>
              )}
              {(category.hasSubCategory === 'N' && !category.videoID) && (
              <Link to={`/admin/videos/add?type=category&title_pt=${category.namePt}&title_en=${category.nameEn}&product=${category.productID}&category=${category.id}`} title="Add Video">
                <img src={AddVideoIcon} alt="Edit User" />
              </Link>
              )}
              {(category.hasSubCategory === 'Y') && (
              <Link to={`/admin/sub-categories/by-category/${category.id}?name=${translate.language === 'pt-BR' ? category.namePt : category.nameEn}`} title="List SubCategories">
                <img src={ListOrderIcon} alt="List SubCategories" />
              </Link>
              )}
              <Link to={`/admin/categories/${category.id}`} title="Edit Category">
                <img src={FaEditIcon} alt="Edit Category" />
              </Link>
              <img className="icon-trash" onClick={() => onDelete(category.id)} title="Delete Category" src={TrashIcon} alt="Delete Category" />
            </div>
          </TitleContainer>
        </SquareContainer>
      )}
    </Draggable>
  );
}
