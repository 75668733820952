/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ModalImages } from '../../ModalImages';
import IconGrid from '../../../styles/logos/icon_grid.svg';
import IconGridFull from '../../../styles/logos/icon_grid_full.svg';
import IconCloseX from '../../../styles/logos/icon_close_x.svg';
import { Translate } from './translate';
import {
  Container, ImageList, ImageItem, ImageContainer, ImageModal, CloseModal,
} from './style';

export function Images({ images }) {
  const translate = Translate();
  const [list, setList] = useState(false);
  const [toogleModal, setToogleModal] = useState(false);

  function openCloseList() {
    setList((prevState) => !prevState);
  }

  function openCloseModal() {
    setToogleModal((prevState) => !prevState);
  }

  return (
    <>
      <ModalImages visible={toogleModal} onClose={openCloseModal}>
        <CloseModal onClick={openCloseModal}>
          <img src={IconCloseX} alt="Close" />
        </CloseModal>
        <Swiper
          style={{ width: '100%' }}
          slidesPerView={1}
          navigation
          pagination={{
            clickable: true,
          }}
        >
          {images.map((image) => (
            <SwiperSlide
              key={image.id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ImageModal src={image.url} alt={image.file_name} />
            </SwiperSlide>
          ))}
        </Swiper>
      </ModalImages>
      <Container>
        <div className="header-container">
          <h3>{translate.title}</h3>
          <img src={list ? IconGridFull : IconGrid} alt="grid" onClick={openCloseList} />
        </div>
        <ImageList $islist={list}>
          {images.map((image) => (
            <ImageItem key={image.id} $islist={list} onClick={openCloseModal}>
              <ImageContainer $backgroundImage={image.url} $islist={list}>
                <div className="image-container">
                  <div className="image-background" />
                  <div className="subtitle">
                    <span>{image.file_name}</span>
                  </div>
                </div>
              </ImageContainer>
            </ImageItem>
          ))}
        </ImageList>
      </Container>
    </>
  );
}


