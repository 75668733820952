import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import ClientsService from '../../../../services/Clients';
import TitlePage from '../../../../components/TitlePage';
import Square from '../../../../components/Square';
import { Badge } from '../../../../components/Badge';
import { ButtonLink } from '../../../../components/ButtonLink';
import FaEditIcon from '../../../../styles/logos/FaEdit.svg';
import FaUsers from '../../../../styles/logos/FaUsers.svg';
import toast from '../../../../helpers/toast';
import { Translate } from './translate';
import {
  Container, TitleContainer, SearchContainer,
} from './style';

function Clients() {
  const translate = Translate();
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getClients();
  }, []);

  async function getClients() {
    try {
      const clients = await ClientsService.getAll();
      setClients(clients);
    } catch (err) {
      toast('danger', 'Clients not found');
    }
  }

  function handleSearchTerm(e) {
    setSearchTerm(e.target.value.toLowerCase());
  }

  const clientsListFiltered = useMemo(() => clients?.filter((client) => (client.name.toLowerCase().includes(searchTerm) || client.id_client === Number(searchTerm))));

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>
        {clients
        && (
        <SearchContainer>
          <div className="c-search">
            <label htmlFor="">{translate.search}</label>
            <input type="text" name="" id="" placeholder={translate.placeholderSearch} onChange={handleSearchTerm} />
          </div>
          <div className="c-actions">
            <ButtonLink color="blue" to="add">{translate.labelButtonAddNew}</ButtonLink>
          </div>
        </SearchContainer>
        )}

        {!clients
          ? (<p>{translate.errorMsg}</p>)
          : (clientsListFiltered && clientsListFiltered?.map((client) => (
            <Square size="large" key={client.id}>
              <TitleContainer>
                <div className="c-client">
                  <div className="c-client-name">
                    {client.name}
                  </div>
                  <div className="c-client-info">
                    <Badge size="s">
                      {client.id_client ? client.id_client : 'No ID Client'}
                    </Badge>
                    <Badge size="s">
                      Ecommerce
                      |
                      {' '}
                      {client.is_ecommerce === 'Y' ? translate.yes : translate.no}
                    </Badge>
                    <Badge size="s">
                      {translate.typeClient}
                      {' '}
                      |
                      {' '}
                      {client.type_client}
                    </Badge>
                    <Badge size="s">
                      {translate.currency}
                      {' '}
                      |
                      {' '}
                      {client.currency}
                    </Badge>
                    <Badge size="s">
                      Language Tag
                      {' '}
                      |
                      {' '}
                      {client.language_tag}
                    </Badge>
                  </div>
                </div>
                <div className="c-client-actions" title="Edit Info">
                  <Link to={`/admin/clients/${client.id}`}>
                    <img src={FaEditIcon} alt="Edit" />
                  </Link>
                  <Link to={`/admin/users/client/${client.id}`} title="Users">
                    <img src={FaUsers} alt="Edit" />
                  </Link>
                </div>
              </TitleContainer>
            </Square>
          )))}
      </Container>
    </>
  );
}

export default Clients;
