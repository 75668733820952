import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 16px;
  padding: 20px;
  background-color: ${({ theme }) => theme.grayMenu};
  border-radius: 8px;
  width: 100%;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    img {
      cursor: pointer;
    }
  }
`;

export const ImageList = styled.div`
  display: flex;
  flex-direction: ${({ $islist }) => ($islist ? 'column' : 'row')};
  align-items: ${({ $islist }) => ($islist ? 'flex-start' : 'center')};
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
`;

export const ImageItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: ${({ $islist }) => ($islist ? '100%' : 'none')};
`;

export const ImageContainer = styled.div`
  width: ${({ $islist }) => ($islist ? '100%' : 'none')};

  .image-container {
    display: ${({ $islist }) => ($islist ? 'block' : 'inline-block')};
    cursor: pointer;
    width: ${({ $islist }) => ($islist ? '100%' : '210px')};
    height: ${({ $islist }) => ($islist ? '500px' : '150px')};
    border-radius: 8px;
    transition: 0.2s ease-in-out;
    position: relative;

    .image-background {
      ${({ $backgroundImage }) => $backgroundImage && css`
         background-image: url(${({ $backgroundImage }) => $backgroundImage});
      `}
      background-size: cover;
      background-position: center;
      width: inherit;
      height: inherit;
      border-radius: 8px;
      transition: transform 0.2s ease-in-out;
    }

    .subtitle {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s ease;
      border: 8px;
      border-radius: inherit;

      span {
        color: transparent;
        font-size: ${({ $islist }) => ($islist ? '16px' : '13px')};
        word-wrap: break-word;
        padding: 8px;
      }
    }

    &:hover .image-background,
    &:hover .subtitle,
    &:hover .subtitle span {
      transform: scale(1.02);
    }

    &:hover .subtitle {
      background-color: rgba(0,0,0,0.3);
    }

    &:hover .subtitle span {
      color: ${({ theme }) => theme.fontWhite};
    }
  }
`;

export const ImageModal = styled.img`
  max-width: 90%;
  max-height: 95%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const CloseModal = styled.div`
  font-weight: 500;
  margin-bottom: 16px;
  cursor: pointer;
  align-self: flex-end;
  background-color: ${({ theme }) => theme.grayBackground};
  padding: 8px;
  border-radius: 50%;
  transition: .2s ease-in-out;
  border: 1px solid ${({ theme }) => theme.grayLight};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.grayLight};
    color: ${({ theme }) => theme.grayBackground};
  }

  img {
    width: 15px;
  }
`;
