export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? 'Clicks Ratio' : 'Clicks Ratio',
    tip: language === 'pt-BR'
      ? 'Usuários Total / Cliques'
      : 'Total Users / Clicks',
  };
}
