import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  max-width: 415px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 149px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0px 24px;
  }

  .text {
    text-align: center;
    margin-top: 36px;
  }

  > a {
    /* text-decoration: none; */
  }
`;

export const Form = styled.form`
  margin-top: 24px;
  margin-bottom: 40px;
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  input, button {
    width: 100%;
  }

  button {
    margin-top: 24px;
    height: 50px;
  }
`;
