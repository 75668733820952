import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';

import { DataContext } from '../../../../../context/Data';

import { Translate } from './translate';

const ROITr = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    googleAnalyticsCurrent, googleAnalyticsPrevious, googleAnalyticsYoY, googleAnalyticsPreviousComplete, googleAnalyticsForecast,
    googleAdwordsCurrent, googleAdwordsPrevious, googleAdwordsYoY, googleAdwordsPreviousComplete, googleAdwordsForecast,
    googleAnalyticsOthersPlatformsCurrent, googleAnalyticsOthersPlatformsPrevious, googleAnalyticsOthersPlatformsYoY, googleAnalyticsOthersPlatformsPreviousComplete, googleAnalyticsOthersPlatformsForecast,
    metaAdsCurrent, metaAdsPrevious, metaAdsYoY, metaAdsPreviousComplete, metaAdsForecast,
    linkedinAdsCurrent, linkedinAdsPrevious, linkedinAdsYoY, linkedinAdsPreviousComplete, linkedinAdsForecast,
  } = useContext(DataContext);

  const objectDataFirst = 'revenue';
  const objectDataSecond = 'cost';

  // Current Date
  const currentDataFirst = Number(googleAnalyticsCurrent?.[objectDataFirst]) || 0;
  const currentDataSecond = (Number(googleAdwordsCurrent?.[objectDataSecond]) || 0)
                            + (Number(metaAdsCurrent?.[objectDataSecond]) || 0)
                            + (Number(linkedinAdsCurrent?.[objectDataSecond]) || 0)
                            + (Number(googleAnalyticsOthersPlatformsCurrent?.[objectDataSecond]) || 0);
  const current = accountKPI(currentDataFirst, currentDataSecond);

  // Previous Date
  const previousDataFirst = Number(googleAnalyticsPrevious?.[objectDataFirst]) || 0;
  const previousDataSecond = (Number(googleAdwordsPrevious?.[objectDataSecond]) || 0)
                            + (Number(metaAdsPrevious?.[objectDataSecond]) || 0)
                            + (Number(linkedinAdsPrevious?.[objectDataSecond]) || 0)
                            + (Number(googleAnalyticsOthersPlatformsPrevious?.[objectDataSecond]) || 0);
  const previous = accountKPI(previousDataFirst, previousDataSecond);

  // YoY
  const yoyDataFirst = Number(googleAnalyticsYoY?.[objectDataFirst]) || 0;
  const yoyDataSecond = (Number(googleAdwordsYoY?.[objectDataSecond]) || 0)
                        + (Number(metaAdsYoY?.[objectDataSecond]) || 0)
                        + (Number(linkedinAdsYoY?.[objectDataSecond]) || 0)
                        + (Number(googleAnalyticsOthersPlatformsYoY?.[objectDataSecond]) || 0);
  const yoy = accountKPI(yoyDataFirst, yoyDataSecond);

  // Complete Month
  const completeMonthDataFirst = Number(googleAnalyticsPreviousComplete?.[objectDataFirst]) || 0;
  const completeMonthDataSecond = (Number(googleAdwordsPreviousComplete?.[objectDataSecond]) || 0)
                        + (Number(metaAdsPreviousComplete?.[objectDataSecond]) || 0)
                        + (Number(linkedinAdsPreviousComplete?.[objectDataSecond]) || 0)
                        + (Number(googleAnalyticsOthersPlatformsPreviousComplete?.[objectDataSecond]) || 0);
  const completeMonth = accountKPI(completeMonthDataFirst, completeMonthDataSecond);

  // Forecast
  const forecastGoogleAnalyticsDataFirst = googleAnalyticsForecast?.length > 0 && googleAnalyticsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataFirst]) }));

  const forecastGoogleAdwordsDataSecond = googleAdwordsForecast?.length > 0 && googleAdwordsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataSecond]) }));
  const forecastMetaAdsDataSecond = metaAdsForecast?.length > 0 && metaAdsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataSecond]) }));
  const forecastLinkedinAdsDataSecond = linkedinAdsForecast?.length > 0 && linkedinAdsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataSecond]) }));
  const forecastGoogleAnalyticsOthersPlatformsDataSecond = googleAnalyticsOthersPlatformsForecast?.length > 0 && googleAnalyticsOthersPlatformsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataSecond]) }));

  const forecastDataFirst = (forecastGoogleAnalyticsDataFirst && (ForecastMonthly(forecastGoogleAnalyticsDataFirst) || 0));
  const forecastDataSecond = (forecastGoogleAdwordsDataSecond && (ForecastMonthly(forecastGoogleAdwordsDataSecond) || 0))
                              + (forecastMetaAdsDataSecond && (ForecastMonthly(forecastMetaAdsDataSecond) || 0))
                              + (forecastLinkedinAdsDataSecond && (ForecastMonthly(forecastLinkedinAdsDataSecond) || 0))
                              + (forecastLinkedinAdsDataSecond && (ForecastMonthly(forecastGoogleAnalyticsOthersPlatformsDataSecond) || 0));

  const forecast = accountKPI(forecastDataFirst, forecastDataSecond);

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function accountKPI(num1, num2) {
    return num1 / num2;
  }

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta red';
    }
    return 'delta green';
  }

  return (
    <tr title={translate.tip}>
      {showLevelColumn ? <td>1st & 2nd </td> : null}
      {showSourceColumn ? <td>Calc</td> : null}
      <td>ROAS</td>
      <td>{numberFormat(current, 'number', 1)}</td>
      <td>{numberFormat(previous, 'number', 1)}</td>
      <td className={classNameAtrr(deltaNormal)}>
        {numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(yoy, 'number', 1)}</td>
      <td className={classNameAtrr(deltaYoY)}>
        {numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(forecast, 'number', 1)}</td>
      <td>{numberFormat(completeMonth, 'number', 1)}</td>
      <td className={classNameAtrr(deltaForecast)}>
        {numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

ROITr.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default ROITr;
