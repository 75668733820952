class VideosCommentService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findOneByIdVideo(idVideo) {
    const path = `${this.baseUrl}/video-favorites/${idVideo}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async findAllByUser() {
    const path = `${this.baseUrl}/video-favorites`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async delete(id) {
    const path = `${this.baseUrl}/video-favorites/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'DELETE',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async store(idVideo) {
    const path = `${this.baseUrl}/video-favorites`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({ idVideo }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }
}

export default new VideosCommentService();
