class TrackerService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async getUserInfo() {
    const path = `${this.baseUrl}/tracker/user-info`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async getUserInfoMonth() {
    const path = `${this.baseUrl}/tracker/user-info/modules-completed`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async getUserInfoVideosWatched() {
    const path = `${this.baseUrl}/tracker/user-info/videos-watched`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async getCompanyInfo() {
    const path = `${this.baseUrl}/tracker/company-info`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async getCompanyInfoByUserMonth() {
    const path = `${this.baseUrl}/tracker/company-info/user`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async getCompanyInfoVideosWatchedByUser({ idUser }) {
    const path = `${this.baseUrl}/tracker/company-info/videos-watched/user/${idUser}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async getCompanyInfoProductsStatusByUser({ idUser }) {
    const path = `${this.baseUrl}/tracker/company-info/modules-completed/user/${idUser}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }
}

export default new TrackerService();
