class SubCategoryProductService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findAll(id, type) {
    let query = '';

    if (id && type) {
      if (type === 'category') {
        query = `?idCategory=${id}`;
      }

      if (type === 'product') {
        query = `?idProduct=${id}`;
      }
    }

    const path = `${this.baseUrl}/subcategories-products${query}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async findAllByProductAndCategory(idProduct, idCategory) {
    const path = `${this.baseUrl}/subcategories-products?idProductCategory=${idProduct}&idCategory=${idCategory}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async findById(id) {
    const path = `${this.baseUrl}/subcategories-products/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async store({
    namePt, nameEn, ordering, hasPosts, slug, idProductCategory,
  }) {
    const path = `${this.baseUrl}/subcategories-products`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        namePt, nameEn, ordering, hasPosts, slug, idProductCategory,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async update(id, {
    namePt, nameEn, ordering, hasPosts, slug, idProductCategory,
  }) {
    const path = `${this.baseUrl}/subcategories-products/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify({
        namePt, nameEn, ordering, hasPosts, slug, idProductCategory,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body}`);
    }

    return body;
  }

  async updateOrdering(subCategoriesList) {
    const path = `${this.baseUrl}/subcategories-product/ordering`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify(subCategoriesList),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async delete(id) {
    const path = `${this.baseUrl}/subcategories-products/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'DELETE',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }
}

export default new SubCategoryProductService();
