import { useContext } from 'react';
import DatePicker from 'react-datepicker';
import { PropTypes } from 'prop-types';

import { DateContext } from '../../context/DateContext';
import { Button } from '../Button';

import MenuSelect from '../../styles/logos/menu_select.svg';
import * as S from './style';
import { Translate } from './translate';

function DateChoiceInput() {
  const translate = Translate();

  const {
    selectedData,
    selectedValue,
    listOfDates,
    isCustomPeriod,
    showSelectCustomPeriods,
    disableSelectDates,
    startDateCurrent,
    endDateCurrent,
    startDatePrev,
    endDatePrev,
    setDateRangeCurrent,
    setDateRangePrev,
    handleCustomPeriod,
    disableApplyButtonCustomPeriod,
  } = useContext(DateContext);

  return (
    <S.Container>
      {isCustomPeriod && (
        <S.DateContainer>
          <div className="c-date__container">
            <div className="c-date__picker">
              <label htmlFor="date">{translate.currentLabel}</label>
              <DatePicker
                selectsRange
                startDate={startDateCurrent}
                endDate={endDateCurrent}
                onChange={(update) => {
                  setDateRangeCurrent(update);
                }}
                isClearable
              />
            </div>
            <div className="c-date__picker">
              <label htmlFor="date">{translate.prevLabel}</label>
              <DatePicker
                selectsRange
                startDate={startDatePrev}
                endDate={endDatePrev}
                onChange={(update) => {
                  setDateRangePrev(update);
                }}
                isClearable
              />
            </div>
          </div>
          <div className="btns-container">
            <Button color="blue" type="button" onClick={handleCustomPeriod} disabled={disableApplyButtonCustomPeriod}>{translate.apply}</Button>
            <Button color="ghost" type="button" onClick={showSelectCustomPeriods}>
              <img src={MenuSelect} alt="Back to select" />
            </Button>
          </div>
        </S.DateContainer>
      )}
      {!isCustomPeriod && (
        <>
          <div id="category-select">
            <label htmlFor="options-view-button">{translate.date}</label>
            <input type="checkbox" id="options-view-button" disabled={disableSelectDates} />

            <div id="select-button">
              <div id="selected-value">{selectedValue}</div>
            </div>
          </div>

          <ul id="options" onClick={selectedData}>
            {listOfDates.map((date) => (
              <li className="option" key={date.date}>
                <div className="option-star-container">
                  <span className="option-star">
                    {date.favorite && (
                      '⭐'
                    )}
                  </span>
                </div>
                <div className="option-text-container">
                  <input
                    type="radio"
                    name="category"
                    value={date.date}
                    data-label={date.date}
                  />
                  <span className="option-description">
                    {date.date}
                  </span>
                  {date.showDatesOnDescription && (
                    <small>
                      {date.init?.description}
                      -
                      {date.end?.description}
                      {' '}
                      vs
                      {' '}
                      {date.initPrev?.description}
                      -
                      {date.endPrev?.description}
                    </small>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </S.Container>
  );
}

export default DateChoiceInput;
