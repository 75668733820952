import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.grayDark};
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  button {
    width: 20%;
    align-self: flex-end;
    margin-top: 8px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }
  }

  .comment-input {
    display: flex;
    gap: 8px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
    }

    textarea {
      width: 100%;
      height: 100px;
      resize: none;
    }
  }
`;

export const CommentItem = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.grayMenu};
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
    flex-direction: column;
    align-items: flex-start;
  }

  .info {
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${({ theme }) => theme.grayDark};
    padding-right: 16px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
      border: none;
      padding: 0;
      margin-bottom: 8px;
    }

    .date {
      margin-top: 4px;
      font-size: 14px;
      font-style: italic;
      color: ${({ theme }) => theme.grayDark};
    }
  }

  .description {
    padding-left: 16px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
      padding: 0;
    }
  }
`;
