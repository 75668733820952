import styled, { css } from 'styled-components';

const containerVariants = {
  default: css`
    background-color: ${({ theme }) => theme.grayLight};
  `,
  success: css`
    background-color: ${({ theme }) => theme.green};
  `,
  danger: css`
    background-color: ${({ theme }) => theme.red};
  `,
};

export const Container = styled.div`
  padding: 16px 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  margin-top: 8px;

  ${({ type }) => containerVariants[type]}

  svg {
    cursor: pointer;
  }
`;
