export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? 'T. Conversão (S) - Top' : 'Conv. Rate (S) - Top',
    tip: language === 'pt-BR'
      ? 'Transações - Top / Sessões - Top'
      : 'Transactions - Top / Sessions - Top',
  };
}
