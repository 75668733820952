export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    userView: language === 'pt-BR' ? 'Vista' : 'User view',
    userViewTip: language === 'pt-BR' ? 'Visões segmentadas dos dados' : 'Segmented views of data',
    platform: language === 'pt-BR' ? 'Plataforma' : 'Platform',
    platformTip: language === 'pt-BR' ? 'Plataforma de Veiculação de Mídia' : 'Media Delivery Platform',
    metaIdTip: language === 'pt-BR' ? 'Conta/ID de plataforma Meta' : 'Meta Account/Platform ID',
    region: language === 'pt-BR' ? 'Região' : 'Region',
    regionTip: language === 'pt-BR' ? 'Regiões geográficas segmentadas em suas campanhas' : 'Targeted geographic regions in your campaigns',
    strategy: language === 'pt-BR' ? 'Estratégia' : 'Strategy',
    strategyTip: language === 'pt-BR' ? 'Tipo de mídia de divulgação' : 'Type of dissemination media',
    channel: language === 'pt-BR' ? 'Canal' : 'Channel',
    channelTip: language === 'pt-BR' ? 'Canal de Marketing' : 'Marketing Channel',
    utmCampaignTip: language === 'pt-BR' ? 'UTM configurada nas campanhas' : 'UTM configured in campaigns',
    legendFirstLevel: language === 'pt-BR' ? '1st Level - Mídia Data' : '1st Level - Media Data',
    labelOrigin: language === 'pt-BR' ? 'Origem' : 'Origin',
    apply: language === 'pt-BR' ? 'Aplicar' : 'Apply',
  };
}
