import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0px 16px;

  span {
    font-size: 13px;
    color: ${({ theme }) => theme.grayLight};
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.grayDark};
  border-radius: 10px;
  overflow: hidden;
`;

export const ProgressBarFiller = styled.div`
  height: 7px;
  width: ${({ value }) => (value || 0)}%;
  background-color: ${({ color }) => (color || '#FFF')};
  transition: width 0.2s ease-in;
  border-radius: 10px 0 0 10px;
`;
