import React, { useState } from 'react';
import AdminRoutes from '../../routes/AdminRoutes';
import SideBarAdmin from '../../components/SidebarAdmin';
import Menu from '../../components/Menu';
import { MainContainer } from '../../components/MainContainer';
import * as S from '../IndexUser/style';

function IndexUser() {
  const [toogle, setToogle] = useState(true);

  function handleToogleMenu() {
    setToogle(!toogle);
  }

  return (
    <>
      <SideBarAdmin toogle={toogle} />
      <S.PageContainer $toogle={toogle}>
        <Menu onHandleToogle={handleToogleMenu} toogle={toogle} />
        <MainContainer>
          <AdminRoutes />
        </MainContainer>
      </S.PageContainer>
    </>
  );
}

export default IndexUser;
