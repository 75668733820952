import React, { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { Container, ContainerRating } from './style';
import { Translate } from './translate';

export function ModalContent({
  color, rating, setRating, comment, setComment,
}) {
  const translate = Translate();

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
  };

  function handleComment(event) {
    setComment(event.target.value);
  }

  return (
    <Container>
      <ContainerRating>
        <Rating
          onClick={handleRating}
          transition
          size={30}
          titleSeparator=""
          allowFraction
          fillClassName="filled-icons"
          fillColor={color}
        />
        <span className="score">{rating !== 0 && `(${rating})`}</span>
      </ContainerRating>
      <div className="form-group">
        <label htmlFor="rating">{translate.label}:</label>
        <textarea
          value={comment}
          onChange={handleComment}
          id="rating"
          placeholder={translate.placeholder}
        />
      </div>
    </Container>
  );
}
