export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    rating: language === 'pt-BR' ? 'avaliação' : 'rating',
    ratings: language === 'pt-BR' ? 'avaliações' : 'ratings',
    titleModal: language === 'pt-BR' ? 'Avaliação' : 'Rating',
    submitRating: language === 'pt-BR' ? 'Avaliar' : 'Rate',
    language,
  };
}
