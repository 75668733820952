import React from 'react';
import { PropTypes } from 'prop-types';

import { Container } from './styles';
import { ButtonLink } from '../ButtonLink';
import { Button } from '../Button';
import { Translate } from './translate';

function ButtonSaveCancel({
  onSave = () => {}, goToWhenCancel, disabled, labelSave = Translate().labelSave, labelCancel = Translate().labelCancel, smallText = '',
}) {
  return (
    <Container>
      <div>
        <Button color="green" onClick={() => onSave()} disabled={disabled}>
          {' '}
          {labelSave}
          {' '}
        </Button>
        <ButtonLink color="ghost" to={goToWhenCancel}>
          {' '}
          {labelCancel}
          {' '}
        </ButtonLink>
      </div>
      <small>{smallText}</small>
    </Container>
  );
}

ButtonSaveCancel.propTypes = {
  onSave: PropTypes.func,
  goToWhenCancel: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  smallText: PropTypes.string,
  labelSave: PropTypes.string,
  labelCancel: PropTypes.string,
};

export default ButtonSaveCancel;
