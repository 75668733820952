import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import { Button } from '../../../components/Button';
import LogoShifters from '../../../styles/logos/shiftersy.png';
import Footer from '../Footer';
import * as S from './styles';
import { Translate } from './translate';

function Form() {
  const translate = Translate();
  const { handleLogin } = useContext(AuthContext);
  const [form, setForm] = useState({ email: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);

  function handleInput(event) {
    setForm((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }

  async function onSubmit(event) {
    setIsLoading(true);
    event.preventDefault();
    handleLogin(form);
  }

  return (
    <S.Wrapper>
      <S.Container>
        <img src={LogoShifters} alt="Shifters" />
        <form onSubmit={onSubmit}>
          <S.FormItem>
            <label>E-mail</label>
            <input type="email" onChange={handleInput} name="email" value={form.email} autoComplete="true" />
          </S.FormItem>
          <S.FormItem>
            <label>{translate.password}</label>
            <input type="password" onChange={handleInput} name="password" value={form.password} autoComplete="true" />
          </S.FormItem>
          <S.FormItem className="form-forgotpass">
            <Link to="/recovery-password" className="form-forgotpass">{translate.forgotPassword}</Link>
          </S.FormItem>
          <S.FormItem>
            <Button color="yellow" type="submit">
              {!isLoading ? translate.btn : 'Loading...'}
            </Button>
          </S.FormItem>
        </form>
      </S.Container>
      <Footer />
    </S.Wrapper>
  );
}

export default Form;
