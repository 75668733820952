/* eslint-disable no-tabs */
/* eslint-disable no-sparse-arrays */
export const languageTag = [
  { value: 'ar-SA',	language: 'Arabic', region: 'Saudi Arabia' },
  { value: 'bn-BD',	language: 'Bangla', region: 'Bangladesh' },
  { value: 'bn-IN',	language: 'Bangla', region: 'India' },
  { value: 'cs-CZ',	language: 'Czech', region: 'Czech Republic' },
  { value: 'da-DK',	language: 'Danish', region: 'Denmark' },
  { value: 'de-AT',	language: 'German', region: 'Austria	' },
  { value: 'de-CH',	language: 'German', region: 'Switzerland' },
  { value: 'de-DE',	language: 'German', region: 'Germany' },
  { value: 'el-GR',	language: 'Greek', region: 'Greece' },
  { value: 'en-AU',	language: 'English', region: 'Australia' },
  { value: 'en-CA',	language: 'English', region: 'Canada' },
  { value: 'en-GB',	language: 'English', region: 'United Kingdom' },
  { value: 'en-IE',	language: 'English', region: 'Ireland' },
  { value: 'en-IN',	language: 'English', region: 'India' },
  { value: 'en-NZ',	language: 'English', region: 'New Zealand' },,
  { value: 'en-US',	language: 'English', region: 'United States' },
  { value: 'en-ZA',	language: 'English', region: 'South Africa' },
  { value: 'es-AR',	language: 'Spanish', region: 'Argentina' },
  { value: 'es-CL',	language: 'Spanish', region: 'Chile' },
  { value: 'es-CO',	language: 'Spanish', region: 'Columbia' },
  { value: 'es-ES',	language: 'Spanish', region: 'Spain' },
  { value: 'es-MX',	language: 'Spanish', region: 'Mexico' },
  { value: 'es-US',	language: 'Spanish', region: 'United States' },
  { value: 'fi-FI',	language: 'Finnish', region: 'Finland	Finnish' },
  { value: 'fr-BE',	language: 'French', region: 'Belgium' },
  { value: 'fr-CA',	language: 'French', region: 'Canada' },
  { value: 'fr-CH',	language: 'French', region: 'Switzerland' },
  { value: 'fr-FR',	language: 'French', region: 'France' },
  { value: 'he-IL',	language: 'Hebrew', region: 'Israel' },
  { value: 'hi-IN',	language: 'Hindi', region: 'India' },
  { value: 'hu-HU',	language: 'Hungarian', region: 'Hungary' },
  { value: 'id-ID',	language: 'Indonesian', region: 'Indonesia' },
  { value: 'it-CH',	language: 'Italian', region: 'Switzerland' },
  { value: 'it-IT',	language: 'Italian', region: 'Italy' },
  { value: 'ja-JP',	language: 'Japanese', region: 'Japan' },
  { value: 'ko-KR',	language: 'Korean', region: 'Republic of Korea' },
  { value: 'nl-BE',	language: 'Dutch', region: 'Belgium' },
  { value: 'nl-NL',	language: 'Dutch', region: 'The Netherlands	Standard Dutch' },
  { value: 'no-NO',	language: 'Norwegian', region: 'Norway' },
  { value: 'pl-PL',	language: 'Polish', region: 'Poland' },
  { value: 'pt-BR',	language: 'Portuguese', region: 'Brazil' },
  { value: 'pt-PT',	language: 'Portuguese', region: 'Portugal' },
  { value: 'ro-RO',	language: 'Romanian', region: 'Romania' },
  { value: 'ru-RU',	language: 'Russian', region: 'Russian Federation' },
  { value: 'sk-SK',	language: 'Slovak', region: 'Slovakia' },
  { value: 'sv-SE',	language: 'Swedish', region: 'Sweden' },
  { value: 'ta-IN',	language: 'Tamil', region: 'India' },
  { value: 'ta-LK',	language: 'Tamil', region: 'Sri Lanka' },
  { value: 'th-TH',	language: 'Thai', region: 'Thailand' },
  { value: 'tr-TR',	language: 'Turkish', region: 'Turkey' },
  { value: 'zh-CN',	language: 'Chinese', region: 'China' },
  { value: 'zh-HK',	language: 'Chinese', region: 'Hond Kong' },
  { value: 'zh-TW',	language: 'Chinese', region: 'Taiwan' },
];
