import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  overflow: hidden;
  height: 100vh;

  @media(max-width: ${(props) => props.theme.breakpoints.smartphone}) {
    padding: 0px 20px;
  }
`;
