import React, {
  createContext, useEffect, useState, useContext, useMemo,
} from 'react';
import { PropTypes } from 'prop-types';
import MetaAdsServiceService from '../../../../../services/MetaAds';
import GoogleAnalitycsService from '../../../../../services/GoogleAnalitycs';
import LeadsGoogleAnalitycsService from '../../../../../services/LeadsGoogleAnalitycs';
import { AuthContext } from '../../../../../context/AuthContext';

const FilterContext = createContext();

function FilterProvider({ children }) {
  const { accountNames } = useContext(AuthContext);

  // Filters
  const [accountName, setAccountName] = useState('');

  const [platform, setPlatform] = useState('');
  const [metaId, setMetaId] = useState('');
  const [region, setRegion] = useState('');
  const [strategy, setStrategy] = useState('');
  const [channel, setChannel] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [statusVtex, setStatusVtex] = useState('Payment-Pending, Payment-Approved, Ready-for-Handling, Handling, Invoiced, Invoice');
  const [originVtex, setOriginVtex] = useState('');

  const [accountNameApplied, setAccountNameApplied] = useState(null);
  const [platformApplied, setPlatformApplied] = useState(null);
  const [metaIdApplied, setMetaIdApplied] = useState(null);
  const [regionApplied, setRegionApplied] = useState(null);
  const [strategyApplied, setStrategyApplied] = useState(null);
  const [channelApplied, setChannelApplied] = useState(null);
  const [utmCampaignApplied, setUtmCampaignApplied] = useState(null);
  const [statusVtexApplied, setStatusVtexApplied] = useState('Payment-Pending, Payment-Approved, Ready-for-Handling, Handling, Invoiced, Invoice');
  const [originVtexApplied, setOriginVtexApplied] = useState(null);
  const [leadType, setLeadType] = useState(null);

  const [filterSecondLevel, setFilterSecondLevel] = useState([]);

  // Lists
  const [metaIdList, setMetaIdList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [strategyList, setStrategyList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [utmCampaignList, setUtmCampaingList] = useState([]);
  const [leadsTypeList, setLeadsTypeList] = useState([]);

  // Loaders
  const [isLoadingMetaList, setIsLoadingMetaList] = useState(false);
  const [isLoadingLeadsTypeList, setisLoadingLeadsTypeList] = useState(false);
  const [isLoadingGAData, setIsLoadingGAData] = useState(false);

  useEffect(() => {
    handleAccountName();
  }, []);

  useEffect(() => {
    async function fecthData() {
      await getFilters();
    }
    fecthData();
    return () => {};
  }, [accountName]);

  useEffect(() => {
    handleFiltersSecondLevelWhenHandlingInputs();
  }, [platform, region, strategy, channel, utmCampaign]);

  function handleAccountName() {
    const accountName = accountNames;
    if (accountName.length > 0 && accountName) {
      setAccountName(accountName[0]);
    }
  }

  async function getFilters() {
    const [metasIds, leadsType, filterSecondLevel] = await Promise.allSettled([mountListFromMetaId(), mountListLeadsType(), mountFiltersSecondLevel()]);
    setMetaIdList(metasIds?.value);
    setLeadsTypeList(leadsType?.value);
    setRegionList(filterSecondLevel?.value?.regionList);
    setStrategyList(filterSecondLevel?.value?.strategyList);
    setChannelList(filterSecondLevel?.value?.channelList);
    setUtmCampaingList(filterSecondLevel?.value?.utmCampaingList);
  }

  async function getFiltersSecondLevel() {
    if (accountName) {
      const filters = await GoogleAnalitycsService.getFilters(accountName);
      return filters;
    }
  }

  async function mountListFromMetaId() {
    try {
      setIsLoadingMetaList(true);
      if (accountName) {
        const metasIds = await MetaAdsServiceService.getAccounts(accountName);
        return metasIds;
      }
    } finally {
      setIsLoadingMetaList(false);
    }
  }

  async function mountListLeadsType() {
    try {
      setisLoadingLeadsTypeList(true);
      if (accountName) {
        const leadsType = await LeadsGoogleAnalitycsService.getLeadsTypes(accountName);
        return leadsType;
      }
    } finally {
      setisLoadingLeadsTypeList(false);
    }
  }

  async function mountFiltersSecondLevel() {
    try {
      setIsLoadingGAData(true);
      if (accountName) {
        const data = await getFiltersSecondLevel();
        setFilterSecondLevel(data);

        const regionList = [...new Set(data.map((data) => data.region))];
        const strategyList = [...new Set(data.map((data) => data.strategy))];
        const channelList = [...new Set(data.map((data) => data.channel))];
        const utmCampaingList = [...new Set(data.map((data) => data.utm_campaign))];

        return {
          regionList, strategyList, channelList, utmCampaingList,
        };
      }
    } finally {
      setIsLoadingGAData(false);
    }
  }

  function handleFiltersSecondLevelWhenHandlingInputs() {
    const datas = filterSecondLevel;
    const datafiltered = datas?.filter((data) => data.platform.includes(platform)
                                                && data.region.includes(region)
                                                && data.strategy.includes(strategy)
                                                && data.channel.includes(channel)
                                                && data.utm_campaign.includes(utmCampaign));

    const regionList = [...new Set(datafiltered.map((data) => data.region))];
    const strategyList = [...new Set(datafiltered.map((data) => data.strategy))];
    const channelList = [...new Set(datafiltered.map((data) => data.channel))];
    const utmCampaingList = [...new Set(datafiltered.map((data) => data.utm_campaign))];
    setRegionList(regionList);
    setStrategyList(strategyList);
    setChannelList(channelList);
    setUtmCampaingList(utmCampaingList);
  }

  const contextValues = useMemo(() => ({
    accountName,
    setAccountName,
    setAccountNameApplied,
    accountNameApplied,
    platform,
    setPlatform,
    platformApplied,
    setPlatformApplied,
    metaId,
    setMetaId,
    metaIdApplied,
    setMetaIdApplied,
    metaIdList,
    region,
    setRegion,
    regionApplied,
    setRegionApplied,
    regionList,
    strategy,
    setStrategy,
    strategyApplied,
    setStrategyApplied,
    strategyList,
    channel,
    setChannel,
    channelApplied,
    setChannelApplied,
    channelList,
    utmCampaign,
    utmCampaignApplied,
    setUtmCampaign,
    setUtmCampaignApplied,
    statusVtex,
    setStatusVtex,
    statusVtexApplied,
    setStatusVtexApplied,
    originVtex,
    setOriginVtex,
    originVtexApplied,
    setOriginVtexApplied,
    utmCampaignList,
    leadType,
    setLeadType,
    leadsTypeList,
    isLoadingMetaList,
    isLoadingGAData,
    isLoadingLeadsTypeList,
  }), [
    accountName,
    setAccountName,
    setAccountNameApplied,
    accountNameApplied,
    platform,
    setPlatform,
    platformApplied,
    setPlatformApplied,
    metaId,
    setMetaId,
    metaIdApplied,
    setMetaIdApplied,
    metaIdList,
    region,
    setRegion,
    regionApplied,
    setRegionApplied,
    regionList,
    strategy,
    setStrategy,
    strategyApplied,
    setStrategyApplied,
    strategyList,
    channel,
    setChannel,
    channelApplied,
    setChannelApplied,
    channelList,
    utmCampaign,
    utmCampaignApplied,
    setUtmCampaign,
    setUtmCampaignApplied,
    statusVtex,
    setStatusVtex,
    statusVtexApplied,
    setStatusVtexApplied,
    originVtex,
    setOriginVtex,
    originVtexApplied,
    setOriginVtexApplied,
    utmCampaignList,
    leadType,
    setLeadType,
    leadsTypeList,
    isLoadingMetaList,
    isLoadingGAData,
    isLoadingLeadsTypeList,
  ]);

  return (
    <FilterContext.Provider value={contextValues}>
      {children}
    </FilterContext.Provider>
  );
}

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FilterContext, FilterProvider };
