import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import TitlePage from '../../../components/TitlePage';
import Loader from '../../../components/Loader';
import { NoDataMessage } from '../../../components/NoDataMessage';
import Modal from '../../../components/Modal';
import { Badge } from '../../../components/Badge';
import { ButtonLink } from '../../../components/ButtonLink';
import toast from '../../../helpers/toast';
import { formatDate } from '../../../helpers/dates';
import URLBuilderService from '../../../services/URLBuilder';
import clipboard from '../../../styles/logos/clipboard.svg';
import trash from '../../../styles/logos/trash-alt.svg';
import check from '../../../styles/logos/check_icon.svg';
import * as S from './styles';
import { Translate } from './translate';

function URLBuilder() {
  const translate = Translate();
  const [urls, setURLs] = useState([]);
  const [seachTerms, setSeachTerms] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [toogleModal, setToogleModal] = useState(false);
  const [urltoDelete, setUrlToDelete] = useState(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const urlsBuilder = await URLBuilderService.getAll();
        setURLs(urlsBuilder);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {};
  }, []);

  const filteredurls = useMemo(() => urls?.filter((item) => (
    item.url.toLowerCase().includes(seachTerms) || item.url_masked.toLowerCase().includes(seachTerms)
  )), [urls, seachTerms]);

  async function deleteUrl() {
    try {
      const response = await URLBuilderService.delete(urltoDelete.id);
      setURLs((prevState) => prevState.filter((url) => url.id !== urltoDelete.id));
      toast('success', response.message);
    } catch (error) {
      toast('success', error.message);
    } finally {
      handleCloseModalDeleteUrl();
    }
  }

  function handleSearchTerm(event) {
    setSeachTerms(event.target.value.toLowerCase());
  }

  function handleCopyUrls(urls) {
    const element = urls.event.target;
    element.className = 'copy-check';
    element.setAttribute('src', check);

    const url = !urls.url ? '' : urls.url;
    const urlMasked = !urls.urlMask ? '' : urls.urlMask;
    const urlShortened = !urls.urlShortened ? '' : urls.urlShortened;

    navigator.clipboard.writeText(`${url}\n${urlMasked}\n${urlShortened}`);

    setTimeout(() => {
      element.className = 'copy-icon';
      element.setAttribute('src', clipboard);
    }, 3000);
  }

  function handleCopyOneUrl(url) {
    const element = url.event.target;
    element.className = 'copy-check';
    element.setAttribute('src', check);

    navigator.clipboard.writeText(`${url.url}`);

    setTimeout(() => {
      element.className = 'copy-icon';
      element.setAttribute('src', clipboard);
    }, 3000);
  }

  function handleOpenModalDeleteUrl(url) {
    setToogleModal(true);
    setUrlToDelete(url);
  }

  function handleCloseModalDeleteUrl() {
    setToogleModal(false);
    setUrlToDelete(null);
  }

  return (
    <>
      <Modal
        danger
        visible={toogleModal}
        onClose={handleCloseModalDeleteUrl}
        onAction={deleteUrl}
        title={translate.modalTextTitle}
        btnMessage="Delete"
      >
        <>
          <strong>URL: </strong>
          {urltoDelete?.url}
          {urltoDelete?.url_masked && (
            <>
              <br />
              <br />
              <strong>URL Masked: </strong>
              {urltoDelete?.url_masked}
            </>
          )}
        </>
      </Modal>

      <TitlePage title="URL Builder" />
      <S.ActionContainer $hasurl={urls?.length >= 1}>
        {urls?.length >= 1
          && <input type="text" placeholder={translate.placeholderUrl} onChange={handleSearchTerm} value={seachTerms} />}
        <div className="button-container">
          <ButtonLink color="blue" to="/client/urlbuilder/addparams">
            {' '}
            {translate.buttonAdd}
            {' '}
          </ButtonLink>
          <ButtonLink color="blue" to="/client/urlbuilder/add"> {translate.buttonCreateURL} </ButtonLink>
        </div>
      </S.ActionContainer>

      <S.ListContainer>
        { isLoading && <Loader loading={isLoading} /> }

        { !isLoading && (
          <>
            {(urls?.length === 0 || urls === undefined) && <NoDataMessage>{translate.messageNoUrl}</NoDataMessage> }
            {(urls?.length >= 1 && filteredurls?.length === 0)
            && (
            <p className="no-data-message">
              {translate.messageNoUrlSearched}
              <small>
                {seachTerms}
              </small>
            </p>
            )}

            {/* List Items */}
            { filteredurls?.length > 0
            && filteredurls?.map((item) => (
              <S.ListUrl key={item.id}>
                <div className="head">
                  <div className="badges">
                    <Badge>{formatDate(item.date)}</Badge>
                    <Badge>
                      {item.userFirstName}
                      {' '}
                      {item.userLastName}
                    </Badge>
                  </div>
                  <div className="actions">
                    {item.schemaUrl && (
                      <Link className="action-clone" to={`/client/urlbuilder/add?clone_for=${item.id}`}>
                        {translate.clone}
                      </Link>
                    )}
                    <div className="action-background">
                      <img
                        src={clipboard}
                        alt="Copy All URLs"
                        title="Copy All URLs"
                        className="copy-icon"
                        onClick={(event) => handleCopyUrls({
                          url: item.url, urlMask: item.url_masked, urlShortened: item.url_shortened, event,
                        })}
                      />
                    </div>
                    <div className="action-background">
                      <img
                        src={trash}
                        alt="Delete URL"
                        title="Delete URL"
                        className="delete-icon"
                        onClick={() => handleOpenModalDeleteUrl(item)}
                      />
                    </div>
                  </div>
                </div>
                <S.List>
                  <img
                    src={clipboard}
                    alt="Copy URL"
                    title="Copy URL"
                    className="copy-icon"
                    onClick={(event) => handleCopyOneUrl({ url: item.url, event })}
                  />
                  <p>{item.url}</p>
                </S.List>
                {item.url_masked
                && (
                <>
                  <hr />
                  <S.List>
                    <img
                      src={clipboard}
                      alt="Copy Masked URL"
                      title="Copy Masked URL"
                      className="copy-icon"
                      onClick={(event) => handleCopyOneUrl({ url: item.url_masked, event })}
                    />
                    <p>{item.url_masked}</p>
                  </S.List>
                </>
                )}
                {item.url_shortened
                && (
                <>
                  <hr />
                  <S.List>
                    <img
                      src={clipboard}
                      alt="Copy Shortened URL"
                      title="Copy Shortened URL"
                      className="copy-icon"
                      onClick={(event) => handleCopyOneUrl({ url: item.url_shortened, event })}
                    />
                    <p>{item.url_shortened}</p>
                  </S.List>
                </>
                )}
              </S.ListUrl>
            ))}
          </>
        )}
      </S.ListContainer>
    </>
  );
}

export default URLBuilder;
