import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import UsersService from '../../services/Users';
import toast from '../../helpers/toast';
import Logo from '../../styles/logos/shiftersy.png';
import { Container, Form } from './style';
import { Translate } from './translate';

export function RecoveryPassword() {
  const translate = Translate();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [btnDisable, setBtnDisable] = useState(true);

  async function handleSubmit(event) {
    event.preventDefault();
    const emailIsValid = verifyEmail(email);
    if (emailIsValid) {
      try {
        const response = await UsersService.recoveryEmail({ email });
        toast('success', response.message);
        navigate('/');
      } catch {
        toast('danger', 'E-mail not found. Send a valid e-mail');
      }
    }
  }

  function verifyEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  function handleEmail(event) {
    if (event) {
      const emailTyped = event.target.value;
      setEmail(emailTyped);
      const emailIsValid = verifyEmail(emailTyped);
      if (emailIsValid) {
        setBtnDisable(false);
      } else {
        setBtnDisable(true);
      }
    }
  }

  return (
    <Container>
      <Link to="/">
        <img src={Logo} alt="Shifters Logo" />
      </Link>
      <div className="text">
        <p>{translate.phrase1}</p>
        <p>{translate.phrase2}</p>
      </div>
      <Form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder={translate.placeholder}
          value={email}
          onChange={(event) => handleEmail(event)}
        />
        <Button
          color="yellow"
          type="submit"
          disabled={btnDisable}
        >
          {translate.btn}
        </Button>
      </Form>
      <Link to="/">{translate.backHome}</Link>
    </Container>
  );
}
