import React, { useEffect, useState } from 'react';
import VideosCommentService from '../../../services/VideosComment';
import { Button } from '../../Button';
import toast from '../../../helpers/toast';
import Dates_New from '../../../helpers/Dates_New';
import { Translate } from './translate';
import { Container, Comment, CommentItem } from './style';

export function Comments({ idVideo }) {
  const translate = Translate();
  const [comment, setComment] = useState('');
  const [commentsList, setCommentsList] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);

  useEffect(() => {
    listAllComments();
  }, [idVideo]);

  useEffect(() => handleBtn(), [comment]);

  async function listAllComments() {
    if (idVideo !== 'null') {
      try {
        const commentsList = await VideosCommentService.findAll(idVideo);
        setCommentsList(commentsList);
      } catch {
        setCommentsList([]);
      }
    }
  }

  async function handleComment() {
    try {
      await VideosCommentService.store(idVideo, comment);
      toast('success', translate.successMsg);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setComment('');
      listAllComments();
    }
  }

  function handleBtn() {
    if (comment.length >= 3) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }

  return (
    <Container>
      <Comment>
        <div className="comment-input">
          <label htmlFor="comment">{translate.title}</label>
          <textarea
            id="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <Button color="white" onClick={handleComment} disabled={disableBtn}>{translate.buttonMsg}</Button>
      </Comment>
      <div>
        {commentsList.length > 0 && (
          commentsList.map((comment) => (
            <CommentItem key={comment.id}>
              <div className="info">
                <span>{comment.first_name}</span>
                <span className="date">{Dates_New.formatDatesMask(comment.date_act)}</span>
              </div>
              <div className="description">
                {comment.comment}
              </div>
            </CommentItem>
          ))
        )}
      </div>
    </Container>
  );
}
