import styled from 'styled-components';

export const ActionContainer = styled.div`
  margin-top: ${(props) => props.theme.sizes.s24};
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.sizes.s16};
  position: relative;

  .form-item-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    .form-label-container {
      display: flex;
      margin-bottom: 4px;

      label {
        margin-right: 8px;
      }

      img:hover {
        cursor: pointer;
      }
    }

  }

  select {
    width: 100%;

    :focus {
      border: 1px solid ${(props) => props.theme.grayLight};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
      margin-bottom: ${(props) => props.theme.sizes.s8};
    }
  }

  input[type="text"] {
    flex: 2;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
      margin-bottom: ${(props) => props.theme.sizes.s8};
      padding: ${(props) => props.theme.sizes.s8};
    }
  }
`;
