import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import Loader from '../../../../../../../../components/Loader';
import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { DataContext } from '../../../../../context/Data';
import { Translate } from './translate';

const ConversionRateLeadsSessionsTr = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    leadsGoogleAnalyticsCurrent, leadsGoogleAnalyticsPrevious, leadsGoogleAnalyticsYoY, leadsGoogleAnalyticsPreviousComplete, leadsGoogleAnalyticsForecast, isLoandingGALeadsData,
    googleAnalyticsCurrent, googleAnalyticsPrevious, googleAnalyticsYoY, googleAnalyticsPreviousComplete, googleAnalyticsForecast,
  } = useContext(DataContext);

  const objectOne = 'leads';
  const objectTwo = 'session';

  const current = (Number(leadsGoogleAnalyticsCurrent?.[objectOne]) / Number(googleAnalyticsCurrent?.[objectTwo])) || 0;
  const previous = (Number(leadsGoogleAnalyticsPrevious?.[objectOne]) / Number(googleAnalyticsPrevious?.[objectTwo])) || 0;
  const yoy = (Number(leadsGoogleAnalyticsYoY?.[objectOne]) / Number(googleAnalyticsYoY?.[objectTwo])) || 0;
  const completeMonth = (Number(leadsGoogleAnalyticsPreviousComplete?.[objectOne]) / Number(googleAnalyticsPreviousComplete?.[objectTwo])) || 0;

  const forecastDataFirst = leadsGoogleAnalyticsForecast?.length > 0 && leadsGoogleAnalyticsForecast.map((data) => ({ date: data.date, value: Number(data[objectOne]) }));
  const forecastDataSecond = googleAnalyticsForecast?.length > 0 && googleAnalyticsForecast.map((data) => ({ date: data.date, value: Number(data[objectTwo]) }));
  const forecastFirst = forecastDataFirst && (ForecastMonthly(forecastDataFirst) || 0);
  const forecastSecond = forecastDataFirst && (ForecastMonthly(forecastDataSecond) || 0);
  const forecast = forecastFirst / forecastSecond;

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta red';
    }
    return 'delta green';
  }

  return (
    <tr title={translate.tip}>
      {showLevelColumn ? <td>2nd</td> : null}
      {showSourceColumn ? <td>Calc</td> : null}
      <td>{translate.kpi}</td>
      <td>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat(current, 'percentage', 2)}
      </td>
      <td>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat(previous, 'percentage', 2)}
      </td>
      <td className={classNameAtrr(deltaNormal)}>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat(yoy, 'percentage', 2)}
      </td>
      <td className={classNameAtrr(deltaYoY)}>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat(forecast, 'percentage', 2)}
      </td>
      <td>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat(completeMonth, 'percentage', 2)}
      </td>
      <td className={classNameAtrr(deltaForecast)}>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

ConversionRateLeadsSessionsTr.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default ConversionRateLeadsSessionsTr;
