export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Vídeos Favoritos' : 'Favorite Videos',
    category: language === 'pt-BR' ? 'Categoria' : 'Category',
    subcategory: language === 'pt-BR' ? 'Subcategoria' : 'Subcategory',
    watch: language === 'pt-BR' ? 'Assitir' : 'Watch',
    noFavorites: language === 'pt-BR' ? 'Você não possui vídeos favoritos na sua lista' : 'You don\'t have favorite videos in your list',
    language,
  };
}
