import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import { DateContext } from '../../../../../../context/DateContext';
import Dates_New from '../../../../../../helpers/Dates_New';
import { Translate } from './translate';

function TableHeader({ showLevelColumn, showSourceColumn }) {
  const translate = Translate();
  const { infoDateSelected } = useContext(DateContext);

  return (
    <thead>
      <tr>
        {showLevelColumn ? <td>{translate.level}</td> : null}
        {showSourceColumn ? <td>{translate.source}</td> : null}
        <td>KPIs</td>
        <td>
          {translate.current}
          {' '}
          <br />
          <small>
            {infoDateSelected?.init?.description}
            {' '}
            -
            {' '}
            {infoDateSelected?.end?.description}
          </small>
        </td>
        <td>
          {translate.previous}
          {' '}
          <br />
          <small>
            {infoDateSelected?.initPrev?.description}
            {' '}
            -
            {' '}
            {infoDateSelected?.endPrev?.description}
          </small>
        </td>
        <td>Delta</td>
        <td>
          YoY
          {' '}
          <br />
          <small>
            {infoDateSelected?.initYoY?.description}
            {' '}
            -
            {' '}
            {infoDateSelected?.endYoY?.description}
          </small>
        </td>
        <td>Delta</td>
        <td>
          Forecast
          {' '}
          <br />
          <small>{Dates_New.getInfoDateForecast().description}</small>
        </td>
        <td>
          {Dates_New.getInfoDateCompletePreviousMonth().description}
          {' '}
          <br />
          <small>
            {Dates_New.getInfoDateCompletePreviousMonth().init.description}
            {' '}
            -
            {' '}
            {Dates_New.getInfoDateCompletePreviousMonth().end.description}
          </small>

        </td>
        <td>Delta</td>
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default TableHeader;
