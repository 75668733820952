import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import AccessGroupService from '../../../../services/AccessGroup';
import TitlePage from '../../../../components/TitlePage';
import Square from '../../../../components/Square';
import Loader from '../../../../components/Loader';
import { NoDataMessage } from '../../../../components/NoDataMessage';
import { ButtonLink } from '../../../../components/ButtonLink';
import FaEditIcon from '../../../../styles/logos/FaEdit.svg';
import FaUsers from '../../../../styles/logos/FaUsers.svg';
import IconTrash from '../../../../styles/logos/trash-alt.svg';
import toast from '../../../../helpers/toast';
import { Translate } from './translate';
import {
  Container, TitleContainer, SearchContainer,
} from './style';

export function AccessGroups() {
  const translate = Translate();
  const [accessGroup, setAccessGroup] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getAccessGroups();
  }, []);

  async function getAccessGroups() {
    try {
      setIsLoadingData(true);
      const data = await AccessGroupService.getAll();
      setAccessGroup(data);
    } catch (err) {
      toast('danger', 'Access group not found');
    } finally {
      setIsLoadingData(false);
    }
  }

  async function deleteItem(id) {
    try {
      setIsLoadingData(true);
      const response = await AccessGroupService.delete(id);
      toast('success', response.message);
      setAccessGroup(accessGroup.filter((accessGroup) => accessGroup.id !== id));
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoadingData(false);
    }
  }

  function handleSearchTerm(e) {
    setSearchTerm(e.target.value.toLowerCase());
  }

  const accessGroupListFiltered = useMemo(() => accessGroup?.filter((accessGroup) => (accessGroup.name.toLowerCase().includes(searchTerm))));

  return (
    <>
      <TitlePage title={translate.title} />
      {isLoadingData && <Loader loading={isLoadingData} /> }
      {!isLoadingData && (
        <Container>
          <SearchContainer>
            {accessGroup.length > 0 ? (
              <div className="c-search">
                <label htmlFor="">{translate.search}</label>
                <input type="text" name="" id="" placeholder={translate.placeholderSearch} onChange={handleSearchTerm} />
              </div>
            ) : <div />}
            <div className="c-actions">
              <ButtonLink color="blue" to="add">{translate.labelButtonAddNew}</ButtonLink>
            </div>
          </SearchContainer>

          {accessGroup.length === 0
            ? (<NoDataMessage>{translate.errorMsg}</NoDataMessage>)
            : (accessGroupListFiltered && accessGroupListFiltered?.map((accessGroup) => (
              <Square size="large" key={accessGroup.id}>
                <TitleContainer>
                  <div className="c-container">
                    <div className="c-container-name">
                      {accessGroup.name}
                    </div>
                  </div>
                  <div className="c-container-actions" title="Edit access group">
                    <Link to={`/admin/access-group/${accessGroup.id}`}>
                      <img src={FaEditIcon} alt="Edit access group" />
                    </Link>
                    <Link to={`/admin/access-group/users/${accessGroup.id}`} title="Users by access group">
                      <img src={FaUsers} alt="Users by access group" />
                    </Link>
                    <span className="icon-trash" onClick={() => deleteItem(accessGroup.id)} title="Delete access group">
                      <img src={IconTrash} alt="Delete access group" />
                    </span>
                  </div>
                </TitleContainer>
              </Square>
            )))}
        </Container>
      )}
    </>
  );
}
