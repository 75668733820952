import { styled } from 'styled-components';

export const ContainerFilters = styled.div`
  width: 100%;

  form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    fieldset {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.grayDark};

      legend {
        color: ${({ theme }) => theme.grayDark};
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
    }
  }

  form .group {
    display: flex;
    gap: 6px;
    align-items: flex-end;
    position: relative;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }

    label {
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        cursor: pointer;
        width: 14px;
      }
    }

    select {
      position: relative;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
      }
    }
  }

  button {
    width: 100%;


    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-top: 16px;
    }
  }

  input, select {
    outline: none;
    border:1px solid ${({ theme }) => theme.grayLight};
    width: ${({ $typeClient }) => ($typeClient === 'VTEX' ? '75px' : '110px')};
  }
`;
