import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 32px;

  h2{
    margin-bottom: 16px;
  }
`;

export const CointainerVideo = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.grayMenu};
  border-radius: 4px;
`;

export const VideoArea = styled.div`
  padding: 16px 16px;
  position: relative;
  width: 100%;
  height: 100%;

  h3 {
    margin-bottom: 16px;
  }
`;

export const VideoInfosArea = styled.div`
  padding: 0px 16px;

  hr {
    border: none;
    height: 1px;
    background-color: ${({ theme }) => theme.grayBackground};
    margin: 8px 0px;
  }
`;

export const VideoActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 16px;

  @media screen  and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    padding: 0;
  }

  .container-like {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: 0.2s;
  }

  .container-like:hover {
    transform: scale(1.1);
  }

  .container-path {
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: 10px;
      align-items: flex-start;
      margin-left: -23px;
      margin-bottom: 8px;
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.grayLight};
      text-align: right;
      margin-right: -23px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: none;
      }
    }
  }

  .container-links {
    display: flex;
    align-items: center;
    text-decoration: none;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        align-items: flex-start;
        margin-right: 0;
      }

    div {
      border-top-right-radius: 45px;
      border-bottom-right-radius: 45px;
      position: relative;
    }
  }

  .container-links:hover {
    text-decoration: underline;
  }
`;

export const Likes = styled.div`
  color: ${({ theme }) => theme.grayLight};
`;

export const PathModule = styled.div`
  padding: 4px 16px 4px 16px;
  color: ${({ color, theme }) => (color || theme.grayLight)};
  background-color: ${({ theme }) => theme.grayBackground};
  left: 24px;
  z-index: 2;
`;

export const PathTitle = styled.div`
  padding: 4px 16px 4px 32px;
  background-color: ${({ theme }) => theme.grayDark};
  z-index: 1;
`;

export const DescriptionArea = styled.div`
  color: ${({ theme }) => theme.fontWhite};
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  span {
    color: ${({ theme }) => theme.yellow};
    cursor: pointer;
    text-decoration: underline;
  }
`;
