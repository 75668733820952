export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Lista de usuários' : 'User\'s list',
    labelButtonAddNew: language === 'pt-BR' ? 'Adicionar usuário' : 'Add user',
    errorMsg: language === 'pt-BR' ? 'Não foi possível carregar os usuários' : 'User\'s list unavailable',
    search: language === 'pt-BR' ? 'Pesquisa' : 'Search',
    placeholderSearch: language === 'pt-BR' ? 'Email, nome, perfil ou nome do cliente' : 'Email, name, profile or client\'s name',
  };
}
