export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    titlePage: language === 'pt-BR' ? 'Valores de Parâmetros Personalizados' : 'Custom Params Values',
    labelParam: language === 'pt-BR' ? 'Parâmetros' : 'Params',
    buttonNewParam: language === 'pt-BR' ? 'Adicionar novo' : 'Add new',
    labelUrl: language === 'pt-BR' ? 'Nome' : 'Name',
    labelUrlValue: language === 'pt-BR' ? 'Valor da Url' : 'Url value',
    messageInfo: language === 'pt-BR'
      ? 'Valores não cadastrados para esta parâmetro, para inserir clique em "Adicionar novo"'
      : 'Values not registered for this param, to insert click on "Add new"',
    modalTextTitle: language === 'pt-BR'
      ? 'Deseja realmente deletar o parâmetro?'
      : 'Do you really want to delete the parameter?',
    modalTextDelete: language === 'pt-BR' ? 'Deletar' : 'Delete',
    language,
  };
}
