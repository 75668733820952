import {
  useState, useEffect, createContext, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import ProductService from '../../services/Products';

const SideBarVideosContext = createContext();

function SideBarVideosProvider({ children }) {
  const [product, setProduct] = useState({});
  const [list, setList] = useState([]);
  const { id } = useParams();
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [percentageWatched, setPercentageWatched] = useState(0);

  useEffect(() => {
    async function init() {
      setIsLoadingList(true);
      await getProduct();
      await getList();
      setIsLoadingList(false);
    }

    init();

    return () => {
      setProduct({});
      setList([]);
      setIsLoadingList(true);
    };
  }, [id]);

  async function getProduct() {
    const product = await ProductService.findOne(id);
    const percentageWatched = await ProductService.calculatePercentageWatched({ idProduct: id });
    setProduct(product);
    setPercentageWatched(percentageWatched);
  }

  async function getList() {
    try {
      setList([]);
      const listDB = await ProductService.getList(id);
      setList(listDB);
    } catch {
      setList([]);
    }
  }

  const contextValues = useMemo(() => ({
    product,
    list,
    isLoadingList,
    percentageWatched,
  }), [
    product,
    list,
    isLoadingList,
    percentageWatched,
  ]);

  return (
    <SideBarVideosContext.Provider value={contextValues}>
      {children}
    </SideBarVideosContext.Provider>
  );
}

export { SideBarVideosContext, SideBarVideosProvider };
