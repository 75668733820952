import styled from 'styled-components';

export const FormInputsContainer = styled.div`
  > div {
    margin-top: 16px;
    padding: 16px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .c-fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    flex-wrap: wrap;

    select, input {
      border-color: ${({ theme }) => theme.grayLight};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
        width: 100%;
      }
    }
  }

  .input-large {
    width: 400px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }
  }

  .c-explanation {
    font-size: 13px;
    margin-top: 16px;
    color: ${({ theme }) => theme.grayLight};
  }
`;
