import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.grayMenu};
  padding: 16px;
  border-radius: 4px;
  width: 520px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }

  #category-select {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  #options-view-button {
    all: unset;

    position: absolute;
    inset: 0;

    cursor: pointer;
    z-index: 3;

    &:disabled {
      cursor: not-allowed;
    }
  }

  & #options-view-button:disabled + #select-button{
    border-color: ${({ theme }) => theme.grayDark};
  }

  & #options-view-button:disabled + #select-button > #selected-value {
    color: ${({ theme }) => theme.grayDark};
  }

  #select-button {
    border: 1px solid ${({ theme }) => theme.grayLight};
    border-radius: 4px;
    padding: 8px;
  }

  #selected-value {
    width: 420px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }
  }

  #options {
    margin-top: 1rem;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.grayMenu};
    border: 1px solid ${({ theme }) => theme.grayLight};

    max-height: 430px;
    overflow-y: auto;

    display: none;
    z-index: 99 !important;
    position: absolute;
    right: 37px;
    top: 118px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      right: 20px;
      width: 90%;
    }
  }

  .option {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .option:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.grayBackground};
  }

  .option-star-container {
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .option-text-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 2px 4px 4px;
  }

  .option-text-container small {
    color: ${({ theme }) => theme.grayLight};
    font-size: 12px;
  }

  .option-text-container input[type="radio"] {
    all: unset;
    inset: 0;
    cursor: pointer;
  }

  #category-select:has(#options-view-button:checked) + #options {
    display: block;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;

  .c-date__container {
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }

    display: flex;
    align-items: center;
    gap: 8px;
  }

   .c-date__picker {
      display: flex;
      flex-direction: column;

       input {
          border: 1px solid ${({ theme }) => theme.grayLight};
          width: 100%;
        }
    }

  .btns-container {
    display: flex;
    align-items: center;
    gap: 4px;

    button {
      padding: 0px 8px !important;
      height: 40px;

      display: flex;
      align-items: center;
    }
  }
`;
