export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Adicionar Video' : 'Add Video',
    labelName: language === 'pt-BR' ? 'Título' : 'Title',
    labelVideo: language === 'pt-BR' ? 'Vídeo' : 'Video',
    namePt: language === 'pt-BR' ? 'Título PT' : 'Title PT',
    nameEn: language === 'pt-BR' ? 'Título EN' : 'Title EN',
    videoType: language === 'pt-BR' ? 'Tipo' : 'Type',
    descriptionPt: language === 'pt-BR' ? 'Descrição PT' : 'Description PT',
    descriptionEn: language === 'pt-BR' ? 'Descrição EN' : 'Description EN',
    transcriptionOpt: language === 'pt-BR' ? 'Mostrar transcrição aos usuários?' : 'Show transcript to users?',
    transcriptionPt: language === 'pt-BR' ? 'Transcrição PT' : 'Transcription PT',
    transcriptionEn: language === 'pt-BR' ? 'Transcrição EN' : 'Transcription EN',
    product: language === 'pt-BR' ? 'Produto' : 'Product',
    category: language === 'pt-BR' ? 'Categoria' : 'Category',
    subCategory: language === 'pt-BR' ? 'SubCategoria' : 'SubCategory',
    warningAddTo: language === 'pt-BR'
      ? 'Serão visualizados apenas os campos que podem conter Video associado (Categoria, SubCategoria ou Post)'
      : 'Only fields that can contain associated Video (Category, SubCategory or Post) will be displayed.',
    language,
  };
}
