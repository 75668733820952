import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import { AuthContext } from '../../context/AuthContext';
import SettingsService from '../../services/Settings';
import TitlePage from '../../components/TitlePage';
import BackButton from '../../components/BackButton';
import Square from '../../components/Square';
import { ButtonLink } from '../../components/ButtonLink';
import ButtonSaveCancel from '../../components/ButtonsSaveCancel';
import toast from '../../helpers/toast';
import Translate from './translate';
import * as S from './style';

function Settings() {
  const translate = Translate();
  const { handleLogout, userInfo: { firstName: firstNameAuth, lastName: lastNameAuth, email } } = useContext(AuthContext);
  const language = JSON.parse(localStorage.getItem('language'));

  const [lang, setLang] = useState(language.replaceAll('"', ''));
  const [firstName, setFirstName] = useState(firstNameAuth);
  const [lastName, setLastName] = useState(lastNameAuth);
  const [disabled, setDisabled] = useState(true);
  const smallFirstName = useRef();
  const smallLastName = useRef();
  const { pathname } = window.location;
  const pathNameLocal = pathname.split('/')[1];

  async function handleSubmit() {
    try {
      const response = await SettingsService.update({ lang, firstName, lastName });
      toast('success', response.message);
      handleLogout();
    } catch (err) {
      toast('danger', err.message);
    }
  }

  useEffect(() => {
    setFirstName(firstName);
    setLastName(lastName);

    if (firstName.length < 3) {
      smallFirstName.current.innerHTML = translate.errorNamesMsg;
    } else {
      smallFirstName.current.innerHTML = '';
    }

    if (lastName.length < 3) {
      smallLastName.current.innerHTML = translate.errorNamesMsg;
    } else {
      smallLastName.current.innerHTML = '';
    }

    if (firstName.length >= 3 && lastName.length >= 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    return () => {};
  }, [firstName, lastName, disabled, translate.errorNamesMsg]);

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton />
      </TitlePage>

      <S.MainContainer>
        <Square size="large">
          <S.Container>
            <h3>{translate.profile}</h3>
            <div>
              <form>
                <div>
                  <label htmlFor="">{translate.labelName}</label>
                  <input
                    type="text"
                    placeholder={translate.placeholderName}
                    value={firstName}
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                  />
                  <small ref={smallFirstName} />
                </div>
                <div>
                  <label htmlFor="">{translate.labelLastName}</label>
                  <input
                    type="text"
                    placeholder={translate.placeholderLastName}
                    value={lastName}
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                  />
                  <small ref={smallLastName} />
                </div>
                <div>
                  <label htmlFor="">Email</label>
                  <input
                    type="text"
                    value={email}
                    disabled
                  />
                  <small />
                </div>
              </form>
            </div>
          </S.Container>
        </Square>
      </S.MainContainer>

      <S.MainContainer>
        <Square size="large">
          <S.Container>
            <h3>{translate.language}</h3>
            <div>
              <select
                value={lang}
                onChange={(event) => setLang(event.target.value)}
              >
                <option value="pt-BR">Português (BR)</option>
                <option value="en-US">English (US)</option>
              </select>
            </div>
          </S.Container>
        </Square>
      </S.MainContainer>

      <S.MainContainer>
        <Square size="large">
          <S.Container>
            <h3>{translate.password}</h3>
            <ButtonLink color="blue" to={`/${pathNameLocal}/settings/edit-password`}>
              {' '}
              {translate.editButton}
              {' '}
            </ButtonLink>
          </S.Container>
        </Square>
      </S.MainContainer>

      <ButtonSaveCancel
        disabled={disabled}
        onSave={handleSubmit}
        goToWhenCancel={`/${pathNameLocal}`}
        smallText={translate.textMessageOnSave}
      />

    </>
  );
}

export default Settings;
