import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import Loader from '../../../../../../../../components/Loader';
import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { DataContext } from '../../../../../context/Data';
import { Translate } from './translate';

const CPLTr = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    googleAdwordsCurrent, googleAdwordsPrevious, googleAdwordsYoY, googleAdwordsPreviousComplete, googleAdwordsForecast,
    googleAnalyticsOthersPlatformsCurrent, googleAnalyticsOthersPlatformsPrevious, googleAnalyticsOthersPlatformsYoY, googleAnalyticsOthersPlatformsPreviousComplete, googleAnalyticsOthersPlatformsForecast,
    metaAdsCurrent, metaAdsPrevious, metaAdsYoY, metaAdsPreviousComplete, metaAdsForecast,
    linkedinAdsCurrent, linkedinAdsPrevious, linkedinAdsYoY, linkedinAdsPreviousComplete, linkedinAdsForecast,
    leadsGoogleAnalyticsCurrent, leadsGoogleAnalyticsPrevious, leadsGoogleAnalyticsYoY, leadsGoogleAnalyticsPreviousComplete, leadsGoogleAnalyticsForecast, isLoandingGALeadsData,
  } = useContext(DataContext);

  const objectDataFirst = 'cost';
  const objectDataSecond = 'leads';

  // Current Date
  const currentDataFirst = (Number(googleAdwordsCurrent?.[objectDataFirst]) || 0)
                            + (Number(metaAdsCurrent?.[objectDataFirst]) || 0)
                            + (Number(linkedinAdsCurrent?.[objectDataFirst]) || 0)
                            + (Number(googleAnalyticsOthersPlatformsCurrent?.[objectDataFirst]) || 0);
  const currentDataSecond = (Number(leadsGoogleAnalyticsCurrent?.[objectDataSecond]) || 0);
  const current = accountKPI(currentDataFirst, currentDataSecond);

  // Previous Date
  const previousDataFirst = (Number(googleAdwordsPrevious?.[objectDataFirst]) || 0)
                              + (Number(metaAdsPrevious?.[objectDataFirst]) || 0)
                              + (Number(linkedinAdsPrevious?.[objectDataFirst]) || 0)
                              + (Number(googleAnalyticsOthersPlatformsPrevious?.[objectDataFirst]) || 0);
  const previousDataSecond = (Number(leadsGoogleAnalyticsPrevious?.[objectDataSecond]) || 0);
  const previous = accountKPI(previousDataFirst, previousDataSecond);

  // YoY
  const yoyDataFirst = (Number(googleAdwordsYoY?.[objectDataFirst]) || 0)
                        + (Number(metaAdsYoY?.[objectDataFirst]) || 0)
                        + (Number(linkedinAdsYoY?.[objectDataFirst]) || 0)
                        + (Number(googleAnalyticsOthersPlatformsYoY?.[objectDataFirst]) || 0);
  const yoyDataSecond = (Number(leadsGoogleAnalyticsYoY?.[objectDataSecond]) || 0);
  const yoy = accountKPI(yoyDataFirst, yoyDataSecond);

  // Complete Month
  const completeMonthDataFirst = (Number(googleAdwordsPreviousComplete?.[objectDataFirst]) || 0)
                                  + (Number(metaAdsPreviousComplete?.[objectDataFirst]) || 0)
                                  + (Number(linkedinAdsPreviousComplete?.[objectDataFirst]) || 0)
                                  + (Number(googleAnalyticsOthersPlatformsPreviousComplete?.[objectDataFirst]) || 0);
  const completeMonthDataSecond = (Number(leadsGoogleAnalyticsPreviousComplete?.[objectDataSecond]) || 0);
  const completeMonth = accountKPI(completeMonthDataFirst, completeMonthDataSecond);

  // Forecast
  const forecastGoogleAdwordsDataFirst = googleAdwordsForecast?.length > 0 && googleAdwordsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataFirst]) }));
  const forecastMetaAdsDataFirst = metaAdsForecast?.length > 0 && metaAdsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataFirst]) }));
  const forecastLinkedinAdsDataFirst = linkedinAdsForecast?.length > 0 && linkedinAdsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataFirst]) }));
  const forecastGoogleAnalyticsOthersPlatformsDataFirst = googleAnalyticsOthersPlatformsForecast?.length > 0 && googleAnalyticsOthersPlatformsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataFirst]) }));

  const forecastLeadsGoogleAnalyticsDataSecond = leadsGoogleAnalyticsForecast?.length > 0 && leadsGoogleAnalyticsForecast?.map((data) => ({ date: data.date, value: Number(data[objectDataSecond]) }));

  const forecastDataFirst = (forecastGoogleAdwordsDataFirst && (ForecastMonthly(forecastGoogleAdwordsDataFirst) || 0))
                              + (forecastMetaAdsDataFirst && (ForecastMonthly(forecastMetaAdsDataFirst) || 0))
                              + (forecastLinkedinAdsDataFirst && (ForecastMonthly(forecastLinkedinAdsDataFirst) || 0))
                              + (forecastGoogleAnalyticsOthersPlatformsDataFirst && (ForecastMonthly(forecastGoogleAnalyticsOthersPlatformsDataFirst) || 0));
  const forecastDataSecond = (forecastLeadsGoogleAnalyticsDataSecond && (ForecastMonthly(forecastLeadsGoogleAnalyticsDataSecond) || 0));

  const forecast = accountKPI(forecastDataFirst, forecastDataSecond);

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function accountKPI(num1, num2) {
    return num1 / num2;
  }

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta green';
    }
    return 'delta red';
  }

  return (
    <tr title={translate.tip}>
      {showLevelColumn ? <td>1st & 2nd</td> : null}
      {showSourceColumn ? <td>Calc</td> : null}
      <td>{translate.kpi}</td>
      <td>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat(current, 'currency', 2)}
      </td>
      <td>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat(previous, 'currency', 2)}
      </td>
      <td className={classNameAtrr(deltaNormal)}>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat(yoy, 'currency', 2)}
      </td>
      <td className={classNameAtrr(deltaYoY)}>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat(forecast, 'currency', 2)}
      </td>
      <td>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat(completeMonth, 'currency', 2)}
      </td>
      <td className={classNameAtrr(deltaForecast)}>
        <Loader loading={isLoandingGALeadsData} tofield />
        {!isLoandingGALeadsData && numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

CPLTr.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default CPLTr;
