export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'RBI Model' : 'RBI Model',
    subtitle: language === 'pt-BR'
      ? 'Confira o comportamento do negócio, sempre com forecasts e previsão de crescimento comparando dois períodos diferentes.'
      : 'Check the behavior of the business, always with forecasts and growth forecast comparing two different periods.',
    level: language === 'pt-BR' ? 'Nível' : 'Level',
    source: language === 'pt-BR' ? 'Fonte' : 'Source',
  };
}
