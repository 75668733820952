import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.grayMenu};
  border-radius: 8px;
  font-size: 16px;
  min-height: 100%;

  ${({ size }) => size === 'small' && css`
    padding: 8px;
    font-size: 13px
  `}

  ${({ size }) => size === 'medium' && css`
    padding: 16px;
  `}

  ${({ size }) => size === 'large' && css`
    padding: 24px;
  `}

  .main-info {
    ${({ size }) => size === 'small' && css`
      font-size: 14px;
    `}

    ${({ size }) => size === 'medium' && css`
      font-size: 16px;
    `}

    ${({ size }) => size === 'large' && css`
      font-size: 17px;
    `}
  }
`;
