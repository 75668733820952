import styled from 'styled-components';

export const Icon = styled.img`
  cursor: pointer;
  width: 24px;
  transition: .2s ease-in-out;
  filter: invert(95%) sepia(52%) saturate(0%) hue-rotate(55deg) brightness(102%) contrast(101%);

  &:hover {
    transform: scale(1.1);
  }

   &:active {
    transform: scale(1.2);
  }
`;
