import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { ContainerBlur, ContainerInfo } from './styles';

export function ModalImages({ children, visible, onClose }) {
  if (!visible) {
    return null;
  }

  function handleClose(e) {
    if (e.target.classList.contains('js-container-blur')) {
      onClose();
    }
  }

  return ReactDOM.createPortal(
    <ContainerBlur className="js-container-blur" onClick={(e) => handleClose(e)}>
      <ContainerInfo>
        {children}
      </ContainerInfo>
    </ContainerBlur>,
    document.getElementById('modals-root'),
  );
}

ModalImages.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
