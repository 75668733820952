export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? 'CPM' : 'CPM',
    tip: language === 'pt-BR'
      ? 'Investimento Perf / (Impressões / 1000)'
      : 'Investment Perf / (Impressions / 1000)',
  };
}
