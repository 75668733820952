export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    language,
    subtitle: language === 'pt-BR'
      ? 'Confira o share do KPI escolhido por plataforma, canais e UTM Campanha.'
      : 'Check out the share of the chosen KPI by platform, channels and UTM Campaign.',
  };
}
