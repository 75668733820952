import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import Loader from '../../../../../../../../components/Loader';
import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { filterArrOfValues, filterArrOfValuesByDate } from '../../../../../../../../helpers/filterArrayByFunnel';
import { DataContext } from '../../../../../context/Data';
import { Translate } from './translate';

const ConversionRateLeadsSessionsTrReturn = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    leadsGoogleAnalyticsByFunnelCurrent, leadsGoogleAnalyticsByFunnelPrevious, leadsGoogleAnalyticsByFunnelYoY, leadsGoogleAnalyticsByFunnelPreviousComplete, leadsGoogleAnalyticsByFunnelForecast, isLoandingGALeadsData, isLoandingFunnelData,
    googleAnalyticsByFunnelCurrent, googleAnalyticsByFunnelPrevious, googleAnalyticsByFunnelYoY, googleAnalyticsByFunnelPreviousComplete, googleAnalyticsForecast,
  } = useContext(DataContext);

  const datasAreLoading = !!(isLoandingGALeadsData || isLoandingFunnelData);

  const funnelFilter = '4';

  const objectOne = 'leads';
  const objectTwo = 'session';

  const current = (Number(filterArrOfValues(leadsGoogleAnalyticsByFunnelCurrent, funnelFilter)?.[objectOne])
                  / Number(filterArrOfValues(googleAnalyticsByFunnelCurrent, funnelFilter)?.[objectTwo])) || 0;

  const previous = (Number(filterArrOfValues(leadsGoogleAnalyticsByFunnelPrevious, funnelFilter)?.[objectOne])
                  / Number(filterArrOfValues(googleAnalyticsByFunnelPrevious, funnelFilter)?.[objectTwo])) || 0;

  const yoy = (Number(filterArrOfValues(leadsGoogleAnalyticsByFunnelYoY, funnelFilter)?.[objectOne])
              / Number(filterArrOfValues(googleAnalyticsByFunnelYoY, funnelFilter)?.[objectTwo])) || 0;

  const completeMonth = (Number(filterArrOfValues(leadsGoogleAnalyticsByFunnelPreviousComplete, funnelFilter)?.[objectOne])
                         / Number(filterArrOfValues(googleAnalyticsByFunnelPreviousComplete, funnelFilter)?.[objectTwo])) || 0;

  const forecastDataFirst = filterArrOfValuesByDate(leadsGoogleAnalyticsByFunnelForecast, funnelFilter)?.length > 0 && filterArrOfValuesByDate(leadsGoogleAnalyticsByFunnelForecast, funnelFilter)?.map((data) => ({ date: data.date, value: Number(data[objectOne]) }));
  const forecastDataSecond = filterArrOfValuesByDate(googleAnalyticsForecast, funnelFilter)?.length > 0 && filterArrOfValuesByDate(googleAnalyticsForecast, funnelFilter)?.map((data) => ({ date: data.date, value: Number(data[objectTwo]) }));
  const forecastFirst = forecastDataFirst && (ForecastMonthly(forecastDataFirst) || 0);
  const forecastSecond = forecastDataFirst && (ForecastMonthly(forecastDataSecond) || 0);
  const forecast = forecastFirst / forecastSecond;

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta red';
    }
    return 'delta green';
  }

  return (
    <tr title={translate.tip}>
      {showLevelColumn ? <td>2nd</td> : null}
      {showSourceColumn ? <td>Calc</td> : null}
      <td className="l-return">{translate.kpi}</td>
      <td>
        <Loader loading={datasAreLoading} tofield />
        {!datasAreLoading && numberFormat(current, 'percentage', 2)}
      </td>
      <td>
        <Loader loading={datasAreLoading} tofield />
        {!datasAreLoading && numberFormat(previous, 'percentage', 2)}
      </td>
      <td className={classNameAtrr(deltaNormal)}>
        <Loader loading={datasAreLoading} tofield />
        {!datasAreLoading && numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>
        <Loader loading={datasAreLoading} tofield />
        {!datasAreLoading && numberFormat(yoy, 'percentage', 2)}
      </td>
      <td className={classNameAtrr(deltaYoY)}>
        <Loader loading={datasAreLoading} tofield />
        {!datasAreLoading && numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>
        <Loader loading={datasAreLoading} tofield />
        {!datasAreLoading && numberFormat(forecast, 'percentage', 2)}
      </td>
      <td>
        <Loader loading={datasAreLoading} tofield />
        {!datasAreLoading && numberFormat(completeMonth, 'percentage', 2)}
      </td>
      <td className={classNameAtrr(deltaForecast)}>
        <Loader loading={datasAreLoading} tofield />
        {!datasAreLoading && numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

ConversionRateLeadsSessionsTrReturn.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default ConversionRateLeadsSessionsTrReturn;
