import { isNumberInvalid } from '../../../../../helpers/isNumberInvalid';

export function calculateDataArraysByDate(arr1, arr2, isPercentage = false, divideBy1000 = false) {
  // Função auxiliar para calcular os totais por data e view em um array
  const calculateTotals = (arr) => {
    const totalsMap = {};

    arr.forEach(({ date, data }) => {
      if (!totalsMap[date]) {
        totalsMap[date] = {};
      }
      data.forEach(({ view, total }) => {
        if (!totalsMap[date][view]) {
          totalsMap[date][view] = 0;
        }
        totalsMap[date][view] += isNumberInvalid(Number(total)) ? 0 : total;
      });
    });

    return totalsMap;
  };

  // Calcular totais separadamente para cada array
  const totals1 = calculateTotals(arr1);
  const totals2 = calculateTotals(arr2);

  // Combinar os totais dos dois arrays dividindo os valores
  const result = Object.keys(totals1).map((date) => ({
    date,
    data: Object.keys(totals1[date]).map((view) => {
      const total1 = totals1[date] ? totals1[date][view] : 0;
      const total2 = totals2[date] ? totals2[date][view] : 0;
      return {
        view,
        total:
          divideBy1000
            ? total1 / (total2 / 1000) || 0.0000001
            : isPercentage
              ? (total1 / total2) * 100 || 0.0000001
              : total1 / total2 || 0.000001, // Dividir o total por arr2, se não for zero
      };
    }),
  }));

  return result;
}
