export default function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Editar senha' : 'Update password',
    textMessage: language === 'pt-BR' ? 'Senha deve conter pelo menos 6 dígitos' : 'Password must have at least 6 digits',
    textPassword: language === 'pt-BR' ? 'Nova senha' : 'New Password',
    textConfirmPassword: language === 'pt-BR' ? 'Confirmar senha' : 'Confirm Password',
    errorPassword: language === 'pt-BR' ? 'Menor que 6 letras' : 'Small than 6 letters',
    errorPasswordEquals: language === 'pt-BR' ? 'Ambas as senhas devem ser iguais' : 'Both Passwords must be equals',
    textMessageOnSave: language === 'pt-BR' ? 'Ao salvar, será redirecionado a fazer o login novamente' : 'When saving you will be redirected to login again',
    saveButton: language === 'pt-BR' ? 'Salvar' : 'Save',
    cancelButton: language === 'pt-BR' ? 'Cancelar' : 'Cancel',
  };
}
