import styled from 'styled-components';

export const ContainerUserview = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
    flex-direction: column;
    width: 100%;
  }

  .c-select-fields__container {
    width: 100%;
  }

  .c-select-fields {
    position: relative;
  }

  .c-select-fields select {
    border: 1px solid ${({ theme }) => theme.grayLight};
    width: 150px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
      width: 100%;
    }
  }
`;
