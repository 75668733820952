export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? 'CTR' : 'CTR',
    tip: language === 'pt-BR'
      ? 'Cliques / Impressões'
      : 'Clicks / Impressions',
  };
}
