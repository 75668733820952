export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Edição de produto' : 'Product edit',
    labelName: language === 'pt-BR' ? 'Infos' : 'Infos',
    placeholderPt: language === 'pt-BR' ? 'Nome PT' : 'Name PT',
    placeholderEn: language === 'pt-BR' ? 'Nome EN' : 'Name EN',
    placeholderOrder: language === 'pt-BR' ? 'Ordem' : 'Order',
    language,
  };
}
