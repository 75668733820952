import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { filterArrOfValues, filterArrOfValuesByDate } from '../../../../../../../../helpers/filterArrayByFunnel';
import { DataContext } from '../../../../../context/Data';
import { Translate } from './translate';

const InvestmentTrLower = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    googleAdwordsByFunnelCurrent,
    googleAdwordsByFunnelPrevious,
    googleAdwordsByFunnelYoY,
    googleAdwordsPreviousByFunnelComplete,
    googleAdwordsByFunnelForecast,

    metaAdsByFunnelCurrent,
    metaAdsByFunnelPrevious,
    metaAdsByFunnelYoY,
    metaAdsByFunnelPreviousComplete,
    metaAdsByFunnelForecast,

    linkedinAdsByFunnelCurrent,
    linkedinAdsByFunnelPrevious,
    linkedinAdsByFunnelYoY,
    linkedinAdsByFunnelPreviousComplete,
    linkedinAdsByFunnelForecast,

    googleAnalyticsOthersPlatformsByFunnelCurrent,
    googleAnalyticsOthersPlatformsByFunnelPrevious,
    googleAnalyticsOthersPlatformsByFunnelYoY,
    googleAnalyticsOthersPlatformsByFunnelPreviousComplete,
    googleAnalyticsOthersPlatformsByFunnelForecast,
  } = useContext(DataContext);

  const funnelFilter = '3';
  const object = 'cost';

  const current = (
    (Number(filterArrOfValues(googleAdwordsByFunnelCurrent, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(metaAdsByFunnelCurrent, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(linkedinAdsByFunnelCurrent, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(googleAnalyticsOthersPlatformsByFunnelCurrent, funnelFilter)?.[object]) || 0)
  );

  const previous = (
    (Number(filterArrOfValues(googleAdwordsByFunnelPrevious, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(metaAdsByFunnelPrevious, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(linkedinAdsByFunnelPrevious, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(googleAnalyticsOthersPlatformsByFunnelPrevious, funnelFilter)?.[object]) || 0)
  );

  const yoy = (
    (Number(filterArrOfValues(googleAdwordsByFunnelYoY, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(metaAdsByFunnelYoY, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(linkedinAdsByFunnelYoY, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(googleAnalyticsOthersPlatformsByFunnelYoY, funnelFilter)?.[object]) || 0)
  );

  const completeMonth = (
    (Number(filterArrOfValues(googleAdwordsPreviousByFunnelComplete, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(metaAdsByFunnelPreviousComplete, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(linkedinAdsByFunnelPreviousComplete, funnelFilter)?.[object]) || 0)
      + (Number(filterArrOfValues(googleAnalyticsOthersPlatformsByFunnelPreviousComplete, funnelFilter)?.[object]) || 0)
  );

  const forecastGoogleAdwords = filterArrOfValuesByDate(googleAdwordsByFunnelForecast, funnelFilter)?.length > 0
                                && filterArrOfValuesByDate(googleAdwordsByFunnelForecast, funnelFilter)?.map((data) => ({ date: data.date, value: Number(data[object]) }));

  const forecastMetaAds = filterArrOfValuesByDate(metaAdsByFunnelForecast, funnelFilter)?.length > 0
                          && filterArrOfValuesByDate(metaAdsByFunnelForecast, funnelFilter)?.map((data) => ({ date: data.date, value: Number(data[object]) }));

  const forecastLinkedinAds = filterArrOfValuesByDate(linkedinAdsByFunnelForecast, funnelFilter)?.length > 0
                              && filterArrOfValuesByDate(linkedinAdsByFunnelForecast, funnelFilter)?.map((data) => ({ date: data.date, value: Number(data[object]) }));

  const forecastGoogleAnalitycsOthersPlatforms = filterArrOfValuesByDate(googleAnalyticsOthersPlatformsByFunnelForecast, funnelFilter)?.length > 0
                                                && filterArrOfValuesByDate(googleAnalyticsOthersPlatformsByFunnelForecast, funnelFilter)?.map((data) => ({ date: data.date, value: Number(data[object]) }));

  const forecast = (ForecastMonthly(forecastGoogleAdwords) || 0)
                    + (ForecastMonthly(forecastMetaAds) || 0)
                    + (ForecastMonthly(forecastLinkedinAds) || 0)
                    + (ForecastMonthly(forecastGoogleAnalitycsOthersPlatforms) || 0);

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta green';
    }
    return 'delta red';
  }

  return (
    <tr>
      {showLevelColumn ? <td>1st & 2nd</td> : null}
      {showSourceColumn ? <td>APIs/GA</td> : null}
      <td className="l-lower">{translate.kpi}</td>
      <td>{numberFormat(current, 'currency', 0)}</td>
      <td>{numberFormat(previous, 'currency', 0)}</td>
      <td className={classNameAtrr(deltaNormal)}>
        {numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(yoy, 'currency', 0)}</td>
      <td className={classNameAtrr(deltaYoY)}>
        {numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(forecast, 'currency', 0)}</td>
      <td>{numberFormat(completeMonth, 'currency', 0)}</td>
      <td className={classNameAtrr(deltaForecast)}>
        {numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

InvestmentTrLower.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default InvestmentTrLower;
