/* eslint-disable no-param-reassign */
import {
  createContext, useState, useEffect, useMemo, useContext,
} from 'react';
import FeedService from '../../services/Feed';
import VideoLikeService from '../../services/VideosLike';
import { AuthContext } from '../AuthContext';

const FeedContext = createContext();

function FeedProvider({ children }) {
  const [videosFeed, setVideosFeed] = useState([]);
  const [isLoadingVideos, setIsLoadingVideos] = useState(true);
  const { userInfo: { email } } = useContext(AuthContext);

  useEffect(() => {
    async function init() {
      setIsLoadingVideos(true);
      await getVideos();
      setIsLoadingVideos(false);
    }

    init();
  }, []);

  async function getVideos() {
    let feedFromLocalStorage = localStorage.getItem('feed');

    if (feedFromLocalStorage) {
      feedFromLocalStorage = JSON.parse(feedFromLocalStorage)[0];
      const isSameDay = new Date(feedFromLocalStorage.updated).getDate() === new Date().getDate();
      const isSameMonth = new Date(feedFromLocalStorage.updated).getMonth() + 1 === new Date().getMonth() + 1;
      const isSameYear = new Date(feedFromLocalStorage.updated).getFullYear() === new Date().getFullYear();
      const isSameDate = isSameDay && isSameMonth && isSameYear;
      const emailLocalStorage = feedFromLocalStorage.email;
      const isSameUser = email === emailLocalStorage;

      if (isSameDate && isSameUser) {
        const videos = feedFromLocalStorage.videos;
        videos.map((video) => ({ ...video, opened: false }));
        setVideosFeed(videos);
      } else {
        await getVideosFromDB();
      }
    } else {
      await getVideosFromDB();
    }
  }

  async function getVideosFromDB() {
    if (videosFeed.length === 0) {
      const videos = await FeedService.findAll();
      const videosMapped = videos?.map((video) => ({ ...video, opened: false })) || [];
      setVideosFeed(videosMapped);
      addVideosListAtLocalStorage(videosMapped);
    }
  }

  function addVideosListAtLocalStorage(videosList) {
    localStorage.setItem('feed', JSON.stringify([{ videos: videosList, updated: new Date(), email }]));
  }

  async function handleLike(id) {
    const updatedVideosFeed = await Promise.all(videosFeed.map(async (video) => {
      if (video.id === id) {
        if (video.userLiked === false) {
          await VideoLikeService.store({ idVideo: id });
          video.userLiked = true;
          video.totalLikes += 1;
        } else {
          await VideoLikeService.delete({ idVideo: id });
          video.userLiked = false;
          if (video.totalLikes === 0) return;
          video.totalLikes -= 1;
        }
      }
      return video;
    }));

    setVideosFeed(updatedVideosFeed);
    addVideosListAtLocalStorage(updatedVideosFeed);
  }

  function handleToogleDescription(id) {
    const newData = videosFeed.map((item) => {
      if (item.id === id) item.opened = !item.opened;
      return item;
    });

    setVideosFeed(newData);
    addVideosListAtLocalStorage(newData);
  }

  const contextValues = useMemo(() => ({
    videosFeed,
    isLoadingVideos,
    handleLike,
    handleToogleDescription,
  }), [videosFeed, isLoadingVideos, handleLike, handleToogleDescription]);

  return (
    <FeedContext.Provider value={contextValues}>
      {children}
    </FeedContext.Provider>
  );
}

export { FeedContext, FeedProvider };

