import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import { ModulesContext } from '../../../context/ModulesContext';
import { FeedContext } from '../../../context/FeedContext';
import Square from '../../../components/Square';
import Loader from '../../../components/Loader';
import { ButtonLink } from '../../../components/ButtonLink';
import Translate from './translate';
import {
  Container, TitleContainer, ContainerBody, VideoRow,
} from './style';


export default function Home() {
  const translate = Translate();
  const [userAllowedFeed, setUserAllowedFeed] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(true);
  const { userInfo: { firstName }, accessHash } = useContext(AuthContext);
  const { videosFeed, isLoadingVideos } = useContext(FeedContext);
  const { modules } = useContext(ModulesContext);

  useEffect(() => {
    verifyFeedPermission();
  }, [accessHash, modules]);

  function verifyFeedPermission() {
    if (modules.length > 0) {
      setLoadingPermission(true);
      const userAllowFeed = accessHash.includes(modules?.filter((item) => item.id === 19).map((item) => item.accessHash)[0]);
      setUserAllowedFeed(userAllowFeed);
      setLoadingPermission(false);
    }
  }

  const link = translate.language === 'pt-BR'
    ? 'https://player.vimeo.com/video/981451617?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
    : 'https://player.vimeo.com/video/981451679?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';

  return (
    <>
      <Container>

        {/* Welcome + Video */}
        <Square size="medium">
          <TitleContainer>
            <h4>{translate.welcomeText}{', '}{firstName}</h4>
          </TitleContainer>
          <div style={{
            padding: '56.25% 0 0 0', position: 'relative',
          }}
          >
            <iframe
              src={link}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              style={{
                position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', borderRadius: '4px',
              }}
              title="Crescimento Cruzado - Introdução - 20210802"
            />
          </div>
        </Square>

        {/* Feed */}
        <Square size="medium">
          <TitleContainer>
            <h4>{translate.titleFeed}</h4>
          </TitleContainer>
          {(isLoadingVideos) && <Loader loading={(isLoadingVideos)} />}
          {(!isLoadingVideos && userAllowedFeed) && (
            <ContainerBody>
              <div className="container-video">
                {videosFeed?.map((video) => (
                  <VideoRow key={video.id}>
                    <span>{translate.language === 'pt-BR' ? video.title_pt : video.title_en}</span>
                    <ButtonLink color="ghost" to="/client/feed">{translate.btnWatch}</ButtonLink>
                  </VideoRow>
                )).slice(0, 3)}
                <span>{translate.msgTwoMorevideos}</span>
              </div>
              <Link to="/client/feed" className="container-button">
                {translate.btnLinkToFeed}
              </Link>
            </ContainerBody>
          )}
          {(!isLoadingVideos && !userAllowedFeed) && (
            <ContainerBody>
              <div className="no-access-container">
                <p>{translate.msgRestrictedAccess} <br /> {translate.msgAdmContact}</p>
              </div>
            </ContainerBody>
          )}
        </Square>
      </Container>
    </>
  );
}
