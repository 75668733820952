import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction:column ;
  gap: ${({ theme }) => theme.sizes.s20};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  h3 {
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-bottom: ${({ theme }) => theme.sizes.s16};
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
      gap: 0;
      margin-bottom: 0px;
    }

    small {
      color: ${(props) => props.theme.red};
      height: 12px;

      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-bottom: 12px;
      }
    }
  }

  select, input {
    border-color: ${(props) => props.theme.grayLight};
    margin-right: ${(props) => props.theme.sizes.s8};
    width: 300px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }
  }
`;

export const AlertMessage = styled.p`
  color: ${({ theme }) => theme.red};
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${(props) => props.theme.sizes.s40};
  width: 100%;

  div {
    margin-bottom: ${(props) => props.theme.sizes.s20};
  }
`;
