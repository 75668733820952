import React, { memo, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { IoCloseCircleSharp } from 'react-icons/io5';
import * as S from './styled';

function Toast({ message, onRemoveToast }) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onRemoveToast(message.id);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [onRemoveToast, message.id]);

  function handleRemoveToast() {
    onRemoveToast(message.id);
  }

  return (
    <S.Container type={message.type}>
      <strong>{message.text}</strong>
      <IoCloseCircleSharp size={22} onClick={handleRemoveToast} />
    </S.Container>
  );
}

Toast.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'danger', 'default']),
  }).isRequired,
  onRemoveToast: PropTypes.func.isRequired,
};

export default memo(Toast);
