export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Comentário: ' : 'Comment: ',
    buttonMsg: language === 'pt-BR' ? 'Enviar' : 'Send',
    successMsg: language === 'pt-BR'
      ? 'Comentário enviado com sucesso. Comentário será avaliado pelo moderador'
      : 'Comment sent successfully. Comment will be evaluated by the moderator',
    language,
  };
}
