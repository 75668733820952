import styled from 'styled-components';

export const InputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  /* margin-bottom: 32px; */

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
    flex-direction: column;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    select {
      width: 100%;
      border-color: ${({ theme }) => theme.grayLight};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet} ) {
        max-width: none;
      }
    }
  }
`;
