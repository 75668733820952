import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import CategoryProductsService from '../../../../../services/CategoryProduct';
import TitlePage from '../../../../../components/TitlePage';
import { Button } from '../../../../../components/Button';
import Loader from '../../../../../components/Loader';
import { NoDataMessage } from '../../../../../components/NoDataMessage';
import toast from '../../../../../helpers/toast';
import { Translate } from './translate';
import { Container, ListContainer, AreaDragDrop } from './style';
import CategoryItem from './CategoryItem';

function CategoryByProduct() {
  const translate = Translate();
  const { id } = useParams();
  const location = useLocation();
  const nameParam = new URLSearchParams(location.search).get('name') || '';
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [btnOrderingIsDisabled, setBtnOrderingIsDisabled] = useState(false);

  useEffect(() => {
    getCategoriesProduct();
  }, []);

  async function getCategoriesProduct() {
    try {
      setIsLoading(true);
      const categoriesProduct = await CategoryProductsService.findAll(id);
      setCategories(categoriesProduct);
    } catch {} finally {
      setIsLoading(false);
    }
  }

  async function handleDelete(id) {
    try {
      await CategoryProductsService.delete(id);
      getCategoriesProduct();
    } catch (error) {
      toast('danger', error.message);
    }
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const newList = reorder(categories, startIndex, endIndex);
    setCategories(newList);
  }

  async function updateOrdering() {
    try {
      setBtnOrderingIsDisabled(true);
      await CategoryProductsService.updateOrdering(categories);
      toast('success', 'Ordering updated');
      getCategoriesProduct();
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setBtnOrderingIsDisabled(false);
    }
  }

  return (
    <>
      <TitlePage title={`${translate.title} | ${nameParam}`}>
        {categories.length > 0 && (
          <Button color="blue" onClick={updateOrdering} disabled={btnOrderingIsDisabled}>{btnOrderingIsDisabled ? 'Loading Ordering' : translate.btnUpdateOrdering}</Button>
        )}
      </TitlePage>
      <Container>
        <ListContainer>
          <Loader loading={isLoading} />
          {!isLoading && (
            categories.length === 0
              ? <NoDataMessage>{translate.categoryInfo}</NoDataMessage>
              : categories && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="categoryDragDrop" direction="vertical" type="list">
                    {(provided) => (
                      <AreaDragDrop
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {categories.map((categoryProduct, index) => (
                          <CategoryItem key={categoryProduct.id} category={categoryProduct} onDelete={handleDelete} index={index} />
                        ))}
                        {provided.placeholder}
                      </AreaDragDrop>
                    )}
                  </Droppable>
                </DragDropContext>
              )
          )}
        </ListContainer>
      </Container>
    </>
  );
}

export default CategoryByProduct;
