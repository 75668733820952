import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import UTMService from '../../../../../services/UTM';
import Event from '../../../../../lib/EventEmitter';
import Loader from '../../../../../components/Loader';
import { Translate } from './translate';
import IconAdd from '../../../../../styles/logos/add.svg';

function Channel({
  value, setValue, onToogleModal, setUtmCategoryToAdd, tipOfAddParams,
}) {
  const translate = Translate();
  const [paramsCustomList, setParamsCustomList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idParam] = useState(19);

  useEffect(() => {
    getCustomsUTMSources();
    Event.addListener('IDCustomParamAdded', (idParamToAdd) => {
      if (idParamToAdd === idParam) {
        getCustomsUTMSources();
      }
    });
  }, []);

  async function getCustomsUTMSources() {
    setIsLoading(true);
    const listofParamsCustom = await UTMService.getAllBYCategoryUTM(idParam);
    setParamsCustomList(listofParamsCustom);
    setIsLoading(false);
  }

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, channel: event.target.value }));
  }

  function handleToogleModal() {
    setUtmCategoryToAdd(idParam);
    onToogleModal();
  }

  return (
    <div className="form-item">
      <label htmlFor="channel">
        {translate.label}
        <img src={IconAdd} alt="Add" title={tipOfAddParams} onClick={handleToogleModal} />
      </label>
      <select value={value} onChange={handleSelect} id="channel" disabled={isLoading}>
        <option value="" className="noneParam">None</option>
        {paramsCustomList && paramsCustomList.map((item) => (
          <option key={item.id} value={item.value} className="customParam">
            {' '}
            {item.name}
            {' '}
          </option>
        ))}

        <option value="bing">bing</option>
        <option value="criteo">criteo</option>
        <option value="email-base">email-base</option>
        <option value="email-parceiros">email-parceiros</option>
        <option value="facebook">facebook</option>
        <option value="globo">globo</option>
        <option value="google">google</option>
        <option value="instagram">instagram</option>
        <option value="meta">meta</option>
        <option value="offline">offline</option>
        <option value="outdoors">outdoors</option>
        <option value="pinterest">pinterest</option>
        <option value="rtb-house">rtb-house</option>
        <option value="sms">sms</option>
        <option value="youtube">youtube</option>
      </select>
      { isLoading && <Loader loading={isLoading} tofield /> }
    </div>
  );
}

Channel.propTypes = {
  value: PropTypes.string.isRequired,
  tipOfAddParams: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onToogleModal: PropTypes.func.isRequired,
  setUtmCategoryToAdd: PropTypes.func.isRequired,
};

export default Channel;
