import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../../../../context/AuthContext';
import { FilterContext } from '../../context/Filters';
import { DataContext } from '../../context/Data';
import Square from '../../../../../components/Square';
import { Button } from '../../../../../components/Button';
import Loader from '../../../../../components/Loader';
import { Translate } from './translate';
import * as S from './style';

const Filters = React.memo(() => {
  const translate = Translate();

  const {
    accountName,
    setAccountName,
    setAccountNameApplied,
    platform,
    setPlatform,
    setPlatformApplied,
    metaId,
    setMetaId,
    setMetaIdApplied,
    metaIdList,
    isLoadingMetaList,
    region,
    setRegion,
    setRegionApplied,
    regionList,
    strategy,
    setStrategy,
    setStrategyApplied,
    strategyList,
    channel,
    setChannel,
    setChannelApplied,
    channelList,
    utmCampaign,
    setUtmCampaign,
    setUtmCampaignApplied,
    statusVtex,
    setStatusVtex,
    setStatusVtexApplied,
    originVtex,
    setOriginVtex,
    setOriginVtexApplied,
    utmCampaignList,
    isLoadingGAData,
  } = useContext(FilterContext);
  const { isLoandingData } = useContext(DataContext);
  const { accountNames, userInfo: { typeClient } } = useContext(AuthContext);
  const [accountNamesList] = useState(accountNames);
  const [localAccountName, setLocalAccountName] = useState(accountName);
  const [localPlatform, setLocalPlatform] = useState('');
  const [localMetaId, setLocalMetaId] = useState('');
  const [localRegion, setLocalRegion] = useState('');
  const [localStrategy, setLocalStrategy] = useState('');
  const [localChannel, setLocalChannel] = useState('');
  const [localUtmCampaign, setLocalUtmCampaign] = useState('');
  const [localStatusVtex, setLocalStatusVtex] = useState('Payment-Pending, Payment-Approved, Ready-for-Handling, Handling, Invoiced, Invoice');
  const [localOriginVtex, setLocalOriginVtex] = useState('');

  useEffect(() => {
    // Atualize os estados globais quando os estados locais mudarem
    setAccountName(localAccountName || accountName);
    setPlatform((localPlatform === '' || localPlatform === 'All') ? '' : localPlatform);
    setMetaId((localMetaId === '' || localMetaId === 'All') ? '' : localMetaId);
    setRegion((localRegion === '' || localRegion === 'All') ? '' : localRegion);
    setStrategy((localStrategy === '' || localStrategy === 'All') ? '' : localStrategy);
    setChannel((localChannel === '' || localChannel === 'All') ? '' : localChannel);
    setUtmCampaign((localUtmCampaign === '' || localUtmCampaign === 'All') ? '' : localUtmCampaign);
    setStatusVtex(localStatusVtex);
    setOriginVtex((localOriginVtex === '' || localOriginVtex === 'All') ? '' : localOriginVtex);
  }, [
    localAccountName,
    localPlatform,
    localMetaId,
    localRegion,
    localStrategy,
    localChannel,
    localUtmCampaign,
    localStatusVtex,
    localOriginVtex,
  ]);

  async function handleSubmit(e) {
    e.preventDefault();
    setAccountNameApplied(accountName || null);
    setPlatformApplied(platform || null);
    setMetaIdApplied(metaId || null);
    setRegionApplied(region || null);
    setStrategyApplied(strategy || null);
    setChannelApplied(channel || null);
    setUtmCampaignApplied(utmCampaign || null);
    if (typeClient === 'VTEX') {
      setStatusVtexApplied(statusVtex);
      setOriginVtexApplied(originVtex || null);
    }
  }

  function handleChangeAccountName(e) {
    setLocalAccountName(e.target.value);
    setLocalPlatform('All');
    setLocalMetaId('All');
    setLocalRegion('All');
    setLocalStrategy('All');
    setLocalChannel('All');
    setLocalUtmCampaign('All');
  }

  function handleChangePlatform(e) {
    if (e.target.value === '') {
      setLocalPlatform('All');
    } else {
      setLocalPlatform(e.target.value);
    }
    setLocalMetaId('All');
    setLocalRegion('All');
    setLocalStrategy('All');
    setLocalChannel('All');
    setLocalUtmCampaign('All');
  }

  function handleChangeMetaId(e) {
    if (e.target.value === '') {
      setLocalMetaId('All');
    } else {
      setLocalMetaId(e.target.value);
    }
    setLocalRegion('All');
    setLocalStrategy('All');
    setLocalChannel('All');
    setLocalUtmCampaign('All');
  }

  function handleChangeRegion(e) {
    if (e.target.value === '') {
      setLocalRegion('All');
    } else {
      setLocalRegion(e.target.value);
    }
    setLocalStrategy('All');
    setLocalChannel('All');
    setLocalUtmCampaign('All');
  }

  function handleChangeStrategy(e) {
    if (e.target.value === '') {
      setLocalStrategy('All');
    } else {
      setLocalStrategy(e.target.value);
    }
    setLocalChannel('All');
    setLocalUtmCampaign('All');
  }

  function handleChangeChannel(e) {
    if (e.target.value === '') {
      setLocalChannel('All');
    } else {
      setLocalChannel(e.target.value);
    }
    setLocalUtmCampaign('All');
  }

  function handleChangeUtmCampaign(e) {
    if (e.target.value === '') {
      setLocalUtmCampaign('All');
    } else {
      setLocalUtmCampaign(e.target.value);
    }
  }

  function handleChangeStatusVtex(e) {
    setLocalStatusVtex(e.target.value);
  }

  function handleChangeOriginVtex(e) {
    if (e.target.value === '') {
      setLocalOriginVtex('All');
    } else {
      setLocalOriginVtex(e.target.value);
    }
  }

  return (
    <Square size="large">
      <S.ContainerFilters $typeClient={typeClient}>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <legend>{translate.legendFirstLevel}</legend>
            <div className="group">
              <div className="form-group">
                <label htmlFor="vista" title={translate.userViewTip}>
                  {translate.userView}
                </label>
                <select name="vista" value={localAccountName} onChange={handleChangeAccountName}>
                  {accountNamesList?.map((accountName) => <option key={accountName} value={accountName}>{accountName}</option>)}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="platform" title={translate.platformTip}>
                  {translate.platform}
                </label>
                <select name="platform" value={localPlatform} onChange={handleChangePlatform}>
                  <option value="">All</option>
                  <option value="Google Ads">Google Ads</option>
                  <option value="Meta Ads">Meta Ads</option>
                  <option value="LinkedIn Ads">LinkedIn Ads</option>
                  <option value="Other Sources">Other Platforms</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="meta-id">
                  Meta ID
                </label>
                <select name="meta-id" disabled={isLoadingMetaList} value={localMetaId} onChange={handleChangeMetaId}>
                  <option value="">All</option>
                  {metaIdList && metaIdList.map((metaId) => (
                    <option key={metaId} value={metaId}>{metaId}</option>
                  ))}
                </select>
                <Loader loading={isLoadingMetaList} tofield />
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>2nd Level - Site Data</legend>
            <div className="group">
              <div className="form-group">
                <label htmlFor="region" title={translate.regionTip}>
                  {translate.region}
                </label>
                <select name="region" disabled={isLoadingGAData} value={localRegion} onChange={handleChangeRegion}>
                  <option value="">All</option>
                  {regionList && regionList.map((region) => (
                    <option key={region} value={region}>{region}</option>
                  ))}
                </select>
                <Loader loading={isLoadingGAData} tofield />
              </div>
              <div className="form-group">
                <label htmlFor="strategy" title={translate.strategyTip}>
                  {translate.strategy}
                </label>
                <select name="strategy" disabled={isLoadingGAData} value={localStrategy} onChange={handleChangeStrategy}>
                  <option value="">All</option>
                  {strategyList && strategyList.map((strategy) => (
                    <option key={strategy} value={strategy}>{strategy}</option>
                  ))}
                </select>
                <Loader loading={isLoadingGAData} tofield />
              </div>
              <div className="form-group">
                <label htmlFor="channel" title={translate.channelTip}>
                  {translate.channel}
                </label>
                <select name="channel" disabled={isLoadingGAData} value={localChannel} onChange={handleChangeChannel}>
                  <option value="">All</option>
                  {channelList && channelList.map((channel) => (
                    <option key={channel} value={channel}>{channel}</option>
                  ))}
                </select>
                <Loader loading={isLoadingGAData} tofield />
              </div>
              <div className="form-group">
                <label htmlFor="utm_campaign" title={translate.utmCampaignTip}>
                  UTM Campaign
                </label>
                <select name="utm_campaign" disabled={isLoadingGAData} value={localUtmCampaign} onChange={handleChangeUtmCampaign}>
                  <option value="">All</option>
                  {utmCampaignList && utmCampaignList.map((utmCampaign) => (
                    <option key={utmCampaign} value={utmCampaign}>{utmCampaign}</option>
                  ))}
                </select>
                <Loader loading={isLoadingGAData} tofield />
              </div>
            </div>
          </fieldset>

          {typeClient === 'VTEX' && (
            <fieldset>
              <legend>3rd Level - CRM/VTEX Data</legend>
              <div className="group">
                <div className="form-group">
                  <label htmlFor="status_vtex" title={translate.utmCampaignTip}>
                    Status
                  </label>
                  <select name="status_vtex" value={statusVtex} onChange={handleChangeStatusVtex}>
                    <option value="Payment-Pending, Payment-Approved, Ready-for-Handling, Handling, Invoiced, Invoice, Canceled">Payment-Pending + Payment-Approved + Ready-for-Handling + Handling + Invoiced + Invoice + Canceled</option>
                    <option value="Payment-Pending, Payment-Approved, Ready-for-Handling, Handling, Invoiced, Invoice">Payment-Pending + Payment-Approved + Ready-for-Handling + Handling + Invoiced + Invoice</option>
                    <option value="Payment-Approved, Ready-for-Handling, Handling, Invoiced, Canceled">Payment-Approved + Ready-for-Handling + Handling + Invoiced + Canceled</option>
                    <option value="Payment-Approved, Ready-for-Handling, Handling, Invoiced, Invoice">Payment-Approved + Ready-for-Handling + Handling + Invoiced + Invoice</option>
                    <option value="Payment-Pending">Payment-Pending</option>
                    <option value="Payment-Approved">Payment-Approved</option>
                    <option value="Ready-for-Handling">Ready-for-Handling</option>
                    <option value="Handling">Handling</option>
                    <option value="Invoiced">Invoiced</option>
                    <option value="Invoice">Invoice</option>
                    <option value="Canceled">Canceled</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="status_vtex" title={translate.utmCampaignTip}>
                    {translate.labelOrigin}
                  </label>
                  <select name="status_vtex" value={originVtex} onChange={handleChangeOriginVtex}>
                    <option value="">All</option>
                    <option value="Marketplace">Marketplace</option>
                    <option value="Ecom, Tmkt">Ecom + Tmkt</option>
                    <option value="Ecom">Ecom</option>
                    <option value="Tmkt">Tmkt</option>
                  </select>
                </div>
              </div>
            </fieldset>
          )}

          <div className="group">
            <Button
              color="blue"
              type="submit"
              disabled={(isLoadingGAData || isLoadingMetaList || isLoandingData)}
            >
              {translate.apply}
            </Button>
          </div>
        </form>
      </S.ContainerFilters>
    </Square>
  );
});

export default Filters;
