export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Editar Categoria' : 'Edit Category',
    textName: language === 'pt-BR' ? 'Nome' : 'Name',
    textSubCategory: language === 'pt-BR' ? 'Tem SubCategoria?' : 'Has SubCategory?',
    textProduct: language === 'pt-BR' ? 'Produto' : 'Product',
    warningProduct: language === 'pt-BR' ? 'Faça o vínculo de um produto para a categoria' : 'Link a product to the category',
    warningSubCategory: language === 'pt-BR' ? 'Escolha "Y" para habilitar subcategorias a esta categoria. Ao escolher "N", é possível fazer o vínculo diretamente de vídeos a esta Categoria' : 'Choose "Y" to enable subcategories for this category. By choosing "N", you can directly link videos to this Category',
    warningSlug: language === 'pt-BR'
      ? 'Não é permitido caracteres especiais e espaços'
      : 'Special characters and spaces are not allowed',
    textOrdering: language === 'pt-BR' ? 'Ordem' : 'Order',
    labelNamePt: language === 'pt-BR' ? 'Nome PT' : 'Name PT',
    labelNameEn: language === 'pt-BR' ? 'Nome EN' : 'Name EN',
    language,
  };
}
