export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Lista de Produtos' : 'List of Products',
    addButton: language === 'pt-BR' ? 'Adicionar Novo' : 'Add New',
    textActive: language === 'pt-BR' ? 'Ativo? ' : 'Active? ',
    textYes: language === 'pt-BR' ? 'Sim' : 'Yes',
    textNo: language === 'pt-BR' ? 'Não' : 'No',
    msg: language === 'pt-BR' ? 'Produtos não carregados' : 'Products not loaded',
    language,
  };
}
