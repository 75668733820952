/* eslint-disable no-restricted-syntax */
import React from 'react';
import ApexChart from 'react-apexcharts';
import { isNumberInvalid } from '../../../../../../helpers/isNumberInvalid';
import DatesNew from '../../../../../../helpers/Dates_New';
import './Chart.css';

export function ChartLine({ kpiInfo, showTotalOnChartLine }) {
  function generateArrayOfValuesFromView(arrByDate, groupBy) {
    const items = arrByDate?.map((objItem) => objItem.data);
    const arrayOfValues = [];

    for (let item of items) {
      let found = false; // Flag para verificar se encontramos a view
      for (let obj of item) {
        if (obj.view === groupBy) {
          const total = isNumberInvalid(Number(obj.total.toFixed(2))) ? 0 : Number(obj.total.toFixed(2));
          arrayOfValues.push(total);
          found = true;
          break; // Parar a busca após encontrar a view desejada
        }
      }
      if (!found) {
        arrayOfValues.push(0); // Se não encontrarmos, adicionar 0
      }
    }
    return arrayOfValues;
  }

  function getMaxValue(arrOfSeries) {
    const newArrs = arrOfSeries.map((item) => item.data);
    let maxValue = 0;
    for (let arr of newArrs) {
      for (let values of arr) {
        if (values > maxValue) maxValue = values;
      }
    }
    return maxValue;
  }

  let days = [];
  let maxValue = 0;
  const newSeries = [];

  if (kpiInfo) {
    const { data, dataByDate } = kpiInfo;
    days = dataByDate && dataByDate.map((item) => DatesNew.formatDatesMask(item.date));
    const top5Views = data.map((item) => item.view).slice(0, 10);
    for (let item of top5Views) {
      const obj = {
        name: item.substring(0, 12),
        data: dataByDate ? generateArrayOfValuesFromView(dataByDate, item) : [],
      };
      newSeries.push(obj);
    }
    maxValue = newSeries.length > 0 && getMaxValue(newSeries);
  }

  const confs = {
    series: newSeries,
    options: {
      chart: {
        fontFamily: 'noway, Arial, sans-serif',
        height: 280,
        width: '100%',
        type: 'line',
        dropShadow: {
          enabled: false,
          color: '#FFF',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: true,
          style: {
            fontSize: '12px',
            fontFamily: 'noway, Arial, sans-serif',
            color: '#000', // Mudança da cor da fonte para preto
          },
        },
      },
      responsive: [{
        breakpoint: 768,
        options: {
          chart: {
            height: 400,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            floating: false,
          },
        },
      }],
      colors: ['#6EDCE6', '#FC4C02', '#00D689', '#FFD100', '#B26161'],
      dataLabels: {
        enabled: showTotalOnChartLine,
      },
      stroke: {
        curve: 'straight',
        lineCap: 'round',
        width: [2, 2, 2, 2, 2],
      },
      // title: {
      //   text: 'Top 5 By Channel',
      //   align: 'left',
      //   style: {
      //     color: '#FFF',
      //   },
      // },
      grid: {
        borderColor: '#363636',
        row: {
          colors: undefined, // takes an array which will be repeated on columns
          opacity: 'none',
        },
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: days,
        title: {
          text: undefined,
          style: {
            color: '#FFF',
          },
        },
        labels: {
          style: {
            colors: '#FFF',
          },
        },
      },
      yaxis: {
        showAlways: true,
        showForNullSeries: true,
        tickAmount: 4,
        logarithmic: false,
        title: {
          text: undefined,
        },
        labels: {
          style: {
            colors: '#FFF',
          },
        },
        min: 0,
        max: (maxValue * 0.02) + maxValue, // ? Tamanho máximo eixo y
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        floating: false,
        offsetY: 20,
        fontSize: '11px',
        // offsetX: -10,
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      tooltip: {
        followCursor: false,
        theme: 'dark',
        // Configuração do formato da tooltip
        formatter: (val) => val.toFixed(2),
      },
    },


  };
  return (
    <ApexChart
      options={confs.options}
      series={confs.series}
      type={confs.options.chart.type}
      width={confs.options.chart.width}
      theme="dark"
    />
  );
}


