class URLBuilder {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async getAll() {
    const response = await fetch(`${this.baseUrl}/urlbuilder`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();
    if (!response.ok) {
      throw new Error(response.message);
    }
    return body;
  }

  async getOne({ idUrl }) {
    const response = await fetch(`${this.baseUrl}/urlbuilder/${idUrl}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    const body = await response.json();
    if (!response.ok) {
      throw new Error(response.message);
    }
    return body;
  }

  /* eslint-disable-next-line camelcase */
  async add({
    urlFinal, urlMasked, urlShortened, utms,
  }) {
    const response = await fetch(`${this.baseUrl}/urlbuilder`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        url: urlFinal, urlMasked, urlShortened, utms,
      }),
    });
    const body = await response.json();
    if (!response.ok) {
      throw new Error(response.message);
    }
    return body;
  }

  async edit(id, value) {
    if (!id || !value) throw new Error('Id or value is required');

    const response = await fetch(`${this.baseUrl}/urlbuilder`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify(id, value),
    });

    const body = await response.json();
    if (!response.ok) {
      throw new Error(response.message);
    }
    return body;
  }

  async delete(id) {
    if (!id) throw new Error('Id or value is required');

    const response = await fetch(`${this.baseUrl}/urlbuilder/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();
    if (!response.ok) {
      throw new Error(response.message);
    }
    return body;
  }
}

export default new URLBuilder();
