import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Translate } from './translate';

function Funnel({ value, setValue }) {
  const translate = Translate();

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, funnel: event.target.value }));
  }

  return (
    <div>
      <label htmlFor="funnel">{translate.label}</label>
      <select value={value} onChange={handleSelect} name="funnel">
        <option value="" className="noneParam">None</option>
        <option value="top">top</option>
        <option value="mid">mid</option>
        <option value="lower">lower</option>
        <option value="return">return</option>
      </select>
    </div>
  );
}

Funnel.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default Funnel;
