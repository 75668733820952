import React from 'react';
import { Link } from 'react-router-dom';
import { Draggable } from '@hello-pangea/dnd';
import { Badge } from '../../../../../components/Badge';
import FaEditIcon from '../../../../../styles/logos/FaEdit.svg';
import AddVideoIcon from '../../../../../styles/logos/add_video.svg';
import EditVideoIcon from '../../../../../styles/logos/edit_video.svg';
import TrashIcon from '../../../../../styles/logos/trash-alt.svg';
import IconDragDrop from '../../../../../styles/logos/icon_dragdrop.svg';
import { Translate } from './translate';
import { TitleContainer, SquareContainer } from './style';

export function PostItem({ post, deletePost, index }) {
  const translate = Translate();

  return (
    <Draggable draggableId={post.id.toString()} index={index}>
      {(provided) => (
        <SquareContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <TitleContainer>
            <div className="c-square">
              <div className="c-square-name">
                <img className="c-icon-drag" src={IconDragDrop} alt="Drag and drop" />
                {post.name_pt } | {post.name_en }
              </div>
              <Badge color={post.product_color}>
                {translate.language === 'pt-BR' ? post.product_name_pt : post.product_name_en}
              </Badge>
              <Badge>
                SubCategory {'>'} {translate.language === 'pt-BR' ? post.subcategory_name_pt : post.subcategory_name_en}
              </Badge>
            </div>
            <div className="c-square-action">
              <input type="text" id="order" value={post.ordering} disabled />
              {post.video_id ? (
                <Link to={`/admin/videos/edit/${post.video_id}`} title="Edit Video">
                  <img src={EditVideoIcon} alt="Edit Video" />
                </Link>
              ) : (
                <Link to={`/admin/videos/add?type=post&title_pt=${post.name_pt}&title_en=${post.name_en}&product=${post.product_id}&post=${post.id}`} title="Add Video">
                  <img src={AddVideoIcon} alt="Add Video" />
                </Link>
              )}
              <Link to={`/admin/posts/${post.id}`} title="Edit Post">
                <img src={FaEditIcon} alt="Edit Post" />
              </Link>
              <img className="icon-trash" onClick={() => deletePost(post.id)} title="Delete Post" src={TrashIcon} alt="Delete Post" />
            </div>
          </TitleContainer>
        </SquareContainer>
      )}
    </Draggable>
  );
}
