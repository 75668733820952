class Feed {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findAll() {
    const response = await fetch(`${this.baseUrl}/feed`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });
    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }
}

export default new Feed();
