import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../styles/logos/loader.svg';

import * as S from './style';

export default function Loader({ loading, tofield = false }) {
  if (!loading) return null;

  return (
    <S.LoaderArea className="loader" $tofield={tofield}>
      <img src={loader} alt="Loading" />
    </S.LoaderArea>
  );
}

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  tofield: PropTypes.bool,
};
