import { useContext } from 'react';
import { FilterContext } from '../../../../../context/Filters';

export function Translate() {
  const { leadType } = useContext(FilterContext);
  const leadName = leadType === null || leadType === '' ? '- All' : `- ${leadType}`;

  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? `T. Conversão Eventos (S) ${leadName} - Mid` : `Conv. Rate Events (S) ${leadName} - Mid`,
    tip: language === 'pt-BR'
      ? `Eventos ${leadName} - Mid / Sessões - Mid`
      : `Events ${leadName} - Mid / Sessions - Mid`,
  };
}
