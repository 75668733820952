import styled from 'styled-components';

export const ContainerFunnel = styled.div`
  width: 100%;
  height: 320px;

  .apexcharts-svg {
    background-color: transparent !important;
  }

  .apexcharts-toolbar {
    z-index: 2 !important;
  }

 .apexcharts-legend {
    display: none !important;
  }

  .apexcharts-menu-item {
    color: #F1F1F1 !important;
  }

  .apexcharts-tooltip {
    /* background-color: #333 !important; */
    font-size: 14px !important;
  }


`;
