import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import Loader from '../../../../../../components/Loader';
import { FilterContext } from '../../../context/Filters';

import { Translate } from './translate';

import * as S from './style';

function InputsFromCard({ userView, setUserView }) {
  const translate = Translate();
  const { type_client: typeClient } = JSON.parse(localStorage.getItem('clientInfo'));
  const { isLoadingLeadsTypeList, leadsTypeList, setLeadType } = useContext(FilterContext);
  const [localLeadType, setLocalLeadType] = useState('');

  useEffect(() => {
    setLeadType((localLeadType === '' || localLeadType === 'All') ? null : localLeadType);
  }, [localLeadType]);

  function handleUserView(e) {
    setUserView(e.target.value);
  }

  function handleLocalLeadType(e) {
    setLocalLeadType(e.target.value);
  }

  return (
    <S.ContainerUserview>
      <div className="c-select-fields__container">
        <label htmlFor="events_leads">{translate.labelEvents}</label>
        <div className="c-select-fields">
          <select name="events_leads" value={localLeadType} onChange={handleLocalLeadType} disabled={isLoadingLeadsTypeList}>
            <option value="">All</option>
            {leadsTypeList?.map((leadType) => (
              <option key={leadType} value={leadType}>{leadType}</option>
            ))}
          </select>
          <Loader loading={isLoadingLeadsTypeList} tofield />
        </div>
      </div>
      <div className="c-select-fields__container">
        <label htmlFor="user_view">{translate.labelUserView}</label>
        <div className="c-select-fields">
          <select name="user_view" value={userView} onChange={handleUserView}>
            <option value={translate.reducedInput}>{translate.reducedInput}</option>
            <option value={translate.intermediateInput}>{translate.intermediateInput}</option>
            <option value={translate.enlargedInput}>{translate.enlargedInput}</option>
            <option value={translate.enlargedJourneyInput}>{translate.enlargedJourneyInput}</option>
            {typeClient === 'VTEX' && <option value="VTEX">VTEX</option>}
          </select>
        </div>
      </div>
    </S.ContainerUserview>
  );
}

InputsFromCard.propTypes = {
  userView: PropTypes.string.isRequired,
  setUserView: PropTypes.func.isRequired,
};

export default InputsFromCard;
