class ClientsService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async store(id_client, name, is_ecommerce, currency, type_client, language_tag) {
    const path = `${this.baseUrl}/clients`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        id_client, name, is_ecommerce, currency, type_client, language_tag,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async getOne(id) {
    const path = `${this.baseUrl}/clients/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async getAll() {
    const path = `${this.baseUrl}/clients`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async updateOne(id, id_client, name, is_ecommerce, currency, type_client, language_tag) {
    const path = `${this.baseUrl}/clients`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify({
        id, id_client, name, is_ecommerce, currency, type_client, language_tag,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }
}

export default new ClientsService();
