import React, { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { DateProvider } from '../../../context/DateContext';
import { FilterProvider } from './context/Filters';
import { DataProvider } from './context/Data';
import { DataChannelsOverviewProvider } from './context/DataCardChannelsOverview';
import DateChoiceInput from '../../../components/DateChoiceInput';
import { NoDataMessage } from '../../../components/NoDataMessage';
import Filters from './components/Filters';
import CardRBIModel from './components/CardRBIModel';
import { CardChannelsOverview } from './components/CardChannelsOverview';
import Translate from './translate';
import * as S from './style';

export default function Dashboard() {
  const translate = Translate();
  const { accountNames } = useContext(AuthContext);

  if (accountNames.length === 0) {
    return <NoDataMessage>{translate.message}</NoDataMessage>;
  }

  return (
    <FilterProvider>
      <DateProvider>
        <DataProvider>
          <S.HeadContainer>
            <h2>{translate.title}</h2>
            <DateChoiceInput />
          </S.HeadContainer>
          <br />
          <S.ContainerBody>
            <Filters />
            <CardRBIModel />
            <DataChannelsOverviewProvider>
              <CardChannelsOverview />
            </DataChannelsOverviewProvider>
          </S.ContainerBody>
        </DataProvider>
      </DateProvider>
    </FilterProvider>
  );
}
