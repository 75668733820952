export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Marcados como completo por mês' : 'Marked as complete by month',
    subtitle: language === 'pt-BR' ? 'Total de vídeos completados por mês' : 'Total videos completed by month',
    labelPeriod: language === 'pt-BR' ? 'Período' : 'Period',
    last12Months: language === 'pt-BR' ? 'Últimos 12 meses' : 'Last 12 months',
  };
}
