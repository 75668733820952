import styled, { css } from 'styled-components';

export const LoaderArea = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({ $tofield }) => $tofield && css`
      display: inline-block;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 5px;
      z-index: 99px;

      img {
        width: 30px;
      }
  `}
`;
