export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    kpi: language === 'pt-BR' ? 'CPC' : 'CPC',
    tip: language === 'pt-BR'
      ? 'Investimento Perf / Cliques'
      : 'Investment Perf / Clicks',
  };
}
