import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import UTMService from '../../../../../services/UTM';
import Event from '../../../../../lib/EventEmitter';
import Loader from '../../../../../components/Loader';
import { Translate } from './translate';
import IconAdd from '../../../../../styles/logos/add.svg';

function Strategy({
  value, setValue, onToogleModal, setUtmCategoryToAdd, tipOfAddParams,
}) {
  const translate = Translate();
  const [paramsCustomList, setParamsCustomList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idParam] = useState(8);

  useEffect(() => {
    getCustomsUTMSources();
    Event.addListener('IDCustomParamAdded', (idParamToAdd) => {
      if (idParamToAdd === idParam) {
        getCustomsUTMSources();
      }
    });
  }, []);

  async function getCustomsUTMSources() {
    setIsLoading(true);
    const listofParamsCustom = await UTMService.getAllBYCategoryUTM(idParam);
    setParamsCustomList(listofParamsCustom);
    setIsLoading(false);
  }

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, strategy: event.target.value }));
  }

  function handleToogleModal() {
    setUtmCategoryToAdd(idParam);
    onToogleModal();
  }

  return (
    <div className="form-item">
      <label htmlFor="strategy">
        {translate.label}
        <img src={IconAdd} alt="Add" title={tipOfAddParams} onClick={handleToogleModal} />
      </label>
      <select value={value} onChange={handleSelect} id="strategy" disabled={isLoading}>
        <option value="" className="noneParam">None</option>
        {paramsCustomList && paramsCustomList.map((item) => (
          <option key={item.id} value={item.value} className="customParam">
            {' '}
            {item.name}
            {' '}
          </option>
        ))}
        <option value="direto">direto</option>
        <option value="display">display</option>
        <option value="display-portals">display-portals</option>
        <option value="email-base">email-base</option>
        <option value="email-parceiros">email-parceiros</option>
        <option value="influenciadores">influenciadores</option>
        <option value="offline">offline</option>
        <option value="referral">referral</option>
        <option value="reviews">reviews</option>
        <option value="sem">sem</option>
        <option value="seo">seo</option>
        <option value="site">site</option>
        <option value="social">social</option>
        <option value="tv">tv</option>
        <option value="video">video</option>
      </select>
      { isLoading && <Loader loading={isLoading} tofield /> }
    </div>
  );
}

Strategy.propTypes = {
  value: PropTypes.string.isRequired,
  tipOfAddParams: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onToogleModal: PropTypes.func.isRequired,
  setUtmCategoryToAdd: PropTypes.func.isRequired,
};

export default Strategy;
