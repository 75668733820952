import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import { numberFormat } from '../../../../../../../../helpers/numberFormat';
import { ForecastMonthly } from '../../../../../../../../helpers/forecast';
import { DataContext } from '../../../../../context/Data';

import { Translate } from './translate';

const ConversionsTr = React.memo(({ showLevelColumn, showSourceColumn }) => {
  const translate = Translate();
  const {
    googleAnalyticsCurrent, googleAnalyticsPrevious, googleAnalyticsYoY, googleAnalyticsPreviousComplete, googleAnalyticsForecast,
  } = useContext(DataContext);

  const object = 'conversions';

  const current = Number(googleAnalyticsCurrent?.[object]) || 0;
  const previous = Number(googleAnalyticsPrevious?.[object]) || 0;
  const yoy = Number(googleAnalyticsYoY?.[object]) || 0;
  const completeMonth = Number(googleAnalyticsPreviousComplete?.[object]) || 0;

  const forecastData = googleAnalyticsForecast?.length > 0 && googleAnalyticsForecast?.map((data) => ({ date: data.date, value: Number(data[object]) }));
  const forecast = forecastData && (ForecastMonthly(forecastData) || 0);

  const deltaNormal = current - previous;
  const deltaYoY = current - yoy;
  const deltaForecast = forecast - completeMonth;

  function classNameAtrr(delta) {
    if (delta === 0) {
      return 'delta';
    } if (delta < 0) {
      return 'delta red';
    }
    return 'delta green';
  }

  return (
    <tr>
      {showLevelColumn ? <td>2nd</td> : null}
      {showSourceColumn ? <td>GA</td> : null}
      <td>{translate.kpi}</td>
      <td>{numberFormat(current, null, 0)}</td>
      <td>{numberFormat(previous, null, 0)}</td>
      <td className={classNameAtrr(deltaNormal)}>
        {numberFormat((current / previous) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(yoy, null, 0)}</td>
      <td className={classNameAtrr(deltaYoY)}>
        {numberFormat((current / yoy) - 1, 'percentage', 1)}
      </td>
      <td>{numberFormat(forecast, null, 0)}</td>
      <td>{numberFormat(completeMonth)}</td>
      <td className={classNameAtrr(deltaForecast)}>
        {numberFormat((forecast / completeMonth) - 1, 'percentage', 1)}
      </td>
    </tr>
  );
});

ConversionsTr.propTypes = {
  showLevelColumn: PropTypes.bool.isRequired,
  showSourceColumn: PropTypes.bool.isRequired,
};

export default ConversionsTr;
