import {
  createContext, useMemo, useState, useEffect,
} from 'react';
import ModulesService from '../../services/Modules';

const ModulesContext = createContext();

function ModulesProvider({ children }) {
  const [modules, setModules] = useState([]);

  useEffect(() => {
    async function getListOfModules() {
      const modulesDB = await ModulesService.getAll();
      setModules(modulesDB);
    }
    getListOfModules();
  }, []);

  const contextValues = useMemo(() => ({
    modules,
  }), [modules]);

  return (
    <ModulesContext.Provider value={contextValues}>
      {children}
    </ModulesContext.Provider>
  );
}

export { ModulesContext, ModulesProvider };


