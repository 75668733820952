import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TitlePage from '../../../../components/TitlePage';
import Loader from '../../../../components/Loader';
import BackButton from '../../../../components/BackButton';
import FormGroup from '../../../../components/FormGroup';
import ButtonsSaveCancel from '../../../../components/ButtonsSaveCancel';
import UTMService from '../../../../services/UTM';
import helpIcon from '../../../../styles/logos/help_info.svg';
import toast from '../../../../helpers/toast';
import { Translate } from './translate';
import * as S from './styles';

export default function URLBuilderAddParamsNew() {
  const translate = Translate();
  const navigate = useNavigate();
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [paramSelected, setParamSelected] = useState('');
  const [name, setName] = useState('');
  const [urlValue, setUrlValue] = useState('');
  const [messageHelp, setMessageHelp] = useState('');
  const [showMessageHelper, setShowMessageHelper] = useState(false);
  const [isCategoryLoad, setIsCategoryLoad] = useState(true);

  useEffect(() => {
    getAllCategories();
    return () => {};
  }, []);

  async function handlePost() {
    try {
      const response = await UTMService.add(name, urlValue, paramSelected);
      toast('success', response.message);
    } catch (err) {
      toast('danger', err.message);
    } finally {
      navigate('/client/urlbuilder/addparams');
    }
  }

  async function getAllCategories() {
    try {
      const utmCategoriesList = await UTMService.getAllCategories();
      function filterParams(utm) {
        return utm.id !== 4 // 'Customer Journey'
               && utm.id !== 3 // 'Paid Unpaid'
               && utm.id !== 12 // 'Country'
               && utm.id !== 13; // 'Region';
      }
      const utmCategories = utmCategoriesList.filter((data) => filterParams(data));
      utmCategories.sort((a, b) => {
        const firstValue = translate.language === 'pt-BR' ? a.namePt : a.nameEn;
        const nextValue = translate.language === 'pt-BR' ? b.namePt : b.nameEn;
        return firstValue.localeCompare(nextValue);
      });
      setCategories(utmCategories);
      setParamSelected(id);
    } finally {
      setIsCategoryLoad(false);
    }
  }

  function handleName(event) {
    setName(event.target.value);
  }

  function handleURLValue(event) {
    setUrlValue(event.target.value);
  }

  return (
    <>
      {/* Render */}
      <TitlePage title={translate.titlePage}>
        <BackButton to="/client/urlbuilder/addparams" />
      </TitlePage>
      <S.ActionContainer>
        <S.FormItem>
          <div className="form-item-header">
            <div className="form-label-container">
              <label htmlFor="parametro">{translate.parameterLabel}</label>
            </div>
          </div>
          <FormGroup>
            <select
              value={paramSelected}
              onChange={(event) => setParamSelected(event.target.value)}
              name="parametro"
              disabled={isCategoryLoad}
            >
              {categories.map((item) => (
                <option
                  key={item.id}
                  value={`${+item.id}`}
                  defaultValue
                >
                  {translate.language === 'pt-BR' ? item.namePt : item.nameEn}
                </option>
              ))}
            </select>
            <Loader loading={isCategoryLoad} tofield />
          </FormGroup>
        </S.FormItem>
        <S.FormItem>
          <div className="form-item-header">
            <div className="form-label-container">
              <label htmlFor="nome">{translate.nameLabel}</label>
              <img
                src={helpIcon}
                alt="Ajuda"
                data-img="name"
                title={translate.nameHelperMessage}
              />
            </div>
          </div>
          <input
            type="text"
            value={name}
            placeholder={translate.namePlaceholder}
            onChange={handleName}
            name="nome"
          />
        </S.FormItem>
        <S.FormItem>
          <div className="form-item-header">
            <div className="form-label-container">
              <label htmlFor="value_url">{translate.urlValueLabel}</label>
              <img
                src={helpIcon}
                alt="Ajuda"
                data-img="url_value"
                title={translate.urlValueHelperMessage}
              />
            </div>
          </div>
          <input
            type="text"
            value={urlValue}
            placeholder={translate.urlPlaceholder}
            onChange={handleURLValue}
            name="value_url"
          />
        </S.FormItem>
        <ButtonsSaveCancel
          onSave={handlePost}
          goToWhenCancel="/client/urlbuilder/addparams"
          disabled={(!name || !urlValue)}
        />
      </S.ActionContainer>
    </>
  );
}
