export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Lista de Videos' : 'List of Videos',
    search: language === 'pt-BR' ? 'Pesquisar' : 'Search',
    placeholderSearchTerm: language === 'pt-BR' ? 'Nome, categoria ou sub-categoria' : 'Name, category or sub-category',
    addButton: language === 'pt-BR' ? 'Adicionar Novo' : 'Add New',
    videosInfo: language === 'pt-BR' ? 'Sem Vídeos para listar' : 'No Videos to list',
    categoryText: language === 'pt-BR' ? 'Categoria' : 'Category',
    subCategoryText: language === 'pt-BR' ? 'SubCategoria' : 'SubCategory',
    language,
  };
}
