import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import UTMService from '../../../../../services/UTM';
import Event from '../../../../../lib/EventEmitter';
import Loader from '../../../../../components/Loader';
import IconAdd from '../../../../../styles/logos/add.svg';

function UtmSource({
  value, setValue, onToogleModal, setUtmCategoryToAdd, tipOfAddParams,
}) {
  const [paramsCustomList, setParamsCustomList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idParam] = useState(1);

  useEffect(() => {
    getCustomsUTMSources();
    Event.addListener('IDCustomParamAdded', (idParamToAdd) => {
      if (idParamToAdd === idParam) {
        getCustomsUTMSources();
      }
    });
  }, []);

  async function getCustomsUTMSources() {
    setIsLoading(true);
    const listofParamsCustom = await UTMService.getAllBYCategoryUTM(idParam);
    setParamsCustomList(listofParamsCustom);
    setIsLoading(false);
  }

  function handleSelect(event) {
    setValue((prevState) => ({ ...prevState, utmSource: event.target.value }));
  }

  function handleToogleModal() {
    setUtmCategoryToAdd(idParam);
    onToogleModal();
  }

  return (
    <div className="form-item">
      <label htmlFor="utm-source">
        Source
        <img src={IconAdd} alt="Add" title={tipOfAddParams} onClick={handleToogleModal} />
      </label>
      <select value={value} onChange={handleSelect} id="utm-source" disabled={isLoading}>
        <option value="" className="noneParam">None</option>
        {paramsCustomList && paramsCustomList.map((item) => (
          <option key={item.id} value={item.value} className="customParam">
            {' '}
            {item.name}
            {' '}
          </option>
        ))}
        <option value="ask">ask</option>
        <option value="baidu">baidu</option>
        <option value="bing-ads">bing-ads</option>
        <option value="bing-seo">bing-seo</option>
        <option value="direct">direct</option>
        <option value="duckduckgo">duckduckgo</option>
        <option value="ecosia">ecosia</option>
        <option value="email-base">email-base</option>
        <option value="espn">espn</option>
        <option value="facebook-ads">facebook-ads</option>
        <option value="facebook-organic">facebook-organic</option>
        <option value="globo">globo</option>
        <option value="google-ads">google-ads</option>
        <option value="google-busca">google-busca</option>
        <option value="google-gdn">google-gdn</option>
        <option value="google-seo">google-seo</option>
        <option value="instagram-ads">instagram-ads</option>
        <option value="instagram-organic">instagram-organic</option>
        <option value="linkedin-ads">linkedin-ads</option>
        <option value="linkedin-organic">linkedin-organic</option>
        <option value="meta-ads">meta-ads</option>
        <option value="referral">referral</option>
        <option value="site">site</option>
        <option value="spotify">spotify</option>
        <option value="tiktok-ads">tiktok-ads</option>
        <option value="tiktok-organic">tiktok-organic</option>
        <option value="uol">uol</option>
        <option value="yahoo">yahoo</option>
        <option value="youtube-ads">youtube-ads</option>
        <option value="youtube-organic">youtube-organic</option>
      </select>
      { isLoading && <Loader loading={isLoading} tofield /> }
    </div>
  );
}

UtmSource.propTypes = {
  value: PropTypes.string.isRequired,
  tipOfAddParams: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onToogleModal: PropTypes.func.isRequired,
  setUtmCategoryToAdd: PropTypes.func.isRequired,
};

export default UtmSource;
