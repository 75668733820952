import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { ProductsContext } from '../../context/ProductsContext';
import IconAcademy from '../../styles/logos/icon_academy.svg';
import IconConsultancy from '../../styles/logos/icon_consultancy1.svg';
import IconDataBase from '../../styles/logos/icon_database.svg';
import IconHouse from '../../styles/logos/icon_house.svg';
import IconMarketplace from '../../styles/logos/icon_marketplace.svg';
import IconMentoring from '../../styles/logos/icon_mentoring2.svg';
import IconRBI from '../../styles/logos/icon_rbi.svg';
import IconArrow from '../../styles/logos/icon_sidebararrow.svg';
import logoShifters from '../../styles/logos/shiftersy.png';
import { Translate } from './translate';
import * as S from './styles';

function SideBarUserMock({ toogle }) {
  const translate = Translate();
  const { products } = useContext(ProductsContext);
  const { userInfo } = useContext(AuthContext);

  const productsAcademy = products
    .filter((product) => product.type === 'academy'
                      && product.active === 'Y');

  const productsTransformation = products
    .filter((product) => product.type === 'transformation'
                      && product.active === 'Y');

  const productsConsulting = products
    .filter((product) => product.type === 'consulting'
                      && product.active === 'Y');

  const productsHome = products
    .filter((product) => product.type === 'home'
                      && product.active === 'Y');

  function handleCollapse(e) {
    let submenuHTML = '';
    let arrowHTML = '';

    if (e.target.classList.contains('sidebar-logo') || e.target.classList.contains('sidebar-name') || e.target.classList.contains('sidebar-arrow')) {
      submenuHTML = e.target.parentElement.nextElementSibling;
    } else {
      submenuHTML = e.target.nextElementSibling;
    }

    if (e.target.classList.contains('sidebar-logo')) {
      arrowHTML = e.target.nextElementSibling.nextElementSibling;
    } else if (e.target.classList.contains('sidebar-name')) {
      arrowHTML = e.target.nextElementSibling;
    } else if (e.target.classList.contains('sidebar-arrow')) {
      arrowHTML = e.target;
    } else {
      arrowHTML = e.target.children[2];
    }

    if (submenuHTML.classList.contains('submenu')) {
      if (submenuHTML.className === 'submenu') {
        submenuHTML.classList.add('collapse');
        arrowHTML.classList.add('collapse');
      } else {
        submenuHTML.className = 'submenu';
        arrowHTML.className = 'sidebar-arrow';
      }
    }
  }

  return (
    <S.Nav $toogle={toogle}>
      <div className="container-logo">
        <Link to="/client">
          <img src={logoShifters} alt="Logo Shifters" />
        </Link>
      </div>
      <ul>
        <li className="menu">
          <div onClick={handleCollapse}>
            <img src={IconHouse} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name">Home</span>
            <img src={IconArrow} alt="Home" className="sidebar-arrow" />
          </div>
          <ul className="submenu">
            <li>
              <Link to="/client/feed">
                <span className="sidebar-name">Feed</span>
              </Link>
            </li>
            <li>
              <Link to="/client/onboarding">
                <span className="sidebar-name">Onboarding</span>
              </Link>
            </li>
            <li>
              <Link to="/client/favorites-videos">
                <span className="sidebar-name">{translate.favorite}</span>
              </Link>
            </li>
            {userInfo.admin === 'Y' && (
              <li>
                <Link to="/client/company-tracker">
                  <span className="sidebar-name">{translate.companyTracker}</span>
                </Link>
              </li>
            )}
            <li>
              <Link to="/client/user-tracker">
                <span className="sidebar-name">{translate.userTracker}</span>
              </Link>
            </li>
            {productsHome.length > 0 && (
              productsHome.map((product) => (
                <S.ListAcademy color={product.color} key={product.id}>
                  <Link to={`/client/home/${product.id}/${product.first_video_id}`}>
                    <span className="sidebar-name">
                      {translate.language === 'pt-BR' ? product.namePt : product.nameEn}
                    </span>
                  </Link>
                </S.ListAcademy>
              ))
            )}
          </ul>
        </li>
        <li className="menu">
          <div onClick={handleCollapse}>
            <img src={IconAcademy} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name">Academy</span>
            <img src={IconArrow} alt="Home" className="sidebar-arrow" />
          </div>
          <ul className="submenu">
            {productsAcademy.length > 0 && (
              productsAcademy.map((product) => (
                <S.ListAcademy color={product.color} key={product.id}>
                  <Link to={`/client/academy/${product.id}/${product.first_video_id}`}>
                    <span className="sidebar-name">
                      {translate.language === 'pt-BR' ? product.namePt : product.nameEn}
                    </span>
                  </Link>
                </S.ListAcademy>
              ))
            )}
          </ul>
        </li>

        {/* <li className="menu">
          <div onClick={handleCollapse}>
            <img src={IconMentoring} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name">{translate.transformation}</span>
            <img src={IconArrow} alt="Home" className="sidebar-arrow" />
          </div>
          <ul className="submenu">
            {productsTransformation.length > 0 && (
              productsTransformation.map((product) => (
                <S.ListAcademy color={product.color} key={product.id}>
                  <Link to={`/client/transformation/${product.id}/${product.first_video_id}`}>
                    <span className="sidebar-name">
                      {translate.language === 'pt-BR' ? product.namePt : product.nameEn}
                    </span>
                  </Link>
                </S.ListAcademy>
              ))
            )}
          </ul>
        </li> */}

        <li className="menu">
          <div onClick={handleCollapse}>
            <img src={IconConsultancy} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name">{translate.consulting}</span>
            <img src={IconArrow} alt="Home" className="sidebar-arrow" />
          </div>
          <ul className="submenu">
            {productsConsulting.length > 0 && (
              productsConsulting.map((product) => (
                <S.ListAcademy color={product.color} key={product.id}>
                  <Link to={`/client/consulting/${product.id}/${product.first_video_id}`}>
                    <span className="sidebar-name">
                      {translate.language === 'pt-BR' ? product.namePt : product.nameEn}
                    </span>
                  </Link>
                </S.ListAcademy>
              ))
            )}
          </ul>
        </li>

        <li className="menu">
          <div onClick={handleCollapse}>
            <img src={IconRBI} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name"> RBI Software </span>
            <img src={IconArrow} alt="Home" className="sidebar-arrow" />
          </div>
          <ul className="submenu">
            <li>
              <Link to="/client/dashboard/macro">
                <span className="sidebar-name">{translate.dashboardExecutivoMacro}</span>
              </Link>
            </li>
            <li>
              <Link to="/client/dashboard/micro">
                <span className="sidebar-name">{translate.dashboardExecutivoMicro}</span>
              </Link>
            </li>
            <li>
              <Link to="/client/dashboard/full-funnel">
                <span className="sidebar-name">Full Funnel</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="menu">
          <div onClick={handleCollapse}>
            <img src={IconDataBase} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name">Data Services</span>
            <img src={IconArrow} alt="Home" className="sidebar-arrow" />
          </div>
          <ul className="submenu">
            <li>
              <Link to="/client/apis">
                <span className="sidebar-name"> APIs </span>
              </Link>
            </li>
            <li>
              <Link to="/client/urlbuilder">
                <span className="sidebar-name"> URL Builder </span>
              </Link>
            </li>
            <li>
              <Link to="/client/b2b-databases">
                <span className="sidebar-name">B2B Databases</span>
              </Link>
            </li>
            <li>
              <Link to="/client/mass-keywords">
                <span className="sidebar-name">Mass Keywords</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="menu">
          <div onClick={handleCollapse}>
            <img src={IconMarketplace} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name">Marketplace</span>
            <img src={IconArrow} alt="Home" className="sidebar-arrow" />
          </div>
          <ul className="submenu">
            <li>
              <Link to="/client/marketplace/search">
                <span className="sidebar-name">Search</span>
              </Link>
            </li>
            <li>
              <Link to="/client/marketplace/register">
                <span className="sidebar-name">Register</span>
              </Link>
            </li>
            <li>
              <Link to="/client/marketplace/cfo">
                <span className="sidebar-name">CFO As a Service</span>
              </Link>
            </li>
            <li>
              <Link to="/client/marketplace/cto">
                <span className="sidebar-name">CTO As a Service</span>
              </Link>
            </li>
            <li>
              <Link to="/client/marketplace/cmo">
                <span className="sidebar-name">CMO As a Service</span>
              </Link>
            </li>
            <li>
              <Link to="/client/marketplace/consultant">
                <span className="sidebar-name">{translate.digitalConsulting}</span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </S.Nav>
  );
}

export default SideBarUserMock;
