export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    labelEvents: language === 'pt-BR' ? 'Eventos' : 'Events',
    labelUserView: language === 'pt-BR' ? 'Camada' : 'Tier',
    userViewSelectedOnLoad: language === 'pt-BR' ? 'Reduzida' : 'Reduced',
    userViewList: language === 'pt-BR'
      ? ['Reduzida', 'Intermediária', 'Ampliada', 'Ampliada/Jornada']
      : ['Reduced', 'Intermediate', 'Enlarged', 'Enlarged/Journey'],
    reducedInput: language === 'pt-BR' ? 'Reduzida' : 'Reduced',
    intermediateInput: language === 'pt-BR' ? 'Intermediária' : 'Intermediate',
    enlargedInput: language === 'pt-BR' ? 'Ampliada' : 'Enlarged',
    enlargedJourneyInput: language === 'pt-BR' ? 'Ampliada/Jornada' : 'Enlarged/Journey',
  };
}
