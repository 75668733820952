import React, { useState, useContext, useEffect } from 'react';
import { Translate } from './translate';
import { InputContainer } from './style';
import Loader from '../../../../../../components/Loader';
import { DataChannelsOverviewContext } from '../../../context/DataCardChannelsOverview/index';

export function InputsFromCard() {
  const translate = Translate();
  const {
    ListKpis,
    kpiSelected,
    setKpiSelected,
    userViewList,
    userViewSelected,
    setUserViewSelected,
    event,
    setEvent,
    eventList,
    isLoadingEventList,
  } = useContext(DataChannelsOverviewContext);
  const [eventKpiSelected, setEventKpiSelected] = useState(false);

  function toogleEventInput(kpiId) {
    // From 20 until 24 are KPIs of Events Context
    if ([20, 21, 22, 23, 24].includes(kpiId)) {
      setEventKpiSelected(true);
    } else {
      setEventKpiSelected(false);
    }
  }

  return (
    <InputContainer>
      <div className="input-group">
        <label htmlFor="kpis">KPIs</label>
        <select
          id="kpis"
          value={JSON.stringify(kpiSelected)}
          onChange={(e) => {
            setKpiSelected(JSON.parse(e.target.value));
            toogleEventInput(JSON.parse(e.target.value).id);
          }}
        >
          {ListKpis()?.map((item) => <option value={JSON.stringify(item)} key={item.id}>{item.name}</option>)}
        </select>
      </div>
      {eventKpiSelected && (
      <div className="input-group">
        <label htmlFor="events">{translate.eventsLabel}</label>
        <select
          id="events"
          value={event}
          onChange={(e) => setEvent(e.target.value)}
          disabled={isLoadingEventList}
        >
          <option value="">All</option>
          {eventList?.map((event) => <option key={event} value={event}>{event}</option>)}
        </select>
        <Loader loading={isLoadingEventList} tofield />
      </div>
      )}
      <div className="input-group">
        <label htmlFor="user-view">{translate.userViewLabel}</label>
        <select
          id="user-view"
          value={JSON.stringify(userViewSelected)}
          onChange={(e) => setUserViewSelected(JSON.parse(e.target.value))}
        >
          {userViewList?.map((item) => (
            <option value={JSON.stringify(item)} id={item.id} key={item.id}>{item.name}</option>
          ))}
        </select>
      </div>
    </InputContainer>
  );
}
