/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { reduceArraysSingle } from '../reduceArraysSingle';
import { numberFormat } from '../../../../../../helpers/numberFormat';
import { groupAndSumByDynamicKey } from '../groupAndSumByDynamicKey';

export function EngagedSessions(group, googleAnalyticsGoogleAdsDataCurrent, googleAnalyticsGoogleAdsDataPrevious) {
  const type = 'number'; // number, currency or percentage
  const decimal = 0; // 0, 1, 2
  const dataCurrent = reduceArraysSingle(group, 'engaged_sessions', googleAnalyticsGoogleAdsDataCurrent);
  const dataPrevious = reduceArraysSingle(group, 'engaged_sessions', googleAnalyticsGoogleAdsDataPrevious);
  const dataByDate = groupAndSumByDynamicKey(group, 'new_users', googleAnalyticsGoogleAdsDataCurrent);

  const kpiInfo = {
    isInverted: false,
    total: {
      current: 0,
      previous: 0,
      delta: '',
    },
    data: [],
    dataByDate,
  };

  const totalCurrent = dataCurrent ? Object.entries(dataCurrent).reduce((acc, item) => (acc + (isNaN(item[1]) ? 0 : Number(item[1]))), 0) : 0;
  const totalPrevious = dataPrevious ? Object.entries(dataPrevious).reduce((acc, item) => (acc + (isNaN(item[1]) ? 0 : Number(item[1]))), 0) : 0;
  kpiInfo.total.current = numberFormat(totalCurrent, type, decimal);
  kpiInfo.total.previous = numberFormat(totalPrevious, type, decimal);
  kpiInfo.total.delta = numberFormat(((totalCurrent / totalPrevious) - 1), 'percentage', 1);

  // Percorre as chaves do objeto 'current'
  const arr = [];
  for (let key in dataCurrent) {
  // Verifica se a chave também existe no objeto 'previous'
    if (dataPrevious.hasOwnProperty(key)) {
    // Soma os valores correspondentes das chaves
      arr.push({
        view: key,
        current: numberFormat(dataCurrent[key], type, decimal),
        previous: numberFormat(dataPrevious[key], type, decimal),
        currentNumber: Number(dataCurrent[key]),
        previousNumber: Number(dataPrevious[key]),
        delta: numberFormat(((dataCurrent[key] / dataPrevious[key]) - 1), 'percentage', 1),
      });
    } else {
    // Se a chave não existir em 'previous', utiliza apenas o valor de 'current'
      arr.push({
        view: key,
        current: numberFormat(dataCurrent[key], type, decimal),
        previous: numberFormat(0, type, decimal),
        currentNumber: Number(dataCurrent[key]),
        previousNumber: Number(0),
        delta: numberFormat(((dataCurrent[key] / 0) - 1), 'percentage', 1),
      });
    }
  }

  // Adiciona as chaves de 'previous' que não existem em 'current' (caso existam)
  for (let key in dataPrevious) {
    if (!dataCurrent.hasOwnProperty(key)) {
      arr.push({
        view: key,
        current: numberFormat(0, type, decimal),
        previous: numberFormat(dataPrevious[key], type, decimal),
        currentNumber: Number(0),
        previousNumber: Number(dataPrevious[key]),
        delta: numberFormat(((0 / dataPrevious[key]) - 1), 'percentage', 1),
      });
    }
  }
  const arrSorted = arr.sort((a, b) => b.currentNumber - a.currentNumber);
  kpiInfo.data = arrSorted;
  return kpiInfo;
}
