class UsersService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async store(idClient, firstName, lastName, email, admin, language, perfil, accessGroupID) {
    const path = `${this.baseUrl}/user`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        idClient, firstName, lastName, email, admin, language, perfil, accessGroupID,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async getAll(id) {
    let path = '';
    if (id) {
      path = `${this.baseUrl}/user?idClient=${id}`;
    } else {
      path = `${this.baseUrl}/user`;
    }

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async getOne(id) {
    const path = `${this.baseUrl}/user/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async update({
    id, firstName, lastName, admin, language, active, accessGroupID,
  }) {
    const path = `${this.baseUrl}/user`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify({
        id, firstName, lastName, admin, language, active, accessGroupID,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async fowardEmail({ id }) {
    const path = `${this.baseUrl}/user/foward-email`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify({ id }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async recoveryEmail({ email }) {
    const path = `${this.baseUrl}/user/recovery-email`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({ email }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }
}

export default new UsersService();
