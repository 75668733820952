import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  .container-apis {
    flex: 1;
    width: 100%;
    margin-bottom: 24px;

    .container-main-title {
      margin-bottom: 16px;
      font-weight: 400;

      h4 {
        color: ${({ theme }) => theme.grayLight}
      }
    }
  }

  .container-apis-cards {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
`;

export const ContainerSquare = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .container-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .title {
    flex: 1;
    color: ${({ theme }) => theme.fontWhite}
  }

  .container-infos {
    display: flex;
    align-items: center;
    justify-content:flex-start ;
    gap: 8px;
  }

  .online-offline {
    width: 12px;
    height: 12px;
    border-radius: 50%;

    ${({ $status, theme }) => $status === 'online' && css`
      background-color: ${theme.green};
      `
}

    ${({ $status, theme }) => $status === 'offline' && css`
      background-color: ${theme.red};
      `
}

    ${({ $status, theme }) => $status === 'requires validation' && css`
      background-color: ${theme.yellow};
      `
}
  }
`;
