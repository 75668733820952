export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Lista de Posts' : 'Post\'s List',
    search: language === 'pt-BR' ? 'Pesquisar' : 'Search',
    placeholderSearchTerm: language === 'pt-BR' ? 'Nome, subcategoria ou produto' : 'Name, subcategory or product',
    addButton: language === 'pt-BR' ? 'Adicionar Novo' : 'Add New',
    info: language === 'pt-BR' ? 'Sem Posts para listar' : 'No Posts to list',
    language,
  };
}
