export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    language,
    titleProducts: language === 'pt-BR' ? 'Resumo por produto' : 'Summary by product',
    titleListVideos: language === 'pt-BR' ? 'Vídeos marcados como completo' : 'Videos marked as complete',
    msgNoDataList: language === 'pt-BR' ? 'Sem dados para mostrar' : 'No data to show',

  };
}
