class PostsProductService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findAll(id, type) {
    let query = '';

    if (id && type) {
      if (type === 'subcategory') {
        query = `?idSubCategory=${id}`;
      }

      if (type === 'product') {
        query = `?idProduct=${id}`;
      }
    }

    const path = `${this.baseUrl}/posts${query}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async findById(id) {
    const path = `${this.baseUrl}/posts/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async store({
    namePt, nameEn, ordering, slug, idSubCategory,
  }) {
    const path = `${this.baseUrl}/posts`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        namePt, nameEn, ordering, slug, idSubCategory,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async update(id, {
    namePt, nameEn, ordering, slug, idSubCategory,
  }) {
    const path = `${this.baseUrl}/posts/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify({
        namePt, nameEn, ordering, slug, idSubCategory,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async updateOrdering(postsList) {
    const path = `${this.baseUrl}/posts/ordering`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PATCH',
      body: JSON.stringify(postsList),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }

  async delete(id) {
    const path = `${this.baseUrl}/posts/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'DELETE',
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status} - ${body.message}`);
    }

    return body;
  }
}

export default new PostsProductService();
