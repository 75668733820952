export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Usuários por grupo de acesso' : 'Users by access group',
    labelButtonAddNew: language === 'pt-BR' ? 'Adicionar novo' : 'Add new',
    errorMsg: language === 'pt-BR' ? 'Este grupo de acesso ainda não possui usuários' : 'This access group has no users yet ',
    search: language === 'pt-BR' ? 'Pesquisa' : 'Search',
  };
}
