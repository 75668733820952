import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AccessGroupUserService from '../../../../services/AccessGroupUser';
import TitlePage from '../../../../components/TitlePage';
import Square from '../../../../components/Square';
import Loader from '../../../../components/Loader';
import BackButton from '../../../../components/BackButton';
import { NoDataMessage } from '../../../../components/NoDataMessage';
import toast from '../../../../helpers/toast';
import { Translate } from './translate';
import {
  Container, TitleContainer, SearchContainer,
} from './style';
import { Badge } from '../../../../components/Badge';

export function AccessGroupsUsers() {
  const translate = Translate();
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    try {
      setIsLoadingData(true);
      const users = await AccessGroupUserService.getAllById(id);
      setUsers(users);
    } catch (err) {
      toast('danger', 'Access group not found');
    } finally {
      setIsLoadingData(false);
    }
  }

  function handleSearchTerm(e) {
    setSearchTerm(e.target.value.toLowerCase());
  }

  const usersListFiltered = useMemo(() => users?.filter((user) => (user.email.toLowerCase().includes(searchTerm))));

  return (
    <>
      <TitlePage title={`${translate.title} ${users[0]?.accessGroupName ? `(${users[0]?.accessGroupName})` : ''}`}>
        <BackButton to="/admin/access-group" />
      </TitlePage>
      {isLoadingData && <Loader loading={isLoadingData} /> }
      {!isLoadingData && (
        <Container>
          <SearchContainer>
            {users.length > 0 ? (
              <div className="c-search">
                <label htmlFor="">{translate.search}</label>
                <input type="text" name="" id="" placeholder="E-mail" onChange={handleSearchTerm} />
              </div>
            ) : <div />}
          </SearchContainer>

          {users.length === 0
            ? (<NoDataMessage>{translate.errorMsg}</NoDataMessage>)
            : (usersListFiltered && usersListFiltered?.map((user) => (
              <Square size="large" key={user.id}>
                <TitleContainer>
                  <div className="c-container">
                    <div className="c-container-name">
                      {`${user.firstName} ${user.lastName}`}
                    </div>
                    <Badge>
                      {user.email}
                    </Badge>
                  </div>
                  <div className="c-container-actions" title="Edit access group" />
                </TitleContainer>
              </Square>
            )))}
        </Container>
      )}
    </>
  );
}
