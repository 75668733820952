import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import TitlePage from '../../../../components/TitlePage';
import BackButton from '../../../../components/BackButton';
import ButtonSaveCancel from '../../../../components/ButtonsSaveCancel';
import Square from '../../../../components/Square';
import ClientsService from '../../../../services/Clients';
import { currencyCodes } from '../../../../helpers/lists/currency_ISO4217';
import { languageTag } from '../../../../helpers/lists/languageTag_BCP47';
import { FormContainer, FormInputsContainer } from './style';
import { Translate } from './translate';
import toast from '../../../../helpers/toast';

function UpdateClient() {
  const translate = Translate();
  const navigate = useNavigate();
  const { id } = useParams();
  const [clientInfos, setClientInfos] = useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    getCompanyInfo();
  }, [id]);

  async function getCompanyInfo() {
    if (id) {
      const clientInfoResponse = await ClientsService.getOne(id);
      setClientInfos(clientInfoResponse);
    }
  }

  async function updateClientInfo(e) {
    e.preventDefault();
    setIsSubmiting(true);

    try {
      const {
        id, id_client, name, is_ecommerce, currency, type_client, language_tag,
      } = clientInfos;
      const response = await ClientsService.updateOne(id, id_client, name, is_ecommerce, currency, type_client, language_tag);
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsSubmiting(false);
      navigate('/admin/clients');
    }
  }

  return (
    <>
      {!clientInfos && <p>Loading Infos...</p>}
      {clientInfos && (
        <>
          <TitlePage title={clientInfos.name}>
            <BackButton />
          </TitlePage>
          <form onSubmit={updateClientInfo}>
            <FormInputsContainer>
              <Square size="large">
                <FormContainer>
                  <div className="c-fields">
                    <label htmlFor="name">{translate.labelName}</label>
                    <input
                      type="text"
                      value={clientInfos.name}
                      name="name"
                      onChange={(e) => setClientInfos({ ...clientInfos, name: e.target.value })}
                    />
                  </div>
                </FormContainer>
              </Square>
              <Square size="large">
                <FormContainer>
                  <div className="c-fields">
                    <label htmlFor="id_client">ID Client</label>
                    <input type="text" value={clientInfos.id_client} name="id_client" disabled />
                  </div>
                </FormContainer>
              </Square>
              <Square size="large">
                <FormContainer>
                  <div className="c-fields">
                    <label htmlFor="ecommerce">Ecommerce?</label>
                    <select
                      name="ecommerce"
                      value={clientInfos.is_ecommerce}
                      onChange={(e) => setClientInfos({ ...clientInfos, is_ecommerce: e.target.value })}
                    >
                      <option value="Y">Sim</option>
                      <option value="N">Não</option>
                    </select>
                  </div>
                  <div className="c-explanation">{translate.textEcommerce}</div>
                </FormContainer>
              </Square>
              <Square size="large">
                <FormContainer>
                  <div className="c-fields">
                    <label htmlFor="perfil">{translate.labelPerfil}</label>
                    <select
                      name="perfil"
                      value={clientInfos.type_client}
                      onChange={(e) => setClientInfos({ ...clientInfos, type_client: e.target.value })}
                    >
                      <option value="GA">GA</option>
                      <option value="VTEX">VTEX</option>
                    </select>
                  </div>
                  <div className="c-explanation">{translate.textGA}</div>
                </FormContainer>
              </Square>
              <Square size="large">
                <FormContainer>
                  <div className="c-fields">
                    <label htmlFor="currency">{translate.labelCurrency}</label>
                    <select
                      name="currency"
                      value={clientInfos.currency}
                      onChange={(e) => setClientInfos({ ...clientInfos, currency: e.target.value })}
                    >
                      {currencyCodes?.map((currency) => <option value={currency} key={currency}>{currency}</option>)}
                    </select>
                  </div>
                  <div className="c-explanation">
                    {translate.textCurrency}
                    {' '}
                    <Link to="https://en.wikipedia.org/wiki/ISO_4217" target="_blank">{translate.labelClickHere}</Link>
                  </div>
                </FormContainer>
              </Square>
              <Square size="large">
                <FormContainer>
                  <div className="c-fields">
                    <label htmlFor="language_tag">{translate.labelLanguageTag}</label>
                    <select
                      name="language_tag"
                      value={clientInfos.language_tag}
                      onChange={(e) => setClientInfos({ ...clientInfos, language_tag: e.target.value })}
                    >
                      {languageTag?.map((langTag) => (
                        <option value={langTag.value} key={langTag.value}>
                          {langTag.value}
                          {' '}
                          |
                          {' '}
                          {langTag.language}
                          {' '}
                          |
                          {' '}
                          {langTag.region}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="c-explanation">{translate.textLanguageTag}</div>
                </FormContainer>
              </Square>
              <ButtonSaveCancel goToWhenCancel="/admin/clients" onSave={() => {}} disabled={isSubmiting} />
            </FormInputsContainer>
          </form>
        </>
      )}
    </>
  );
}

export default UpdateClient;
