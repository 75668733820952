import React, { useCallback, useEffect, useState } from 'react';
import * as S from './style';
import Toast from '../Toast';

import { toastEventManager } from '../../../helpers/toast';

function ToastContainer() {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    function handleAddToast({ type, text }) {
      setMessages((prevState) => [...prevState, { id: Math.random(), type, text }]);
    }

    toastEventManager?.on('addtoast', handleAddToast);

    return () => {
      toastEventManager?.removeListeners('addtoast', handleAddToast);
    };
  }, []);

  const handleRemoveToast = useCallback((id) => {
    setMessages((prevState) => prevState.filter((message) => message.id !== id));
  }, []);

  return (
    <S.Container>
      {messages.map((message) => (
        <Toast
          key={message.id}
          message={message}
          onRemoveToast={handleRemoveToast}
        />
      ))}
    </S.Container>
  );
}

export default ToastContainer;
