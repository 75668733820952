import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const ButtonLink = styled(Link)`
  text-decoration: none;
  padding: ${({ theme }) => theme.sizes.s8} ${({ theme }) => theme.sizes.s32};
  word-wrap: none;
  transition: .3s ease;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.radius};
  font-size: ${({ theme }) => theme.sizes.s16};
  color: ${({ theme }) => theme.grayBackground};

  ${({ color, theme, disabled }) => color === 'blue' && css`
    background-color: ${disabled ? theme.grayLight : theme.blue} !important;
    color: ${({ theme }) => theme.grayBackground};

    &:hover {
      background-color: transparent !important;
      border-color: ${disabled ? theme.grayLight : theme.blue} !important;
      color: ${disabled ? theme.grayLight : theme.blue};
    }
  `}

  ${({ color, theme, disabled }) => color === 'yellow' && css`
    background-color: ${disabled ? theme.grayLight : theme.yellow} !important;
    color: ${theme.grayBackground};

    &:hover {
      background-color: transparent !important;
      border-color: ${disabled ? theme.grayLight : theme.yellow};
      color: ${disabled ? theme.grayLight : theme.yellow};
    }
  `}

  ${({ color, theme, disabled }) => color === 'green' && css`
    background-color: ${disabled ? theme.grayLight : theme.green} !important;
    color: ${theme.grayBackground};

    &:hover {
      background-color: transparent !important;
      border-color: ${disabled ? theme.grayLight : theme.green};
      color:${disabled ? theme.grayLight : theme.green};
    }
  `}

  ${({ color, theme, disabled }) => color === 'red' && css`
    background-color: ${disabled ? theme.grayLight : theme.red} !important;

    &:hover {
      background-color: transparent;
      border-color: ${disabled ? theme.grayLight : theme.red};
      color: ${disabled ? theme.grayLight : theme.red};
    }
  `}

  ${({ color, theme, disabled }) => color === 'ghost' && css`
    background-color: ${disabled ? theme.grayLight : 'transparent'} !important;
    border-color: ${disabled ? theme.grayLight : 'transparent'};
    color: ${theme.grayLight};

    &:hover {
      background-color: transparent;
      border-color: ${disabled ? theme.grayLight : theme.grayLight};
      color: ${disabled ? theme.grayLight : theme.grayLight};
    }
  `}

  @media(max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &:hover {
    ${({ disabled, theme }) => disabled && css`
      background-color: ${disabled && theme.grayLight};
    `}

    ${({ color, theme }) => color === 'blue' && css`
      color: ${theme.blue};
      border: 1px solid ${theme.blue};
      background-color: transparent;
    `}

    ${({ color, theme }) => color === 'yellow' && css`
      color: ${theme.yellow};
      border: 1px solid ${theme.yellow};
      background-color: transparent;
    `}

    ${({ color, theme }) => color === 'green' && css`
      color: ${theme.green};
      border: 1px solid ${theme.green};
      background-color: transparent;
    `}

    ${({ color, theme }) => color === 'red' && css`
      color: ${theme.red};
      border: 1px solid ${theme.red};
      background-color: transparent;
    `}

    ${({ color, theme }) => color === 'ghost' && css`
      color: ${theme.grayLight};
      border: 1px solid transparent;
      background-color: transparent;
    `}
  }
`;
